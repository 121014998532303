import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  modalButton: {
    marginRight: "22px"
  },
  modalActions: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  content: {
    paddingTop: "0"
  },
  buttons: {
    margin: "0.5rem"
  },
  nextButton: {
    backgroundColor: "rgba(41, 19, 46, 0.95)",
    color: "white",
    "&:hover": {
      color: "rgba(41, 19, 46, 0.95)",
      backgroundColor: "white"
    }
  },
  cancelButton: {
    color: "rgba(41, 19, 46, 0.95)",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(41, 19, 46, 0.95)",
      color: "white"
    }
  },
  btns: {
    paddingLeft: "2rem",
    paddingRight: "2rem"
  },
  dialogTitle: {
    color: "#29132e",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "normal",
    borderBottom: "1px solid #d5d2d5",
    backgroundColor: "#f6f6f7",
    height: "60px",
    maxHeight: "60px"
  },
  dialogStyle: { maxHeight: "calc(100% - 140px)", width: "fit-content", maxWidth: "95%" },
  systemsDialogStyle: {
    width: "95%"
  },
  iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
  dialogHeader: {
      paddingLeft: 20,
      paddingRight: 13,
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

  },
  dialogContent: {
      flex: "1 1 auto",
      padding: 20,
      overflowY: "auto",
      paddingBottom: 0
    }
});

export default useStyles;
