import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((darkTheme: Theme) =>
  createStyles({
    buttonBack: {
      height: "53px",
      width: "192px",
      borderRadius: "4px",
      border: "1px solid rgba(255, 255, 255, 0.6)",
      fontFamily: "roboto",
      fontWeight: "bold",
      margin: "0",
      marginBottom: "10px",
      color: "rgb(255, 255, 255)",
      "& span": { fontSize: "14px" }
    },
    buttonReset: {
      minHeight: "53px",
      width: "192px",
      marginBottom: "10px"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "400px",
      margin: "1rem"
    },
    container: {
      display: "flex",
      flexDirection: "column"
    },
    content: {
      width: "640px",
      height: "450px",
      borderRadius: "6px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "row"
    },
    radio: {
      "& span": { color: "white" }
    },
    buttonLogin: {
      height: "53px",
      width: "480px",
      borderRadius: "4px",
      border: "none",
      fontFamily: "roboto",
      fontWeight: "bold",
      margin: "0",
      marginBottom: "10px",
      backgroundColor: "rgb(240, 83, 71)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        backgroundColor: "rgb(240, 83, 71)"
      },
      "& span": { fontSize: "14px" }
    },

    Login: {
      background: "white",
      MozFlexDirection: "column",
      flexDirection: "column",
      height: "100vh",
      display: "flex",
      fontSize: "1.5rem",
      color: "#29abe2"
    },

    logos: {
      justifyContent: "center",
      display: "flex",
      MozFlexDirection: "column",
      flexDirection: "column",
      alignItems: "center"
    },

    title: {
      fontSize: "22px",
      color: "rgb(255, 255, 255)",
      paddingBottom: "20px",
      paddingTop: "40px",
      lineHeight: "normal",
      letterSpacing: "normal"
    },

    input: {
      height: "53px",
      width: "354px",
      borderRadius: "4px",
      fontFamily: "roboto",
      fontSize: "15px",
      marginBottom: "10px",
      backgroundColor: "rgb(96, 56, 94)",
      color: "rgba(255, 255, 255, 0.7)"
    },

    overrideField: {
      "&>label": {
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.7)"
        }
      }
    },

    bottom: {
      height: "48px",
      opacity: "0.6",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff"
    },

    mailto: {
      color: "rgba(255, 255, 255, 0.9)"
    },

    button: {
      height: "53px",
      width: "354px",
      borderRadius: "4px",
      fontFamily: "roboto",
      fontWeight: "bold",
      fontSize: "15px",
      margin: "0",
      marginBottom: "10px",
      backgroundColor: "rgb(240, 83, 71)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        backgroundColor: "rgb(240, 83, 71)"
      }
    },

    splashScreen: {
      width: "100%",
      height: "100%",
      position: "fixed",
      background: "rgba(41, 19, 46, 0.95)",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      zIndex: 100
    },

    logo: {
      paddingBottom: "50px"
    },

    logoTitle: {
      height: "32px",
      width: "206px",
      paddingBottom: "40px"
    },

    forgotPassLink: {
      boxSizing: "border-box",
      color: "rgb(31, 44, 50)",
      cursor: "pointer",
      display: "inline",
      fontSize: "15px",
      fontWeight: 300,
      listStyleImage: "none, outside, none",
      outline: "rgb(31, 44, 50), none, 0px",
      padding: "5px, 12.5px, 12.5px, 12.5px",
      textAlign: "left",
      textDecoration: "none",

      textSizeAdjust: "100%",
      userSelect: "none",
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      "&:hover": {
        textShadow: "0px 0px 0.5px rgb(31, 44, 50)"
      }
    },

    CoolAutomationName: {
      fontSize: "50px",
      color: "white",
      marginTop: "8rem"
    },

    splashScreenImg: {
      marginTop: "10rem"
    },

    interactionSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      paddingBottom: "15rem"
    },

    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      MozFlex: "1",
      flex: "1",
      backgroundImage: "linear-gradient(rgb(58, 24, 58), rgb(22, 18, 32))"
    },

    forgotPassBtn: {
      color: "rgba(255, 255, 255, 0.7)",
      backgroundColor: "inherit"
    },

    newPasswordForm: {
      display: "inline-flex",
      flexDirection: "column"
    }
  })
);

export default useStyles;
