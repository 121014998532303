import { Close } from "@material-ui/icons";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import { OnGrey as PowerIcon } from "../../icons";
import ArrowIcon from "../../icons/BigArrowUp";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { SearchIcon } from "../../svgComponents";
import useStyle from "./Control.style";
import { GroupItem, GroupPanel, UnitPanel } from "./ControlComponents";
import { SensorPanel } from "./ControlComponents/SensorPanel";

import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@material-ui/core/";

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Control = (props: any) => {
  const classes = useStyle();

  const types = useStoreState((state) => state.types);
  const setActiveSetpoint = useStoreActions((state) => state.units.setUnitActiveSetpoint);
  const setActiveFanMode = useStoreActions(
    (state) => state.units.setUnitActiveFanMode
  );
  const setActiveSwingMode = useStoreActions(
    (state) => state.units.setUnitActiveSwingMode
  );
  const setActiveOperationMode = useStoreActions(
    (state) => state.units.setUnitActiveOperationMode
  );
  const setActiveOperationStatus = useStoreActions(
    (state) => state.units.setUnitActiveOperationStatus
  );
  const getSiteFullAddress = useStoreState(
    (state) => state.sites.getSiteFullAddress
  );
  const {addMessage} = useStoreActions(
    (action) => action.errorMessage
  );
  const getFullGroups = useStoreActions(
    (state) => state.groups.getFullGroups
  );

  const {
    fanModesMirror,
    operationStatusesMirror,
    operationModesMirror,
    swingModesMirror,
    temperatureScaleMirror
  } = useStoreState((state) => state);

  const user = useStoreState((state) => state.users.me);
  const {
    selections,
    getControlUnitsBySite
  } = useStoreState((state) => state.selections);

  const requestStatsRefresh = useStoreActions(
    (a) => a.units.requestStatsRefresh
  );
  const getCustomerSchedules = useStoreActions(
    (a) => a.schedules.getCustomerSchedules
  );
  const updateUnit = useStoreActions(
    (a) => a.units._storeUpdateUnit
  );

  const { allUnits } = useStoreState((s) => s.units);
  const { allSensors} = useStoreState((s) => s.sensors);
  const { changeSitePower } = useStoreActions((state) => state.sites);
  const { getTemperatureScaleDisplay } = useStoreState((s) => s.users);
  const getSite = useStoreState((state) => state.sites.getSite);

  const { temperatureScale = 1 } = user;
  const { operationStatuses, fanModes, operationModes, swingModes, unitTypes, unitSubTypes, sensorTypes, sensorMeasurementUnits } = types;
  const isCelsius = +temperatureScaleMirror?.celsius === +temperatureScale;
  const { siteId, customerId } = selections;

  const [allGroups, setAllGroups] = useState<any>("loading");
  const [searchValue, setSearchValue] = useState<any>("");
  const [fullSiteGroups, setFullSiteGroups] = useState<any>({});
  const [filteredUnitsGroups, setFilteredUnitsGroup] = useState<any>({});
  const [siteGroupsArray, setSiteGroupsArray] = useState<any>([]);
  const [filteredSiteGroupsArray, setFilteredSiteGroupsArray] = useState<any>([]);
  const [allSchedules, setAllSchedules] = useState<any>({});
  const [unitsTogroupsMapping, setUnitsToGroupsMapping] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>({
    groupId: "",
    unitId: "",
    sensorId: "",
    isGroup: true
  });
  const [externalTemp, setExternalTemp] = useState<any>({ C: null, F: null });
  const [openSchedulesPanel, handleSchedulesPanel] = useState<boolean>(false);
  const [siteOffset, setSiteOffset] = useState<number>(0);

  const onSelectItem = (siteId: any, itemSelectedIds: any) => {
    const lastSelectedItems = JSON.parse(localStorage.getItem("lastSelectedItemsPerSite") as string) || {};
    localStorage.setItem("lastSelectedItemsPerSite", JSON.stringify({ ...lastSelectedItems, [siteId]: itemSelectedIds }));
    handleSchedulesPanel(false);
    setSelectedItem(itemSelectedIds);
    //props.unitUpdateStatusUpdate(itemSelectedIds.unitId);
  };

  useEffect(() => {
    if (!customerId){
      return;
    }
    getCustomerSchedules(customerId).then((res: any) => setAllSchedules(res))
    .catch((err: any) => addMessage({message: err.message}));
  }, [customerId, temperatureScale]);

  useEffect(() => {

  getFullGroups().then((groups: any) => {
    let sitesMap: any = {};

    Object.keys(groups).forEach((groupId) => {

    const group = groups[groupId];
    const { id, units = {}, sensors = [], schedules, name }: any = group;
    const sensorsArray: any = [];
    const unitsArray: any = [];
    const unitsKeys: any = [];
    Object.values(units).forEach((unit: any) => {
      if (unit?.isVisible){
        const isRelatedServiceUnitHidden = (unit.serviceUnits || []).some((unitId: string) =>
        !allUnits[unitId]?.isVisible);
        if (!isRelatedServiceUnitHidden){
          unitsArray.push(unit);
          unitsKeys.push(unit.id);
        }
        }
    });
    Object.values(sensors).forEach((sensor: any) => {
      if (sensorTypes[sensor.type]?.enableView){
      sensorsArray.push(sensor);
    }
    });

    let groupSite = unitsArray[0]?.site || sensorsArray[0]?.site || "";
    const isMultiSiteGroup = unitsArray.some((unit: any) => unit.site !== groupSite) || sensorsArray.some((sensor: any) => sensor.site !== groupSite);

    if (isMultiSiteGroup){
      return;
    }

    if (!sitesMap[groupSite])
    {
      sitesMap = {...sitesMap, [groupSite]: {[id]: {id, name, schedules, units: unitsKeys, sensors: sensorsArray}}};
  }else{

      sitesMap[groupSite] =  {...sitesMap[groupSite], [id]: {id, name, schedules, units: unitsKeys, sensors: sensorsArray}};
  }
  });
    setAllGroups(sitesMap);
}).catch((err: any) => {
  setAllGroups({});
  addMessage({message: err.message});
});

}, []);
  const changeSearchValue = (event: any) => {
    const {
      target: { value }
    } = event;
    setSearchValue(value);

    if (_.isEmpty(siteGroupsArray)){
      return;
    }

    if (!value) {
      setFilteredSiteGroupsArray(siteGroupsArray);
      setFilteredUnitsGroup({...fullSiteGroups});
      return;
    }
    const {groupsObjectF, groupsArrayF} = filterUnits(value, siteGroupsArray, fullSiteGroups);
    setFilteredUnitsGroup(groupsObjectF);
    setFilteredSiteGroupsArray(groupsArrayF);
  };

  const filterUnits = (value: string, siteGroupsArray: any, fullSiteGroups: any) => {
    const lowCaseValue = value.toLowerCase();
    let newFilteredData = Object.create(filteredUnitsGroups);
    let filteredGroupsArray: any = [];

    siteGroupsArray.forEach((group: any) => {
      const groupId = group[0];
      const { units } = fullSiteGroups[groupId];
      const unitsMap = new Map();
      let atleastOneUnitMatch = false;

      units.forEach((unit: any) => {
        if ((allUnits[unit[0]]?.name).toLowerCase().includes(lowCaseValue))
        {
          atleastOneUnitMatch = true;
          unitsMap.set(unit[0], true);
          return;
        }
        unitsMap.set(unit[0], false);
      });
      const groupMatchSearchValue: any = (fullSiteGroups[groupId]?.name || "").toLowerCase().includes(lowCaseValue);
      newFilteredData[groupId] = {...newFilteredData[groupId], units :  Array.from(unitsMap)};
      (atleastOneUnitMatch || groupMatchSearchValue) && filteredGroupsArray.push([groupId, atleastOneUnitMatch ? false : true]);
    });

    return {groupsObjectF: newFilteredData, groupsArrayF: filteredGroupsArray };
  };

  useEffect(() => {
    if (!selectedItem.unitId) {
      return;
    }
    requestStatsRefresh({id: selectedItem.unitId});
  }, [selectedItem.unitId]);

  useEffect(() => {
    setFilteredSiteGroupsArray([]);
    setSelectedItem({
      unitId: "",
      sensorId: "",
      groupId: "",
      type: "",
      isGroup: false
    });
    props.unitUpdateStatusUpdate("control");
    handleSchedulesPanel(false);

    if (!siteId || allGroups === "loading") {
      return;
    }
    const sensors = Object.values(allSensors).filter((sensor: any) => sensor.site === siteId && sensorTypes[sensor.type].enableView);
    const storedUnitOrders = JSON.parse(localStorage.getItem("storedUnitOrders") as string) || {};
    const specificSiteUnitOrder = storedUnitOrders[siteId] || {};
    const allUnitsOrder = new Map(specificSiteUnitOrder["allUnits"] || []);

    const units: any = getControlUnitsBySite(siteId, allUnitsOrder);

    if (_.isEmpty(units) && _.isEmpty(sensors)) {
      return;
    }

    const timezone = getSite(siteId)?.timezone || "";
    const offset = moment().tz(timezone).utcOffset();
    setSiteOffset(offset);

    const weatherAPIUrl = `https://api.worldweatheronline.com/premium/v1/weather.ashx?key=875fe1d8d3354a12b93211840200905&q=${getSiteFullAddress(
      siteId
    )}&format=json&num_of_days=1`;

    fetch(weatherAPIUrl)
      .then((res) => res.json())
      .then((res) => {
        const { data: { weather } } = res;

        if (!weather || _.isEmpty(weather)) {
          setExternalTemp({ C: null, F: null });
          return;
        }
        const today = weather[0];
        setExternalTemp({C: today.avgtempC || null, F: today.avgtempF || null});
      })
      .catch(() => setExternalTemp({ C: null, F: null }));

    const lastSelectedItems = JSON.parse(localStorage.getItem("lastSelectedItemsPerSite") as string) || {};
    const storedSite = lastSelectedItems[siteId] || {};
    const { unitId, groupId, isGroup = true } = storedSite;
    const storedGroupsOrders = JSON.parse(localStorage.getItem("storedGroupsOrders") as string) || {};
    const specificSiteGroupOrder = new Map(storedGroupsOrders[siteId] || []);

    let groups: any = {};
    let unitsTogroups: any = {};
    const groupsMap: any = new Map();
    Object.values(allGroups[siteId] || {}).forEach((group: any) => {
      const { id = "", units = [] } = group;
      const storedOrderedUnitsMap = new Map(specificSiteUnitOrder[id] || []);
      const currentGroupUnitsMap = new Map();

      units.forEach((unitId: string) => {
        currentGroupUnitsMap.set(unitId, true);
        storedOrderedUnitsMap.set(unitId, true);

        if (!unitsTogroups[unitId]){
          unitsTogroups[unitId] = [id];
        }else{
          unitsTogroups[unitId] = [...(unitsTogroups[unitId] || []), id];
        }
      });
      Array.from(storedOrderedUnitsMap).forEach((unit: any) => (!allUnits[unit[0]] || !currentGroupUnitsMap.has(unit[0])) && storedOrderedUnitsMap.delete(unit[0]));
      groups[id] = { ...group, units: Array.from(storedOrderedUnitsMap) };
      groupsMap.set(id, true);
      specificSiteGroupOrder.set(id, true);
    });

    groups = {...groups,  allUnits: { name: t`All Units`, id: "allUnits", units, sensors: [] }};
    if (sensors.length !== 0){
      groups = {...groups, sensors: { name: t`All Sensors`, id: "sensors" , units: [], sensors }};
      groupsMap.set("sensors", true);
      specificSiteGroupOrder.set("sensors", true);
    }
    groupsMap.set("allUnits", true);
    specificSiteGroupOrder.set("allUnits", true);
    Array.from(specificSiteGroupOrder).forEach((group: any) => !groupsMap.has(group[0]) && specificSiteGroupOrder.delete(group[0]));

    const {groupsObjectF, groupsArrayF} = searchValue ?  filterUnits(searchValue, Array.from(specificSiteGroupOrder), groups) : {groupsObjectF: groups, groupsArrayF: Array.from(specificSiteGroupOrder) };

    const storedGroupStillExist = groups.hasOwnProperty(groupId);
    const bothSelectedIdsStillMatch = unitId
      ? storedGroupStillExist && new Map(groups[groupId]?.units).has(unitId)
      : storedGroupStillExist;

    setSelectedItem(bothSelectedIdsStillMatch ? { ...storedSite, firstTimeLoading: true }
    : {unitId: "", isGroup: true, groupId: Array.from(specificSiteGroupOrder)[0][0] || "", firstTimeLoading: true});
    //props.unitUpdateStatusUpdate(bothSelectedIdsStillMatch ? storedSite.unitId : "");

    setFilteredUnitsGroup(groupsObjectF);
    setFilteredSiteGroupsArray(groupsArrayF);
    setFullSiteGroups(groups);
    setSiteGroupsArray(Array.from(specificSiteGroupOrder));
    setUnitsToGroupsMapping(unitsTogroups);

  }, [siteId, allGroups]);

  const onDragEnd = (result: any) => (groupId: any = "") => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder = reorder(
      !groupId ? filteredSiteGroupsArray : filteredUnitsGroups[groupId]?.units,
      result.source.index,
      result.destination.index
    );
    if (!groupId) {
      setFilteredSiteGroupsArray(newOrder);
      const currentStored = JSON.parse(localStorage.getItem("storedGroupsOrders") as string) || {};
      const currentSiteId = siteId || "";

      if (!currentStored[currentSiteId]){
        currentStored[currentSiteId] = [];
      }
      currentStored[currentSiteId] = newOrder;
      localStorage.setItem("storedGroupsOrders", JSON.stringify(currentStored));
      return;
    }

    filteredUnitsGroups[groupId].units = newOrder;
    setFilteredUnitsGroup({ ...filteredUnitsGroups });
    const currentStored = JSON.parse(localStorage.getItem("storedUnitOrders") as string) || {};
    const currentSiteId = siteId || "";

    if (!currentStored[currentSiteId]){
      currentStored[currentSiteId] = {};
    }
    currentStored[currentSiteId] = {...currentStored[currentSiteId], [groupId]: newOrder};
    localStorage.setItem("storedUnitOrders", JSON.stringify(currentStored));

  };

  const addItemSchedule = (isGroup: boolean, itemId: any, scheduleId: any) => {
  if (isGroup){
    allGroups[itemId]?.schedules?.push(scheduleId);
    allGroups[itemId] && setAllGroups(allGroups);
    fullSiteGroups[itemId]?.schedules?.push(scheduleId);
    fullSiteGroups[itemId] && setFullSiteGroups({...fullSiteGroups});
    return;
  }
  const unit = allUnits[itemId];
  unit && updateUnit({id: unit.id, data: {...unit, schedules: [...unit.schedules, scheduleId]} });
};

  const deleteItemSchedule = (isGroup: boolean, itemId: any, scheduleId: any) => {
  if (isGroup) {
    if (allGroups[itemId]){
    const group = allGroups[itemId];
    group.schedules = group?.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
    setAllGroups({...allGroups});
  }
    const group1 = fullSiteGroups[itemId];
    group1.schedules = group1?.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
    setFullSiteGroups({...fullSiteGroups});
    return;
  }

  const unit = allUnits[itemId];
  const schedules = unit?.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
  unit && updateUnit({ id: unit.id, data: { ...unit, schedules } });

};

  const NoSiteSelected = () => (
    <Grid
      container={true}
      direction={"column"}
      className={classes.noContentContainer}
    >
      <div className={classes.grayOval}>
        <ArrowIcon className={classes.arrowIcon} />
      </div>
      <Typography className={classes.noUnitText}>
        {t`Please select a site using the above filters.`}
      </Typography>
    </Grid>
  );

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["Control"]}
          customGeneralNames={{ site: "select Site" }}
          hideSystemSelection
          hideUnitSelection
          hideOutdoor
        />
        <div id="pageContent" className={classes.pageContentBox}>
          {!siteId ? (
            <NoSiteSelected />
          ) : (
            <div id="cardsHolder" className={classes.cardsHolder}>

              <div id="itemsContainer" className={classes.leftSideContainer}>
                <div id="searchHeader" className={classes.searchBar}>
                  <Input
                    placeholder="Search Unit"
                    value={searchValue}
                    onChange={changeSearchValue}
                    disableUnderline={true}
                    classes={{ root: classes.inputRoot }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    endAdornment={
                      searchValue && (
                        <IconButton
                          onClick={() => {setSearchValue("");
                                          setFilteredSiteGroupsArray(siteGroupsArray);
                                          setFilteredUnitsGroup({...fullSiteGroups});
                                         }}
                          className={classes.closeIconStyle}
                        >
                          <Close />
                        </IconButton>
                      )
                    }
                  />
                  <Button
                    disableRipple
                    className={classes.powerOffButton}
                    onMouseUp={() => changeSitePower({ state: 2, siteId })}
                    startIcon={<PowerIcon className={classes.powerIconStyle} />}
                  >
                    {t`ALL OFF`}
                  </Button>
                </div>
                <div className={classes.itemsWrapper}>
                  <DragDropContext
                    onDragEnd={(result: any) => onDragEnd(result)("")}
                  >
                    <Droppable droppableId="droppable" type="groupsDropDown">
                      {(provided: any) => (
                        <div ref={provided.innerRef}>
                          {filteredSiteGroupsArray.map(
                            (item: any, index: number) => (
                              <Draggable
                                key={`group-${item[0]}`}
                                draggableId={item[0]}
                                index={index}
                                isDragDisabled={searchValue ? true : false}
                              >
                                {(provided: any) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <GroupItem
                                      handleDragging={provided.dragHandleProps}
                                      key={`u-group-${item[0]}`}
                                      onDragEnd={onDragEnd}
                                      changeSitePower={changeSitePower}
                                      selectedItem={selectedItem}
                                      item={filteredUnitsGroups[item[0]]}
                                      siteId={siteId}
                                      selectItem={onSelectItem}
                                      hasSchedules={true}
                                      disableDragging={!!searchValue}
                                      addMessage={addMessage}
                                      openCollapse={item[1]}
                                      isCelsius={isCelsius}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
              <div className={classes.controlContainer}>
              {openSchedulesPanel ?
                <ScheduleList
                timezoneOffset={siteOffset}
                isGroup={selectedItem.isGroup}
                item={selectedItem.isGroup ? fullSiteGroups[selectedItem.groupId] : allUnits[selectedItem.unitId]}
                hideSchedulesPanel={() => handleSchedulesPanel(false)}
                allSchedules={allSchedules}
                setAllSchedules={setAllSchedules}
                deleteItemSchedule={deleteItemSchedule}
                addItemSchedule={addItemSchedule}
                unitGroups={selectedItem.isGroup ? [] : unitsTogroupsMapping[selectedItem.unitId] || []}
                allGroups={fullSiteGroups}
                />
                : <>
                {selectedItem.isGroup && fullSiteGroups[selectedItem.groupId] && (
                  <GroupPanel
                    group={fullSiteGroups[selectedItem.groupId]}
                    key={selectedItem.groupId}
                    user={user}
                    types={types}
                    navigateToSchedules={() => handleSchedulesPanel(true)}
                    tempSymbol={getTemperatureScaleDisplay()}
                    temperatureScale={temperatureScale}
                    operationStatusesMirror={operationStatusesMirror}
                    isCelsius={isCelsius}
                    addMessage={addMessage}
                    siteTemp={externalTemp}
                    siteId={siteId}
                    changeSitePower={changeSitePower}
                  />
                )}
                {selectedItem.type === "unit" && selectedItem.unitId && allUnits[selectedItem.unitId] && (
                  <UnitPanel
                    tempSymbol={getTemperatureScaleDisplay()}
                    siteTemp={externalTemp}
                    key={selectedItem.unitId}
                    types={{
                      operationStatuses,
                      fanModes,
                      operationModes,
                      swingModes,
                      unitSubTypes,
                      unitTypes
                    }}
                    unit={allUnits[selectedItem.unitId]}
                    setActiveSetpoint={setActiveSetpoint}
                    setActiveOperationMode={setActiveOperationMode}
                    setActiveFanMode={setActiveFanMode}
                    setActiveSwingMode={setActiveSwingMode}
                    setActiveOperationStatus={setActiveOperationStatus}
                    operationModesMirror={operationModesMirror}
                    fanModesMirror={fanModesMirror}
                    swingModesMirror={swingModesMirror}
                    operationStatusesMirror={operationStatusesMirror}
                    temperatureScale={temperatureScale}
                    isCelsius={isCelsius}
                    navigateToSchedules={() => handleSchedulesPanel(true)}
                    addMessage={addMessage}
                  />
                )}
                {selectedItem.type === "sensor" && allSensors[selectedItem.sensorId].type !== 0 && (
                  <SensorPanel
                    timezoneOffset={siteOffset}
                    key={selectedItem.sensorId}
                    tempSymbol={getTemperatureScaleDisplay()}
                    siteTemp={externalTemp}
                    types={{unitSubTypes, sensorTypes, sensorMeasurementUnits}}
                    sensor={allSensors[selectedItem.sensorId]}
                    isCelsius={isCelsius}
                  />
                )}
                </>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Control;
