import React from "react";

const SupportTicket = props => (
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="1em" height="1em" viewBox="0 0 22 22" {...props}>
    <defs>
        <filter id="lyd3to7t5a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
        </filter>
        <path id="lhg5zh0gxb" d="M0 1.088L19.8 1.088 19.8 14.445 0 14.445z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g filter="url(#lyd3to7t5a)" transform="translate(-25 -553) translate(25 553)">
                    <g transform="translate(1.1 .013)">
                        <mask id="7o7u709gsc" fill="#fff">
                            <use href="#lhg5zh0gxb"/>
                        </mask>
                        <path fill="#1D1D1B" d="M18.708 12.065c0 .71-.577 1.288-1.288 1.288h-.728V9.19h.728c.71 0 1.288.577 1.288 1.288v1.586zm-15.6 1.288h-.727c-.71 0-1.29-.578-1.29-1.288v-1.586c0-.711.58-1.288 1.29-1.288h.728v4.162zM17.632 8.12c-.126-3.898-3.328-7.032-7.258-7.032H9.4c-3.93 0-7.133 3.135-7.26 7.035C.944 8.245 0 9.248 0 10.48v1.586c0 1.312 1.068 2.38 2.38 2.38h.882c.518 0 .94-.421.94-.94V9.039c0-.52-.422-.94-.94-.94h-.028C3.372 4.812 6.081 2.18 9.4 2.18h.973c3.319 0 6.028 2.634 6.166 5.92-.518 0-.94.42-.94.94v4.466c0 .519.422.94.94.94h.88c1.313 0 2.381-1.068 2.381-2.38v-1.586c0-1.24-.956-2.25-2.169-2.359z" mask="url(#7o7u709gsc)"/>
                    </g>
                </g>
                <path fill="#FFF" d="M7.506 16.235c1.027.681 2.215 1.07 3.48 1.07 1.266 0 2.453-.389 3.48-1.069 2.633.508 4.638 2.771 4.755 5.521l.005.255H2.747c0-2.865 2.047-5.251 4.759-5.777zm3.48-10.685c2.296 0 4.159 1.862 4.159 4.158v1.237c0 2.297-1.863 4.158-4.158 4.158-2.296 0-4.158-1.861-4.158-4.158V9.708c0-2.296 1.862-4.158 4.158-4.158z" transform="translate(-25 -553) translate(25 553)"/>
            </g>
        </g>
    </g>
</svg>

);
export default SupportTicket;
