import { fade, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  navigationBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    color: "white",
    background: theme.palette.primary.dark,
    borderRight: "1px solid rgb(87, 69, 92)"
  },
  navLogo: {
    padding: 0,
    width: "100%",
    minHeight: "70px",
    maxHeight: "70px",
    display: "unset",
    color: "#fff"
  },
  navMenuItem: {
    color: "white",
    textTransform: "none",
    paddingRight: "3rem",
    paddingLeft: "0.8rem",
    opacity: 0.4
  },
  navMenuItemSelected: {
    borderRadius: 0,
    borderLeft: "3px solid rgb(240, 83, 71)",
    backgroundColor: theme.palette.primary.selected,
    opacity: 1,
    "&>svg": { opacity: 1 }
  },
  navIcon: {
    width: "11rem"
    // padding: "5px"
  },
  navIconUnused: {
    color: "white",
    opacity: 0.2
  },
  navIconSelected: {
    borderRadius: 0,
    borderLeft: "3px solid rgb(240, 83, 71)",
    backgroundColor: "rgb(87,69,92, 0.6)",
    opacity: 1,
    "&>svg": { opacity: 1 }
  },
  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    width: "100%",
    padding: "1.5rem",
    boxSizing: "border-box"
  },
  navItemTitle: {},
  versionText: {
    alignSelf: "center",
    margin: "auto 0 1rem",
    opacity: "0.5"
  },
  label: {
    paddingLeft: "12px",
    textAlign: "left",
    height: "41.45px"
  },
  playIcon:
  {
    transform: "scale(1.2)",
    marginLeft: "3px",
    marginRight: "13px",
    fill: fade("#fff", 0.6)
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    height: "70%",
    overflowY: "auto"
  }
}));

export default useStyles;
