import { css } from "@emotion/core";
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import * as Spinners from 'react-spinners';
import { loaderStyles } from './loader.style';

interface loaderProps {
  showLoader: boolean;
}

const Loader = (props: loaderProps) => {
  const { showLoader } = props;
  const useStyles = makeStyles(loaderStyles);
  const classes = useStyles();

  const Spinner = Spinners["MoonLoader" as keyof typeof Spinners];

  // Set spinner speed
  const override = css`animation-duration: 2s;`;

  return (
    <div className={clsx(classes.loaderWrapper, { [classes.hide]: !showLoader })}>
      <Spinner css={override} size={150} color={"#fff"} loading={true} />
    </div>
  );
};

export default Loader;
