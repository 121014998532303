import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    fontSize: "4rem",
    background: "coral",
    padding: "4rem",
    borderRadius: "10rem",
    marginBottom: "2rem"
  },

  personalDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px"
  },

  generateButton: {
    alignSelf: "center",
    padding: "0.6rem",
    marginTop: "5px"
  },

  customer: {
    margin: "0.5rem auto",
    paddingBottom: "1rem"
  },

  submit: {
    gridColumn: "2/3",
    justifyContent: "end"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  passwordTitle: {
    display: "flex",
    alignItems: "center"
  },
  operationModeContainer: {
    border: "1px solid #d5d2d5;",
    borderRadius: "6px",
    height: " 50px",
    padding: "5px 10px",
    "& label": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  unitsMeasurementContainer: {
    border: "1px solid #d5d2d5;",
    borderRadius: "6px",
    height: " 50px",
    padding: "5px 10px",
    "& label": {
      display: "flex",
      justifyContent: "space-between"
    },
    marginTop: "10px"
  },
  thumb: {
    backgroundColor: "#4b1c46"
  },
  track: {
    backgroundColor: "#d5d2d5"
  },
  tempFontStyle: {
    color: "#4b1c46"
  },
  language: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: " 1px solid #d5d2d5",
    marginTop: "1rem",
    paddingLeft: "1rem",
    borderRadius: "6px",
    maxWidth: "380px"
  },

  selectFormat: {
    width: "100%",
    maxWidth: "initial"
  },

  selectFormatMargin: {
    // marginBottom: "10px",
  },

  selectLang: {
    marginRight: "1rem",
    marginBottom: ".5rem",
    marginTop: "0 !important",
    border: "none",
    "&:before": {
      borderColor: "white"
    },

    "&:after": {
      borderColor: "white"
    },
    width: "15rem",
    "&>div": {
      margin: "none"
    }
  },
  itemTitle: {
    display: "flex",
    flex: 3,
    justifyContent: "flex-start"
  },
  itemSwtich: {
    display: "flex",
    flex: 1,
    justifyContent: "center"
  },
  switchContainer: {
    width: "auto",
    minWidth: "155px"
  },
  switchOptionText: {
    width: "70px",
    display: "flex",
    justifyContent: "center"
  }
}));

export default useStyles;
