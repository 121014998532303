import { Button, TextField, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import EditRow from "./EditRow";
import InfoRow from "./InfoRow";
import styles from "./unitEdits.styles";

const UnitsEdits = (props: any) => {
    const classes = styles();
    const [tree, setTree] = useState<any>({});
    const [allUnits, setUnits] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedUnit, setSelectedUnit] = useState<any>(null);
    const [anch, setAnch] = useState<any>(null);

    const getFullTree = useStoreActions((a) => a.getFullTree);
    const getUnits = useStoreActions((a) => a.units.getAllUnits);
    const updateUnit = useStoreActions((a) => a.units.updateUnit);
    const types = useStoreState((s) => s.types);

    const { unitTypes } = types;

    const saveUnit = () => {
        const { id, name, serialNumber = "", model = "", capacity = "" } = selectedUnit;
        updateUnit({ id, updatedData: { name, serialNumber: serialNumber || "", model: model || "", capacity } })
            .then(() => {
                setUnits({ ...allUnits, [selectedUnit.id]: selectedUnit });
                setSelectedUnit(null);
            });
    };

    const editRow = (e: any, unit: any) => {
        setSelectedUnit(unit);
        setAnch(e?.currentTarget || null);
    };

    useEffect(() => {

        Promise.all([getFullTree(), getUnits()])
            .then((resp: any) => {
                const [tree, units] = resp;
                setTree(tree);
                setUnits(units);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const { customers } = tree;
    const customersArr: any = customers && Object.values(customers);

    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header
                    path={["Units Edit"]}
                    hideCustomerSelection
                    hideSiteSelection
                    hideSystemSelection
                    hideUnitSelection
                />

                <div className={classes.content}>
                    {
                        customersArr && customersArr.length > 1 ? (
                            <Typography variant={"h1"}>
                                This Feature is not available for Global Admins
                            </Typography>
                        ) :
                            (!isLoading && customersArr && customersArr.length > 0 && (
                                Object.values(customersArr[0].sites).map((site: any, siteIndex: any) => {
                                    if (!site) {
                                        return null;
                                    }
                                    const { devices } = site;
                                    return (
                                        <div className={classes.box} key={`site-${site.id}`}>
                                            <div className={classes.boxTitle}>
                                                <Typography variant={"h4"}>
                                                    {`Site: ${site.name}`}
                                                </Typography>
                                            </div>
                                            <div className={classes.boxContent}>
                                                {
                                                    !_.isEmpty(devices) && Object.values(devices).map((device: any) => {
                                                        const { systems } = device;
                                                        return (
                                                            <div key={`device-${device.id}`} className={classes.box}>
                                                                <div className={classes.boxTitle}>
                                                                    <Typography variant={"h5"}>
                                                                        {`Device: ${device.name}`}
                                                                    </Typography>
                                                                </div>
                                                                <div className={classes.boxContent}>
                                                                    {
                                                                        !_.isEmpty(systems) && Object.values(systems).map((system: any) => {
                                                                            const { units: sysUnits } = system;
                                                                            return (
                                                                                <div className={classes.box} key={`sys-${system.id}`}>
                                                                                    <div className={classes.boxTitle}>
                                                                                        <Typography variant={"h6"}>
                                                                                            {`System: ${system.name}`}
                                                                                        </Typography>
                                                                                    </div>
                                                                                    <div className={classes.boxContent}>
                                                                                        {
                                                                                            sysUnits && sysUnits.length && sysUnits.map((unitId: any) => {
                                                                                                const unit: any = allUnits[unitId];
                                                                                                if (!unit || unit.type === unitTypes.indoor) {
                                                                                                    return null;
                                                                                                }
                                                                                                return (
                                                                                                    <div key={`unit-${unitId}`} className={classes.unitRow}>
                                                                                                        <div className={classes.unitRowContent}>
                                                                                                            <InfoRow
                                                                                                                unit={unit}
                                                                                                                isOpen={!false}
                                                                                                                setUnit={setSelectedUnit}
                                                                                                                edit={editRow}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <EditRow
                                                unit={selectedUnit || {}}
                                                isOpen={!!selectedUnit}
                                                setUnit={setSelectedUnit}
                                                save={saveUnit}
                                                anch={anch}
                                                setAnch={setAnch}
                                                editRow={editRow}
                                            />

                                        </div>
                                    );
                                })
                            ))
                    }

                </div>
            </div>
        </div>
    );
};

export default UnitsEdits;
