import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    marginTop: "15px",
    width: "100%"
  },
  alert: {
    display: "grid",
    width: "100%"
  },

  item: {
    padding: "0 0.8rem 0 0.8rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: "0.1rem"
  },
  infoTitle: {
    fontSize: "0.8125rem",
    color: "#29132e",
    fontFamily: "RobotoLight",
    marginRight: "1rem",
    width: 70
  },
  info: {
    fontSize: "0.8125rem",
    color: "#545964",
    fontFamily: "RobotoMedium",
    marginRight: "1rem"
  },
  button: {
    backgroundColor: "white",
    color: "rgba(41, 19, 46, 0.95)",
    marginTop: 12
  },
  itemButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  ulContainer: {
    display: "flex",
    width: "100%",
    minHeight: "180px"
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    maxHeight: "75px",
    maxWidth: '170px',
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical"
  }
});
export default useStyles;
