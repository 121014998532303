import { MuiThemeProvider, StylesProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import coolRemoteSDK, {
  Admin as sdkAdmin,
  AlertGroup as sdkAlertGroup,
  Compressor as sdkCompressor,
  Customer as sdkCustomer,
  Device as sdkDevice,
  Ecobee as sdkEcobee,
  Event as sdkEvent,
  File as sdkFile,
  Group as sdkGroup,
  Invite as sdkInvite,
  Notes as sdkNote,
  PowerMeter as sdkPowerMeter,
  Scheduler as sdkScheduler,
  Sensor as sdkSensor,
  Services as sdkService,
  Site as sdkSite,
  System as sdkSystem,
  Unit as sdkUnit,
  User as sdkUser,
  xhr as sdkXhr
} from "coolremote-sdk";
import { createStore, StoreProvider } from "easy-peasy";
import React from "react";
import RootStore from "./models/RootStore";
import Routes from "./screens/Routes/Routes";

// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
// import MomentUtils from '@date-io/moment';
// import LuxonUtils from '@date-io/luxon';

const store = createStore(RootStore, {
  injections: {
    coolRemoteSDK,
    sdkUser,
    sdkPowerMeter,
    sdkXhr,
    sdkDevice,
    sdkCustomer,
    sdkSite,
    sdkSensor,
    sdkInvite,
    sdkGroup,
    sdkUnit,
    sdkScheduler,
    sdkSystem,
    sdkEcobee,
    sdkAdmin,
    sdkAlertGroup,
    sdkService,
    sdkEvent,
    sdkCompressor,
    sdkNote,
    sdkFile
  }
});

export default function App() {
  return (
    <StoreProvider store={store}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Routes />
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </StoreProvider>
  );
}
