import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from "@material-ui/core";
import { any } from "bluebird";
import { FormikProps } from "formik";
import _, { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreState } from "../../../models/RootStore";
import UnitTableRow from "./UnitTableRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    tableContainer: {},
    headCell: {
      backgroundColor: "whitesmoke"
      // width: '40px'
    },
    headCellName: {
      backgroundColor: "whitesmoke"
    },
    headCellDelete: {
      backgroundColor: "whitesmoke",
      width: "5ch !important"
    },
    unitName: {
      display: "flex",
      "flex-direction": "row"
    },
    inputText: {
      width: "100%"
    },
    disabledTrue: {
      color: "grey"
    },
    systemActionsContainer: {
      display: "flex",
      "align-items": "flex-start"
    },
    dropDown: {
      width: "10rem"
    },
    tableWrapper: {
      // height: "470px",
      overflow: "auto",
      height: "calc(100%  - 7px)"
    },
    headCellType: {
      backgroundColor: "whitesmoke",
      width: "3rem"
    },
    headCellSystem: {
      backgroundColor: "whitesmoke",
      width: "6rem"
    },
    tableHeadCell: {
      borderBottom: "none",
      // width: "0ch",
      padding: "0 15px",

      color: "#79797d",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      backgroundColor: "#efeff2",
      textTransform: "uppercase",
      height: 45,
      fontSize: 13
    }
  })
);

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
  touched: any;
  values: any;
  deviceId: string;
  isDaikin: boolean;
  systemLine: number | null | string;
  lineIndex: number;
  handleUnitDeletion: (lineIndex: number, unitIndex: any) => void;
  removeHeight?: number | string;
  deviceSystems?: any;
  deviceControlUnits?: any;
  import?: boolean | undefined;
  setForceReRenderTable?: any;
  systemId?: any;
  isBrandHaier: (system: any) => boolean;
  isBrandMitsubishi: (system: any) => boolean;
}

const UnitsToSystemsMapTable: React.FC<IProps> = ({
  errors,
  touched,
  values,
  deviceId,
  isDaikin,
  systemLine,
  setFieldValue,
  lineIndex,
  handleUnitDeletion,
  removeHeight = "",
  deviceSystems,
  deviceControlUnits,
  systemId,
  // setForceReRenderTable,
  isBrandHaier,
  isBrandMitsubishi,
  ...restFormikProps
}) => {
  const classes = useStyles();
  const types = useStoreState((s) => s.types);
  const [forceReRender, setForceReRender] = useState<boolean>(false);
  const units = values.unitsChanges[lineIndex] || [];

  const checkRemoved = () => {
    for (let i = 0; i < units.length; i++) {
      if (units[i] && !units[i].isConnected) {
        return true;
      }
    }
    return false;
  };

  const countOtherUnits = () => {
    for (let i = 0; i < units.length; i++) {
      if (units[i] && units[i].type === 5) {
        return true;
      }
    }
    return false;
  };

  let isRemoved: boolean = checkRemoved();
  let hasOthers: boolean = countOtherUnits();

  const { unitTypes, systemTypes } = types;
  const bsBoxUnits: any = units.reduce((bsBoxes: any, unit: any) => {
    if (unit.type === unitTypes["bsBox"]) {
      bsBoxes.push({ value: unit.id, label: unit.name });
    }
    return bsBoxes;
  }, [{ value: null, label: t`Not Assigned` }]);

  useEffect(() => {
    if (forceReRender) {
      setForceReRender(false);
    }
  }, [forceReRender]);

  const handleUnitDeletionProxy = async (lineIndex: number, unitIndex: any) => {
    await handleUnitDeletion(lineIndex, unitIndex);

    // Because we update an internal Formik state object in a non-Formik way, React needs to know to re-render.
    setForceReRender(true);
  };

  const isMitsubishi = isBrandMitsubishi(values.brand);
  const isHaier = isBrandHaier(values.brand);

  return (
    <div className={classes.tableWrapper} style={{ height: removeHeight ? `calc(100% - ${removeHeight}px)` : "calc(100%  - 7px)" }}>
      <Table
        size="small"
        // stickyHeader
        // aria-label="sticky table"
        className={classes.tableContainer}
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            {(isRemoved || hasOthers) && (
              <TableCell
                classes={{ root: classes.tableHeadCell }}
                className={classes.headCellDelete}
                align="left"
              />
            )}
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCellName}
              align="left"
              style={{ width: "37ch", minWidth: "37px" }}
            >
              {t`Unit name`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "9ch", minWidth: "9ch" }}
            >
              {t`Type`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCellType}
              align="left"
              style={{ width: "16ch", minWidth: "16ch" }}
            >
              {t`Role`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "18ch", minWidth: "18ch" }}
            >
              {t`Model`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "18ch", minWidth: "18ch" }}
            >
              {t`SN`}
            </TableCell>
            {isDaikin && (<TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "10ch", minWidth: "10ch" }}
            >
              {t`Airnet# (internal#)`}
            </TableCell>)}

            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "10ch", minWidth: "10ch" }}
            >
              {isDaikin ? t`Group/Centralized` : t`Central ID`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "31ch", minWidth: "31ch" }}
            >
              {t`Capacity`}
            </TableCell>
            {
              isMitsubishi || isHaier ||
              systemTypes && systemTypes[values.type]  === "Heat Recovery" ?
                <TableCell
                  classes={{ root: classes.tableHeadCell }}
                  className={classes.headCell}
                  align="left"
                  style={{ width: "15ch", maxWidth: "15ch" }}
                >
                  {t`BS Box`}
                </TableCell> : <></>
            }
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCellSystem}
              align="left"
              style={{ width: "42ch", minWidth: "42ch", paddingLeft: "0 15px" }}
            >
              {"System"}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "9ch", minWidth: "9ch" }}
            >
              {t`Visible`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {units.map((unit: any, index: any) => {
            const isControl = unit.type === unitTypes.indoor;
            const isService = unit.type === unitTypes.service;
            const isOutdoor = unit.type === unitTypes.outdoor;
            const isOther = unit.type === unitTypes.other;
            return unit && (isOutdoor || isService || isOther)
              ? (
                <UnitTableRow
                  key={index}
                  // setForceReRender={setForceReRenderTable}
                  lineIndex={lineIndex}
                  index={index}
                  errors={errors}
                  touched={touched}
                  values={values}
                  isRemoved={isRemoved}
                  hasOthers={hasOthers}
                  isDaikin={isDaikin}
                  unit={unit}
                  setFieldValue={setFieldValue}
                  deviceId={deviceId}
                  deleteUnitFromContainer={handleUnitDeletionProxy}
                  deviceSystems={deviceSystems}
                  deviceControlUnits={deviceControlUnits}
                  bsBoxUnits={bsBoxUnits}
                  isOutdoor={isOutdoor}
                  isService={isService}
                  isControl={isControl}
                  isOther={isOther}
                  isMitsubishi={isMitsubishi}
                  isHaier={isHaier}
                  {...restFormikProps}
                />
              ) : null;
            // )
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default UnitsToSystemsMapTable;
