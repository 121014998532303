import {
  Button,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FastField, Field, FormikProps } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../../components/Delete/Delete";
import Checkbox from "../../../cool_widgets/CoolCheckbox";
import { Arrow as SvgArrow } from "../../../icons/";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import { IUnit } from "../../../models/Units";
import FixedInputField from "../FixedInputField";
import InputField from "../InputField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unitDisconnected: {
      background: "rgba(240, 81, 70, 0.2)"
    },
    iconDeleteUnit: {
      fontSize: "1rem",
      cursor: "pointer",
      color: "initial"
    },
    capacityItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& input": {
        fontSize: "14px"
      }
    },
    headCellType: {
      padding: "0",
      paddingLeft: "15px"
    },
    headCellSystem: {
      padding: "0"
    },
    headCell: {
      // backgroundColor: 'whitesmoke',
      // width: '40px'
    },
    arrowIcon: {
      top: 19.5,
      right: 10
    },
    removedUnit: {
      color: "red"
    },
    overrideMargin: {
       textAlign: "left",
       paddingRight: "25px !important",
       minWidth: "calc(100% - 39px)"
       },
    headCellAirnet: {
      padding: "0",
      "& div": {
        paddingLeft: "3px"
        // width: "5rem",
      }
    },
    headCellRole: {
      padding: "0",
      "& div": {
        fontSize: "14px",
        paddingLeft: "2px"
      }
    },
    wideCell: {
      // width: "15%",
      boxSizing: "border-box"
    },
    snModelWidth: {
      width: "18ch"
    },
    alignLeft: {
      textAlign: "left"
    },
    long30ch: {
      minWidth: "28ch"
    },
    padd15: {
      padding: "0 15px !important"
    },
    selectlongLength: {
      maxWidth: "26ch"
    },
    wid15chr: {
      maxWidth: "15ch"
    },
    wid10chr: {
      maxWidth: "10ch"
    },
    unAssingBtn: {
      textDecoration: "underline"
    }
  }));
const convertProIdToText = (proId: string) => {
  return proId[1] + proId[3] + proId[5];
};

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
  touched: any;
  values: any;
  unit: any;
  index: any;
  deviceId: any;
  isDaikin: any;
  lineIndex: number;
  isRemoved: boolean;
  deleteUnitFromContainer: (lineIndex: number, unitIndex: any) => void;
  deviceSystems?: any;
  deviceControlUnits?: any;
  bsBoxUnits?: any;
  isOutdoor: boolean;
  isService: boolean;
  isControl: boolean;
  isMitsubishi: boolean;
  isHaier: boolean;
  isOther: boolean;
  hasOthers: boolean;
}

const UnitTableRow: React.FC<IProps> = ({
  errors,
  touched,
  values,
  unit,
  index,
  deviceId,
  isDaikin,
  lineIndex,
  isRemoved,
  deleteUnitFromContainer,
  setFieldValue,
  deviceSystems,
  deviceControlUnits,
  bsBoxUnits,
  isOutdoor,
  isService,
  isControl,
  isMitsubishi,
  isHaier,
  isOther,
  hasOthers
}) => {
  const classes = useStyles();
  const allDeviceSystems = deviceSystems ?? useStoreState((state) => state.devices.getDeviceSystems(deviceId));
  const unassignUnitFromSystem = useStoreActions((action) => action.unassignUnitFromSystem);
  const getUnitType = useStoreState((s) => s.units.getUnitType);

  const types = useStoreState(
    (state) => state.types
  );
  const dissociateControlUnitFromServiceUnit = useStoreActions((action) => action.dissociateControlUnitFromServiceUnit);

  const { capacityMeasurementUnitTypes, unitTypes, systemTypes } = types;
  const indoorDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits(deviceId, "all", "indoor"));

  const allDeviceControlUnits = values.controlLine ? _.filter(indoorDeviceUnits, (unit: IUnit) => ((unit.line === values.controlLine) && (unit.type === unitTypes.indoor))) : _.filter(indoorDeviceUnits, (unit: IUnit) => ((unit.line === values.unitsChanges[lineIndex][index].line) && (unit.type === unitTypes.indoor)));

  const allDeviceBranchBoxUnits = bsBoxUnits ?? useStoreState((state) => state.devices.getDeviceUnits(deviceId, "all", "bsBox"));

  const unitRoles: any = { Unknown: 0, Master: 1, Slave: 2 };
  const deleteUnitFromContainerProxy = () => {
  return  deleteUnitFromContainer(lineIndex, index);
  };
  const unitConnected = (unit: IUnit) => {
    return !_.has(unit, "isConnected") || unit.isConnected;
  };

  let systems: any = [
    {
      value: 0,
      label: t`Not Assigned`
    }
  ];
  let controlUnits: any = [
    {
      value: 0,
      label: t`Not Assigned`
    }
  ];
  let branchBoxUnits: any = [
    {
      value: 0,
      label: t`Not Assigned`
    }
  ];

  _.filter(allDeviceSystems, (system) => system.line === values.unitsChanges[lineIndex][index].line).map(
    (system, index) =>
      systems.push({
        value: system.id,
        label: system.name
      })
  );
  allDeviceControlUnits.map(
    (controlUnit: any) =>
      controlUnits.push({
        value: controlUnit.id,
        label: controlUnit.address
      })
  );
  _.filter(allDeviceBranchBoxUnits, (unit) => unit.line === values.unitsChanges[lineIndex][index].line).map(
    (unit, index) =>
      branchBoxUnits.push({
        value: unit.id,
        label: unit.name
      })
  );
  return (
    <TableRow key={unit.id} className={unitConnected(unit) ? undefined : classes.unitDisconnected}>
      {(isRemoved || hasOthers) && (
        <TableCell align="center" style={{ padding: "0" }}>
          {(!unit.isConnected || isOther) && (
            <Delete
              type={t`unit`}
              object={unit}
              detach={deleteUnitFromContainerProxy}
              buttonClass={classes.iconDeleteUnit}
            />
          )}
        </TableCell>
      )}

      <TableCell align="center" style={{ padding: "0", paddingLeft: "15px" }} className={classes.wideCell}>
        <FastField
          name={`unitsChanges[${lineIndex}][${index}].name`}
          component={FixedInputField}
          disabled={!unit.isConnected}
          classes={{ disabled: !unit.isConnected ? classes.removedUnit : "", root: classes.long30ch }}
        />
      </TableCell>
      <TableCell align="center" className={classes.headCellType} style={{ width: "9ch" }}>
        {getUnitType(unit.id)}
        {unitConnected(unit) ? "" : " disconnected"}
      </TableCell>
      <TableCell align="center" style={{ padding: "0", width: "16ch", paddingLeft: "15px" }} className={classes.headCellRole}>
        {unit.type === 2 ? (
          <FastField
            label={""}
            name={`unitsChanges[${lineIndex}][${index}].task`}
            options={_.map(unitRoles, (value, key) => ({
              value,
              label: key
            }))}
            component={Select}
            variant="outlined"
            value={
              values.unitsChanges[lineIndex][index].task ?? unitRoles[Object.keys(unitRoles)[0]]
            }
            error={errors.task && touched.task ? true : false}
            helpertext={errors.role && touched.task ? errors.task : ""}
            classes={{ root: classes.alignLeft, icon: classes.arrowIcon }}
            IconComponent={SvgArrow}
            disabled={!unit.isConnected}
            margin="none"
            style={{ maxHeight: "44px", height: "44px" }}
            MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
          />
        ) : (
            <></>
          )}
      </TableCell>
      <TableCell align="center" style={{ padding: "0", paddingLeft: "15px" }} className={clsx(classes.wideCell, classes.snModelWidth)}>
        <FastField
          name={`unitsChanges[${lineIndex}][${index}].model`}
          component={InputField}
          disabled={!unit.isConnected}
        />
      </TableCell>
      <TableCell align="center" style={{ padding: "0", paddingLeft: "15px" }} className={clsx(classes.wideCell, classes.snModelWidth)}>
        <FastField
          name={`unitsChanges[${lineIndex}][${index}].serialNumber`}
          component={InputField}
          disabled={!unit.isConnected}
        />
      </TableCell>
      {isDaikin && (
        <TableCell align="left" style={{ padding: "0", paddingLeft: "15px", minWidth: "9ch" }}>
          <Typography>
            {isOutdoor || isService ? `${values.unitsChanges[lineIndex][index].airnet} (${values.unitsChanges[lineIndex][index].address})` : ""}
          </Typography>
        </TableCell>
      )}
      <TableCell align="center" className={clsx(classes.headCellSystem, classes.wideCell)} style={{ width: "30ch", maxWidth: "27ch", padding: "0", paddingLeft: "15px" }}>
        {unit.type === 3 || unit.type === 5 ? (
          <Field
            label={""}
            name={`unitsChanges[${lineIndex}][${index}].controlUnit`}
            options={controlUnits}
            component={Select}
            variant="outlined"
            value={values.unitsChanges[lineIndex][index].controlUnit ?? controlUnits[0].value}
            error={errors.controlUnits && touched.controlUnits ? true : false}
            helpertext={errors.controlUnits && touched.controlUnits ? errors.controlUnits : ""}
            classes={{ root: clsx(classes.overrideMargin, classes.wid10chr), icon: classes.arrowIcon }}
            IconComponent={SvgArrow}
            disabled={!unit.isConnected}
            style={{ height: "44px", maxHeight: "44px" }}
            margin="none"
            MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
          ></Field>
        ) : (
            <></>
          )}
      </TableCell>
      <TableCell align="center" style={{ padding: "0", width: "32ch", maxWidth: "32ch", paddingLeft: "15px" }} className={classes.wideCell}>
        <div className={classes.capacityItem} style={{ maxHeight: "56px" }}>
          <div style={{ width: "100%", paddingRight: "15px" }}>
            <FastField
              component={InputField}
              name={`unitsChanges[${lineIndex}][${index}].capacity`}
              variant="outlined"
              error={errors.capacity && touched.capacity ? true : false}
              helperText={errors.capacity && touched.capacity ? errors.capacity : ""}
              disabled={!unit.isConnected}
            />
          </div>
          <FastField
            label={""}
            name={`unitsChanges[${lineIndex}][${index}].capacityMeasurementUnits`}
            options={_.map(capacityMeasurementUnitTypes, (value, key) => ({
              value,
              label: key
            }))}
            component={Select}
            variant="outlined"
            value={
              values.unitsChanges[lineIndex][index].capacityMeasurementUnits ??
              capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]]
            }
            error={
              errors.capacityMeasurementUnits && touched.capacityMeasurementUnits ? true : false
            }
            helpertext={
              errors.capacityMeasurementUnits && touched.capacityMeasurementUnits
                ? errors.capacityMeasurementUnits
                : ""
            }
            classes={{ root: classes.alignLeft, icon: classes.arrowIcon }}
            IconComponent={SvgArrow}
            disabled={!unit.isConnected}
            margin="none"
            style={{ maxHeight: "44px", height: "44px" }}
            MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
          />
        </div>
      </TableCell>
      {
        isMitsubishi || isHaier ||
        systemTypes && systemTypes[values.type]  === "Heat Recovery" &&
        bsBoxUnits.length > 1 ?
          <TableCell align="center" className={clsx(classes.headCellSystem, classes.wideCell)} style={{ padding: "0", paddingLeft: "15px", width: "30ch", maxWidth: "27ch" }}>
            {unit.type === 3 ? (
              <FastField
                label={""}
                name={`unitsChanges[${lineIndex}][${index}].branchBoxUnit`}
                options={branchBoxUnits}
                component={Select}
                variant="outlined"
                value={values.unitsChanges[lineIndex][index].branchBoxUnit ?? 0}
                error={errors.branchBoxUnit && touched.branchBoxUnit ? true : false}
                helpertext={errors.branchBoxUnit && touched.branchBoxUnit ? errors.branchBoxUnit : ""}
                classes={{ root: clsx(classes.overrideMargin, classes.wid15chr), icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                disabled={!unit.isConnected}
                style={{ height: "44px", maxHeight: "44px" }}
                margin="none"
                MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
              ></FastField>
            ) : (
                <></>
              )}
          </TableCell>

          : <></>
      }

      <TableCell align="center" className={clsx(classes.headCellSystem, classes.wideCell)} style={{ padding: "0", paddingLeft: "15px", width: "30ch", maxWidth: "27ch" }}>
        <FastField
          label={""}
          name={`unitsChanges[${lineIndex}][${index}].system`}
          options={systems}
          component={Select}
          variant="outlined"
          value={values.unitsChanges[lineIndex][index].system ?? systems[0].value}
          error={errors.systems && touched.systems ? true : false}
          helpertext={errors.systems && touched.systems ? errors.systems : ""}
          classes={{ root: clsx(classes.overrideMargin, classes.selectlongLength), icon: classes.arrowIcon }}
          IconComponent={SvgArrow}
          disabled={!unit.isConnected}
          style={{ height: "44px", maxHeight: "44px" }}
          margin="none"
          MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        ></FastField>
      </TableCell>

      <TableCell align="center" className={classes.headCellType} style={{ width: "9ch", padding: "0 15px" }}>
        <FastField
          name={`unitsChanges[${lineIndex}][${index}].isVisible`}
          disabled={!unit.isConnected}
          // variant="outlined"
          component={Checkbox}
          checked={values.unitsChanges[lineIndex][index].isVisible}
          onChange={(e: any) => {
            setFieldValue(`unitsChanges[${lineIndex}][${index}].isVisible`, e.target.checked);
          }}
        />
      </TableCell>

    </TableRow >);

};

export default UnitTableRow;
