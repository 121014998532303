import { createStyles, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import Tooltip from "../../components/Tooltip/LightTooltip";
import {
  Alert as SvgAlert,
  Disconnect as SvgDisconnect,
  Refresh as SvgRefresh
} from "../../icons/";
import { IDevice } from "../../models/Devices";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import styles from "./DeviceCard.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceInfo: {
      padding: "1rem 1rem 0",
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      minWidth: "20.7rem",
      maxWidth: "22%",
      paddingLeft: 20
    },
    deviceInfoRow: {
      borderBottom: "1px solid rgb(213, 210, 213)",
      padding: "5px 0",
      justifyContent: "space-between"
    },
    deviceSubTitle: {
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      color: "rgb(84, 89, 100)",
      marginRight: ".5rem"
    },
    deviceSubTitleNotAssigned: {
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      width: "70%",
      display: "flex",
      alignItems: "center",
      color: "rgb(240, 81, 70)",
      "& span": {
        marginLeft: ".5rem"
      }
    },
    serialStyle: {
      fontWeight: "bold",
      color: "#29132e",
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      width: "50%",
      margin: "auto"
    },
    deleteSite: {
      color: "#bdb9b98a"
    },
    iconButtonStyleDeviceBox: {
      paddingRight: 0,
      padding: 5
    },
    alertIcon: {
      color: "rgb(240, 81, 70)",
      paddingRight: 5
    },
    errorText: {
      color: "rgb(240, 81, 70)"
    },
    cmntTitle: {
      display: "flex",
      alignItems: "center",
      minWidth: "50%"
    },
    cmntIcons: {
      display: "grid",
      gridAutoFlow: "column",
      gridGap: 8
    },
    cmnetContainer: {
      alignItems: "center",
      justifyContent: "space-between"
    },
    refreshIcon: {
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
    }
  })
);

const DeviceCard: React.FC<{ device: IDevice, allPowerMeters: any }> = ({ device, allPowerMeters }) => {
  const getDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const types = useStoreState((state) => state.types);
  const deleteDevice = useStoreActions((action) => action.deleteDevice);
  const refreshDevice = useStoreActions((action) => action.refreshDevice);

  // const numUnassigedOutdoorUnits = getDeviceUnits(device.id, "unassigned", "outdoor").length;
  // const numUnassigedServiceUnits = getDeviceUnits(device.id, "unassigned", "service").length;
  // const numUnassigedUnits = numUnassigedOutdoorUnits + numUnassigedServiceUnits;
  // const numOutdoorUnits = getDeviceUnits(device.id, "all", "outdoor").length;
  // const numIndoorUnits = getDeviceUnits(device.id, "all", "indoor").length;
  // const numServiceUnits = getDeviceUnits(device.id, "all", "service").length;
  const [isLoading, setIsLoading] = useState(false);
  const [sensors, setSensors] = React.useState<any>({});
  // const numDisconnectedIndoorUnits = getDeviceUnits(device.id, "disconnected", "indoor").length;
  // const numDisconnectedOutdoorUnits = getDeviceUnits(device.id, "disconnected", "outdoor").length;
  const numDisconnectedServiceUnits = getDeviceUnits(device.id, "disconnected", "service").length;

  const { unitTypes } = types;

  useEffect(() => {
    const sensorUnits = Object.values(allUnits).filter((unit: any) => unit.type === unitTypes["sensor"] && unit.device === device.id);
    setSensors(sensorUnits);
  }, [allUnits]);

  const counts:
    {
      service: number;
      outdoor: number;
      bsBox: number;
      disconnectedService: number;
      disconnectedOutdoor: number;
      disconnectedBsBox: number;
      unassingedUnits: number;
      unMappedControlUnits: number;
    }
    = {
    service: 0,
    outdoor: 0,
    bsBox: 0,
    disconnectedService: 0,
    disconnectedOutdoor: 0,
    disconnectedBsBox: 0,
    unassingedUnits: 0,
    unMappedControlUnits: 0
  };

  for (const unit of Object.values(allUnits)) {
    if (unit.device === device.id) {

      if (unit.type === unitTypes["bsBox"]) {
        counts.bsBox++;
        if (!unit.system) {
          counts.unassingedUnits++;
        }
        if (!unit.isConnected) {
          counts.disconnectedBsBox++;
        }
        continue;
      }

      if (unit.type === unitTypes["service"]) {
        counts.service++;
        if (!unit.system) {
          counts.unassingedUnits++;
        }
        if (!unit.isConnected) {
          counts.disconnectedService++;
        }
        continue;
      }

      if (unit.type === unitTypes["outdoor"]) {
        counts.outdoor++;
        if (!unit.system) {
          counts.unassingedUnits++;
        }
        if (!unit.isConnected) {
          counts.disconnectedOutdoor++;
        }
        continue;
      }

      if (unit.type === unitTypes["indoor"]) {
        if (unit.serviceUnits?.length === 0 && unit.otherUnits?.length === 0) {
          counts.unMappedControlUnits++;
        }
        continue;
      }
    }
  }

  const classes = useStyles();

  const fetchData = async () => {
    setIsLoading(true);

    try {
      await refreshDevice({ id: device.id });
    } catch (err) {
      //console.log('fetch err', err);
    }

    setIsLoading(false);
  };
  const ppd = Object.values(allPowerMeters).filter(
    (pm: any) => pm.device === device.id
  );
  return (
    <Grid item xs={3} className={classes.deviceInfo}>
      <Grid container direction="column" className={classes.deviceInfoRow}>
        <Grid container wrap={"nowrap"} className={classes.cmnetContainer}>
          <div className={classes.cmntTitle}>
            <Typography
              className={classes.deviceSubTitle}
              style={{
                color: "#545964 !important"
                // minWidth: "96.63px"
                // serial style remove 50% // the other p
              }}
            >{t`CA Device`}</Typography>
            <Typography className={classes.serialStyle}>{device.serial}</Typography>
          </div>
          <div className={classes.cmntIcons}>
            {!device.isConnected ? <SvgDisconnect style={{ fontSize: "1.8rem" }} /> : <></>}
            <Tooltip title={t`Update Device Systems`}>
            <IconButton
              disableRipple
              disabled={!device.isConnected}
              aria-label="refresh icon"
              onClick={fetchData}
              className={classes.refreshIcon}
            >
              <SvgRefresh
                className={isLoading ? styles.refreshStyleLoading : styles.refreshStyle}
              />
            </IconButton>
            </Tooltip>
            <Delete
              type={t`Device`}
              object={device}
              detach={deleteDevice}
              className={classes.deleteSite}
              buttonClass={classes.iconButtonStyleDeviceBox}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.deviceInfoRow}>
        <Typography className={classes.deviceSubTitle}>{t`Outdoor Units:`}</Typography>
        <Typography>
          {counts.outdoor}
          {counts.disconnectedOutdoor !== 0 && (
            <span className={classes.errorText}> ({counts.disconnectedOutdoor} disconnected)</span>
          )}
        </Typography>
      </Grid>

      <Grid container alignItems="center" className={classes.deviceInfoRow}>
        <Typography className={classes.deviceSubTitle}>{t`Indoor Units:`}</Typography>
        <Typography>
          {counts.service}
          {counts.disconnectedService !== 0 && (
            <span className={classes.errorText}> ({counts.disconnectedService} disconnected)</span>
          )}
        </Typography>
      </Grid>
      {counts.bsBox > 0 &&
        (
          <Grid container alignItems="center" className={classes.deviceInfoRow}>
            <Typography className={classes.deviceSubTitle}>{t`BS Box Units:`}</Typography>
            <Typography>
              {counts.bsBox}
              {counts.disconnectedBsBox !== 0 && (
                <span className={classes.errorText}> ({counts.disconnectedBsBox} disconnected)</span>
              )}
            </Typography>
          </Grid>
        )
      }
      {counts.unassingedUnits > 0 &&
        <Grid
          container
          alignItems="center"
          className={classes.deviceInfoRow}
        >
          <Typography className={classes.deviceSubTitleNotAssigned}>
            <SvgAlert />
            <span>{t`Units Not Assigned:`}</span>
          </Typography>
          <Typography>{counts.unassingedUnits}</Typography>
        </Grid>
      }
      {counts.unMappedControlUnits > 0 &&
        (
          <Grid container alignItems="center" className={classes.deviceInfoRow}>
            <Typography className={classes.deviceSubTitle}>{t`Unmapped Control IDs:`}</Typography>
            <Typography>{counts.unMappedControlUnits}</Typography>
          </Grid>
        )
      }
    </Grid>
  );
};

export default DeviceCard;
