import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import "../../icons.css";
import assetsService from "../../services/assetsService";
import useStyles from "./Login.style";

const ResetSuccess: React.FC<any> = (props) => {
    const [isLoginBack, setIsLoginBack] = useState(false);

    const classes = useStyles();

    const handleBack = () => {
        setIsLoginBack(true);
    };

    // const handleEnter = (event: any) => {
    //     const code = event.keyCode || event.which;
    //     if (code === 13) {
    //         handleSetPassword();
    //     }
    // };
    if (isLoginBack) {
        return (<Redirect to={"/"} />);
    }

    return (
        <div className={classes.Login}>
            <div className={classes.main}>
                <div className={classes.logos}>
                    <img
                        alt="logo"
                        src={assetsService.getResourcePath("logo.png")}
                        className={classes.logo}
                    />
                </div>

                <div className={classes.interactionSection}>
                    <div className={classes.resetSuccessContainer}>
                        <Typography
                            className={classes.title}
                        >{t`Your password has been successfully changed`}</Typography>
                        <Button
                            title="Login"
                            className={classes.buttonReset1}
                            onClick={handleBack}
                            red
                            uppercase
                            bold
                        >
                            {t`Login`}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetSuccess;
