import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    wrapper: {
        backgroundColor: "white",
        height: "100%"
    },
    title: {
        fontSize: "1.25rem",
        fontFamily: "RobotoMedium",
        display: "inline-block",
        color: "rgb(75, 28, 70)"
    },
    tableHead: {
        background: "rgb(236,235,239,0.5)",
        color: "rgb(84,89,100)"
    },
    elevation: {
        boxShadow: "none"
    },
    root: {
        /* width: '100%',
        height: '100% */
        height: "calc(100% - 20px)"
        /* padding: '1rem */
    },

    root_padding: {
        padding: "1rem"
    },

    titleUnit: {
        fontSize: "1.25rem",
        padding: "1rem",
        fontFamily: "RobotoMedium",
        color: "#4b1c46"
    },

    showAllAlertsWrapper: {
        marginRight: "0",
        alignSelf: "center",
        "& span": {
            fontSize: "0.875rem"
        }
    },

    tableCell: {
        fontSize: "0.75rem",
        color: "rgb(84, 89, 100)"
        // width: '80px'
        /* padding: '6px 10px */
        /* width: '100% */
    },

    container: {
        overflow: "auto",
        maxHeight: "calc(100% - 20px)"

    },
    unitsCellContainer: {
        display: "flex"
    },
    table: {
        overflow: "auto",
        height: "100%"
    },
    tableHeadCell: {
        borderBottom: "none",
        cursor: "default",
        minWidth: "7.69%",
        width: "129px",
        maxWidth: 0,
        padding: "8px 15px",
        height: "45px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    headCellContainer: {
        height: "100%"
        // maxWidth: '2rem',
    },
    blueFilter: { fill: "#1976d2" },
    filterStyle: { marginLeft: "10px" },
    headCellTitle: {
        display: "block"
    },
    headCellUnitContainer: {
        display: "flex"
        // maxWidth: '2rem',
    },
    // bodyCell: {
    //     maxWidth: '5rem',
    // }
    unitHeadCell: {
        borderBottom: "none",
        cursor: "default",
        width: "7.69%",
        maxWidth: "0ch",
        wordBreak: "break-word",
        minWidth: "30ch",
        padding: "0 15px",
        height: "45px"
    },
    unitCell: {
      whiteSpace: "nowrap",
      maxWidth: "25ch",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: 13
    },
    selectedRowStyle: {
        backgroundColor: "#f4f4f4"
    },
    headerMultiLine: {
      whiteSpace: "pre-wrap",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 3,
      lineHeight: 1.3,
      wordBreak: "break-word",
      maxWidth: "99px"
    },
    hiddenTitle: {visibility: "hidden",
                  height: 0,
                  maxWidth: "99px"
                 },
    maxWidth: {width: 0}
}));

export default useStyles;
