/* global google */
/// <reference types="googlemaps" />

import SelectInput from "@material-ui/core/Select/SelectInput";
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import alertSeverities from "../../constants/alertSeverities";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import AlertInfoWindow from "./AlertInfoWindow";
const severties: any = alertSeverities;

export interface IAlertMarker {
  id: number;
  site: ISite;
  pos: google.maps.LatLng | any;
  color: string;
  severity: number;
  siteAlerts: any;
}

const AlertsMap: React.FC = () => {
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = useState<IAlertMarker[]>([]);
  const [infoOpen, setInfoOpen] = useState<string | undefined>(undefined);
  const allSites = useStoreState((s) => s.selections.getSitesBySelection);
  const selections = useStoreState((s) => s.selections.selections);
  const getAlertsByFilter = useStoreState((state) => state.alerts.getAlertsByFilter);
  const openParsedAlerts = useStoreState((state) => state.alerts.openParsedAlerts);
  const getSiteFullAddress = useStoreState((s) => s.sites.getSiteFullAddress);
  const setSelections = useStoreActions((s) => s.selections.setSelections);
  const updateSite = useStoreActions((action) => action.updateSite);
  const [shouldCenterMap, setShouldCenterMap] = useState<boolean>(true);
  const [currentSiteId, setCurrentSiteId] = useState<string>("");
  const { eventStatusTypes } = useStoreState((state) => state.types);
  const getAlertsByFilters = useStoreActions((actions) => actions.alerts.getAlertsByFilters);

  const { siteId, customerId, isFromMap } = selections;

  const currentSites = siteId
    ? Object.values(allSites).filter((site) => site.id === siteId)
    : allSites;

  const closeInfoWindow = () => {
    setInfoOpen(undefined);
  };

  const onAlertClick = (marker: any) => {
    const siteId = marker.site.id;
    // Cancel option to auto reset map (see one of the useEffect for usage)
    setShouldCenterMap(false);
    setSelections({
      siteId,
      isFromMap: true
    });

    // Set's map to position of clicked marker
    if (mapRef) {
      const lat = marker.pos.lat;
      const lng = marker.pos.lng;
      mapRef.setCenter({ lat, lng });
    }

    const filter: any = { siteId };
    const hasAlerts = !!getAlertsByFilter(filter).length;

    if (hasAlerts) {
      setInfoOpen((infoOpen) => (infoOpen === siteId ? undefined : siteId));
    }
  };

  const onMapLoad = (map: google.maps.Map) => {
    // Remember map reference
    setMapRef(map);
  };

  // Update markers in accordance with selected sites
  const updateMarkers = () => {
    setMarkers([]);

    const geoCoder = new google.maps.Geocoder();
    for (let id = 0; id < currentSites.length; id++) {
      const site = currentSites[id];
      const siteAlerts: any = [];
      getAlertsByFilter({ siteId: site.id }).forEach((alert: any) => siteAlerts.push(alert));
      let maxAlertSeverity = severties["ALL_IS_WELL"]?.severity;

      if (siteAlerts.length) {
        maxAlertSeverity = _.reduce(
          siteAlerts,
          (prevMax, alert: any) => _.max([prevMax, alert.severity?.severity]),
          severties["ALL_IS_WELL"]?.severity
        );
      }

      if (site.lat && site.lon) {

        setMarkers((markers) => [
          ...markers,
          {
            id,
            site,
            siteAlerts,
            pos: { lat: site.lat, lng: site.lon },
            color: _.keyBy(severties, "severity")[maxAlertSeverity].color,
            severity: maxAlertSeverity
          }
        ]);

      }

      else {
        setTimeout(
          function() {
            geoCoder.geocode({ address: getSiteFullAddress(site.id) }, (results, status) => {

              if (status === "OK") {

                updateSite({
                  siteId: site.id, updatedData:
                    { lat: results[0].geometry.location.lat(), lon: results[0].geometry.location.lng() }
                })
                  .catch((err: any) => console.log(err));

                setMarkers((markers) => [
                  ...markers,
                  {
                    id,
                    site,
                    siteAlerts,
                    pos: results[0].geometry.location,
                    color: _.keyBy(severties, "severity")[maxAlertSeverity].color,
                    severity: maxAlertSeverity
                  }
                ]);

              }

            }); //end geocoding

          }, 200 * id);
      }//end

    }//end of loop

  };

  // Fit map bounds according to markers
  const fitMapToMarkers = () => {
    if (mapRef) {
      const bounds = new google.maps.LatLngBounds();
      if (bounds) {
        _.map(markers, (marker) => {
          bounds.extend(marker.pos);
        });
        mapRef.fitBounds(bounds);
        // mapRef.setCenter(bounds.getCenter());
        mapRef.panToBounds(bounds);
      }
    }
  };

  // Update markers as soon as we get map reference
  // and when site selection changes
  useEffect(() => {
    // If user clicked mark - don't update markes -this will update map
    if (!shouldCenterMap) {
      return;
    }

    if (mapRef) { updateMarkers(); }
  }, [mapRef, siteId, customerId, isFromMap, openParsedAlerts]);

  useEffect(() => {
    const newId = selections.siteId || selections.customerId;
    if (newId && currentSiteId !== newId) {
      // On each site change enable again reseting marks
      setShouldCenterMap(true);
      setCurrentSiteId(newId);
    }

    // handle clicked same marker twise
    if (currentSiteId === newId && !shouldCenterMap) {
      setShouldCenterMap(true);
    }
    // Closing any open info about any marker
    setInfoOpen(undefined);
  }, [selections]);

  // Fit map as soon as we get new markers
  useEffect(() => {
    if (mapRef) { fitMapToMarkers(); }
  }, [markers]);

  const onMapCenterChanged = () => {
    // if (mapRef) {
    //   const newCenter = mapRef.getCenter();
    //   if (newCenter.lat() !== center.lat && newCenter.lng() !== center.lng)
    //     setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    // }
  };

  // Load the Google maps scripts
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBJIN2UpLEwA0WENCrw5eahjzwq7aMzRBY",
    language: "en"
  });

  return (
    <Fragment>
      {isLoaded ? (
        <GoogleMap
          id="alerts-map"
          mapContainerStyle={{ height: "100%", width: "auto", borderRadius: 4 }}
          onLoad={onMapLoad}
          onCenterChanged={onMapCenterChanged}
          options={{
            minZoom: 3,
            maxZoom: 16,
            zoomControl: true, // +/- zoom buttons
            disableDefaultUI: true,
            styles: [
              {},

              {
                featureType: "poi",
                stylers: [{ visibility: "off" }]
              },
              {
                featureType: "administrative",
                stylers: [{ visibility: "simplified" }]
              },
              {
                featureType: "transit",
                stylers: [{ visibility: "off" }]
              },
              {
                featureType: "road",
                stylers: [{ visibility: "simplified" /*'off'*/ }]
              }
            ]
          }}
        >
          {_.map(markers, (marker, id) => (
            <Fragment key={id}>
              <Marker
                position={marker.pos}
                onClick={() => {
                  onAlertClick(marker);
                }}
                animation={google.maps.Animation.DROP}
                icon={{
                  url:
                    "data:image/svg+xml;charset=utf-8," +
                    encodeURIComponent(
                      marker.severity
                        ? `<svg viewBox="0 0 220 220" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="50" cy="50" r="50" stroke="none" fill="${marker.color}" fill-opacity="20%" />
                          <circle cx="50" cy="50" r="30" stroke="none" fill="${marker.color}" fill-opacity="30%" />
                          <circle cx="50" cy="50" r="10" stroke="none" fill="${marker.color}" fill-opacity="40%" />
                          </svg>`
                        : `<svg viewBox="0 0 220 220" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="30" stroke="none" fill="${marker.color}" fill-opacity="30%" />
                            <circle cx="50" cy="50" r="10" stroke="none" fill="${marker.color}" fill-opacity="40%" />
                            </svg>`
                    ),
                  size: new google.maps.Size(200, 200),
                  scaledSize: new google.maps.Size(128, 128),
                  anchor: new google.maps.Point(29, 30)
                }}
              />
              {infoOpen === marker.site.id && (
                <InfoWindow
                  position={marker.pos}
                  onCloseClick={closeInfoWindow}
                  options={{ maxWidth: 500 }}
                >
                  <AlertInfoWindow marker={marker} />
                </InfoWindow>
              )}
            </Fragment>
          ))}
        </GoogleMap>
      ) : (
          "Loading map..."
        )}
    </Fragment>
  );
};

export default AlertsMap;
