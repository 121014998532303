import React from 'react';
import { FieldProps } from 'formik';
import { TextField, FormControl, makeStyles, FilledInputProps } from '@material-ui/core';
import { FilledTextFieldProps } from '@material-ui/core/TextField';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  input: {
    width: '354px',
    '& label': {
      color: 'rgba(255, 255, 255, 0.7)'
    },
    '& .Mui-focused': {
      // color: 'red'
      color: 'rgba(255, 255, 255, 0.7)'
    },
    '& div': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    '& .MuiFilledInput-input': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontSize: '20px'
    },
    '& .MuiFormLabel-filled': {
      color: 'rgba(255, 255, 255, 0.7)'
    },
    '& :-webkit-autofill': {
      '-webkit-text-fill-color': 'rgba(255, 255, 255, 0.7) !important',
      '-webkit-box-shadow': '0 0 0 30px #5d4e61 inset !important'
    },
    '& .MuiFilledInput-underline:before': {
      'border-bottom': 'none',
      content: 'none'
    },
    '& .MuiFilledInput-underline:after': {
      'border-bottom': 'none',
      content: 'none'
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      color: 'rgba(255, 255, 255, 0.7)',
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    '&:-internal-autofill-selected': {
      color: 'rgba(255, 255, 255, 0.7)'
    }
  },
  margin: {
    'margin-top': '0.5rem',
    'margin-bottom': '0.5rem',
    'margin-right': '1.2rem',
    'margin-left': '1.2rem'
  },
  textField: {
    width: '200'
  }
}));
const InputField: React.FC<FieldProps & FilledTextFieldProps & FilledInputProps> = ({
  field,
  meta,
  form,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
      <TextField
        error={props.error}
        helperText={props.helperText}
        {...field}
        {...props}
        margin="dense"
        variant="filled"
        className={classes.input}
        // color='secondary'
      />
    </FormControl>
  );
};

export default InputField;
