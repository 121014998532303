import { purple } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const defaultColors = {
  background: {
    default: "white",
    dark: "#29132e", //'rgba(66, 26, 64, 1)',
    light: "#5c465d" //'rgba(87, 69, 92, 0.6)'
  },
  text: {
    default: "#545964",
    contrast: "white",
    themedLight: "#29132e",
    themedDark: "#4b1c46"
  },
  primary: { main: "rgba(66, 26, 64, 1)", light: "rgba(66, 26, 64, .85)" },
  secondary: {
    main: "rgba(240, 83, 71, 1)",
    light: "rgba(240, 83, 71, 0.85)"
  },
  default: {
    text: "#545964",
    main: "#ffffff",
    light: "#ffffff"
  }
};

const theme = createMuiTheme({
  palette: {
    background: { paper: "#fff", default: "#efeff2" },
    primary: {
      main: defaultColors.primary.main,
      dark: defaultColors.background.dark,
      light: defaultColors.background.light
    },
    secondary: {
      main: defaultColors.secondary.main
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: "6px"
      }
    },
    MuiButton: {
      root: {},
      outlined: {
        border: "solid 1px #d5d2d5",
        boxShadow: "none",
        color: defaultColors.default.text,
        backgroundColor: defaultColors.default.main,
        "&:hover": {
          backgroundColor: defaultColors.default.light
        }
      },
      contained: {
        border: "solid 1px #d5d2d5",
        boxShadow: "none",
        color: defaultColors.default.text,
        backgroundColor: defaultColors.default.main,
        "&:hover": {
          backgroundColor: defaultColors.default.light
        }
      },
      outlinedPrimary: {
        color: defaultColors.text.themedDark,
        backgroundColor: defaultColors.background.default,
        "&:hover": {
          backgroundColor: defaultColors.background.dark
        }
      },
      containedPrimary: {
        color: defaultColors.text.contrast,
        backgroundColor: defaultColors.primary.main,
        "&:hover": {
          backgroundColor: defaultColors.primary.light
        }
      },
      containedSecondary: {
        color: defaultColors.text.contrast,
        backgroundColor: defaultColors.secondary.main,
        "&:hover": {
          backgroundColor: defaultColors.secondary.light
        }
      }
    },

    MuiCheckbox: {
      root: {
        color: "#d5d2d5",
        backgroundColor: "transparent",
        "&$checked": {
          color: "#736973"
        },
        "&:hover": {
          backgroundColor: "transparent"
        }
      },
      colorPrimary: {
        color: "#d5d2d5",
        backgroundColor: "transparent",
        "&$checked": {
          color: "#5c465d"
        },
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    }
  },
  typography: {
    subtitle1: {
      fontWeight: 300
    }
  }
});

export default theme;
