import {
  Dialog,
  Drawer,
  IconButton,
  makeStyles,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import {
  Close, SwitchOff as SvgSwitchOff,
  SwitchOn as SvgSwitchOn,
} from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { toC, toF } from "../../services/converter";
import ErrorBox from "../WarnningBox/ErrorBox";
import styles from "./AddEditScript.style";
import Condition from "./Condition";
import conditionUtils from "./conditionUtils";
import { Box, CustomedTextField, CustomSelect, SystemWrapper, UnitWrapper } from "./CustomedComponents";
import ItemsTree from "./ItemsTree";

const defaultScript = { command: "", type: "", value: "", proceedOnFailure: true };

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { customerId, close, brand, procedureId, onSave, selectedUnits } = props;

  const types = useStoreState((s) => s.types);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  // const { } = types;

  const [brandSystems, setBrandSystems] = useState<any>({});
  const [controlUnits, setControlUnits] = useState<any>({});
  const [systemsToSitesMap, setSystemsToSitesMap] = useState<any>({});
  const [customers, setCustomers] = useState<any>({});

  useEffect(() => {
    getBrandSystems(brand);
  }, []);

  const getBrandSystems = (brand: any) => {
    let systemsMappedToSites: any = {};
    let customers: any = { [customerId]: { ...allCustomers[customerId] } };
    let systems: any = {};
    let currentUnits: any = {};

    for (const system in allSystems) {
      const { brandNum, site } = allSystems[system];

      if (!brand || (!_.isUndefined(brandNum) && brandNum === brand)) {
        if (systemsMappedToSites[site]) {
          systemsMappedToSites[site][system] = true;
        } else {
          systemsMappedToSites[site] = { [system]: true };
        }

        const fullSystem: any = { ...allSystems[system], unitsObject: {} };
        const { units } = fullSystem;

        if (units.length === 0) {
          systems[system] = fullSystem;
          continue;
        }

        for (const i in units) {
          const unitId = units[i];
          const unit = allUnits[unitId];

          if (!unit) {
            continue;
          }
          if (!unit.isVisible) {
            continue;
          }
          if (unit.type === 2 || unit.type === 1) {
            continue;
          }

          if (unit.type === 3 && unit.controlUnit && allUnits[unit.controlUnit]) {
            // unit.id = unit.controlUnit;
            fullSystem.unitsObject[unit.id] = unit;
          }
          currentUnits[unit.id] = unit;
        }
        systems[system] = fullSystem;
      }
    }

    setCustomers(customers);
    setControlUnits(currentUnits);
    setBrandSystems(systems);
    setSystemsToSitesMap(systemsMappedToSites);
  };

  const saveSelectedItems = (units: any, systems: any) => {
    onSave(procedureId, units, systems);
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.treeDialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Apply to`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close color="#7f7692" /></IconButton>
      </div>
              <ItemsTree
                allSystems={brandSystems}
                sitesWithSystems={systemsToSitesMap}
                allSites={allSites}
                customers={customers}
                allUnits={allUnits}
                onSave={saveSelectedItems}
                close={close}
                previouslySelectedUnitsIds={selectedUnits}
              />
    </Dialog>
  );
};
