import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { Close } from "../../icons";
import { Add, Refresh as SvgRefresh } from "../../icons/";
import { EditIcon } from "../../logos";
import { useStoreState } from "../../models/RootStore";
import EditParamPopUp from "./EditParamPopUp";
import styles from "./UnitStatsTable.module.css";
import useStyles from "./UnitStatsTable.style";

export default function UnitStatsTable(props: any) {
  const {
    isLoading,
    onRefresh,
    updateTime,
    autoupdate,
    setAutoupdate,
    endTime,
    isToday,
    paramsTable,
    numOfShowing,
    showHideAllParams,
    showHideParam,
    unit,
    reFetchData,
    timezone,
    updateParamRow
  } = props;

  const classes = useStyles();

  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedRow, setEditedRow] = useState<any>(null);
  const [editedRowIndex, setEditedRowIndex] = useState<any>(null);
  const [values, setValues] = useState<any>({});

  useEffect(() => {
    if (isLoading && !_.isEmpty(values)) {
      setValues({});
    }

  }, [isLoading]);

  const openParamsList = (event: any) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editParam = (row: any, index: number) => {
    if (row.editable) {
      setEditedRow(row);
      setEditedRowIndex(index);

      setOpenEditDialog(true);
    }
  };
  const closeDialog = () => {
    setOpenEditDialog(false);
    setEditedRow(null);
  };
  const setNewValue = (newValue: any, index: number) => {
    let temp = { ...values };
    temp[index] = newValue;
    setValues(temp);
  };
  const disableUpdateButtons = !isToday(new Date(moment(endTime).tz(timezone).format("llll")));
  const numOfParams = Object.values(paramsTable)?.filter((param: any) => !!param)?.length;
  const allShowed = numOfParams === numOfShowing;
  const someShowed = !allShowed && (numOfShowing !== 0);
  const dateTime = `${dateFormat} ${timeFormat}`;

  return (
    <Paper className={classes.root}>

      <div className={classes.header}>
        <div className={classes.parametersHeader}>
          <Typography variant="h6" className={classes.title}>{t`Parameters`}</Typography>
          <IconButton disableRipple className={clsx(classes.iconBtnStyle, classes.addIcon, someShowed && classes.pushedAddIcon)} onClick={openParamsList} id="open-unit-params">
            <Add disabled={isLoading} />
          </IconButton>
        </div>
        <div className={classes.autoUpdateContainer}>
          <FormControlLabel
            classes={{ label: classes.autoRefreshLabel, root: classes.autoUpdate }}
            control={
              <Checkbox
                color="default"
                classes={{ root: clsx(classes.removePaddingRight, classes.grayCheckbox) }}
                checked={autoupdate}
                disabled={disableUpdateButtons}
                onChange={() => setAutoupdate(!autoupdate)}
                value="checkedA"
              />
            }
            label="AUTO UPDATE"
          />
          <Typography variant="body2" className={classes.lastUpdatedTitle}>
            {updateTime
              ? t`Last updated at:` + ` ${moment(updateTime).tz(timezone).format(dateTime)}`
              : t`No unit data `}
          </Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} aria-label="refresh icon" onClick={onRefresh ?? _.noop} disabled={autoupdate || disableUpdateButtons}>
            <SvgRefresh className={isLoading ? styles.refreshStyleLoading : styles.refreshStyle} />
          </IconButton>
        </div>
      </div>

      {!isLoading && (<Popover
        id="unitParamsListPopup"
        disableAutoFocus={false}
        disableEnforceFocus={false}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Edit Parameters list`}</Typography>
          <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>

        {(true || updateTime) ? <List className={classes.paramsPopupList} >
          <ListItem disableGutters dense button onClick={(event: any) => showHideAllParams(event.target.checked, paramsTable)}>

            <Checkbox
              color="default"
              checked={allShowed}
              classes={{ root: classes.grayCheckbox }}
            />

            <ListItemText id={`checkbox-text-all`} primary={t`All`} />
          </ListItem>

          {_.orderBy(Object.values(paramsTable), ["plottable", "name"], ["desc", "asc"]).map((row: any, index: number) => {
            if (!row) {
              return null;
            }
            const isSelected = row?.isShowing;
            return (
              <ListItem key={`param-show-${index}`} disableGutters dense button onClick={(event: any) => showHideParam(row.code, paramsTable)}>
                <Checkbox
                  color="default"
                  checked={isSelected}
                  classes={{ root: classes.grayCheckbox }}
                />
                <ListItemText id={`checkbox-text-${index}`} primary={row.name} />
              </ListItem>

            );
          })}
        </List>
          : <Typography style={{ padding: "20px 15px", fontSize: "18px" }}>{t`No parameters for this unit`}</Typography>
        }
      </Popover>)}

      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <colgroup>
            <col style={{ width: "20px" }} />
            <col style={{ width: "fit-content" }} />
            <col style={{ width: "43px" }} />
            <col style={{ width: "3rem" }} />
            <col style={{ width: "auto", minWidth: "10rem" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>

              <TableCell
                className={classes.tableCellAlignLeft}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Name`}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Value`}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Value range`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <EditParamPopUp row={editedRow} open={openEditDialog} unit={unit} onClose={closeDialog}
              setValue={setNewValue} reload={reFetchData} editedRowIndex={editedRowIndex}
              newValue={values[editedRowIndex]} />
            {!isLoading && paramsTable && _.orderBy(Object.values(paramsTable), ["plottable", "name"], ["desc", "asc"])?.map((row: any, index: number) => {
              if (!row) {
                return null;
              }
              const {
                isShowing,
                enumVal,
                name,
                hvac_param_name,
                value,
                measurementUnits,
                code,
                isChecked,
                slider,
                plottable,
                editable,
                techInfo
              } = row;
              if (!isShowing) {
                return null;
              }
              const displayedValue = enumVal ? enumVal : value;
              return (

                <TableRow hover className={clsx(!plottable && classes.tableRowUnplottable)} role="checkbox" tabIndex={-1} key={`graphData-${index}`}>
                  <TableCell
                    className={classes.tableCell}
                    style={{
                      paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                    }}
                  >
                    {plottable ?
                      <Checkbox
                        color="default"
                        checked={isChecked}
                        onChange={(event: any) => {
                          updateParamRow(code);
                        }}
                        classes={{ root: classes.grayCheckbox }}
                        disabled={isLoading}
                      />
                      :
                      < span />}
                  </TableCell>

                  <Tooltip
                    arrow
                    title={techInfo ? `${techInfo}` : `${name}`}
                    placement="top-start"
                    classes={{ popper: classes.popper }}
                  >
                    <TableCell
                      style={{ backgroundColor: "white" }}
                      className={
                        clsx(classes.tableCellAlignLeft, classes.nameCell)}
                    >
                      {name}

                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    arrow
                    title={techInfo ? `${techInfo}` : `${hvac_param_name}`}
                    placement="top-start"
                    classes={{ popper: classes.popper }}
                  >
                    <TableCell
                      style={{ backgroundColor: "white" }}
                      className={
                        clsx(classes.tableCellAlignLeft, classes.smallCell)}
                    >
                      {hvac_param_name}

                    </TableCell>
                  </Tooltip>

                  <TableCell
                    className={classes.tableCell}
                    style={{
                      paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                    }}
                  >
                    {editable ?
                      <div className={classes.tableCellValue}>
                        <IconButton onClick={() => editParam(row, index)}><EditIcon /></IconButton>
                        {values[index] ? values[index] : displayedValue}
                      </div>
                      : displayedValue}
                  </TableCell>

                  <TableCell
                    className={classes.tableCellAlignLeft}
                    style={{
                      paddingLeft: "15px", minWidth: "auto", backgroundColor: "white"
                    }}
                  >
                    {measurementUnits}
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    style={{
                      paddingLeft: "auto", minWidth: "200px", backgroundColor: "white"
                    }}
                  >
                    {slider}
                  </TableCell>
                </TableRow>
              );
            })}

          </TableBody>
        </Table>
      </div>

    </Paper >
  );

}
