import { CircularProgress, DialogActions, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear, CompassCalibrationOutlined, CropLandscapeOutlined } from "@material-ui/icons";
import clsx from "clsx";
import _, { isNull } from "lodash";
import React from "react";
import { t } from "ttag";
import * as XLSX from "xlsx";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { Close } from "../../icons/";
import SVGDownload from "../../icons/Download";

import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import { countries } from "./countryList";
import { deviceProps, deviceSchema, siteProps, siteSchema, systemProps, systemSchema, unitExcelSchema, unitProps, unitSchema } from "./ExcelHelpers";
import ImportModel from "./ImportModel";
import useStyles from "./SiteView.style";

export default function ImportExcel(props: any) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [steps, setSteps] = React.useState<number>(1);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
    const updateUnit = useStoreActions((action) => action.units.updateUnit);
    const assignUnitToSystem = useStoreActions((action) => action.assignUnitToSystem);
    const [allErrors, setAllErrors] = React.useState<any>({ sitesErrors: [], unitsErrors: [], devicesErrors: [], systemsErrors: [], generalErrors: [] });
    const [importInfo, setImportInfo] = React.useState<any>({ sites: props.site ? [props.site] : [], units: [], unitsToSave: [], devices: [], systemsToSave: [], detectedSystems: [], detectedSystemsIds: [] });
    const capacityMeasurementUnitTypes = useStoreState(
        (state) => state.types.capacityMeasurementUnitTypes
    );
    const getDeviceBySerial = useStoreState((state) => state.devices.getDeviceBySerial);
    const getDevicesBySite = useStoreState((state) => state.devices.getDevicesBySite);
    const getCustomerByName = useStoreState((state) => state.customers.getCustomerByName);
    const getDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits);

    const hvacBrands = useStoreState((state) => state.types.hvacBrands);
    const getUserTree = useStoreActions((action) => action.getTreeImport);

    const getSystems = useStoreActions((action) => action.systems.getSystems);
    const updateSystem = useStoreActions((action) => action.systems.updateSystem);
    const addNewDeviceToSite = useStoreActions((action) => action.createDevice);
    const createSite = useStoreActions((action) => action.createSite);
    const associateControlUnitToServiceUnit = useStoreActions((action) => action.associateControlUnitToServiceUnit);
    const dissociateControlUnitFromServiceUnit = useStoreActions((action) => action.dissociateControlUnitFromServiceUnit);
    const handleClose = () => {
        setImportInfo({ sites: [], units: [], unitsToSave: [], devices: [], systemsToSave: [], detectedSystems: [], detectedSystemsIds: [] });
        setAllErrors({ sitesErrors: [], unitsErrors: [], devicesErrors: [], systemsErrors: [], generalErrors: [] });
        setIsOpen(false);

    };
    const update = async () => {
        const errors = { ...allErrors };
        errors.systemsErrors = [];
        const allSystems: any = [];
        for (let system of importInfo.systemsToSave) {
            const systemId = system.id;
            delete system.id;
            delete system.device;

            await updateSystem({
                systemId,
                updatedData: system
            })
                .then((data: any) => {
                    allSystems.push(data);
                })
                .catch((err: any) => {
                    // errors.systemsErrors.push(err);
                });
            // }
        }
        for (let unit of importInfo.unitsToSave) {
            const system: any = _.find(allSystems, function (o: any) { return (unit && o.name == unit.system && o.device === unit.device && o.line === unit.line); });
            if (system && system.id) {
                assignUnitToSystem({ systemId: system.id, unitId: unit.id }).then(() => {
                }
                ).catch(() => {
                    errors.unitsErrors.push(`error assigning unit ${unit.address} to system`);
                });
            } else {
                if (unit.system) {
                    errors.unitsErrors.push(`error assigning unit ${unit.address} to system ${unit.system} please select system with same line and device as the unit`);
                }
                else {
                    errors.unitsErrors.push(`error in unit ${unit.address} no system selected`);
                }
            }
        }
        await getUserTree();

        setAllErrors(errors);
        return errors;
    };
    const updateLocal = (id: any, index: number) => {
        const temp: any = { ...importInfo };
        temp.systemsToSave[index].id = id;

        setImportInfo(temp);

    };
    const removeSystem = (name: string) => {
        const temp: any = { ...importInfo };
        const index = _.findIndex(temp.systemsToSave, function (o: any) { return o.name == name; });
        temp.systemsToSave.splice(index, 1);
        setImportInfo(temp);

    };
    const create = (data: any) => {
        const temp: any = { ...importInfo };
        const newSystem = {
            name: data.name,
            brandNum: data.brand,
            line: data.line,
            series: data.series ? data.series : "",
            type: data.type ? data.type : "",
            capacity: data.capacity ? parseInt(data.capacity) : 0,
            capacityMeasurementUnits:
                data.capacity && data.capacityMeasurementUnits
                    ? data.capacityMeasurementUnits
                    : capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]]
        };
        temp.systemsToSave.push(newSystem);
        setImportInfo(temp);
    };

    const handleCapture = async (e: any) => {
        setIsOpen(true);
        setIsDisabled(true);
        e.preventDefault();
        const validExts = ".xlsx";
        const validCsv = ".csv";

        let fileExt = e.target.files[0].name;
        fileExt = fileExt.substring(fileExt.lastIndexOf("."));
        let allImportErrors: any = _.cloneDeep(allErrors);

        if (validExts !== fileExt && validCsv !== fileExt) {
            allImportErrors.generalErrors.push(`wrong file format`);
            setAllErrors(allImportErrors);
            setIsDisabled(false);
        }
        else {
            let header: any[] = [];
            for (let i = 0; i <= 12; i++) {
                header.push(i);
            }
            e.preventDefault();
            let files = e.target.files, f = files[0];
            let reader = new FileReader();
            reader.onload = async function (e) {
                let data = e?.target?.result;
                let readedData = XLSX.read(data, { type: "binary" });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dataParse: any = XLSX.utils.sheet_to_json(ws, { header });
                let systemIndex = -1;
                let unitIndex = -1;
                let deviceIndex = -1;
                let firstSiteIndex = null;
                let lastSiteIndex = -1;
                let allImportInfo: any = _.cloneDeep(importInfo);
                let site = props.site ? props.site : "";
                // TODO make sure the indexes are found(error if not)
                for (let i = 0; i < dataParse.length; i++) {
                    if (_.includes(Object.keys(siteProps), Object.values(dataParse[i])[0])) {
                        if (isNull(firstSiteIndex)) {
                            firstSiteIndex = i;
                        }
                        else {
                            lastSiteIndex = i;
                        }
                    }
                    // if (_.isEqual(Object.values(dataParse[i]).sort(), Object.keys(siteProps).sort())) {
                    //     siteIndex = i;
                    // }
                    if (_.isEqual(Object.values(dataParse[i]).sort(), Object.keys(deviceProps).sort())) {
                        deviceIndex = i;
                    }
                    if (_.isEqual(Object.values(dataParse[i]).sort(), Object.keys(systemProps).sort())) {
                        systemIndex = i;
                    }
                    if (_.isEqual(Object.values(dataParse[i]).sort(), Object.keys(unitProps).sort())) {
                        unitIndex = i;
                        i = dataParse.length;
                    }
                }
                if (!firstSiteIndex || lastSiteIndex === -1 || deviceIndex === -1 || systemIndex === -1 || unitIndex === -1) {
                    allImportErrors.generalErrors.push(`wrong file format`);
                    setAllErrors(allImportErrors);
                    setIsDisabled(false);
                } else {
                    if (props.isNew) {
                        const siteData: any = {};

                        for (let i = firstSiteIndex; i <= lastSiteIndex; i++) {
                            const data = dataParse[i];
                            if (dataParse[i][1]) {
                                siteData[siteProps[dataParse[i][0]].dbName] = dataParse[i][1].toString();
                            }
                        }
                        const customer = getCustomerByName(siteData.customer);

                        if (customer && siteData.name) {
                            await siteSchema.validate(siteData).then(async () => {
                                delete siteData.customer;
                                const countryCode = countries.filter(function (e) {
                                    if (e.name.toLowerCase() === siteData.country.toLowerCase()) {
                                        return e;
                                    }
                                    else { return null; }
                                });
                                if (countryCode.length) {
                                    siteData.country = countryCode[0].code;
                                }
                                // else {
                                //     siteData.country = siteData.country;
                                // }

                                await createSite({
                                    customerId: customer.id,
                                    data: siteData
                                }).then((data: any) => {
                                    site = data;
                                    allImportInfo.sites.push(data);
                                })
                                    .catch((e: any) => {
                                        allImportErrors.sitesErrors.push(`error: ${e} in site ${siteData.name}`);
                                        // error in create function
                                    });
                            }).catch((e) => {
                                (e.errors).forEach((err: string) => {
                                    // error in site data
                                    allImportErrors.sitesErrors.push(`error in import site, missing ${err}`);

                                });
                            });
                        }

                        else {
                            //fix error
                            //missing customer or site name
                            const missingData = !customer ? "customer name" : "site name";
                            allImportErrors.sitesErrors.push(`error in import site, missing ${missingData}`);
                        }
                        if (site && !allImportErrors.sitesErrors.length) {
                            const devicesLength = systemIndex !== -1 ? systemIndex - 1 :
                                (unitIndex !== -1 ? unitIndex - 1 : dataParse.length - 1);
                            for (let i = deviceIndex + 1; i <= devicesLength - 1; i++) {
                                const deviceData: any = {};
                                const data = dataParse[i];
                                _.map(data, (d, index) => {
                                    deviceData[deviceProps[dataParse[deviceIndex][index]].dbName] = d;
                                });
                                const deviceToSave = {
                                    siteId: site.id,
                                    newDevicePin: deviceData.pin,
                                    newDeviceId: deviceData.serial
                                };
                                await deviceSchema.validate(deviceData).then(async () => {
                                    await addNewDeviceToSite(deviceToSave)
                                        .then((data: any) => {
                                            allImportInfo.devices.push(data);
                                            setSteps(2);

                                            // allImportInfo.devices[data.id].status = ` ${deviceData.serial} created`;
                                        })
                                        .catch((e: any) => {
                                            allImportErrors.devicesErrors.push(`error in creating device ${deviceData.serial} , ${e.message}`);
                                        });
                                }).catch((e) => {
                                    (e.errors).forEach((err: string) => {
                                        allImportErrors.devicesErrors.push(`device error: ${err} in device data`);

                                    });
                                });
                            }
                        }
                        if (systemIndex !== -1 && site && allImportInfo.sites.length && allImportInfo.devices.length) {
                            // const systemsDetected: any = [];
                            allImportInfo.devices.forEach((device: any) => {
                                if (device.serviceSystems.length) {
                                    (device.serviceSystems).forEach(async (system: string) => {
                                        allImportInfo.detectedSystemsIds.push(system);
                                    });
                                } else {
                                    allImportErrors.systemsErrors.push(`No systems found in device ${device.serial}`);
                                }

                            });
                            //check if we found systems in device
                            if (allImportInfo.detectedSystemsIds.length) {
                                const resSystems = await getSystems();
                                allImportInfo.detectedSystems = _.filter(Object.values(resSystems), (system: any) => {
                                    return _.includes(allImportInfo.detectedSystemsIds, system.id);
                                });
                                const systemLength = unitIndex !== -1 ? unitIndex - 1 : dataParse.length - 1;
                                for (let i = systemIndex + 1; i < systemLength; i++) {
                                    const systemData: any = {};
                                    const data = dataParse[i];
                                    _.map(data, (d, index) => {
                                        systemData[systemProps[dataParse[systemIndex][index]].dbName] = d;
                                    });
                                    let brandNum;
                                    if (hvacBrands && systemData.brand) {
                                        brandNum = hvacBrands.find((brand) =>
                                            (brand.type).toLowerCase() == (systemData.brand).toLowerCase() ||
                                            (brand.name).toLowerCase() == (systemData.brand).toLowerCase());
                                    }
                                    const device: any = _.filter(allImportInfo.devices, (device: any) => device.serial == systemData.device)[0];
                                    if (device) {
                                        if (!brandNum || !systemData.name) {
                                            const missingData = !brandNum ? "brand" : "name";
                                            allImportErrors.systemsErrors.push(`error in system data - no ${missingData} please create new system`);
                                        } else {
                                            const systemToSave = {
                                                name: systemData.name,
                                                brandNum: brandNum.value,
                                                // brand: brandNum.value,
                                                line: systemData.line,
                                                series: systemData.series ? systemData.series.toString() : "",
                                                type: systemData.type ? systemData.type.toString() : "",
                                                capacity: systemData.capacity ? parseInt(systemData.capacity) : 0,
                                                capacityMeasurementUnits:
                                                    systemData.capacity && systemData.capacityMeasurementUnits
                                                        ? systemData.capacityMeasurementUnits
                                                        : capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]],
                                                device: device.serial
                                            };
                                            allImportInfo.systemsToSave.push(systemToSave);
                                        }
                                    }
                                    else {
                                        allImportErrors.devicesErrors.push(`No device matches system : ${systemData.name} `);

                                    }
                                }
                            }
                            else {
                                allImportErrors.devicesErrors.push(`No systems found in all the devices`);
                            }
                        }
                        if (!allImportInfo.systemsToSave.length) {
                            allImportErrors.devicesErrors.push(`No systems imported from the imported file`);

                        }
                        else { setSteps(3); }
                        if (allImportInfo.sites.length && allImportInfo.devices.length) {
                            for (let j = unitIndex + 1; j < dataParse.length; j++) {
                                const unitData: any = {};
                                const data = dataParse[j];
                                _.map(data, (d, index) => {
                                    unitData[unitProps[dataParse[unitIndex][index]].dbName] = d;
                                });
                                let device: any = _.filter(allImportInfo.devices, (device: any) => device.serial == unitData.device)[0];
                                if (device) {
                                    const system: any = _.find(allImportInfo.systemsToSave, function (o: any) { return (o.name == unitData.system && o.device === unitData.device && unitData.line === o.line); });
                                    if (system) {
                                        let unit: any;
                                        const unitToSave: any = _.pick(unitData, ["name", "capacity", "model", "serialNumber", "isVisible"]);
                                        // const deviceUnits = getDeviceUnits(device.id, "unassigned", "indoor");
                                        const type = (unitData.type === "service" || unitData.type === 3) ? 3 : ((unitData.type === "outdoor" || unitData.type === 2) ? 2 : null);
                                        if (type) {
                                            let controlUnit: any;
                                            if (system?.brandNum !== 1) {
                                                if (type === 3) {
                                                    unit = _.find(Object.values(device.units),
                                                        function (o: any) { return o.address == unitData.controlUnit && o.device === device.id && o.type === type && o.line === unitData.line; });
                                                }
                                                else {
                                                    unit = _.find(Object.values(device.units),
                                                        function (o: any) { return o.device === device.id && o.type === type && o.line === unitData.line; });
                                                }
                                            }
                                            else {
                                                unit = _.find(Object.values(device.units),
                                                    function (o: any) { return o.address == unitData.address && o.airnet == unitData.airnet && o.device === device.id && o.type === type; });
                                                if (type === 3) {
                                                    controlUnit = _.find(Object.values(device.units),
                                                        function (o: any) { return o.address == unitData.controlUnit && o.device === device.id && o.type === 1; });
                                                }
                                            }
                                            if (unit) {
                                                if (controlUnit && unit.controlUnit !== controlUnit) {
                                                    if (_.isEmpty(unitData.controlUnit)) {
                                                        if (!_.isUndefined(unit) && unit.controlUnit) {
                                                            dissociateControlUnitFromServiceUnit({ id: unit.id }).catch((err: any) => {
                                                                //   setError(err.message);
                                                            });
                                                        }
                                                    } else {
                                                        if (unitData.controlUnit) {
                                                            associateControlUnitToServiceUnit({ id: unit.id, controlUnitId: controlUnit.id }).catch((err: any) => {
                                                                //   setError(err.message);
                                                            });
                                                        }
                                                    }
                                                }
                                                if (typeof (unitData.type) !== "number") {
                                                    unitToSave.type = unitData.type === "indoor" ? 1 : (unitData.type === "outdoor" ? 2 : null);
                                                }
                                                unitToSave.model = unitToSave.model ? unitToSave.model.toString() : "";
                                                unitToSave.serialNumber = unitToSave.serialNumber ? unitToSave.serialNumber.toString() : "";
                                                if (unitToSave.task === 2 && unitToSave.task) {
                                                    unitToSave.task = unitData.task.toLowerCase() === "master" ? 1 : (unitData.task.toLowerCase() === "slave" ? 2 : null);
                                                }
                                                await unitSchema.validate(unitToSave).then(async () => {
                                                    await updateUnit({ id: unit.id, updatedData: unitToSave }).then((data: any) => {
                                                        allImportInfo.units.push(data);
                                                        unitToSave.id = unit.id;
                                                        unitToSave.system = unitData.system;
                                                        unitToSave.device = device.id;
                                                        unitToSave.line = unitData.line;
                                                        // unitToSave.isVisible = unitData.isVisible.toLowerCase() === "true" ? true : false;
                                                        allImportInfo.unitsToSave.push(unitToSave);

                                                    }
                                                    ).catch(() => {
                                                        allImportErrors.unitsErrors.push(`error in unit  ${unitData.address} data`);
                                                    }
                                                    );
                                                }).catch((e) => {
                                                    console.log(e);
                                                    // (e.errors).forEach((err: string) => {
                                                    //     unitsErrors.push({ status: `error: ${err} in unit ${unitData.privateId}` });
                                                    // });
                                                });
                                                setSteps(4);

                                            }
                                            else {
                                                allImportErrors.unitsErrors.push(`unit ${unitData.address ?? unitData.name} not found`);
                                            }
                                        }
                                        else {
                                            allImportErrors.unitsErrors.push(`no type selected for unit ${unitData.address ?? unitData.name}`);

                                        }
                                    } else {
                                        allImportErrors.unitsErrors.push(`system not fount for unit ${unitData.address ?? unitData.name}`);

                                    }
                                }
                                else {
                                    allImportErrors.unitsErrors.push(`device for unit ${unitData.address} not found`);
                                }
                            }

                        }
                    }
                    else {
                        allImportInfo.devices = getDevicesBySite(props.site.id);

                        const devicesLength = systemIndex !== -1 ? systemIndex - 2 :
                            (unitIndex !== -1 ? unitIndex - 1 : dataParse.length - 1);
                        const devicesSerials = allImportInfo.devices.map((o: any) => o.serial);
                        let devicesDetected: any = [];
                        for (let i = deviceIndex + 1; i <= devicesLength; i++) {
                            const deviceData: any = {};
                            const data = dataParse[i];
                            _.map(data, (d, index) => {
                                deviceData[deviceProps[dataParse[deviceIndex][index]].dbName] = d;
                            });

                            if (!_.includes(devicesSerials, deviceData.serial)) {
                                allImportErrors.devicesErrors.push(`device ${deviceData.serial} is not under this site`);
                            }
                            devicesDetected.push(deviceData.serial)
                        }
                        allImportInfo.devices = _.filter(allImportInfo.devices, (device: any) => {
                            return _.includes(devicesDetected, device.serial);
                        });

                        if (systemIndex !== -1 && site) {
                            allImportInfo.devices.forEach((device: any) => {
                                if (device.serviceSystems.length) {
                                    (device.serviceSystems).forEach(async (system: string) => {
                                        allImportInfo.detectedSystemsIds.push(system);
                                    });
                                } else {
                                    allImportErrors.systemsErrors.push(`No systems found in device ${device.serial}`);
                                }

                            });
                            //check if we found systems in device
                            if (allImportInfo.detectedSystemsIds.length) {
                                const resSystems = await getSystems();
                                allImportInfo.detectedSystems = _.filter(Object.values(resSystems), (system: any) => {
                                    return _.includes(allImportInfo.detectedSystemsIds, system.id);
                                });
                                const systemLength = unitIndex !== -1 ? unitIndex - 1 : dataParse.length - 1;
                                for (let i = systemIndex + 1; i < systemLength; i++) {
                                    const systemData: any = {};
                                    const data = dataParse[i];

                                    _.map(data, (d, index) => {
                                        systemData[systemProps[dataParse[systemIndex][index]].dbName] = d;
                                    });
                                    let brandNum;
                                    if (hvacBrands && systemData.brand) {
                                        brandNum = hvacBrands.find((brand) =>
                                            (brand.type).toLowerCase() == (systemData.brand).toLowerCase() ||
                                            (brand.name).toLowerCase() == (systemData.brand).toLowerCase());
                                    }
                                    const device: any = _.filter(allImportInfo.devices, (device: any) => device.serial == systemData.device)[0];
                                    if (device) {

                                        if (!brandNum || !systemData.name) {
                                            const missingData = !brandNum ? "brand" : "name";
                                            allImportErrors.systemsErrors.push(`error in system data - no ${missingData} please create new system`);
                                        } else {
                                            const systemToSave = {
                                                name: systemData.name,
                                                brandNum: brandNum.value,
                                                line: systemData.line,
                                                series: systemData.series ? systemData.series.toString() : "",
                                                type: systemData.type ? systemData.type.toString() : "",
                                                capacity: systemData.capacity ? parseInt(systemData.capacity) : 0,
                                                capacityMeasurementUnits:
                                                    systemData.capacity && systemData.capacityMeasurementUnits
                                                        ? systemData.capacityMeasurementUnits
                                                        : capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]],
                                                device: systemData.device
                                            };
                                            allImportInfo.systemsToSave.push(systemToSave);
                                            setSteps(1);
                                        }
                                    }
                                    else {
                                        allImportErrors.devicesErrors.push(`No device found for system : ${systemData.name}`);

                                    }
                                }
                            }
                            else {
                                allImportErrors.devicesErrors.push(`No systems found in all the devices`);
                            }
                        }
                        if (!allImportErrors.sitesErrors.length && !allImportErrors.devicesErrors.length) {
                            for (let j = unitIndex + 1; j < dataParse.length; j++) {
                                const unitData: any = {};
                                const data = dataParse[j];
                                _.map(data, (d, index) => {
                                    unitData[unitProps[dataParse[unitIndex][index]].dbName] = d;

                                });
                                let device: any = _.filter(allImportInfo.devices, (device: any) => device.serial == unitData.device)[0];
                                if (device) {
                                    const deviceUnits = getDeviceUnits(device.id, "all", "all");
                                    const system: any = _.find(allImportInfo.systemsToSave, function (o: any) { return (o.name == unitData.system && o.device === unitData.device && unitData.line === o.line); });
                                    if (system) {
                                        let unit: any;
                                        const unitToSave: any = _.pick(unitData, ["name", "capacity", "model", "serialNumber", "isVisible"]);
                                        const type = (unitData.type === "service" || unitData.type === 3) ? 3 : ((unitData.type === "outdoor" || unitData.type === 2) ? 2 : null);
                                        if (type) {
                                            let controlUnit: any;
                                            if (system?.brandNum !== 1) {
                                                if (type === 3) {
                                                    unit = _.find(deviceUnits,
                                                        function (o: any) { return o.address == unitData.controlUnit && o.device === device.id && o.type === type && o.line === unitData.line; });
                                                }
                                                else {
                                                    unit = _.find(deviceUnits,
                                                        function (o: any) { return o.device === device.id && o.type === type && o.line === unitData.line; });
                                                }
                                            }
                                            else {
                                                unit = _.find(deviceUnits,
                                                    function (o: any) { return o.address == unitData.address && o.airnet == unitData.airnet && o.device === device.id && o.type === type; });
                                                if (type === 3) {
                                                    controlUnit = _.find(deviceUnits,
                                                        function (o: any) { return o.address == unitData.controlUnit && o.device === device.id && o.type === 1; });
                                                }
                                            }
                                            if (unit) {
                                                if (controlUnit && unit.controlUnit !== controlUnit) {
                                                    if (_.isEmpty(unitData.controlUnit)) {
                                                        if (!_.isUndefined(unit) && unit.controlUnit) {
                                                            dissociateControlUnitFromServiceUnit({ id: unit.id }).catch((err: any) => {
                                                                //   setError(err.message);
                                                            });
                                                        }
                                                    } else {
                                                        if (unitData.controlUnit) {
                                                            associateControlUnitToServiceUnit({ id: unit.id, controlUnitId: controlUnit.id }).catch((err: any) => {
                                                                //   setError(err.message);
                                                            });
                                                        }
                                                    }
                                                }
                                                if (typeof (unitData.type) !== "number") {
                                                    unitToSave.type = unitData.type === "indoor" ? 1 : (unitData.type === "outdoor" ? 2 : null);
                                                }
                                                unitToSave.model = unitToSave.model ? unitToSave.model.toString() : "";
                                                unitToSave.serialNumber = unitToSave.serialNumber ? unitToSave.serialNumber.toString() : "";
                                                if (unitToSave.task === 2 && unitToSave.task) {
                                                    unitToSave.task = unitData.task.toLowerCase() === "master" ? 1 : (unitData.task.toLowerCase() === "slave" ? 2 : null);
                                                }
                                                await unitSchema.validate(unitToSave).then(async () => {
                                                    await updateUnit({ id: unit.id, updatedData: unitToSave }).then((data: any) => {
                                                        allImportInfo.units.push(data);
                                                        unitToSave.id = unit.id;
                                                        unitToSave.system = unitData.system;
                                                        unitToSave.device = device.id;
                                                        unitToSave.line = unitData.line;
                                                        allImportInfo.unitsToSave.push(unitToSave);

                                                    }
                                                    ).catch((e: any) => {
                                                        console.log(e);

                                                        allImportErrors.unitsErrors.push(`error in unit  ${unitData.address} data`);
                                                    }
                                                    );
                                                }).catch((e) => {
                                                    console.log(e);
                                                    // (e.errors).forEach((err: string) => {
                                                    //     unitsErrors.push({ status: `error: ${err} in unit ${unitData.privateId}` });
                                                    // });
                                                });
                                                setSteps(2);

                                            }
                                            else {
                                                allImportErrors.unitsErrors.push(`unit ${unitData.address ?? unitData.name} not found`);
                                            }
                                        }
                                        else {
                                            allImportErrors.unitsErrors.push(`no type selected fot unit ${unitData.address ?? unitData.name}`);

                                        }
                                    }
                                    else {
                                        allImportErrors.unitsErrors.push(`system not fount for unit ${unitData.address ?? unitData.name}`);

                                    }
                                }
                                else {
                                    allImportErrors.unitsErrors.push(`device for unit ${unitData.address} not found`);
                                }
                            }

                        }
                        if (!allImportInfo.unitsToSave.length) {
                            allImportErrors.unitsErrors.push(`No units imported`);
                        }

                    }
                }
                setImportInfo(allImportInfo);
                setAllErrors(allImportErrors);
                setIsDisabled(false);

                await getUserTree();

            };
            reader.readAsBinaryString(f);

        }
    };
    return (
        <div >
            <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                // className={classes.input}
                style={{ display: "none" }}
                id={`raised-button-file-${props.site ? props.site.id : ""}`}
                type="file"
                onChange={(e) => handleCapture(e)}
                multiple={true}
            />
            <label htmlFor={`raised-button-file-${props.site ? props.site.id : ""}`}>
                <LightTooltip title={props.isNew ? "Import new site" : "Import Site Information from CSV"}>
                    <IconButton
                        disableRipple
                        component="span"
                        className={clsx(classes.iconBtnStyle, { [classes.textIcon]: props.isNew })}
                    >
                        <SVGDownload className={classes.SVGDownload} />
                        {props.isNew ? t`Import` : ""}
                    </IconButton>
                </LightTooltip>

            </label>
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={isOpen}
                maxWidth="md"
                fullWidth
            >
                <div
                    className={classes.dialogHeader}
                >
                    <Typography className={classes.headerTitle}>{t`Import`}</Typography>
                    <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => handleClose()}>
                        <Close color="#7f7692" />
                    </IconButton>
                </div>
                <div className={classes.dialogContent}>

                    {(isDisabled && isOpen) &&
                     <div className={classes.progressBox}>
                        <CircularProgress />
                    </div>
                    } 
                    <ImportModel
                        allErrors={allErrors}
                        isNew={props.isNew ? true : false}
                        updateSystemName={updateLocal}
                        create={create}
                        allInfo={importInfo}
                        handleClose={handleClose}
                        updateSystem={update}
                        removeSystem={removeSystem}
                        steps={steps}
                        isLoaded={!!isDisabled}
                        handleSiteClose={props.handleClose}
                    ></ImportModel>
                </div>
            </Dialog>
        </div >
    );
}
