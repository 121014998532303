import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    minHeight: "250px !important",
    maxHeight: "380px",
    borderRadius: 0,
    width: "300px",
  },
  header: {
    background: "rgb(240, 81, 70)",
    height: "3rem",
    fontSize: "1rem",
    color: "#fff",
    padding: "0 24px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "2rem",
  },
  subTitle: {
    fontFamily: "Roboto",
    alignSelf: "center",
    color: "rgb(41, 19, 46)",
    fontSize: "0.875rem",
    fontWeight: 500,
    gridColumn: 1,
  },
  text: {
    fontFamily: "Roboto",
    color: "rgb(121, 121, 125)",
    lineHeight: "1.5rem",
    fontSize: "0.875rem",
    gridColumn: 2,
    paddingLeft: "1rem",
  },
  buttonStyle: {
    border: "1px solid rgb(66, 26, 64)",
    borderRadius: "4px",
    color: "rgb(66, 26, 64)",
    padding: "0.4rem 1.2rem",
  },
  actionsStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
  },
  displayed: {
    color: "rgba(0, 0, 0, 0.2)",
  },
  paginationIconBox: {
    color: "rgba(239, 239, 242, 0.6)",
    alignItems: "center",
    display: "flex",
  },
  paginationIcon: {
    width: "0.5rem",
  },
  navPagination: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "rgb(236,235,239,0.5)",
    marginTop: 12,
    alignItems: "center",
  },
  cardContent: {
    marginTop: "5px",
    padding: "0 !important",
  },
  paginationIconButton: {
    padding: "0.3rem",
    "&:hover": {
      backgroundColor: "rgb(236,235,239,0.5)",
    },
  },
  button: {
    backgroundColor: "white",
    color: "rgba(41, 19, 46, 0.95)",
  },
});
export default useStyles;
