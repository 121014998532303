import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CircularSlider from "react-circular-slider-svg";
import tinycolor2 from "tinycolor2";
import { t } from "ttag";
import {
  AlertControl,
  ArrowUp,
  AutoMode,
  ColdIcon,
  ControlWrapper,
  DryIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  FanMode,
  HeatIcon,
  ScheduleOffIcon,
  Swing,
  Swing30,
  Swing45,
  Swing60,
  SwingHor,
  SwingVer,
  Temperature
} from "../../../../icons/index";
import {ArrowDownControl, SwitcherOffIcon as SvgPowerOff, SwitcherOnIcon as SvgPowerOn} from "../../../../svgComponents";
import unitPanelStyle from "./UnitPanel.style";

interface IObject {
  [key: string]: any;
}

const colorRanges: any = {
  COOL: ["#35a8e0", "#2d2e82"],
  HEAT: ["#f8b133", "#f05146"],
  AUTO: ["#35a8e0", "#f05146"],
  DRY: ["#266101", "#266101"],
  FAN: ["#4f00ed", "#4f00ed"],
  default: ["#ffffff", "#000000"]
};

const mainIconsColors = {
  colorblue: "#35A8E0",
  color1: "#4B1C46",
  color2: "#AAA2AA",
  maincolor: "#AAA2AA",
  opacity: 1
};

const UnitPanel: React.FC<any> = (props) => {
  const {
    unit,
    navigateToSchedules,
    selectedSiteId,
    types,
    setActiveSetpoint,
    setActiveOperationMode,
    setActiveOperationStatus,
    operationModesMirror,
    fanModesMirror,
    swingModesMirror,
    setActiveFanMode,
    setActiveSwingMode,
    temperatureScale,
    addMessage,
    operationStatusesMirror,
    isCelsius,
    siteTemp,
    allUnits,
    groupId,
    tempSymbol
  } = props;

  const useStyles = makeStyles(unitPanelStyle);
  const classes = useStyles();

  const { id: unitId,
          name,
          activeSetpoint,
          activeOperationMode,
          activeFanMode,
          activeSwingMode,
          temperatureLimits,
          enableAutoMode,
          enableCoolMode,
          enableHeatMode,
          ambientTemperature,
          activeOperationStatus,
          supportedOperationModes = [],
          supportedFanModes = [],
          supportedSwingModes = [],
          message,
          enableSetpoint,
          enableMode,
          enableOnState,
          enableOnoff,
          filter } = unit;

  const [clickedIcon, setClickedIcon] = useState<string>("");
  const [startTemp, setStart] = useState<number>(0);
  const [endTemp, setEnd] = useState<number>(48);
  const [startAngle, setStartAngle] = useState<number>(0);
  const [endAngle, setEndAngle] = useState<number>(360);
  const [currentColor, setCurrentColor] = useState<any>("#888");
  const [pendingState, setPendingState] = useState<any>({type: "", mode: null});

  const [timer, setTimer] = useState<any>();
  const [localActiveSetpoint, setLocalActiveSetPoint] = useState<any>(0);
  const [togglePowerButton, setTogglePowerButton] = useState<boolean>(false);

  const { operationStatuses, fanModes, operationModes, swingModes, unitTypes, unitSubTypes } = types;
  const powerOffVal = operationStatusesMirror && +operationStatusesMirror.off;
  const powerOnVal = operationStatusesMirror && +operationStatusesMirror.on;
  const isPowerOn = activeOperationStatus === powerOnVal;

  const mainIcons: IObject = {
    operation: {
      COOL: <ColdIcon />,
      HEAT: <HeatIcon />,
      AUTO: <AutoMode />,
      DRY: <DryIcon />,
      FAN: <FanMode />
    },
    fan: {
      LOW: <Fan1Icon />,
      MEDIUM: <Fan2Icon />,
      HIGH: <Fan3Icon />,
      TOP: <Fan4Icon />,
      AUTO: <Typography>{t`Auto`}</Typography>,
      ON: <Typography>{t`On`}</Typography>,
      OFF: <Typography>{t`Off`}</Typography>
    },
    swing: {
      vertical: <SwingVer />,
      horizontal: <SwingHor />,
      30: <Swing30 />,
      45: <Swing45 />,
      60: <Swing60 />,
      auto: <Swing />,
      off: <Typography>{t`Off`}</Typography>,
      on: <Typography>{t`On`}</Typography>
    }
  };

  const enabledMode: IObject = {
    0: enableCoolMode,
    1: enableHeatMode,
    2: enableAutoMode
  };

  useEffect(() => {

    if (!unit || !temperatureLimits || activeOperationMode > 2) { return; }

    const midByType = isCelsius ? 24 : 75;
    const defaultMin = 0;
    const defaultMax = 100;

    const minLimit = Math.min((temperatureLimits[activeOperationMode][0] || defaultMin), activeSetpoint);
    const maxLimit = Math.max((temperatureLimits[activeOperationMode][1] || defaultMax), activeSetpoint);
    if (minLimit === startTemp && maxLimit === endTemp) { return; }

    if (!enabledMode[activeOperationMode]){
      setStart(defaultMin);
      setEnd(defaultMax);
      setStartAngle(5);
      setEndAngle(355);
    } else{
      if (activeSetpoint && activeSetpoint > maxLimit){
        setActiveSetpoint({ id: unit.id, setpoint: Number(maxLimit) });
      }
      if (activeSetpoint && activeSetpoint < minLimit){
        setActiveSetpoint({ id: unit.id, setpoint: Number(minLimit) });
      }
      setStart(minLimit);
      setEnd(maxLimit);

      const startAngle = (180 * +minLimit) / midByType;
      const endAngle = (180 * +maxLimit) / midByType;

      setStartAngle(startAngle);
      setEndAngle(endAngle);
    }
  }, [ temperatureLimits, unit, operationModesMirror, activeSetpoint, isCelsius, activeOperationMode, enableCoolMode, enableHeatMode, enableAutoMode
    // endTemp,
    // startTemp,
    // unitId,
  ]);

  useEffect(() => {
    setLocalActiveSetPoint(activeSetpoint);
  }, [activeSetpoint]);

  useEffect(() => {
    if (!togglePowerButton) { return; }

    setTimeout(() => {
      setTogglePowerButton(false);
      // setLocalActiveSetPoint(activeSetpoint); // not approved
    }, 5000);

    if (pendingState.type === "setpoint" && pendingState.mode === activeSetpoint) {
      setTogglePowerButton(false);
      setPendingState({ type: "", mode: null });
    }

    if (pendingState.type === "power" && pendingState.mode === activeOperationStatus) {
      setTogglePowerButton(false);
      setPendingState({ type: "", mode: null });
    }
  }, [activeOperationStatus, activeSetpoint, togglePowerButton, pendingState.mode, pendingState.type]);

  useEffect(() => {
    if (!localActiveSetpoint || activeSetpoint === localActiveSetpoint) { return; }

    if (timer) { clearTimeout(timer); }

    let newTimer: NodeJS.Timeout = setTimeout(() => {
      setTogglePowerButton(true);
      setActiveSetpoint({unitId, data: localActiveSetpoint})
        .catch((err: any) =>  {
          setLocalActiveSetPoint(activeSetpoint);
          addMessage({message: err.message});
        })
        .finally(() => setTogglePowerButton(false));
    }, 1000);
    setTimer(newTimer);
  }, [
    localActiveSetpoint
    // addMessage, unitId
  ]);

  const changeTemp = (isAdd: boolean) => {
    if (
      (isAdd && (localActiveSetpoint === endTemp || localActiveSetpoint > endTemp)) ||
      (!isAdd && (localActiveSetpoint === startTemp || localActiveSetpoint < startTemp))
    ) { return; }

    const updatedSetPoint = isAdd ? localActiveSetpoint + 1 : localActiveSetpoint - 1;
    setLocalActiveSetPoint(updatedSetPoint);

    setPendingState({type: "setpoint", mode: isAdd ? activeSetpoint + 1 : activeSetpoint - 1});
  };

  useEffect(() => {
    const mode = operationModes[activeOperationMode];

    const range = colorRanges[mode] ? colorRanges[mode] : colorRanges["default"];
    const c1 = range[0];
    const c2 = range[1];
    const absoluteVal = ((localActiveSetpoint - startTemp) / (endTemp - startTemp)) * 100;
    const c3 = tinycolor2.mix(c1, c2, absoluteVal);
    setCurrentColor(c3.toHexString());
  }, [localActiveSetpoint,  operationModes, activeOperationMode, endTemp, startTemp]);

  const togglePower = () => {
    const data = operationStatuses[activeOperationStatus] === "on"   ? powerOffVal : powerOnVal;

    setTogglePowerButton(true);
    setPendingState({type: "power",  mode: data});

    setActiveOperationStatus({ unitId, data })
    .catch((err: any) => {
      setTogglePowerButton(false);
      addMessage({message: err.message});
    });
  };

  const handleClose = () => {
    setClickedIcon("");
  };

  const handleChoose = (name: string, modesMirror: IObject) => {
    const data = modesMirror[name];
    handleClose();

    if (clickedIcon === "operation") {
      setActiveOperationMode({unitId, data})
      .catch((err: any) => addMessage({message: err.message}));
      return;
    }

    if (clickedIcon === "fan") {
      setActiveFanMode({unitId, data})
      .catch((err: any) =>  addMessage({message: err.message}));
      return;
    }

    if (clickedIcon === "swing") {
      setActiveSwingMode({unitId, data})
      .catch((err: any) =>  addMessage({message: err.message}));
    }
  };

  const handleIconClick = (e: any, name: string) => {
    setClickedIcon(name === clickedIcon ? "" : name);
  };
  const createMenu = (activeMode: number, modesMirror: IObject,  supportedArray: Number[], type: string) => {
    const icons = mainIcons[type];
    if (!Object.keys(icons)) {return <></>; }

    return (
      <>
        {Object.keys(icons).map((icon: any, i: number) => {
          if (supportedArray.indexOf(+modesMirror[icon]) < 0) {
            return <React.Fragment key={`mode-${i}`} />;
          }
          return (
            <MenuItem
              key={`mode-${i}`}
              className={activeMode === +modesMirror[icon] ? classes.selected : ""}
              onClick={() => handleChoose(icon, modesMirror)}
            >
              {React.cloneElement(icons[icon], mainIconsColors)}
            </MenuItem>
          );
        })}
      </>
    );
  };

  const currentMode = operationModes[activeOperationMode];
  const noControl = currentMode === "DRY" || currentMode === "FAN";
  const hasError = message && message.replace(/\s/g, "").toUpperCase() !== "OK";

  return (
    <div  className={classes.unitView} >
      <div className={classes.firstRowContainer}>
        <Typography className={classes.siteTemp}>{(isCelsius ? siteTemp.C  || "" : siteTemp.F || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
        <div className={classes.nameSection}>
          <Typography >{name}</Typography>
          <div className={classes.loaderContainer}>
          {togglePowerButton && (<CircularProgress size={40} className={classes.loaderStyle} />)}
          </div>
        </div>

        <div className={classes.powerContainer}>
            <IconButton className={classes.powerIconStyle} onClick={togglePower} disabled={enableOnoff || (activeOperationStatus === 2 && enableOnState)}>
              {isPowerOn ? <SvgPowerOn className={classes.powerOffButtonClick}/> : <SvgPowerOff className={classes.powerOnButtonClick}/>}
            </IconButton>
            <Typography className={classes.powerTextStyle}>{isPowerOn ? t`POWER OFF` : t`POWER ON`}</Typography>
        </div>
      </div>

      {(hasError && !filter) &&
      <div className={classes.redErrorContainer}>
        <AlertControl  className={classes.bigAlertIcon} />
        <Typography className={classes.alertBigMessageStyle}>{`Error ${message}`}</Typography>
      </div>}

      <div className={clsx(classes.secondRowContainer, {[classes.removeElements]: hasError && !filter})}>
        {filter && (
          <div className={classes.alertContainer}>
            <AlertControl color={"#f7b500"} className={classes.alertIcon} />
            <Typography className={clsx(classes.alertMessageStyle, {[classes.filterAlert]: filter})}>{filter ? "Filter" : `Error ${message}`}</Typography>
          </div>
        )}
        <div className={classes.controlDiv}>
          <ControlWrapper className={classes.controlWrapperStyle} fill={isPowerOn ? currentColor : "rgba(253,253,254, 0.5)"} width={336} height={326}/>
          {!noControl ? (
            <>
              <div className={clsx(classes.sliderContainer)}>
                <CircularSlider
                  size={320}
                  minValue={startTemp}
                  maxValue={endTemp}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  coerceToInt={true}
                  angleType={{direction: "cw", axis: "-y"}}
                  handle1={{value: localActiveSetpoint, onChange: (value: any) => setLocalActiveSetPoint(value)}}
                  arcColor={"#48314A"}
                  arcBackgroundColor={"#48314A"}
                  disabled={enableSetpoint}
                />
              </div>
              <div className={clsx(classes.innerControls)}>
                <IconButton disabled={enableSetpoint} onClick={() => changeTemp(true)} className={classes.controlArrow}><ArrowUp /></IconButton>
                <Typography className={clsx(classes.setPointStyle)}>{localActiveSetpoint ? localActiveSetpoint : activeSetpoint}</Typography>
                <div className={classes.ampTempContainer}>
                  <Temperature />
                  <Typography className={classes.ampTempStyle}>{Math.round(ambientTemperature || 0)}</Typography>
                </div>
                <IconButton disabled={enableSetpoint} onClick={() => changeTemp(false)} className={classes.controlArrow}><ArrowDownControl /></IconButton>
              </div>
            </>
          ) : (<Typography className={classes.modeTitle}>{currentMode}</Typography>)}
        </div>
      </div>

      <div className={clsx(classes.lastRowContainer, {[classes.removeElements]: hasError && !filter})}>
        <div className={clsx(classes.iconContainer, classes.rightSpace, !supportedOperationModes.length && classes.removeElements)}>
          <div className={clsx(classes.paper, clickedIcon === "operation" ? classes.showStyle : classes.removeElements)}>
            {createMenu(activeOperationMode, operationModesMirror, supportedOperationModes, "operation")}
          </div>
          <IconButton className={classes.mainIconsStyle} disabled={enableMode} onClick={(e: any) => handleIconClick(e, "operation")}>
            {mainIcons.operation[operationModes[activeOperationMode]]
              ? React.cloneElement(mainIcons.operation[operationModes[activeOperationMode]], {opacity: 0.6})
              : operationModes[activeOperationMode] || activeOperationMode}
          </IconButton>
        </div>
        {/*fan*/}
        <div className={clsx(classes.iconContainer, classes.rightSpace, !supportedFanModes.length && classes.removeElements)}>
          <div className={clsx(classes.paper, clickedIcon === "fan" ? classes.showStyle : classes.removeElements)}>
            {createMenu(activeFanMode, fanModesMirror, supportedFanModes, "fan")}
          </div>
          <IconButton className={classes.mainIconsStyle} onClick={(e: any) => handleIconClick(e, "fan")}>
            {mainIcons.fan[fanModes[activeFanMode]]
              ? React.cloneElement(mainIcons.fan[fanModes[activeFanMode]], {opacity: 0.6})
              : fanModes[activeFanMode] || activeFanMode}
          </IconButton>
        </div>
        {/*swing*/}
        <div className={clsx(classes.iconContainer, classes.rightSpace, !supportedSwingModes.length && classes.removeElements)}>
          <div className={clsx(classes.paper, clickedIcon === "swing" ? classes.showStyle : classes.removeElements)}>
            {createMenu(activeSwingMode, swingModesMirror, supportedSwingModes, "swing")}
          </div>
          <IconButton className={classes.mainIconsStyle} onClick={(e: any) => handleIconClick(e, "swing")}>
            {mainIcons.swing[swingModes[activeSwingMode]]
              ? React.cloneElement(mainIcons.swing[swingModes[activeSwingMode]], {opacity: 0.6})
              : swingModes[activeSwingMode] || activeSwingMode}
          </IconButton>
        </div>
        <div className={classes.iconContainer}>
          <IconButton disableFocusRipple disableRipple className={classes.mainIconsStyle} name="schedule" onClick={() => navigateToSchedules(selectedSiteId, unitId)}>
            <ScheduleOffIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default UnitPanel;
