import { IRootStoreModel } from "./RootStore";
import _ from "lodash";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  debug,
  Computed,
  computed,
  Thunk,
  thunk,
} from "easy-peasy";
import { t } from "ttag";
import coolremoteSDK from "coolremote-sdk";
import { ICoolTablePage } from "../components/CoolTable/CoolTable";

export interface IAudit {
  id: string;
  timestamp: string;
  action: string;
  actor: string;
  device?: string;
  unit?: string;
  customer?: string;
  site?: string;
  user?: string;
  value?: any;
  data?: any;
  source?: number;
  line?: number;
  sourceType?: string;
}

export interface IAuditMap {
  [key: string]: IAudit;
}

export interface IAuditsModel {
  getMyAudits: Thunk<IRootStoreModel, { params: any }>;
  // getAuditsByFilter: Computed<
  //   IAuditsModel,
  //   (
  //     filter: {
  //       customerId: string | null | undefined;
  //       siteId: string | null | undefined;
  //       systemId: string | null | undefined;
  //       unitId: string | null | undefined;
  //     },
  //     isResolved: boolean
  //   ) => IAudit[],
  //   IRootStoreModel
  // >;
}

export const auditsModel: IAuditsModel = {
  getMyAudits: thunk(async (actions, { params }) => {
    const audits = await coolremoteSDK.Audit.getAudits(params);
    return audits;
  }),

  // getAuditsByFilter: computed(
  //   [
  //     state => state.allAudits,
  //     (state, storeState) => storeState.sites.allSites,
  //     (state, storeState) => storeState.units.allUnits,
  //     (state, storeState) => storeState.units.getUnitByInternalOrProId,
  //     (state, storeState) => storeState.devices.getDeviceBySerial
  //   ],
  //   (allAudits, allSites, allUnits, getUnitByInternalOrProId, getDeviceBySerial) => (
  //     filter,
  //     isResolved
  //   ) => {
  //     return _.filter(_.values(allAudits), (audit: IAudit) => {
  //       // First of all, filter out resolved audits if we don't want them.
  //       if (!isResolved) {
  //         if (!_.isUndefined(audit.clearTime)) {
  //           return false;
  //         }
  //       }

  //       // Different flows for device audits and unit audits.

  //       // If this is a device audit.
  //       if (_.has(audit, 'data.type') && audit.data.type === 'DEVICE_DISCONNECTED') {
  //         // No device ID? Filter out.
  //         if (!_.has(audit, 'data.eventData.deviceId')) {
  //           return false;
  //         }

  //         const auditDevice = getDeviceBySerial(audit.data.eventData.deviceId);

  //         // Device doesn't exist? Filter out.
  //         if (!auditDevice) {
  //           return false;
  //         }

  //         // Check if the audit's device falls under the unit filter.
  //         if (filter.unitId) {
  //           const filterUnit = allUnits[filter.unitId];

  //           if (filterUnit && filterUnit.device === auditDevice.id) {
  //             return true;
  //           }

  //           return false;
  //         }

  //         // Check if the audit's device falls under the system filter.
  //         if (filter.systemId) {
  //           return _.includes(auditDevice.systems, filter.systemId);
  //         }

  //         // Check if the audit's device falls under the site filter.
  //         if (filter.siteId) {
  //           return auditDevice.site === filter.siteId;
  //         }

  //         // Check if the audit's device falls under the customer filter.
  //         if (filter.customerId) {
  //           const deviceSite = allSites[auditDevice.site];

  //           return deviceSite && deviceSite.customer === filter.customerId;
  //         }

  //         // No filter - pass.
  //         return true;
  //       }
  //       // If this is a unit audit.
  //       else {
  //         const auditInternalUnitId = audit.data.eventData?.unitId;
  //         let auditUnit: any = null;

  //         // Resolve unit id from the audit (if there is one).
  //         if (auditInternalUnitId) {
  //           auditUnit = getUnitByInternalOrProId(auditInternalUnitId);
  //         }

  //         // No unit? Filter out.
  //         if (!auditUnit) {
  //           return false;
  //         }

  //         // Check if the audit's unit falls under the unit filter.
  //         if (filter.unitId) {
  //           return auditUnit.id === filter.unitId;
  //         }

  //         // Check if the audit's unit falls under the system filter.
  //         if (filter.systemId) {
  //           return auditUnit.system && auditUnit.system === filter.systemId;
  //         }

  //         // Check if the audit's unit falls under the site filter.
  //         if (filter.siteId) {
  //           const filterSite = allSites[filter.siteId];

  //           if (filterSite && _.includes(filterSite.devices, auditUnit.device)) {
  //             return true;
  //           }

  //           return false;
  //         }

  //         // Check if the audit's unit falls under the customer filter.
  //         if (filter.customerId) {
  //           // Get unit's site.
  //           const unitSites = _.filter(_.values(allSites), site =>
  //             _.includes(site.devices, auditUnit.device)
  //           );

  //           // If the audit unit's site is somehow not found.
  //           if (_.isEmpty(unitSites)) {
  //             return false;
  //           }

  //           const unitSite = unitSites[0];

  //           return unitSite.customer === filter.customerId;
  //         }

  //         // No filter - pass.
  //         return true;
  //       }
  //     });
  //   }
  // ),
};
