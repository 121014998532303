import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  disableClicks: {
    pointerEvents: "none",
    opacity: "0.5"
  },
  modes: {
    display: "none",
    visibility: "hidden"
  },
  item: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100%"
  },
  card: {
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    padding: "10px",
    boxSizing: "border-box",
    minWidth: "22rem",
    borderRadius: "10px",
    border: "solid 1px #ebebeb"
  },
  itemOn: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100%",
    borderRight: "1px lightgray solid"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    marginBottom: "5px",
    padding: "8px"
  },
  powerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f6f6f7",
    marginBottom: "5px",
    padding: "0",
    borderRadius: "50%",
    marginLeft: "0.5rem",
    width: "2.8rem",
    height: "2.8rem"
  },
  powerText: {
    fontWeight: 100,
    fontSize: "1rem"
  },
  powerIcon: {
    height: "2.9rem",
    width: "2.9rem",
    margin: "0.15rem"
  },
  icon: {
    fontSize: "2.3125rem"
  },

  controlContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f6f6f6",
    width: "31.5%",
    borderRadius: "10px",
    height: "100%"
  },

  powerOn: {
    width: "2.8rem",
    height: "2.8rem",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05)",
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "50%",
    color: "white"
  },
  powerOff: {
    background: "white",
    color: "gray",
    width: "2.8rem",
    height: "2.8rem",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05)",
    backgroundColor: "#ffffff",
    borderRadius: "50%"
  },
  powerButtonSVG: {
    position: "relative",
    top: "20%",
    fontSize: "1.3rem"
  },
  InputNumber: {
    backgroundColor: "inherit",
    fontSize: "2rem",
    alignItems: "space-between",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  Number: {
    color: "transparent",
    textShadow: "0 0 0 #4b1c46",
    width: "100%",
    maxWidth: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f6f6f7",
    border: "none",
    fontSize: "2.34rem",
    outline: "none",
    letterSpacing: "0.078125rem",
    textAlign: "right",
    fontFamily: "Roboto"
  },
  chevron: {
    fontSize: "0.8125rem"
  },

  temperatureScale: {
    fontFamily: "Roboto",
    fontSize: "1.125rem",
    letterSpacing: "0.03625rem",
    color: "#4b1c46"
  },

  controlWrapper: {
    display: "flex",
    justifyContent: "center",
    transform: "scale(1.3)",
    cursor: "pointer",
    background: "none",
    color: "#7f7182",
    margin: "0px 35px",
    borderRadius: "4px",
    "&:hover": {
      transform: "scale(1.301)"
    },
    "&:active": {
      color: "#4b1c46"
    }
  },
  tempWrapper: {},
  disableClick: { pointerEvents: "none", opacity: 0.4 }
}));

export default useStyles;
