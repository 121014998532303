import { IconButton, Popover } from "@material-ui/core";
import React, { useEffect } from "react";
import { modeIcons } from "../../constants/modes";
import assetsService from "../../services/assetsService";
import useStyles from "./ModeMenu.style";

export default function ModeMenu(props: any) {
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { onClick, unit, supportedModes, serviceTypes } = props;
  const [unitMode, setUnitMode] = React.useState<number>(unit.activeOperationMode);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setUnitMode(unit.activeOperationMode);
  }, [unit]);
  return (
    <React.Fragment>
      <div className={classes.controlContainer}>
        <IconButton disabled={props.disabled} onClick={handleClick} className={classes.selectedButton}>
          {modeIcons[unitMode] ? (
            <img src={assetsService.getImagesPath(`${modeIcons[unitMode]}.svg`)}></img>
          ) : (
              <div>{unitMode}</div>
            )}
        </IconButton>
        {modeIcons[unitMode] ? <span>{serviceTypes.operationModes[unitMode]}</span> : null}
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{ className: classes.menu }}
      >
        {supportedModes.map((mode: number) => (
          <div key={mode} onClick={handleClose}>
            <div className={classes.controlContainer}>
              <IconButton onClick={() => onClick(mode)} className={classes.button}>
                <img
                  className={classes.icon}
                  src={assetsService.getImagesPath(`${modeIcons[mode]}.svg`)}
                ></img>
              </IconButton>
            </div>
          </div>
        ))}
      </Popover>
    </React.Fragment>
  );
}
