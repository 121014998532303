import { createStyles, Theme } from "@material-ui/core";

export const manuallySetSystemViewStyle = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    inputName: {
      width: "25%",
      marginRight: "1rem"
    },
    inputBrand: {
      width: "20%",
      marginRight: "1rem"
    },
    inputModel: {
      width: "10%",
      marginRight: "1rem"
    },
    inputLine: { width: "9%" },
    inputCapacity: {
      width: "10%",
      marginRight: "1rem"
    },
    inputCapacityMeasurement: {
      width: "8%",
      marginTop: "1.4rem"
    },
    autocomplete: {
      width: "10rem",
      height: "3rem"
    },
    systemContainer: {
      borderRadius: "4px",
      border: "solid 1px #d5d2d5"
      // padding: '1rem'
    },
    unitsContainer: {
      borderRadius: "4px",
      border: "solid 1px #d5d2d5",
      marginTop: "1rem",
      display: "flex",
      flexFlow: "column",
      overflow: "hidden",
      maxHeight: "calc(100vh - 478px)",
      paddingBottom: "14px"
    },
    unitsInfoTitle: {
      backgroundColor: "#f6f5f6",
      borderBottom: "1px solid #d5d2d5",
      padding: 0,
      paddingLeft: "14px",
      marginBottom: "14px",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      color: "#4b1c46",
      height: "50px",
      lineHeight: "50px"
    },
    scrollableTable: {
      overflow: "auto",
      maxHeight: "500px",
      padding: "0 7px", display: "flex",
      height: "500px"
    },
    modalActions: {
      marginTop: "1rem",
      display: "flex",
      justifyContent: "flex-end",
      position: "sticky",
      bottom: 0,
      right: 0,
      height: "80px",
      background: "white",
      width: "100%",
      padding: "20px 0",
      zIndex: 7
    },
    container: {
      paddingRight: "2rem"
    },

    headCell: {
      backgroundColor: "whitesmoke"
    },

    inputFormWrapper: {
      margin: "1rem 0 1rem 0"
    },
    textField: {
      width: "100%"
    },
    inputFieldStyle: {
      minWidth: "10ch",
      height: "44px",
      width: "100%",
      "& label": {
          color: "rgba(255, 255, 255, 0.7)",
          "&focus": {
              color: "rgba(255, 255, 255, 0.7)"
          }
      },
      "& div": {
          backgroundColor: "rgba(255, 255, 255, 0.1)"
      },
      "& MuiInputBase-root": {
          color: "white"
      }
    },
    otherFormWarpper: {
      border: "1px solid #D5D2D5",
      overflow: "auto"
    },
    tableWrapper: {
      display: "flex",
      overflow: "auto",
      flex: 1
    },
    otherFormWarpperTitle: {
      color: "#4b1c46",
      backgroundColor: "#f6f5f6",
      height: "50px",
      padding: "0",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: 500,
      lineHeight: "50px",
      paddingLeft: "14px",
      borderBottom: "1px solid #d5d2d5",
      marginBottom: "14px"
    },
    tableHeadCell: {
      borderBottom: "none",
      padding: "0 15px",
      color: "#79797d",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      backgroundColor: "#efeff2",
      textTransform: "uppercase",
      height: 45,
      fontSize: 13,
      width: "37ch",
      minWidth: "37px"
    },
    systemAttributes: {
      width: "100%",
      flex: 1,
      overflowY: "auto",
      padding: 20,
      paddingBottom: 0
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "-webkit-fill-available",
      margin: "1rem",
      flex: 1,
      overflowY: "auto"
    },
    deviceSystemsContainer: {
      width: "50rem",
      marginBottom: "2rem"
    },
    plusIcon: { fontSize: "13px !important" },
    btn: {
       height: "36px !important",
       fontSize: "14px !important"
   },
    unitsInfoHeader: {
     backgroundColor: "#f6f5f6",
     borderBottom: "1px solid #d5d2d5",
     padding: "0 14px",
     marginBottom: "14px",
     height: "50px",
     display: "flex",
     alignItems: "center",
     justifyContent: "space-between"
   },
    unitInfoTypo: {
     fontFamily: "Roboto",
     fontSize: "16px",
     fontWeight: 500,
     color: "#4b1c46",
     lineHeight: "50px"
   },
    mediumCell: {
      width: "20ch",
      minWidth: "20px"
   },
    rangeError: {
      color: "red"
   },
    unFocusedSelect: {
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      }
  },
    Wrapper: {
      width: "100%",
      height: "100%",
      overflowY: "auto"
   },
    loader: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%"
   },
    message: {
    fontSize: "26px",
    color: "#545964"
   }
  });
