import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { IServiceTypes } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { makeFullAddressForSite, makeFullName } from "../../utils/CommonUtils";
import { IAuditRow } from "./AuditsList";

interface INameVal {
  name: string;
  value?: string;
}

const showValues = (rows: INameVal[]) => {
  return (
    <div>
      {rows.map((row, i) => (
        <div key={i}>
          {row.name}:{" "}
          <span style={{ fontWeight: 500 }}>{_.isUndefined(row.value) ? "-" : row.value}</span>
        </div>
      ))}
    </div>
  );
};

export const showAuditAction: React.FC<any> = (audit: IAuditRow) => {
  const ret = auditActionMap[audit.actionId];

  if (!ret) { return <span>{audit.actionId}</span>; }
  return <span>{ret.name}</span>;
};

export const showAuditData: React.FC<any> = (audit: IAuditRow, types: IServiceTypes) => {
  const ret = auditActionMap[audit.actionId];
  if (!ret) {
    return <pre style={{ fontSize: 10 }}>{false ? "-" : JSON.stringify(audit, null, 3)}</pre>;
  } else {
    return ret.description({ audit, types });
  }
};

// const actionDescription = (audit: IAuditRow, actionValues: INameVal[]) => {
//   if (_.isUndefined(audit)) return actionDesc;
//   else return showValues(actionValues);
// };
interface IAuditActionMap {
  [action: string]: {
    name: string;
    description: (params: { audit: IAuditRow; types?: IServiceTypes }) => JSX.Element;
  };
}

export const auditActionMap: IAuditActionMap = {
  rootCreateCustomer: {
    name: t`Created Root Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Customer`, value: audit?.data?.name },
        { name: t`Description`, value: audit?.data?.description }
      ])
  },
  setAcknowledgedEvent: {
    name: t`Acknowledge Alert`,
    description: ({ audit }) => {
      return showValues([
        // { name: t`Customer`, value: audit?.data?.name },
        // { name: t`Description`, value: audit?.data?.description }
      ]);
    }

  },
  refreshDevice: {
    name: t`Refresh Device`,
    description: ({ audit }) => {
      return showValues([
        // { name: t`Customer`, value: audit?.data?.name },
        // { name: t`Description`, value: audit?.data?.description }
      ]);
    }

  },
  setUnAcknowledgedEvent: {
    name: t`Cancel Acknowledge Alert`,
    description: ({ audit }) => {
      return showValues([
        // { name: t`Customer`, value: audit?.data?.name },
        // { name: t`Description`, value: audit?.data?.description }
      ]);
    }

  },
  createCustomer: {
    name: t`Created Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.name },
        { name: t`Fullname`, value: audit?.data?.description },
        { name: t`Email`, value: makeFullAddressForSite(audit.data as ISite) },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateCustomer: {
    name: t`Updated Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteCustomer: {
    name: t`Deleted Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  createCustomerUser: {
    name: t`Created Customer User`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.username },
        { name: t`Fullname`, value: audit?.data?.firstName + " " + audit?.data?.firstName },
        { name: t`Email`, value: audit?.data?.email },
        { name: t`Phone`, value: audit?.data?.phone },
        { name: t`Temperature Scale`, value: types?.temperatureScale[audit?.data.temperatureScale] }
      ])
  },
  rootCreateCustomerUser: {
    name: t`Created Root Customer User`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.username },
        { name: t`Fullname`, value: audit?.data?.firstName + " " + audit?.data?.firstName },
        { name: t`Email`, value: audit?.data?.email },
        { name: t`Temperature Scale`, value: types?.temperatureScale[audit?.data.temperatureScale] }
      ])
  },
  createSite: {
    name: t`Created Site`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.data?.name },
        { name: t`Description`, value: audit?.data?.description },
        { name: t`Address`, value: makeFullAddressForSite(audit.data as ISite) },
        { name: t`Time Zone`, value: audit?.data?.timezone }
      ])
  },
  updateSite: {
    name: t`Updated Site`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.data?.name },
        { name: t`Description`, value: audit?.data?.description },
        { name: t`Address`, value: makeFullAddressForSite(audit.data as ISite) },
        { name: t`Time Zone`, value: audit?.data?.timezone }
      ])
  },
  deleteSite: {
    name: t`Deleted Site`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.siteName }
      ])
  },
  createSiteUser: {
    name: t`Created Site User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Description`, value: audit?.data?.description },
        { name: t`Address`, value: makeFullAddressForSite(audit.data as ISite) }
      ])
  },
  createDevice: {
    name: t`Created Device`,
    description: ({ audit }) =>
      showValues([
        { name: t`Device`, value: audit?.data?.name },
        { name: t`Serial`, value: audit?.data?.serial },
        { name: t`Connected`, value: audit?.data?.isRegistered ? t`yes` : t`no` }
      ])
  },
  updateDevice: {
    name: t`Updated Device`,
    description: ({ audit }) =>
      showValues([
        { name: t`Device`, value: audit?.data?.name },
        { name: t`Serial`, value: audit?.data?.serial },
        { name: t`Connected`, value: audit?.data?.isRegistered ? t`yes` : t`no` }
      ])
  },
  deleteDevice: {
    name: t`Deleted Device`,
    description: ({ audit }) =>
      showValues([
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  // createZone: "createZone",
  // updateZone: "updateZone",
  // deleteZone: "deleteZone",
  // addZoneUnit: "addZoneUnit",
  // removeZoneUnit: "removeZoneUnit",
  // setZoneSetpoint: "setZoneSetpoint",
  // setZoneOperationStatus: "setZoneOperationStatus",
  // setZoneOperationMode: "setZoneOperationMode",
  // setZoneFanMode: "setZoneFanMode",
  // setZoneSwingMode: "setZoneSwingMode",
  createGroup: {
    name: t`Created Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Type`, value: audit?.data?.type },
        { name: t`Description`, value: audit?.data?.description }
      ])
  },
  updateGroup: {
    name: t`Updated Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteGroup: {
    name: t`Deleted Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },

  // addGroupUnit: "addGroupUnit",
  // removeGroupUnit: "removeGroupUnit",
  setGroupSetpoint: {
    name: t`Set Group Setpoint`,
    description: ({ audit }) =>
      showValues([
        { name: t`Setpoint`, value: audit?.data?.value }
      ])
  },
  setGroupOperationStatus: {
    name: t`Set Group Operation Status`,
    description: ({ audit }) =>
      showValues([
        { name: t`Operation Status`, value: audit?.data?.value }
      ])
  },
  setGroupOperationMode: {
    name: t`Set Group Operation Mode`,
    description: ({ audit }) =>
      showValues([
        { name: t`Operation Mode`, value: audit?.data?.value }
      ])
  },
  setGroupFanMode: {
    name: t`Set Group Fan Mode`,
    description: ({ audit }) =>
      showValues([
        { name: t`Fan Mode`, value: audit?.data?.value }
      ])
  },
  setGroupSwingMode: {
    name: t`Set Group Swing Mode`,
    description: ({ audit }) =>
      showValues([
        { name: t`Swing Mode`, value: audit?.data?.value }
      ])
  },
  createAlertGroup: {
    name: t`Created Alert Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  updateAlertGroup: {
    name: t`Updated Alert Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteAlertGroup: {
    name: t`Deleted Alert Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },

  createAlertGroupUser: {
    name: t`Created Alert Group User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  updateAlertGroupUser: {
    name: t`Updated Alert Group User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteAlertGroupUser: {
    name: t`Deleted Alert Group User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },

  // createSystem: "createSystem",
  // createSystemAuto: "createSystemAuto",
  // updateSystem: "updateSystem",
  // deleteSystem: "deleteSystem",
  // addSystemUnit: "addSystemUnit",
  // addSystemUnitAuto: "addSystemUnitAuto",
  // removeSystemUnit: "removeSystemUnit",
  // setSystemSetpoint: "setSystemSetpoint",
  // setSystemOperationStatus: "setSystemOperationStatus",
  // setSystemOperationMode: "setSystemOperationMode",
  // setSystemFanMode: "setSystemFanMode",
  // setSystemSwingMode: "setSystemSwingMode",

  updateUnit: {
    name: t`Updated Unit`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteUnit: {
    name: t`Deleted Unit`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  clearUnitFilter: {
    name: t`Clear unit filter`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },

  setUnitSetpoint: {
    name: t`Set Unit Setpoint`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Setpoint`, value: audit?.data.value }
      ])
  },

  setUnitOperationStatus: {
    name: t`Set Unit Operation Status`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Operation`, value: types?.operationStatuses[audit?.data.value] }
      ])
  },

  setUnitOperationMode: {
    name: t`Set Unit Operation Mode`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Operation`, value: types?.operationModes[audit?.data?.value] }
      ])
  },

  setUnitFanMode: {
    name: t`Set Unit Fan Mode`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Fan`, value: types?.fanModes[audit?.data?.value] },
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  setUnitSwingMode: {
    name: t`Set Unit Swing Mode`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Swing`, value: types?.swingModes[audit?.data?.value] },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  autoIndoorUnitMapping: {
    name: t`Automatic indoor unit mapping`,
    description: ({ audit }) =>
      showValues([
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Line`, value: audit?.line?.toString() }
      ])
  },
  setSystemOperationMode: {
    name: t`set System Operation Mode`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`setSystemOperationMode`, value: audit?.data?.value },
        { name: t`Operation`, value: types?.operationStatuses[audit?.data.value] }

      ])
  },
  setSystemOperationStatus: {
    name: t`set System Operation Status`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`setSystemOperationStatus`, value: audit?.data?.value },
        { name: t`Operation`, value: types?.operationStatuses[audit?.data.value] }

      ])
  },
  createSchedule: {
    name: t`Created Schedule`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName }
      ])
  },
  updateSchedule: {
    name: t`Updated Schedule`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteSchedule: {
    name: t`Deleted Schedule`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },

  createInvite: {
    name: t`Created Invite`,
    description: ({ audit }) =>
      showValues([
        { name: t`User Fullname`, value: makeFullName(audit?.data) },
        { name: t`Email`, value: audit?.data?.email },
        { name: t`Is Expiring`, value: audit?.data?.isExpiring.toString() }
      ])
  },
  acceptInvite: {
    name: t`Accepted Invite`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.siteName }
      ])
  },
  deleteInvite: {
    name: t`Deleted Invite`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  updateUser: {
    name: t`Updated User`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.username },
        { name: t`Fullname`, value: audit?.data ? makeFullName(audit?.data) : "" },
        { name: t`Email`, value: audit?.data?.email },
        { name: t`Temperature Scale`, value: types?.temperatureScale[audit?.data.temperatureScale] }
      ])
  },
  deleteUser: {
    name: t`Deleted User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.name },
        { name: t`Fullname`, value: audit?.data ? makeFullName(audit?.data) : "" }
      ])
  },

  updateUserPassword: {
    name: t`Updated User Password`,
    description: ({ audit }) =>
      showValues([
        { name: t`User`, value: audit?.user }
      ])
  },

  createTrap: {
    name: t`Created Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Enabled`, value: audit?.data?.isEnabled.toString() },
        { name: t`Send Once`, value: audit?.data?.sendOnce.toString() },
        { name: t`Template`, value: audit?.data?.template },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  updateTrap: {
    name: t`Updated Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteTrap: {
    name: t`Deleted Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  createTrigger: {
    name: t`Created Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Enabled`, value: audit?.data?.isEnabled.toString() },
        { name: t`Send Once`, value: audit?.data?.sendOnce.toString() },
        { name: t`Template`, value: audit?.data?.template },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  updateTrigger: {
    name: t`Updated Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteTrigger: {
    name: t`Deleted Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },

  createTriggerTemplate: {
    name: t`Created Trigger Template`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  updateTriggerTemplate: {
    name: t`Updated Trigger Template`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  deleteTriggerTemplate: {
    name: t`Deleted Trigger Template`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  createSystem: {
    name: t`Created System`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Type`, value: audit?.data?.type }])
  },
  removeSystemUnit: {
    name: t`Removed System Unit`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Type`, value: audit?.data?.type }])
  },
  addSystemUnit: {
    name: t`Added System Unit`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Type`, value: audit?.data?.type }])
  },
  updateSystem: {
    name: t`Updated System`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Type`, value: audit?.data?.type }])
  },
  deleteEvent: {
    name: t`Deleted Alert`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Type`, value: audit?.data?.type }])
  },
  setUnitServiceParam: {
    name: t`Set Service Parameter`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Device`, value: audit?.deviceName }
      ])
  },
  eWrcTStatLocks: {
    name: t`Set unit WRC restrictions`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName }
      ])
  },
  createProcedure: {
    name: t`Create procedure`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  updateProcedure: {
    name: t`Update procedure`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  runProcedure: {
    name: t`Run procedure`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  stopProcedure: {
    name: t`Stop procedure`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  rerunProcedure: {
    name: t`Re-run procedure`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  deleteProcedure: {
    name: t`Delete procedure`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  deleteProcedureReport: {
    name: t`Delete procedure report`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  unitLimitsScheduleOn: {
    name: t`unit app restrictions schedule start`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  unitLimitsScheduleOff: {
    name: t`unit app restrictions schedule end`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  eWrcLimitsScheduleOn: {
    name: t`unit WRC restrictions schedule start`,
    description: ({ audit }) =>
      showValues([
      ])
  },
  eWrcLimitsScheduleOff: {
    name: t`unit WRC restrictions schedule end`,
    description: ({ audit }) =>
      showValues([
      ])
  }
};
