import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { Field, FormikProps } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import CoolButton from "../../../cool_widgets/Button";
import { Arrow as SvgArrow, Close } from "../../../icons/";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import { ISystem } from "../../../models/Systems";
import { IUnit } from "../../../models/Units";
import InputField from "../InputField";
import useStyles from "./SystemDetails.style";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
  errors: any;
  touched: any;
  classes: any;
  isDisabled: boolean;
  mode: any;
  deviceUnits: IUnit[];
  system: ISystem | null;
  isSystemDaikin: (system: ISystem | null | undefined) => boolean;
  className?: any;
  closeModal?: any;
  device?: any;
  setForceReRender?: any;
  setLoading?: any;
  deviceLines?: any;
}

const SystemDetails: React.FC<IProps> = ({
  errors,
  touched,
  values,
  isDisabled,
  mode,
  setFieldValue,
  deviceUnits,
  system,
  className,
  isSystemDaikin,
  setLoading,
  device,
  closeModal,
  deviceLines
}) => {
  const classes = useStyles();
  const isCreate = mode === 1;
  const types = useStoreState((state) => state.types);
  const { unitTypes, systemTypes: allSystemTypes, hvacBrands: allBrands, hvacBrands, capacityMeasurementUnitTypes } = types;
  const mapLineIndoors = useStoreActions((actions) => actions.devices.mapLineIndoors);
  const mapLines = useStoreActions((actions) => actions.devices.mapLines);
  const getUnits = useStoreActions((actions) => actions.units.getUnits);
  const [isAutoMappingDialogOpen, setIsAutoMappingDialogOpen] = React.useState(false);
  const [IsLinesLoading, setIsLinesLoading] = useState<boolean>(true);
  const [lines, setLines] = useState<any>(null);

  const getBrandName = (value: any) => {
    return hvacBrands?.filter((item) => item.value === value)[0]?.name;
  };

  useEffect(() => {
    if (isCreate) {
      if (_.isEmpty(deviceLines)) {
        return;
      }

      const linesArr = deviceLines.reduce((linesObj: any, line: any) => {
        const brand = getBrandName(line.brand);
        linesObj.push({
          value: line.id,
          label: `${line.id} ${line.brand !== 999 ? " - " + brand : ""}`
        });
        return linesObj;
      }, []);
      if (linesArr.length === 1) {
        setFieldValue("line", linesArr[0].value);
      }
      setLines(_.orderBy(linesArr, ["value"], ["asc"]));
      setIsLinesLoading(false);
    }

  }, [deviceLines]);

  const dissociateControlUnitFromServiceUnit = useStoreActions((action) => action.dissociateControlUnitFromServiceUnit);

  const updateSystem = useStoreActions((action) => action.systems.updateSystem);

  const controlLines = () => {
    let lineArr = [];
    if (system && system.brandNum === 12) {
      const controlUnits = _.filter(deviceUnits, (unit: IUnit) => ((unit.line !== system.line) && (unit.type === unitTypes.indoor)));
      for (let unit of controlUnits) {
        if (!_.includes(lineArr, unit.line)) {
          lineArr.push(unit.line);
        }
      }
    }
    return lineArr;
  };
  const allControlLines = [
    {
      value: 0,
      label: "None"
    }
  ];
  _.map(controlLines(), (value) => {
    allControlLines.push({
      value,
      label: `L${value}`
    });
  });
  const systemTypes = [
    {
      value: "other",
      label: "Other"
    }
  ];
  _.map(allSystemTypes, (key, value) => {
    systemTypes.push({
      value: key,
      label: key
    });
  });
  const brands = [
    {
      value: 999,
      label: "Other"
    }
  ];
  _.map(allBrands, (brand) => {
    brands.push({
      value: brand.value,
      label: brand.name
    });
  });
  const onChange = async (value: any) => {
    setLoading(true);
    if (values.unitsChanges.length) {

      for (let unit of values.unitsChanges[0]) {

        if (!_.isUndefined(unit) && unit.controlUnit && unit.type === types.unitTypes.service) {

          await dissociateControlUnitFromServiceUnit({ id: unit.id }).catch((err: any) => {
          });
        }
      }
      if (value && system) {
        await mapLines({ deviceId: device.id, serviceLine: system.line.toString(), controlLine: value }).catch((err: any) => { });
        await getUnits();
      }
    }
    system && await updateSystem({
      systemId: system.id, updatedData: { controlLine: value }
    });
    setLoading(false);

  };
  const openAutoMappingDialog = () => {
    setIsAutoMappingDialogOpen(true);
  };
  const closeAutoMappingDialog = () => {
    setIsAutoMappingDialogOpen(false);
  };
  const startAutoMapping = async () => {
    if (system) {
      await mapLineIndoors({ deviceId: system.device, lineId: system.line.toString() });
    }
    closeModal();
    setIsAutoMappingDialogOpen(false);
  };
  const autoMappingEnabled = isSystemDaikin(system);
  const numberOfLineServiceUnits = system && autoMappingEnabled
    ? _.filter(deviceUnits, (unit: IUnit) => ((unit.line === system.line) && (unit.type === unitTypes.service))).length
    : 0;

  const estimatedTime = Math.round(((numberOfLineServiceUnits / 15) + 1) * 6);

  if (isCreate && IsLinesLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Card className={clsx(classes.systemContainer, className || "")}>
        <Typography className={classes.systemInfoTitle}> {t`System Information`}</Typography>
        <div className={classes.systemControlsContainer}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            style={{ padding: "14px" }}
          >
            <InputLabel className={classes.labelStyle} style={{ width: "34ch", minWidth: "34ch" }}>{t`Name`}
              <Field
                value={values.name}
                name="name"
                component={InputField}
                error={errors.name && touched.name ? true : false}
                helperText={errors.name && touched.name ? errors.name : ""}
                className={classes.addMargin}
              />
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "27ch", minWidth: "27ch" }}>{t`Brand`}
              <Field
                value={values.brand}
                name="brand"
                options={brands}
                error={errors.brand && touched.brand ? true : false}
                helperText={errors.brand && touched.brand ? errors.brand : ""}
                component={Select}
                classes={{ icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                variant="outlined"
                margin="none"
                className={classes.addMargin}
                style={{ maxHeight: "44px", height: "44px" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              />
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "22ch", minWidth: "22ch" }}>{t`Type`}

              <Field
                label={""}
                name={"type"}
                options={systemTypes}
                component={Select}
                variant="outlined"
                // value={values.type ? values.type : (systemTypes ? systemTypes[0]: '')}
                error={errors.type && touched.type ? true : false}
                helperText={errors.type && touched.type ? errors.type : ""}
                className={classes.addMargin}
                classes={{ icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                margin="none"
                style={{ maxHeight: "44px", height: "44px" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              />
            </InputLabel>
            {/* <Autocomplete
              // label={t`Series`}
              name='type'
              suggestions={systemTypes}
              value={values.type}
              className={classes.autocomplete}
              /> */}
            {/* <Field
                // label={t`Types`}
                value={values.type}
                name="type"
                options={systemTypes}
                component={AutocompleteField}
                onChange={(e: any, value: any) => setFieldValue("type", value)}
                variant="outlined"
                error={((errors.type && touched.type)) ? true : false}
                helperText={(errors.type && touched.type) ? errors.type : ""}
                /> */}
            <InputLabel className={classes.labelStyle} style={{ width: "22ch", minWidth: "22ch" }}>{t`Series`}
              <Field
                name={"series"}
                value={values.series}
                component={InputField}
                variant="outlined"
                error={errors.series && touched.series ? true : false}
                helperText={errors.series && touched.series ? errors.series : ""}
                className={classes.addMargin}
              />
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "32ch", minWidth: "32ch" }}>{t`Capacity`}
              <div style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between" }} className={classes.addMargin}>
                <div style={{ width: "100%", marginRight: "14px" }} >
                  <Field
                    value={values.capacity}
                    component={InputField}
                    // label={t`Capacity`}
                    name="capacity"
                    variant="outlined"
                    error={errors.capacity && touched.capacity ? true : false}
                    helperText={errors.capacity && touched.capacity ? errors.capacity : ""}
                  />
                </div>
                <Field
                  label={""}
                  name="capacityMeasurementUnits"
                  options={_.map(capacityMeasurementUnitTypes, (key, value) => ({
                    value: key,
                    label: value
                  }))}
                  component={Select}
                  variant="outlined"
                  value={
                    values.capacityMeasurementUnits ??
                    capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]]
                  }
                  error={
                    errors.capacityMeasurementUnits && touched.capacityMeasurementUnits ? true : false
                  }
                  helperText={
                    errors.capacityMeasurementUnits && touched.capacityMeasurementUnits
                      ? errors.capacityMeasurementUnits
                      : ""
                  }
                  classes={{ icon: classes.arrowIcon }}
                  IconComponent={SvgArrow}
                  margin="none"
                  style={{ maxHeight: "44px", height: "44px" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                />
              </div>
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "7ch", minWidth: "7ch" }}>{t`Line`}
              <Field
                name="line"
                options={isCreate ? lines : [{ value: values.line, label: values.line }]}
                component={Select}
                value={!isCreate ? values.line : lines?.length === 1 ? lines[0].value : values.line}
                variant="outlined"
                error={errors.line && touched.line ? true : false}
                helperText={errors.line && touched.line ? errors.line : ""}
                disabled={isDisabled || !isCreate}
                classes={{ icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                margin="none"
                className={classes.addMargin}
                style={{ minWidth: isDisabled || !isCreate ? "6ch" : "14ch", height: "44px", maxHeight: "44px" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              />
            </InputLabel>
            {
              system && system.brandNum === 12 &&
              <InputLabel className={classes.labelStyleControlLines} ><span>{t`Associated control line`}</span>
                <Field
                  onChange={(e: any) => onChange(e.target.value)}
                  label={""}
                  name="controlLine"
                  options={allControlLines}
                  component={Select}
                  variant="outlined"
                  value={values.controlLine ? values.controlLine : 0}
                  error={
                    errors.controlLine && touched.controlLine ? true : false
                  }
                  helperText={
                    errors.controlLine && touched.controlLine
                      ? errors.controlLine
                      : ""
                  }
                  classes={{ icon: classes.arrowIcon }}
                  IconComponent={SvgArrow}
                  margin="none"
                  style={{ maxHeight: "44px", height: "44px" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                />
              </InputLabel>
            }

          </Grid>
          {autoMappingEnabled &&
            <Button
              variant="contained"
              color="default"
              onMouseUp={openAutoMappingDialog}
              style={{ margin: "1rem", boxShadow: "0", width: "20rem" }}
            >
              {t`Automatic Address Mapping`}
            </Button>
          }
        </div>
      </Card>
      <Dialog
        open={isAutoMappingDialogOpen}
        onClose={closeAutoMappingDialog}
        aria-labelledby="mapping-dialog-title"
        aria-describedby="mapping-dialog-description"
        maxWidth="sm"
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{(device.protocolVersion >= 2) ? t`Automatic unit address mapping` : t`Auto Mapping in Progress...`}</Typography>
          <IconButton onClick={closeAutoMappingDialog} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography>
            {device.protocolVersion >= 2 ?
              t`Start automatic unit address mapping? This will take ${estimatedTime} minutes to map ${numberOfLineServiceUnits} units. All units will be moved to Fan mode for the whole period. During this time do not make any changes to the units settings. Upon completion, all units will return to their original status (on/off, setpoint, mode). Do you want to continue?`
              : t`Auto mapping requires upgrade to your device FW. Please contact CoolAutomation Support to upgrade`
            }
          </Typography>
        </div>
        <div className={classes.actionsHolder}>
          {
            device.protocolVersion >= 2 ? (

              <>
                <CoolButton
                  onClick={startAutoMapping}
                  white
                  width={100}
                  uppercase
                  marginRight
                >
                  {t`Yes`}
                </CoolButton>
                <CoolButton
                  onClick={closeAutoMappingDialog}
                  autoFocus={true}
                  width={100}
                  uppercase
                >{t`No`}</CoolButton>
              </>) :
              <CoolButton
                onClick={closeAutoMappingDialog}
                width={100}
                uppercase
              >
                {t`OK`}
              </CoolButton>
          }

        </div>
      </Dialog>
    </>
  );
};

export default SystemDetails;
