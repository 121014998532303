import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import Tooltip from "../../components/Tooltip/LightTooltip";
import AuditsDataPopUp from "../../screens/Audits/AuditsDataPopUp";
import CoolDateRangePicker from "../CoolDateRangePicker/CoolDateRangePicker";
import useStyles from "./CoolTable.style";

type Order = "asc" | "desc";

export interface ICoolTableProps<T> {
  headCells: Array<IHeadCell<T>>;
  rows: T[];
  getPageRows: (page: number, rowsPerPage: number, order: Order, orderBy: keyof T) => T[];
  renderCell: (rowData: T, rowId: keyof T) => any;
  defaultOrderBy: keyof T;
  options?: ICoolTableOptions;
}

export interface ICoolTableOptions {
  withCheckboxes?: boolean;
  withToolbar?: boolean;
  // withDatePicker?: boolean;

  rowsPerPage?: number;
  rowsPerPageOptions?: number[];

  classNameForTableContainer?: string;
}
const defaultCoolTableOptions = {
  withCheckboxes: false,
  withToolbar: false,
  // withDatePicker: false,
  rowsPerPage: 13,
  rowsPerPageOptions: [13, 30, 50]
};

export interface ICoolTablePage<T> {
  page: number;
  rowsPerPage: number;
  order: Order;
  orderBy: keyof T;
}

export interface IHeadCell<T> {
  id: keyof T;
  title: string | undefined;
  numeric?: boolean;
  width?: string | number; // css property 'width' on head cell's div, defaults to 'auto'
  disablePadding?: boolean;
  disableSort?: boolean;
  tableCellClassName?: string;
  tableHeadCellClassName?: string;
  minWidth?: string;
}

interface ICoolTableHeadProps<T> {
  headCells: Array<IHeadCell<T>>;
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  tableOptions: ICoolTableOptions;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator<T>(a, b, orderBy)
    : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CoolTable<T extends any>({
  headCells,
  rows,
  getPageRows,
  renderCell,
  defaultOrderBy,
  options
}: ICoolTableProps<T>) {
  options = { ...defaultCoolTableOptions, ...options };

  const [order, setOrder] = React.useState<Order>("desc"),
    [orderBy, setOrderBy] = React.useState<keyof T>(defaultOrderBy),
    [page, setPage] = React.useState(0),
    [rowsPerPage, setRowsPerPage] = React.useState(options.rowsPerPage!),
    [selected, setSelected] = React.useState<string[]>([]),
    [selectedAudit, setSelectedAudit] = React.useState<any>(null),
    [dense, setDense] = React.useState(false),
    classes = useStyles(),
    handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    handleClick = (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    },
    _getPageRows = () => {
      if (getPageRows) return getPageRows(page, rowsPerPage, order, orderBy);
      else
        return stableSort(rows, getComparator(order, orderBy as string)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
    },
    handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDense(event.target.checked);
    },
    isSelected = (id: string) => selected.indexOf(id) !== -1,
    emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const auditInfo = () => {
    return (
      <div className={classes.itemButton}>
        <AuditsDataPopUp
          audit={selectedAudit}
          setIsSeen={setSelectedAudit} />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {selectedAudit && auditInfo()}
      <Paper className={classes.paper}>
        {options.withToolbar && <CoolTableToolbar numSelected={selected.length} />}
        <div className={classes.tableMenu}>
          {/* {options.withDatePicker && <CoolDateRangePicker range="last 7 days" />} */}
          <TablePagination
            backIconButtonProps={{disableRipple: true, classes: {root: classes.iconBtnStyle}}}
            nextIconButtonProps={{disableRipple: true, classes: {root: classes.iconBtnStyle}}}
            className={classes.paginationHeader}
            rowsPerPageOptions={options.rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <TableContainer classes={{ root: options.classNameForTableContainer }}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <CoolTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy ? (orderBy as string) : ""}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              tableOptions={options}
            />

            <TableBody>
              {_getPageRows().map((row, index) => {
                const isItemSelected = isSelected(row.id),
                  labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <Tooltip key={index} title="Double click to see full audit information">
                    <TableRow
                      hover
                      onClick={(event) => (options?.withCheckboxes ? handleClick(event, row.id) : null)}
                      onDoubleClick={() => setSelectedAudit(row)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    // classes={{ root: classes.overWritePadding }}
                    >
                      {options?.withCheckboxes! && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="default"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            classes={{ root: classes.grayCheckbox }}
                          />
                        </TableCell>
                      )}
                      {_.map(headCells, (col, index) => (
                        <TableCell
                          key={index}
                          // classes={{ root: classes.overWritePadding }}
                          align="left"
                          className={clsx(classes.tableCell, col.tableCellClassName)}
                        >
                          {renderCell ? renderCell(row, col.id) : row[col.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  </Tooltip>
                );
              })}
            </TableBody>

          </Table>
        </TableContainer>
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}

function CoolTableHead<T>({
  headCells,
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  tableOptions
}: ICoolTableHeadProps<T>) {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableOptions.withCheckboxes && (
          <TableCell padding="checkbox" classes={{ root: classes.tableHeadCell }}>
            <Checkbox
              color="default"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              classes={{ root: classes.grayCheckbox }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: !_.isUndefined(headCell.width) ? headCell.width : "auto",
              minWidth: !_.isUndefined(headCell.minWidth) ? headCell.minWidth : "auto"
            }}
            className={headCell.tableHeadCellClassName}
            classes={{ root: classes.tableHeadCell }}
          >
            {!headCell.disableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.title}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
                <span>{headCell.title}</span>
              )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
    title: {
      flex: "1 1 100%"
    }
  })
);

interface CoolTableToolbarProps {
  numSelected: number;
}

const CoolTableToolbar = (props: CoolTableToolbarProps) => {
  const classes = useToolbarStyles(),
    { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Nutrition
          </Typography>
        )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
    </Toolbar>
  );
};
