import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  Typography
} from "@material-ui/core";
import AcUnit from "@material-ui/icons/AcUnit";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import CoolTable, { ICoolTablePage, IHeadCell } from "../../components/CoolTable/CoolTable";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Tooltip from "../../components/Tooltip/LightTooltip";
import {
  AppUser as UserIcon,
  HomeAuto as HomeIcon,
  HVACIcon,
  Timer as ScheduleIcon
} from "../../icons/";
import { IAuditMap } from "../../models/Audits";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { checkDateInRange } from "../../services/timeService";
import { auditActionMap, showAuditAction, showAuditData } from "./AuditsActionMap";
import { AuditsFilter, IAuditsFilter } from "./AuditsFilter";
import useStyle from "./AuditsList.style";

export interface IAuditRow {
  icon: any;
  date: string;
  actorId: string;
  actionId: string;
  // actionOnSubject: { actionId: string; action: string; subject: string };
  data: any;
  // optional fields
  unitId?: string;
  deviceId?: string;
  siteId?: string;
  systemId?: string;
  customerId?: string;
  unitName?: string;
  deviceName?: string;
  siteName?: string;
  customerName?: string;
  user?: string;
  source?: number;
  line?: number;
  sourceType?: string;
  serviceUnits?: any;
  controlUnit?: any;
  serviceSystem?: any;
  type?: any;
  onType?: any;
  timezone?: string;
}
type IAuditRowField = keyof IAuditRow;

type Order = "asc" | "desc";

const AuditsList: React.FC = (props: any) => {
  const classes = useStyle(),
    getMyAudits = useStoreActions((s) => s.audits.getMyAudits),
    isInitialized = useStoreState((s) => s.isInitialized),
    allUsers = useStoreState((s) => s.users.users),
    getSite = useStoreState((s) => s.sites.getSite),
    getSystem = useStoreState((s) => s.systems.getSystem),
    getUnitName = useStoreState((s) => s.units.getUnitName),
    getCustomerName = useStoreState((s) => s.customers.getCustomerName),
    getDeviceName = useStoreState((s) => s.devices.getDeviceName),
    getDevice = useStoreState((s) => s.devices.getDevice),
    getSiteName = useStoreState((s) => s.sites.getSiteName),
    getFullName = useStoreState((s) => s.users.getFullName),
    getUsername = useStoreState((s) => s.users.getUsername),
    { timeFormat, dateFormat } = useStoreState((state) => state.users),
    temperatureScaleDisplay = useStoreState((s) => s.users.getTemperatureScaleDisplayPlainText),
    types = useStoreState((s) => s.types),
    [audits, setAudits] = useState<IAuditRow[]>([]),
    [actorsFilter, setActorsFilter] = useState<IAuditsFilter>({}),
    [actionsFilter, setActionsFilter] = useState<IAuditsFilter>({}),
    selections = useStoreState((s) => s.selections.selections),
    setSelections = useStoreActions((s) => s.selections.setSelections),
    [loading, setLoading] = useState(false),
    setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus),
    allUnits = useStoreState((state) => state.units.allUnits),
    allSystems = useStoreState((s) => s.systems.allSystems),
    allSites = useStoreState((s) => s.sites.allSites);
  setUnitUpdateStatus({ status: "" });
  useEffect(() => {
    if (!!selections?.dateRange?.endDate && !!selections?.dateRange?.startDate) {
      return;
    }
    setSelections({
      customerId: null,
      siteId: null,
      unitId: null,
      dateRange: {
        startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
        endDate: new Date()
      }
    });
  }, []);

  useEffect(() => {
    if (!selections.dateRange) {
      return;
    }

    (async () => {
      await fetchAudits();

    })();
  }, [selections.dateRange]);

  const fetchAudits = async () => {
    if (!selections.dateRange) {
      return;
    }

    setLoading(true);

    // Get and parse audits from API into our current state
    const startTime = Date.UTC(selections.dateRange?.startDate.getFullYear(), selections.dateRange?.startDate.getMonth(), selections.dateRange?.startDate.getDate()) - 32400000;
    const endTime = Date.UTC(selections.dateRange?.endDate.getFullYear(), selections.dateRange?.endDate.getMonth(), selections.dateRange?.endDate.getDate(), 23, 59, 59) + 32400000;

    const auditMap: IAuditMap = await getMyAudits({params: {startTime, endTime}}),
    auditsArr: any = parseAudits(auditMap);

    auditsArr.length && setAudits(auditsArr);

    // Create filters from users and actions
    const actorsFilterMap = parseFilter(allUsers, "id");
    setActorsFilter({"all": true, ...actorsFilterMap});
    const actionsFilterMap = parseFilter(auditMap, "action");
    setActionsFilter(actionsFilterMap);

    setLoading(false);
  },
    // Parse IAuditMap received form API into our internal structure: IAuditRow
    parseAudits = (auditMap: IAuditMap) => {
      const auditsArr = Object.values(auditMap);
      const filteredAudtis: any = [];

      for (let i = 0; i < auditsArr.length; i++) {
        let auditAPI: any = auditsArr[i];

        if (!auditAPI) return [];

        const timezone = getSite(auditAPI.site)?.timezone || moment.tz.guess();

        if (!checkDateInRange(moment(selections.dateRange?.startDate).format("MM/DD/YYYY"),
       moment(selections.dateRange?.endDate).format("MM/DD/YYYY"),
       moment(auditAPI.timestamp).tz(timezone).format("MM/DD/YYYY"))
     ){
       continue;
     }

        if (!(auditAPI.unit && !allUnits[auditAPI.unit])) {
          if (auditAPI.system && (!auditAPI.site || auditAPI.customer)) {
            if (!auditAPI.site) {
              const device = getSystem(auditAPI.system)?.device;
              auditAPI.site = getDevice(device)?.site;

            }
            auditAPI.customer = auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer;
          }

          const row: IAuditRow = {
            icon: <AcUnit />,
            date: auditAPI.timestamp,
            actorId: auditAPI.actor,
            actionId: auditAPI.action,
            data: auditAPI.value ? { value: auditAPI.value } : auditAPI.data,
            unitId: auditAPI.unit,
            unitName: getUnitName(auditAPI.unit),
            siteId: auditAPI.site,
            systemId: auditAPI.system,
            siteName: getSiteName(auditAPI.site),
            deviceId: auditAPI.device,
            deviceName: getDeviceName(auditAPI.device),
            customerId: auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer,
            customerName: getCustomerName(
              auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer
            ),
            user: auditAPI?.user ? getFullName(auditAPI.user) : "-",
            line: auditAPI.line,
            source: auditAPI.source,
            sourceType: auditAPI?.sourceType,
            onType: auditAPI.unit && allUnits[auditAPI.unit] ? allUnits[auditAPI.unit].type : null,
            timezone
          };
          row.onType && row.onType !== 3 && (row.serviceUnits = allUnits[auditAPI.unit].serviceUnits);
          filteredAudtis.push(row);
        }

      }
      return filteredAudtis;

      // return _.map(Object.values(auditMap), auditAPI => {
      // Split camelcase action description into separate words
      // const words = audit.action.replace(/([A-Z])/g, ',$1').split(',');
      // let actorId = getFullName(auditAPI.actor);
      // Action name can be one word or two words if it starts with 'root'
      // let actionId = words.shift()?.toLowerCase();
      // let secondWord: string | undefined = '';
      // if (actionId === 'root') {
      //   actorId = actionId;
      //   secondWord = words.shift();
      // }
      // if (secondWord) actionId = secondWord.toLowerCase();
      // Subject name is the rest of the action description
      // const subjectName = words.join(' ');
      // //console.log('PARSING :', auditAPI.action, words, actionId, subjectName);
      // Construct table row from parsed data
      // const row: IAuditRow = {
      //   icon: <AcUnit />,
      //   date: auditAPI.timestamp,
      //   actorId: auditAPI.actor,
      //   actionId: auditAPI.action,
      //   data: auditAPI.value ? { value: auditAPI.value } : auditAPI.data,
      //   unitId: auditAPI.unit,
      //   unitName: getUnitName(auditAPI.unit),
      //   siteId: auditAPI.site,
      //   siteName: getSiteName(auditAPI.site),
      //   deviceId: auditAPI.device,
      //   deviceName: getDeviceName(auditAPI.device),
      //   customerId: auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer,
      //   customerName: getCustomerName(
      //     auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer
      //   ),
      //   user: auditAPI?.user ? getFullName(auditAPI.user) : '-',
      //   source: auditAPI.source
      // };
      // return row;
      // });
    },
    parseFilter = (valueMap: { [key: string]: any }, field: string) => {
      const filterMap: IAuditsFilter = {};
      _.forEach(Object.keys(valueMap), (key) => {
        let fieldValue: string = valueMap[key][field];
        if (_.isUndefined(fieldValue)) {
          filterMap["undefined"] = true;
        } else {
          if (fieldValue.includes("Trigger")) {
            fieldValue = fieldValue.replace(/Trigger/g, "Trap");
          }
          filterMap[fieldValue] = true;
        }
      });
      return filterMap;
    },
    // Apply filters and selections to audits
    getFilteredAudits = (audits: IAuditRow[]) => {
      function applyFilters(audits: IAuditRow[]) {
        if (actorsFilter.all) {
          return _(audits)
            .filter((audit) => actionsFilter[audit.actionId?.replace(/Trigger/g, "Trap")])
            .value();
        }
        return _(audits)
          .filter((audit) => actorsFilter[audit.actorId])
          .filter((audit) => actionsFilter[audit.actionId])
          .value();
      }
      function applySelections(audits: IAuditRow[]) {
        return _(audits)
          .filter((audit: any) => {

            return selections.unitId ? (audit.unitId === selections.unitId || (audit.unitId ? audit.unitId === allUnits[selections.unitId].controlUnit : false)) : true;

          })
          .filter((audit: any) => {
            if (selections.unitId) return true;

            //if service check selectionsystem.includes its id else check its service units
            if (selections.systemId) {
              if (audit.systemId) return audit.systemId === selections.systemId;
              if (audit.onType === 3) {
                if (allSystems[selections.systemId]) {
                  return allSystems[selections.systemId].units.includes(audit.unitId);
                }
                else {
                  return allUnits[audit.unitId].line.toString() === selections.systemId.split("_")[0] && allUnits[audit.unitId].device === selections.systemId.split("_")[1] && selections.siteId && _.includes(allSites[selections.siteId].devices, allUnits[audit.unitId].device) ? true : false;
                }

              } else {

                return audit.serviceUnits && allSystems[selections.systemId] ? allSystems[selections.systemId].units.includes(audit.serviceUnits[0]) : false;
              }
            }

            return true;

          })
          .filter((audit: any) => {
            if (selections.systemId) return true;
            return selections.siteId ? audit.siteId === selections.siteId : true;
          })
          .filter((audit: any) =>

            selections.customerId ? audit.customerId === selections.customerId : true
          )
          .value();

      }
      return applyFilters(applySelections(audits));
    },
    // Get one page out of audits array
    getAuditsPage = ({ page, rowsPerPage, order, orderBy }: ICoolTablePage<IAuditRow>) => {
      function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) { return order; }
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }
      function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
        return order === "desc"
          ? (a, b) => descendingComparator<T>(a, b, orderBy)
          : (a, b) => -descendingComparator<T>(a, b, orderBy);
      }

      return stableSort<IAuditRow>(getFilteredAudits(audits), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    };
  // Define columns (table header)
  interface IAuditTableColumn extends IHeadCell<IAuditRow> {
    id: IAuditRowField;
    title: string | undefined;
    tableCellClassName?: string | undefined;
  }

  const tableColumns: IAuditTableColumn[] = [
    {
      id: "source",
      title: t`Source`,
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      width: "15ch"

    },
    {
      id: "unitId",
      title: t`Unit`,
      // width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      minWidth: "20ch",
      tableCellClassName: classes.tableCell__unit
    },
    {
      id: "siteId",
      title: t`Site`,
      width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      minWidth: "20ch",
      tableCellClassName: classes.tableCell__unit
    },
    {
      id: "customerId",
      title: t`Customer`,
      width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__customer
    },
    {
      id: "actionId",
      title: t`Action`,
      width: "22ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__actionOnSubject,
      minWidth: "20ch"
    },
    {
      id: "actorId",
      title: t`User`,
      width: "20ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__actor,
      minWidth: "15ch"
    },
    {
      id: "date",
      title: t`DATE/TIME`,
      width: "26ch",
      minWidth: "26ch",
      disablePadding: true,
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__date
    }
    // {
    //   id: 'subjectName',
    //   title: 'Subject',
    //   tableCellClassName: classes.tableCell__subject
    // },
  ],
    // Returns JSX formatted cell value for MUI Table
    formatAuditRowField = (audit: IAuditRow, id: IAuditRowField) => {
      const value = audit[id];
      switch (id) {
        case "icon":
          return null;
        case "date":
          const {timezone} = audit;
          const date = timezone ? moment(value).tz(timezone).format(`ll ${timeFormat}`) : moment(value).format(`ll ${timeFormat}`);
          return date;
        case "actorId":
          // case 'subjectName':
          return getUsername(value);
        case "actionId":
          return showAuditAction(audit);
        case "unitId":
          return audit.unitName;
        case "customerId":
          return audit.customerName;
        case "siteId":
          return audit.siteName;
        case "source":
          if (audit.source === 3) {
            return (
              <Tooltip title={audit.sourceType ? audit.sourceType : t`Home automation / BMS`}>
                <div className={classes.iconWrapper}>
                  <HomeIcon className={classes.homeIcon} />
                </div>
              </Tooltip>
            );
          }
          if (audit.source === 2) {
            return (
              <Tooltip title={t`Scheduled Operation`}>
                <div className={classes.iconWrapper}>
                  <ScheduleIcon className={classes.scheduleIcon} />
                </div>
              </Tooltip>
            );
          }
          if (audit.source === 1) {
            return (
              <Tooltip title={audit.sourceType ? audit.sourceType : t`Application User`}>
                <div className={classes.iconWrapper}>
                  <UserIcon className={classes.userIcon} />
                </div>
              </Tooltip>
            );
          }

          if (audit.source === 4) {
            return (
              <Tooltip title={t`HVAC`}>
                <div className={classes.iconWrapper}>
                  <HVACIcon className={classes.hvacIcon} />
                </div>
              </Tooltip>
            );
          }
          return <div className={classes.noIcon}>-</div>;
        default:
          return value;
      }
    };

  if (!isInitialized) { return <Loading />; }

  const dataPropertiesReplacement: { [key: string]: any } = {
    isRegistered: "Connected",
    isExpired: "Expired"
  },
    notReadable = [
      "customer",
      "isAcceptedTOU",
      "_id",
      "__v",
      "user",
      "userLocationsMetadata",
      "password",
      "randId",
      "id"
    ],
    getReadableData = (data: any, actionId: any) => {
      if (_.isEmpty(data)) {
        return "";
      }
      const lowerCaseAction = actionId.toLowerCase(),
        { value } = data;

      if (lowerCaseAction.includes("setpoint")) {
        return `setpoint value: ${value} ${temperatureScaleDisplay()}`;
      }
      if (lowerCaseAction.includes("operationstatus")) {
        return `operation status: ${types?.operationStatuses[value]}`;
      }
      if (lowerCaseAction.includes("operationmode")) {
        return `operation mode: ${types?.operationModes[value]}`;
      }
      if (lowerCaseAction.includes("fanmode")) {
        return `fan mode: ${types?.fanModes[value]}`;
      }
      if (lowerCaseAction.includes("swingmode")) {
        return `swing mode: ${types?.swingModes[value]}`;
      }

      let dataString = "";
      Object.keys(data).forEach((key: any) => {
        if (typeof data[key] !== "string" || notReadable.includes(key)) {
          return;
        }

        const replacement = dataPropertiesReplacement[key];
        dataString += `${dataString ? "| " : ""}${replacement ? replacement : key}: ${data[key]}`;
      });
      return dataString;
    },
    handleAuditShare = () => {
      const filteredAudtis = getFilteredAudits(audits),
        sortedAudtis = filteredAudtis.sort((a: any, b: any) => {
          const dateA: any = new Date(a.date),
            dateB: any = new Date(b.date);

          return dateB - dateA;
        }),
        headers = "SOURCE,UNIT,SITE,CUSTOMER,ACTION,USER,DATE/TIME,DATA";
      let rows = "";
      const sourceMap = (source: any, sourceType: any) => {
        const sourceMatching: any = {
          1: "Application User",
          2: "Scheduled Operation",
          3: sourceType ? sourceType : "Home Automation",
          4: "HVAC"
        };
        return sourceMatching[source] || "-";
      };
      sortedAudtis.forEach((audit: any) => {
        const formatedData = getReadableData(audit.data, audit.actionId),
          timezone = getSite(audit.siteId)?.timezone || "",
          dateFormated = timezone ? moment(audit.date).tz(timezone).format(`${dateFormat} ${timeFormat}`) : moment(audit.date).format(`${dateFormat} ${timeFormat}`),
          source = sourceMap(audit.source, audit.sourceType),
          row: any = [
            source,
            audit.unitName,
            audit.siteName,
            audit.customerName,
            auditActionMap[audit.actionId]?.name || audit.actionId,
            audit.user,
            dateFormated,
            formatedData
          ];
        rows += row.join(",") + "\r\n";
      });

      let link = window.document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(headers + "\r\n" + rows));
      link.setAttribute("download", "Audits.csv");
      link.click();
    };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["Audit"]}
          // hideCustomerSelection
          // hideSiteSelection
          // hideSystemSelection
          // hideUnitSelection
          showDateRangePicker
        />
        <div className={classes.headerContainer}>
          <Button
            disableRipple
            variant="contained"
            className={classes.shareButton}
            startIcon={
              <Icon style={{ transform: "rotateY(180deg)", marginTop: "-3px" }}>reply</Icon>
            }
            onMouseUp={handleAuditShare}
          >
            {t`Share Report`}
          </Button>
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
            <Typography variant="h5">{t`Loading Audit Reports...`}</Typography>
          </div>
        ) : (
            <Grid container justify="flex-start" alignItems="stretch" className={classes.wrapper}>
              <Grid item xs={2} className={classes.filterContainer}>
                <Grid item className={classes.filterGridItem}>
                  <AuditsFilter
                    title={t`Users`}
                    className={classes.filter}
                    filters={actorsFilter}
                    setFilter={setActorsFilter}
                    getFilterName={(actorId) => getUsername(actorId)}
                     usersFilter
                  />
                </Grid>
                <Grid item className={classes.filterGridItem}>
                  <AuditsFilter
                    title={t`Actions`}
                    className={classes.filter}
                    filters={actionsFilter}
                    setFilter={setActionsFilter}
                    getFilterName={(action) => auditActionMap[action]?.name || action}
                  />
                </Grid>
              </Grid>
              <Grid item xs={10} className={classes.tableWrapper}>
                <CoolTable
                  headCells={tableColumns}
                  rows={getFilteredAudits(audits)}
                  getPageRows={(page, rowsPerPage, order, orderBy) =>
                    getAuditsPage({ page, rowsPerPage, order, orderBy })
                  }
                  renderCell={formatAuditRowField}
                  defaultOrderBy={"date"}
                  options={{
                    // withDatePicker: true,
                    classNameForTableContainer: classes.tableContainer,
                    rowsPerPage: 20,
                    rowsPerPageOptions: [20, 30, 50, 100]
                  }}
                />
              </Grid>
            </Grid>
          )}
      </div>
    </div>
  );
};

export default AuditsList;
