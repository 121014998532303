import { Button, IconButton, makeStyles, Switch } from "@material-ui/core";
import { Field, getIn } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import { t } from "ttag";
import { Delete, MoreVert } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import Condition from "../AddRule/Condition";
import styles from "./AddEditScript.style";
import { CustomedTextField, CustomSelect } from "./CustomedComponents";

export default (props: any) => {
  const {
    errors,
    touched,
    name,
    condition,
    deleteCondition,
    index,
    setFieldValue,
    disabled,
    moveProps
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);
  const { stepsOptions, commandsOptions, types, conditionsOptions, checkOperatorsOptions }: any = useStoreState((s) => s);
  const { procedureStepTypes, procedureDeviceCommands, procedureConditions } = types;
  const commandsOptionsArray = Object.values(commandsOptions);
  const conditionsOptionsArray = Object.values(conditionsOptions);
  const enableConditions = condition.type === procedureStepTypes.condition;
  const enableCommands = condition.type === procedureStepTypes.command;
  const enumText: string = enableCommands ? commandsOptions[condition.command]?.enum :
    enableConditions ? conditionsOptions[condition.condition]?.enum : "";
  const enumValues: any = types[enumText] || {};
  const enumArray = Object.keys(enumValues).map((key: any) => ({ name: enumValues[key], value: key }));

  return (
    <div className={classes.conditionContainer}>
      <div className={classes.valuesContainer}>
        <div {...moveProps} className={classes.moreVertContainer}>
          <MoreVert className={classes.whiteMoreVert} />
        </div>
        <CustomSelect
          className={classes.width110}
          error={getIn(errors, `${name}[${index}].type`) && getIn(touched, `${name}[${index}].type`)}
          placeholder="Type"
          name={`${name}[${index}].type`}
          onChange={(event: any) => {
            setFieldValue(`${name}[${index}].type`, event.target.value);
            setFieldValue(`${name}[${index}].value`, "");
          }}
          value={condition.type}
          options={stepsOptions}
        />
        {enableCommands && (
          <CustomSelect
            className={classes.width110}
            error={getIn(errors, `${name}[${index}].command`) && getIn(touched, `${name}[${index}].command`)}
            placeholder="Action"
            name={`${name}[${index}].command`}
            onChange={(event: any) => {
              setFieldValue(`${name}[${index}].command`, event.target.value);
              setFieldValue(`${name}[${index}].value`, "");
            }}
            value={condition.command}
            options={commandsOptionsArray}
          />
        )}
        {enableConditions && (
          <CustomSelect
            className={classes.width110}
            error={getIn(errors, `${name}[${index}].condition`) && getIn(touched, `${name}[${index}].condition`)}
            placeholder="Action"
            name={`${name}[${index}].condition`}
            onChange={(event: any) => {
              setFieldValue(`${name}[${index}].condition`, event.target.value);
              setFieldValue(`${name}[${index}].value`, "");
            }}
            value={condition.condition}
            options={conditionsOptionsArray}
          />
        )}
        {enableConditions && (
          <CustomSelect
            className={classes.width110}
            error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
            placeholder="Operator"
            name={`${name}[${index}].operator`}
            onChange={(event: any) => {
              setFieldValue(`${name}[${index}].operator`, event.target.value);
              setFieldValue(`${name}[${index}].value`, "");
            }}
            value={condition.condition === types.procedureConditions.unitSetpoint || condition.condition === types.procedureConditions.ambientTemperature ? condition.operator : "="}
            options={checkOperatorsOptions}
            disabled={condition.condition !== types.procedureConditions.unitSetpoint && condition.condition !== types.procedureConditions.ambientTemperature}
            default={"="}
            label={"label"}
          />
        )}
        {condition.type ? (
          enumText ? (
            <CustomSelect
              className={classes.width110}
              error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
              placeholder="Value"
              name={`${name}[${index}].value`}
              onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
              value={condition.value}
              options={enumArray}
            />
          ) : (
              <CustomedTextField
                disabled={condition.type !== procedureStepTypes.wait && (!condition.command && !condition.condition)}
                className={classes.width140}
                error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
                value={condition.value}
                name={`${name}.${index}.value`}
                type="number"
                label={condition.type === procedureStepTypes.wait ? t`Time in minutes` :
                  t`Value` + `${condition.command ? temperatureScale === 2 ? " (°F)" : " (°C)" : ""}`}
              />
            )
        ) : (
            <></>
          )}
      </div>
      <div>
        {
          enableConditions ?
            <>
              <span>{t`continue if check fails`}</span>
              <Field
                name={`${name}.${index}.proceedOnFailure`}
                value={condition.proceedOnFailure}
                default={true}
                as={() => (
                  <Switch
                    color={"default"}
                    onChange={() => {
                      setFieldValue(`${name}[${index}].proceedOnFailure`, condition.proceedOnFailure ? false : true);
                    }}
                    checked={condition.proceedOnFailure}
                    name={`${name}.${index}.proceedOnFailure`}
                    classes={{
                      thumb: classes.thumb,
                      track: classes.track
                    }}
                  />

                )}
              />
            </>
            : <></>
        }
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={deleteCondition}>
          <Delete style={{ width: 20, height: 20 }} />
        </IconButton>
      </div>
    </div>
  );
};
