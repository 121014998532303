import { createStyles, fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: 60,
      height: 30,
      padding: 6
    },
    switchBase: {
      left: 6,
      padding: 1,
      color: "#D8D8D8",
      "&$checked": {
        left: 0,
        transform: "translateX(26px)",
        color: "#f05146",
        "& + $track": {
          backgroundColor: fade("#f05146", 0.25),
          border: "none",
          opacity: 1
        }
      },
      "&$focusVisible $thumb": {
        color: "#f05146"
      }
    },
    thumb: {
      width: 28,
      height: 28
    },
    track: {
      borderRadius: 50 / 2,
      backgroundColor: fade("#fdfdfe", 0.25),
      opacity: 1
      // transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  });
