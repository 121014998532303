import { fade, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  searchField: {
    marginRight: "14px",
    marginLeft: "5px",
    "& div": { height: "40px", marginBottom: "5px" }
  },
  filterStyle: { marginLeft: "10px" },
  blueFilter: { fill: "#1976d2" },
  ImportExportContainer: {
    padding: "15px",
    marginRight: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  dialogPaper: {
    width: "fit-content",
    maxWidth: "95% !important",
    minWidth: "850px",
    height: "fit-content",
    maxHeight: "calc(100vh - 140px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
    overflow: "hidden"
  },
  dialogHeader: {
    borderBottom: "solid 1px #d5d2d5",
    width: "1005",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    height: "60px",
    backgroundColor: "#f6f6f7",
    alignItems: "center"
  },
  headerTitle: {
      fontFamily: "RobotoMedium",
      color: "#29132e",
      lineHeight: "normal",
      fontSize: 18,
      fontWeight: 500
  },
  selectedTrap: {
    backgroundColor: "#efeff2"
  },
  uploadContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  },
  table: {
    background: theme.palette.background.paper
  },
  overWritePadding: {
    fontSize: "13px",
    padding: "0 15px",
    height: "35px"
  },
  editIcon: {
    marginLeft: "-5px",
    width: 19,
    height: 20
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial"
  },

  anomaliesContainer: {
    height: "82%",
    display: "flex",
    paddingBottom: "1rem"
  },

  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  barRIghtSide: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center"
  },
  title: {
    height: "18px",
    width: "72px",
    color: "#312e34",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px"
  },
  "[class^=\"NavigationBar\"]": {
    padding: "0"
  },
  smallWidth: { width: "20%", wordBreak: "break-word" },
  mediumWidth: { width: "60%", wordBreak: "break-word" },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  filterIconContainer: {
    display: "flex",
    alignItems: "center"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  exportIcon: {
    paddingLeft: 5
  },
  importIcon: {
    paddingTop: 5,
    marginLeft: 10
  },
  descriptionCell: {
      padding: "8px 15px"
  },

  /////// check up

  areaBox: {
    height: "100%",
    padding: 30,
    maxHeight: "100%",
    overflow: "hidden"
  },
  paperTableContainer: {
    flex: "1 1 auto",
    overflowY: "auto",
    height: "100%",
    padding: "30px 20px 20px 20px",
    position: "relative"
  },
  borderedArea: {
    display: "flex",
    borderRadius: 4,
    border: "solid 1px #d5d2d5",
    height: "calc(100% - 130px)"  /// re calc
  },
  categoryList: {
    display: "flex",
    width: "27%",
    maxWidth: 320,
    borderRight: "solid 1px #d5d2d5",
    flexFlow: "column nowrap"
  },
  searchBox: {
    borderBottom: "solid 1px #d5d2d5",
    height: 60,
    padding: 10,
    width: "100%"
  },
  searchIcon: {
    transform: "scale(0.8695)"
  },
  closeIconStyle: {
      width: 25,
      height: 25,
      borderRadius: 6,
      padding: 0,
      "& svg": {
      transform: "scale(0.9)"
    }
  },
  inputRoot: {
      width: "100%",
      paddingLeft: 9,
      paddingRight: 4,
      height: "100%",
      borderRadius: "4px",
      backgroundColor: "rgba(170, 162, 170, 0.1)",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "normal",
      letterspacing: "normal",
      color: "#4b4850" //charcoal-grey
  },
  tableContainer: {
    display: "flex",
    height: "100%",
    justifyContent: "center"
  },
  rightSideContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
    height: "100%",
    width: "100%"
  },
  tableHeadCell: {
    borderBottom: "none",
    padding: "0 15px",
    fontFamily: "RobotoMedium",
    color: "#79797d",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#f6f6f7",
    textTransform: "uppercase",
    height: 38,
    fontSize: 13
  },
  unitName: {
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    marginBottom: 20
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 45

  },
  actionsHolder: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    display: "flex",
    justifyContent: "flex-end"
  },

  cancelBtn: {
    marginRight: 10
  },
  noContentContainer: {
      boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
      backgroundColor: "#fefefe",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      width: "calc(100% - 60px)",
      height: "calc(100% - 130px)",
      alignSelf: "center",
      marginTop: "30px"
  },
  grayOval: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "136px",
      height: "136px",
      backgroundColor: "rgba(170, 162, 170, 0.1)",
      borderRadius: "50%",
      marginBottom: "43px"
  },
  arrowIcon: {
    fontSize: "91px",
    transform: "rotate(90deg)"
  },
  noUnitText: {
      fontSize: "26px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#545964"
  },
  ctgLvl1Name: {
    fontFamily: "RobotoMedium",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.13,
    letterSpacing: "normal",
    color: fade("#545964", 0.89)
  },
  ctgLvl1Holder: {
    display: "flex",
    borderBottom: "solid 1px #d5d2d5",
    minHeight: 50,
    paddingLeft: 13,
    alignItems: "center",
    cursor: "pointer"
  },
  ctgLvl2Name: {
     fontFamily: "Roboto",
     fontSize: 14,
     fontWeight: "normal",
     lineHeight: 1.29,
     letterSpacing: "normal",
     color: fade("#545964", 0.89)
},
  ctgLvl2Holder: {
    display: "flex",
    alignItems: "center",
    minHeight: 44,
    paddingLeft: 30,
    backgroundColor: fade("#f6f6f7", 0.5),
    cursor: "pointer"
},
  ctglvl3Name: {
  fontFamily: "Roboto",
  fontSize: 14,
  fontWeight: "normal",
  lineHeight: "36px",
  letterSpacing: "normal",
  color: fade("#545964", 0.89),
  paddingLeft: 47,
  backgroundColor: fade("#f6f6f7", 0.5),
  cursor: "pointer"
},
  selected: {
  backgroundColor: "#edebed"
},
  ctgColumn: {
    display: "flex",
    flexFlow: "column nowrap",
    maxHeight: "100%",
    overflow: "auto"
  },
  txtField: {
    width: 184,
    height: 36,
    padding: "0 12px",
    borderRadius: 3,
    border: "solid 1px #d5d2d5",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  selectField: {
    width: 184,
    height: 36,
    borderRadius: 3,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    padding: 0,
    "& div": {
      padding: 0,
      paddingLeft: 12,
      lineHeight: "36px",
      height: "100%",
      width: "100%",
      "& fieldset": {
        border: "solid 1px #d5d2d5"
      }
    }
  },
  arrowIconSelect: {
    fontSize: 12,
    top: 12,
    right: 10
  },
  errorStyle: {
    position: "absolute",
    fontSize: 10,
    color: "red",
    marginTop: "-1px"
  },
  rotateArrow: {
    transform: "rotate(90deg)"
  },
  tabsArrow: {
    fontSize: 12,
    marginRight: 5
  },
  dialog: {
    width: "100%",
    borderRadius: "8px",
    height: "1000px",
    overflowY: "auto"
  }
}));
export default useStyles;
