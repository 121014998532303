import { fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "100%",
    borderRadius: "8px"
  },
  title: {
    height: "60px",
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    alignItems: "center",
    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignContent: "center"
    }
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflowX: "hidden",
    overflowY: "auto"
  },
  leftCard: {
    marginBottom: "15px"
  },
  headerStyle: {
    fontWeight: "bold",
    color: theme.palette.primary.light,
    marginBottom: "5px"
  },
  personalDetailsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    width: "335px"
  },
  actionsHolder: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "flex-end"
  },
  mainSelect: { width: "100%" },
  textArea: {
    height: "100px",
    maxWidth: "770px !important",
    width: "100%",
    borderRadius: "3px",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    padding: "13px 24px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    fontFamily: "Roboto",
    "&::placeholder": {
      color: fade("#545964", 0.6)
    }
  },
  userInfoContainer: {
    justifyContent: "space-between",
    marginTop: "15px"
  },
  topicContainer: {
    width: "300px",
    marginTop: "10px"
  },
  descriptionContainer: {
    margin: "20px 0"
  },
  label: {
    marginBottom: "10px"
  },
  requiredError: {
    color: "#FF0000",
    marginTop: "-9px"
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal",
    paddingTop: "7px"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  selectStyle: {
    width: "315px"
  },
  labelSelect: { transform: "translate(14px, 11px) scale(1)" },
  arrowDownIcon: {
    top: 14.5,
    right: 9
  },
  select: {
    lineHeight: "36px",
    width: "100%",
    height: "36px",
    borderRadius: "3px",
    backgroundColor: "#ffffff",
    "&>div": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: "30px",
      paddingLeft: "12px"
    }
  },
  disabledSelect: { background: "#d5d2d57d" },
  fullWidth: {
    width: "100%"
  }
}));
export default useStyles;
