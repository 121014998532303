import { Action, action, actionOn, ActionOn, debug } from 'easy-peasy';
import { IRootStoreModel } from './RootStore';
import _ from 'lodash';

export interface IZone {
  id: string;
  name?: string;
  description?: string;
  site: string;
  units: string[];
  role: any;
}

export interface IZoneMap {
  [key: string]: IZone;
}

export interface IZonesModel {
  allZones: IZoneMap;
  initialize: Action<IZonesModel, any>;
  onInitialized: ActionOn<IZonesModel, IRootStoreModel>;
}

export const zonesModel: IZonesModel = {
  allZones: {},

  initialize: action((state, payload) => {
    const newZones: IZoneMap = _(Object.values(payload))
      .map((zone: any) => {
        const newZone: IZone = { ...zone };

        return newZone;
      })
      .keyBy('id')
      .value();
    return { ...state, allZones: newZones };
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  )
};
