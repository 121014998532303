import { CircularProgress, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowRight from "@material-ui/icons/ArrowForward";
import clsx from "clsx";
import { System as sdkSystem } from "coolremote-sdk";
import _, { identity } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ParamsTable from "./ParamsTable";
import { PropsFilter } from "./PropsFilter";
import useStyles from "./SystemDiagnostics.style";

interface IProps {
  children: React.ReactElement;
  open: boolean;
  value: any;
}
function ValueLabelComponent(props: IProps) {
  const classes = useStyles();
  const { children, open, value } = props;

  return (
    <Tooltip
      arrow
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
    >
      {children}
    </Tooltip>
  );
}
const SystemDiagnostics: React.FC = (props: any) => {
  const classes = useStyles();
  const match = useRouteMatch<{ systemId: string }>();
  const history = useHistory();
  const defaultSystemId = match.params.systemId;
  const user = useStoreState((s) => s.users.me);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const selections = useStoreState((s) => s.selections.selections);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const serviceParams = useStoreState((s) => s.serviceParams);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const getSite = useStoreState((s) => s.sites.getSite);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const setSelections = useStoreActions((s) => s.selections.setSelections);
  const [isDisabled, setIsDisabled] = useState(true);
  const [time, setTime] = useState<any>({
    start: moment(new Date(new Date().setHours(0, 0, 0))).valueOf(),
    end: moment.now()
  });
  const [selectedTime, setSelectedTime] = useState<any>(null);
  const [indoorData, setIndoorData] = useState<any>({});
  const [outdoorData, setOutdoorData] = useState<any>({});
  const [bsData, setBsData] = useState<any>({});
  const [orderedIndoors, setOrderedIndoors] = useState<any>([]);
  const [orderedOutdoors, setOrderedOutdoors] = useState<any>([]);
  const [orderedBs, setOrderedBs] = useState<any>([]);
  const [indoorParamsMap, setIndoorParamsMap] = useState<any>({});
  const [bsParamsMap, setBsParamsMap] = useState<any>({});
  const [outdoorParamsMap, setOutdoorParamsMap] = useState<any>({});
  const [indoorOrderedMap, setIndoorOrderedMap] = useState<any>([]);
  const [outdoorOrderedMap, setOutdoorOrderedMap] = useState<any>([]);
  const [bsOrderedMap, setBsOrderedMap] = useState<any>([]);
  const [isLast, setIsLast] = useState<boolean>(false);
  const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;
  const timezone = getSite(selections.siteId)?.timezone || moment.tz.guess();
  const selectedSystemId = selections?.systemId;
  const system = selectedSystemId && allSystems[selectedSystemId];

  const saveUserPref = () => {
    if (!selectedSystemId) {
      return;
    }
    const indoors = orderedIndoors?.length && orderedIndoors?.map((code: any) => [code, indoorParamsMap[code]?.showInTable]);
    const outdoors = orderedOutdoors?.length && orderedOutdoors.map((code: any) => [code, outdoorParamsMap[code]?.showInTable]);
    const bsBoxes = orderedBs?.length && orderedBs.map((code: any) => [code, bsParamsMap[code]?.showInTable]);
    const userPrefObj: any = JSON.parse(localStorage.getItem("recentSystemParamsO") as string);

    let data = {
      ...userPrefObj,
      [selectedSystemId]: {
        indoors,
        outdoors,
        bsBoxes
      }
    };
    localStorage.setItem("recentSystemParamsO", JSON.stringify(data));
  };

  useEffect(() => {
    if (!selections.dateRange || !selections.dateRange.startDate || !selections.dateRange.endDate) {
      setSelections({
        dateRange: {
          startDate: new Date(new Date().setHours(0, 0, 0)),
          endDate: new Date()
        }
      });
    }

  }, []);

  useEffect(() => {
    if (defaultSystemId && defaultSystemId !== selections.systemId) {
      updateSelections({ type: "system", data: defaultSystemId });
      return;
    }

    if (!defaultSystemId && selections.systemId) {
      history.push(`/system-diagnostics/${selections.systemId}`);
      return;
    }
  }, [defaultSystemId, selections.systemId]);

  const parseParams = (paramsMap: any, userPrefByType: any) => {
    let count = 0;
    const map = Object.keys(paramsMap).reduce((obj: any, code: any) => {

      const serviceParam = serviceParams[code];
      if (!serviceParam) {
        return obj;
      }

      const measurementUnits: string = serviceParam
        ? serviceParam.data_unit_of_measurement
        : "";

      const name: string = serviceParam ? serviceParam.title : "";
      const showInGraph: boolean = serviceParam && serviceParam.showInGraph === false ? false : true;

      let enumData = null;
      if (serviceParam?.enum) {
        const typeKey = serviceParam?.enum;
        enumData = serviceParamTypes[typeKey];
      }

      obj[code] = {
        code,
        name,
        value: paramsMap[code].value,
        showInGraph,
        measurementUnits,
        enumData,
        showInList: true,
        showInTable: count > 8 ? false : true
      };

      count++;
      return obj;
    }, {});

    if (userPrefByType?.length) {
      for (let node of userPrefByType) {
        const [code, showInTable] = node;
        if (node && map[code]) {
          map[code].showInTable = showInTable;
        }
      }
    }

    return map;
  };

  useEffect(() => {
    if (!selectedSystemId) {
      setIsDisabled(false);
      return;
    }

    if (!selections.dateRange) {
      return;
    }
    setIsDisabled(true);
    const isToday = moment(moment(selections?.dateRange?.endDate).valueOf()).isSame(moment().valueOf(), "day");
    const currentHourMins = moment().tz(timezone).valueOf();
    const start = moment.tz(selections.dateRange?.endDate, timezone).startOf("day").valueOf();
    const end = !isToday ? moment.tz(selections.dateRange?.endDate, timezone).endOf("day").valueOf() : currentHourMins; //Date.UTC(selection s.dateRange?.endDate.getFullYear(), selections.dateRange?.endDate.getMonth(), selections.dateRange?.endDate.getDate(), isToday ? +currentHourMinsArray[0] : 23, isToday ? +currentHourMinsArray[1] : 59) - utcOffset;
    setTime({ start, end });

    sdkSystem.getSystemDiagData(selectedSystemId, start, end)
      .then((resp: any) => {
        const userPrefObj: any = JSON.parse(localStorage.getItem("recentSystemParamsO") as string);
        let systemUserPref = userPrefObj && userPrefObj[selectedSystemId] || {};
        const { indoors: userIndoor = [], outdoors: userOut = [], bsBoxes: userBs = [] } = systemUserPref;

        const { indoors, outdoors, bsBoxes } = resp;
        if (indoors) {
          const firstIndoor: any = Object.values(indoors)[0];
          if (!!firstIndoor) {
            const { ranges, entries } = firstIndoor;
            const indoorParamsMap = parseParams(ranges, userIndoor);
            const orderedParams = new Set([...userIndoor.map((x: any) => x[0]), ...Object.keys(indoorParamsMap)]);
            setIndoorParamsMap(indoorParamsMap);
            setIndoorData(indoors);
            setOrderedIndoors([...orderedParams]);
            setSelectedTime(entries[entries.length - 1]?.timestamp);
          }
        }

        if (outdoors) {
          const firstOutdoor: any = Object.values(outdoors)[0];
          if (!!firstOutdoor) {
            const { ranges, entries } = firstOutdoor;
            const outdoorParamsMap = parseParams(ranges, userOut);
            const orderedParams = new Set([...userOut.map((x: any) => x[0]), ...Object.keys(outdoorParamsMap)]);
            setOutdoorParamsMap(outdoorParamsMap);
            setOutdoorData(outdoors);
            setOrderedOutdoors([...orderedParams]);
            if (!selectedTime) {
              setSelectedTime(entries[entries.length - 1]?.timestamp);
            }
          }
        }

        if (bsBoxes) {
          const firstBs: any = Object.values(bsBoxes)[0];
          if (!!firstBs) {
            const { ranges, entries } = firstBs;
            const bsBoxParamsMap = parseParams(ranges, userBs);
            const orderedParams = new Set([...userBs.map((x: any) => x[0]), ...Object.keys(bsBoxParamsMap)]);
            setBsParamsMap(bsBoxParamsMap);
            setBsData(bsBoxes);
            setOrderedBs([...orderedParams]);
            if (!selectedTime) {
              setSelectedTime(entries[entries.length - 1]?.timestamp);
            }
          }
        }
      })
      .finally(() => setIsDisabled(false));
  }, [selections.dateRange, defaultSystemId, userTempScale, userPressureScale]);

  setUnitUpdateStatus({ status: "" });

  if (!isInitialized) {
    return <Loading />;
  }

  const valuetext = (value: number) => {
    const timeToShow = moment(value).tz(timezone).format(timeFormat);
    return `${timeToShow}`;
  },
    valueDateText = (value: number) => {
      const timeToShow = moment(value).tz(timezone).format(`${dateFormat} ${timeFormat}`);
      return `${timeToShow}`;
    },
    valueLabelFormat = (value: number) => {
      const timeToShow = moment(value).tz(timezone).format(timeFormat);
      return `${timeToShow}`;
    },
    changeSelectedTime = (event: React.ChangeEvent<{}>, value: any) => {
      setSelectedTime(value as number);
    },
    setPrevMinute = async () => {
      setSelectedTime(
        moment(selectedTime)
          .add(-1, "minute")
          .valueOf()
      );
    },
    setNextMinute = async () => {
      setSelectedTime(
        moment(selectedTime)
          .add(1, "minute")
          .valueOf()
      );
    },
    setLastUpdate = async () => {
      const dateRange = {
        startDate: new Date(new Date().setHours(0, 0, 0)),
        endDate: new Date()
      };
      updateSelections({ type: "time", data: dateRange });
    };

  const updateParam = (type: string, code: any, key: string, value: any) => {
    let map: any = {};
    let updateStateFn = null;

    switch (type) {
      case "indoor":
        map = { ...indoorParamsMap };
        updateStateFn = setIndoorParamsMap;
        break;
      case "outdoor":
        map = { ...outdoorParamsMap };
        updateStateFn = setOutdoorParamsMap;
        break;
      case "bsBox":
        map = { ...bsParamsMap };
        updateStateFn = setBsParamsMap;
        break;
      default:
        map = null;
    }

    if (!map || !updateStateFn) {
      return;
    }

    const numOfShowen = Object.values(map).filter((param: any) => param.showInTable)?.length;

    if (key === "showInTable" && value === true) {
      if (numOfShowen >= 12) {
        return;
      }
    }

    const updatedObj = { [key]: value };

    if (key === "showInList" && value === false) {
      updatedObj["showInTable"] = false;
    }

    if (key === "showInList" && value === true && numOfShowen <= 12) {
      updatedObj["showInTable"] = true;
    }

    updateStateFn({ ...map, [code]: { ...map[code], ...updatedObj } });
    saveUserPref();
  };

  const updateAllParam = (type: string, key: string, value: any) => {
    let map: any = {};
    let updateStateFn = null;

    switch (type) {
      case "indoor":
        map = { ...indoorParamsMap };
        updateStateFn = setIndoorParamsMap;
        break;
      case "outdoor":
        map = { ...outdoorParamsMap };
        updateStateFn = setOutdoorParamsMap;
        break;
      case "bsBox":
        map = { ...bsParamsMap };
        updateStateFn = setBsParamsMap;
        break;
      default:
        map = null;
    }

    if (!map || !updateStateFn) {
      return;
    }

    let numOfSelected: number = 0;
    for (let code of Object.keys(map)) {
      const updatedObj = { [key]: value };

      if (key === "showInList" && value === false) {
        updatedObj["showInTable"] = false;
      }

      if (key === "showInList" && value === true && numOfSelected <= 8) {
        updatedObj["showInTable"] = true;
        numOfSelected++;
      }

      map[code] = { ...map[code], ...updatedObj };

    }

    updateStateFn({ ...map });
    saveUserPref();
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          onSystemSelect={(id: any) => history.push(`/system-diagnostics/${id}`)}
          onSiteSelect={() => history.push(`/system-diagnostics/`)}
          hideUnitSelection
          showOneDatePicker
          disableOneDatePicker={!displayFlags.enableSystemDiagnosticsDatepicker}
          customGeneralNames={{ system: "Select System" }}
        />
        {!selectedSystemId ?
          (<Grid container direction={"column"} className={classes.noContentContainer}>
            <div className={classes.grayOval}>
              <ArrowIcon className={classes.arrowIcon} />
            </div>
            <Typography className={classes.noUnitText}>
              {t`Please select a system using the above filters.`}
            </Typography>
          </Grid>) :
          system ?
            <>
              <Grid container direction="row" alignItems="stretch" className={classes.headerContainer}>
                <div style={{ flexGrow: 0, maxWidth: "16.666667%", flexBasis: "16.666667%" }}></div>
                {!isDisabled ? <Grid container direction="row" alignItems="stretch" className={classes.sliderContainer}>
                  <IconButton
                    disableRipple
                    style={{ marginRight: "16px" }}
                    onClick={setPrevMinute}
                    className={clsx(classes.iconBtnStyle, { [classes.hidden]: time.start === selectedTime })}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography>{valueDateText(time.start)}</Typography>
                  <Grid item className={classes.slider}>
                    <Slider
                      onChangeCommitted={changeSelectedTime}
                      defaultValue={selectedTime}
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider-always"
                      min={time.start}
                      max={time.end}
                      value={selectedTime}
                      valueLabelDisplay="on"
                      valueLabelFormat={valueLabelFormat}
                      ValueLabelComponent={ValueLabelComponent}
                    />
                  </Grid>
                  <Typography>{valueDateText(time.end)}</Typography>
                  <IconButton
                    disableRipple
                    onClick={setNextMinute}
                    style={{ marginLeft: "1rem" }}
                    className={clsx(classes.iconBtnStyle, { [classes.hidden]: isLast })}
                  >
                    <ArrowRight />
                  </IconButton>

                </Grid> : <></>}
                <Button onClick={setLastUpdate}>
                  {t`Last Update`}
                </Button>
              </Grid>
              {isDisabled ? (
                <div className={classes.progress}>
                  <div className={classes.progressContainer}>
                    <div className={clsx(classes.processingAnimationContainer)}>
                      <CircularProgress />
                    </div>
                    <Typography variant="h5">{t`Loading Data`}</Typography>
                  </div>
                </div>
              ) :
                (
                  <Grid container justify="flex-start" alignItems="stretch" className={classes.wrapper}>
                    <Grid item xs={2} className={classes.filterContainer}>
                      <Grid item className={clsx(classes.filterOutdoorGridItem, !_.isEmpty(bsParamsMap) ? "" : classes.filterContainerBs)}>
                        <PropsFilter
                          title={t`Outdoor Parameters`}
                          shownFilters={outdoorOrderedMap}
                          type={"outdoor"}
                          paramsMap={outdoorParamsMap}
                          updateParam={updateParam}
                          updateAllParam={updateAllParam}
                          orderedParams={orderedOutdoors}
                          setOrdered={setOrderedOutdoors}
                          saveUserPref={saveUserPref}
                        />
                      </Grid>
                      {!_.isEmpty(bsParamsMap) ?
                        <Grid item className={classes.filterBsGridItem}>
                          <PropsFilter
                            title={t`BS Parameters`}
                            shownFilters={bsOrderedMap}
                            type={"bsBox"}
                            paramsMap={bsParamsMap}
                            updateParam={updateParam}
                            updateAllParam={updateAllParam}
                            orderedParams={orderedBs}
                            setOrdered={setOrderedBs}
                            saveUserPref={saveUserPref}
                          />
                        </Grid>
                        :
                        <></>}

                      <Grid item className={clsx(classes.filterGridItem, !_.isEmpty(bsParamsMap) ? "" : classes.filterGridItemBs)}>
                        <PropsFilter
                          title={t`Indoor Parameters`}
                          shownFilters={indoorOrderedMap}
                          type={"indoor"}
                          paramsMap={indoorParamsMap}
                          updateParam={updateParam}
                          updateAllParam={updateAllParam}
                          orderedParams={orderedIndoors}
                          setOrdered={setOrderedIndoors}
                          saveUserPref={saveUserPref}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={10} className={classes.tableWrapper}>
                      <Grid item className={clsx(classes.tableOutdoorGridItem, !_.isEmpty(bsParamsMap) ? classes.tableOutdoorGridItemBs : "")}>
                        <ParamsTable
                          title={"Outdoor Dashboard"}
                          columns={outdoorParamsMap}
                          paramsData={outdoorData}
                          selectedTime={selectedTime}
                          isLast={isLast}
                          setIsLast={setIsLast}
                        />
                      </Grid>
                      {!_.isEmpty(bsParamsMap) ?
                        <Grid item className={classes.tableBsGridItem}>
                          <ParamsTable
                            title={"BS Box Dashboard"}
                            columns={bsParamsMap}
                            paramsData={bsData}
                            selectedTime={selectedTime}
                            isLast={isLast}
                            setIsLast={setIsLast}
                          />
                        </Grid>
                        :
                        <></>
                      }
                      <Grid item className={clsx(classes.tableGridItem, !_.isEmpty(bsParamsMap) ? "" : classes.filterGridItemBs)}>
                        <ParamsTable
                          title={"Indoor Dashboard"}
                          columns={indoorParamsMap}
                          paramsData={indoorData}
                          selectedTime={selectedTime}
                          isLast={isLast}
                          setIsLast={setIsLast}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </> :
            <Grid container direction={"column"} className={classes.noContentContainer}>
              <Typography className={classes.noUnitText}>
                {t`No info about system`}
              </Typography>
            </Grid>
        }

      </div>
    </div>
  );
};

export default SystemDiagnostics;
