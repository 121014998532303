import { makeStyles, Theme } from "@material-ui/core/";

const useStyles = makeStyles((theme: Theme) => ({
  optionStyle: {
    color: "#fff",
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%"
  },
  optionText: {
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  selectContainer: {
    // padding: '0 9px',
    height: "36px",
    backgroundColor: "transparent !important",
    "&:disabled": {
      backgroundColor: "transparent !important"
    }
    // display: 'flex',
    // alignItems: 'center'
  },
  selectStyle: {
    width: "10rem",
    color: "#fff",
    backgroundColor: theme.palette.primary.light,
    borderRadius: "4px",
    margin: "1rem .5rem",
    padding: "0 9px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  inputStyle: {},
  iconStyle: {
    right: "0"
  },
  focusStyle: {
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "4px"
    }
  },
  arrowIcon: {
    color: "#fff",
    fontSize: "2.5rem !important",
    top: "0",
    height: "36px"
  },
  paperStyle: { backgroundColor: theme.palette.primary.dark },
  selected: { backgroundColor: `${theme.palette.primary.light} !important` }
}));

export default useStyles;
