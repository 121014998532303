import { makeStyles, Theme, Tooltip } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React from "react";
interface ITooltipProps {
    title: string;
    placement?: any;
    arrow?: boolean;
    classes?: any;
    children?: any;
    interactive?: any;
    open?: boolean;
}

const LightTooltipStyle = makeStyles((theme: Theme) => createStyles({
    tooltip: {
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 12,
        paddingLeft: 12,
        paddingRight: 12,
        fontWeight: 600,
        whiteSpace: "pre-line"
    },
    arrow: {
        color: "#fff",
        filter: "drop-shadow( 0px 6px 7px #000 )",
        marginRight: "10px !important"
    },
    popper: {
    }
}));

export default function LightTooltip(props: React.PropsWithChildren<ITooltipProps>) {
    const {
        tooltip,
        arrow,
        popper
    } = LightTooltipStyle();

    return (
        <Tooltip {...props} classes={{ tooltip, arrow, popper, ...props.classes }} title={props.title}>
            {props.children}
        </Tooltip>
    );
}
