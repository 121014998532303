import { User as SdkUser } from 'coolremote-sdk';
import _ from 'lodash';
import { ISite } from '../../models/Sites';
import { ISystem } from '../../models/Systems';
import { IUser } from '../../models/Users';

export interface IUserFormValues {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  email: string;
  phone: string;
  temperatureScale: number;
  adminRole: any;
  checkedSites: Record<string, boolean>;
  checkedSystems: Record<string, boolean>;
  newPassword?: string;
  repeatPassword?: string;
  measurementUnits: number;
  language?: string;
  timeFormat?: number;
  dateFormat?: number;
}

export const makeUsername = async (firstName: string, lastName: string) => {
  const MAX_USERNAME_LEN = 12;
  const ILLEGAL_CHARS = /[^[a-z0-9]/g;

  let fixedUsername = `${firstName[0]}${lastName}`
    .toLowerCase()
    .replace(ILLEGAL_CHARS, "")
    .substr(0, MAX_USERNAME_LEN);

  if (fixedUsername.length) {
    let userExists = true;
    let testUsername = fixedUsername;
    let addNum = 0;
    do {
      try {
        userExists = await SdkUser.isUsernameAvailable(testUsername);
      } catch (e) {
        //console.log(`makeUsername: error for ${testUsername}`, e);
        return "";
      }
      if (userExists) {
        addNum++;
        testUsername =
          fixedUsername.substr(0, MAX_USERNAME_LEN - 2) + addNum.toString().padStart(2, "0");
      }
    } while (userExists);
    return testUsername;
  } else {
    return "";
  }
};

export const makeCheckedSites = (user: IUser, sites: ISite[], adminRole: any) => {
  let ret: Record<string, boolean> = {};
  for (let i = 0; i < sites.length; i++) {
    if (!sites[i]) {
      continue;
    }
    ret[sites[i].id] =
      adminRole === 0 ||
      !!(user.permissions && user.permissions.sites && user.permissions.sites[sites[i].id]);
  }
  return ret;
};
export const makeCheckedSystems = (user: IUser, systems: ISystem[], adminRole: any) => {
  let ret: Record<string, boolean> = {};
  for (let i = 0; i < systems.length; i++) {
    ret[systems[i].id] = !!(
      user.permissions &&
      user.permissions.systems &&
      user.permissions.systems[systems[i].id]
    );
  }
  return ret;
};

export const buildPermissions = (
  customerId: string,
  adminRole: any,
  checkedSites: Record<string, boolean>,
  checkedSystems: Record<string, boolean>
) => {
  const customersPermissions = {
    [customerId]: adminRole === 0 ? "customerAdmin" : "customerGuest"
  };
  let sitesPermissions: any = {};
  let systemsPermissions: any = {};
  if (adminRole === 1) {
    for (let checkedSite in checkedSites) {
      if (checkedSites[checkedSite]) {
        sitesPermissions[checkedSite] = "siteAdmin";
      }
    }
    return { customers: customersPermissions, sites: sitesPermissions };
  } else if (adminRole === 2) {
    for (let checkedSystem in checkedSystems) {
      if (checkedSystems[checkedSystem]) {
        systemsPermissions[checkedSystem] = "systemAdmin";
      }
    }
    return { customers: customersPermissions, systems: systemsPermissions };
  }
  return { customers: customersPermissions };
};
