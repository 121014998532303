import { Divider, IconButton, makeStyles, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Switch } from "../../cool_widgets/Switch";
import { Delete } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime } from "../../services/timeService";
import { AddIcon, ArrowBack, Edit } from "../../svgComponents";
import AddEditSchedule from "../AddEditSchedule/AddEditSchedule";
import styles from "./scheduleList.style";

const ScheduleList: React.FC<any> = (props: any) => {
  const { allGroups, isGroup, hideSchedulesPanel, item = {}, allSchedules, setAllSchedules,
          addItemSchedule, deleteItemSchedule, unitGroups } = props;

  const types = useStoreState((state) => state.types);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [openAddEditSchedule, handleAddEditSchedule] = useState<string>("");
  const [disabledGroupSchedules, setDisabledGroupSchedules] = useState<any>([]);

  const { updateSchedule, deleteSchedule } = useStoreActions((actions) => actions.schedules);
  const { weekDays } = types;
  const { id: itemId, schedules = [], name = "" } = item;

  useEffect(() => {
    if (isGroup || _.isEmpty(unitGroups)) { setDisabledGroupSchedules([]); }

    let schedules: string[] = [];
    unitGroups.forEach((groupId: string) => {
      const groupSchedules = allGroups[groupId]?.schedules || [];
      groupSchedules.forEach((scheduleId: string) => {
        schedules.push(scheduleId);
      });
    });
    setDisabledGroupSchedules(schedules);

  }, [unitGroups]);

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        deleteItemSchedule(isGroup, itemId, scheduleId);
      })
      .catch((err: any) =>
        addMessage({ message: err.message })
      );
  };

  const changeScheduleActiveState = (e: any, id: string) => {
    const isDisabled = !e.target.checked;
    updateSchedule({ id, data: { isDisabled } }).then(() => {
      setAllSchedules({
        ...allSchedules,
        [id]: { ...allSchedules[id], isDisabled }
      });
    })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const saveScheduleData = (scheduleId: string, updatedData: any) => {

    allSchedules[scheduleId] = updatedData;
    setAllSchedules(allSchedules);
  };

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const Header = () => <div style={{
    background: "linear-gradient(to left, #421a40, #29132e 100%)",
    boxShadow: "0px 5px 9px 0 rgba(0, 0, 0, 0.2)", display: "flex", height: 50, justifyContent: "space-between", alignItems: "center"
  }}>
    <IconButton onClick={hideSchedulesPanel} className={classes.bigIconBtnStyle}>
      <ArrowBack />
    </IconButton>
    <Typography style={{ color: "#fff", fontSize: 25 }}>{t`Schedules`}</Typography>
    <IconButton onClick={() => handleAddEditSchedule("new")} className={classes.bigIconBtnStyle}>
      <AddIcon />
    </IconButton>
  </div>;

  const Schedule = ({ scheduleId, notEditable = false }: any) => {

    const schedule = allSchedules[scheduleId];

    if (!schedule) { return null; }

    const {
      powerOnTime,
      powerOffTime,
      setpoint,
      days = [],
      isDisabled
    } = schedule;
    const startHour =
      powerOnTime || powerOnTime === 0 ? minsToTime(powerOnTime, timeFormat) : "";
    const endHour =
      powerOffTime || powerOffTime === 0
        ? minsToTime(powerOffTime, timeFormat)
        : "";

    const scheduleName = notEditable ? (allGroups[schedule?.group]?.name || "-") : name;

    return (<div
      className={clsx(classes.scheduleContainer, { [classes.notEditable]: notEditable })}
      key={`schedule-${scheduleId}`}
    >
      <div className={classes.scheduleHeader}>
        <div className={classes.scheduleTitle}>
          <Typography className={classes.longNamestyle}>
            {scheduleName}
          </Typography>
        </div>
        <div className={classes.scheduleControl}>
          <IconButton disabled={notEditable} onClick={() => handleAddEditSchedule(scheduleId)} className={classes.iconBtnStyle}>
            <Edit className={classes.editIcon} />
          </IconButton>
          <IconButton disableRipple disabled={notEditable} onClick={() => deleteSelectedSchedule(scheduleId)} className={classes.iconBtnStyle}>
            <Delete className={classes.editIcon} />
          </IconButton>
        </div>
      </div>
      <div className={classes.scheduleBody}>
        <div className={classes.rowContainer}>
          <div className={classes.bodyRow}>
            <div className={classes.timeSection}>
              <TextField
                variant={"outlined"}
                name={"Start Time"}
                label={"Start Time"}
                placeholder={"None"}
                value={powerOnTime || powerOnTime === 0
                  ? startHour
                  : `None`}
                className={classes.inputClass}
                disabled={true}
              />
              <TextField
                variant={"outlined"}
                name={"End Time"}
                label={"End Time"}
                placeholder={"None"}
                value={powerOffTime || powerOffTime === 0
                  ? endHour
                  : `None`}
                className={classes.inputClass}
                disabled={true}
              />
            </div>
          </div>

          {!!setpoint && (
            <div className={classes.setpointContainer}>
              <div className={classes.setPointSection}>
                <Typography className={classes.setPointStyle}>
                  {setpoint}
                </Typography>
                <Typography className={classes.tempSymbolStyle}>
                  {temperatureSymbol()}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div className={classes.bodyRow}>
          <DaysList
            days={Object.keys(weekDays)}
            activeDays={days}
            action={() => { }}
            disablePointer
          />
        </div>
        <div className={classes.bodyRow} style={{ padding: "16px 0" }}>
          <Typography className={classes.scheduleStatusStyle}>
            {isDisabled ? t`Status: Inactive` : t`Status: Active`}
          </Typography>
          <div className={classes.controlSec}>
            <Switch
              disabled={notEditable}
              checked={!isDisabled}
              disableRipple={true}
              onChange={(e: any) =>
                changeScheduleActiveState(e, scheduleId)
              }
              value={true}
            />
          </div>
        </div>
        <Divider className={classes.dividerStyle} />
      </div>
    </div>);
  };

  return <div className={classes.screenContainer}>
    {!openAddEditSchedule && <>
      <Header />
      <div className={classes.schedulesContainer}>
        {schedules.length === 0 && disabledGroupSchedules.length === 0 ? (
          <Typography
            className={classes.noSchedulesStyle}
          >{t`There are no schedules to show`}</Typography>
        ) : (
            <>
              {schedules.map((scheduleId: string) => <Schedule scheduleId={scheduleId} key={`schedule-${scheduleId}`} />)}
              {disabledGroupSchedules.map((scheduleId: string) => <Schedule scheduleId={scheduleId} key={`schedule-${scheduleId}`} notEditable />)}
            </>)}
      </div>
    </>}
    {openAddEditSchedule && <AddEditSchedule
      editMode={openAddEditSchedule !== "new"}
      // key={`schedule-${itemId}-${temperatureScale}-${itemSchedules[openAddEditSchedule]?.setpoint}`}
      itemId={itemId} isGroup={isGroup}
      scheduleData={{ ...allSchedules[openAddEditSchedule] } || {}}
      hideSchedulesPanel={hideSchedulesPanel}
      closeAddEditSchedule={() => handleAddEditSchedule("")}
      saveScheduleData={saveScheduleData}
      addItemSchedule={addItemSchedule}
    />
    }
  </div>;
};

export default ScheduleList;
