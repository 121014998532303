import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectionsContainer: {
    whiteSpace: "nowrap"
    // padding: '1rem',
    // alignItems: 'center'
  },
  selectUnitButton: {
    width: "18.8445rem",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  selectUnitButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectUnitButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  selectDateRangeButton: {
    width: "30ch",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  selectDateRangeButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectDateRangeButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  datePicker: {
    input: {
      color: "white"
    }
  },
  dateRangePickerDialog: {
    "& .MuiPickersCalendar-transitionContainer": {
      minHeight: 218,
      marginTop: 10
    }
  },

  selectedUnitStyle: {
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  paperBg: { background: "transparent" }
}));
export default useStyles;
