import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto"
  },
  container: {
    flex: "1"
    // textAlign: "center"
    // width: "100%",
    // background: "rgb(236, 235, 239)",
    // alignContent: "flex-start"
  },
  card: {
    padding: "15px",
    marginBottom: "15px",
    // marginLeft: "15px",
    marginTop: "15px"
  },
  header: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginTop: "15px"
  },
  rightCard: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "15px"
  },

  submitButton: {
    width: "100%"
  },
  dialog: {
    padding: "10px"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden"
  },
  title: {},
  headerStyle: {
    fontWeight: "bold",
    color: theme.palette.primary.light,
    marginBottom: "5px"
  },
  buttonsContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"
  },
  dialogSubContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 20,
    flexFlow: "column nowrap"
  }
}));

export default useStyles;
