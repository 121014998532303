import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import App from "./App";
import locale from "./services/localeService";

locale.init();

ReactModal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));
