import React from 'react';
import { FieldProps } from 'formik';
import {
  TextField,
  FormControl,
  makeStyles,
  FilledInputProps,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { FilledTextFieldProps } from '@material-ui/core/TextField';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  input: {
    minWidth: '10ch',
    height: '44px',
    width: '100%',
    '& label': {
      color: 'rgba(255, 255, 255, 0.7)',
      '&focus': {
        color: 'rgba(255, 255, 255, 0.7)'
      }
    },
    '& div': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    '& MuiInputBase-root': {
      color: 'white'
    }
  },
  margin: {
    margin: '1rem 0 1rem 0'
  },
  textField: {
    width: '100%'
  }
}));
const FixedInputField: React.FC<FieldProps & FilledTextFieldProps & FilledInputProps> = ({
  field,
  meta,
  form,
  ...props
}) => {
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef<HTMLLabelElement>(null);
  return (
    <FormControl
      className={clsx(classes.margin, classes.textField, props.className)}
      variant="filled"
    >
      <span>{props.label}</span>
      <OutlinedInput
        // id={props.id}
        error={props.error}
        helperText={props.helperText}
        {...field}
        {...props}
        margin="dense"
        // variant="filled"
        className={classes.input}
        labelWidth={labelWidth}
      />
      <span>{props.helperText}</span>
    </FormControl>
  );
};

export default FixedInputField;
