import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";
import { ISite } from "./Sites";

export interface ICustomer {
  id: string;
  name?: string;
  description?: string;
  enableTriggers?: boolean;
  sites: string[];
  triggers: string[];
  role: any;
}

export interface ICustomerMap {
  [key: string]: ICustomer;
}

export interface ICustomersModel {
  allCustomers: ICustomerMap;
  initialize: Action<ICustomersModel, any>;
  onInitialized: ActionOn<ICustomersModel, IRootStoreModel>;
  getCustomerName: Computed<
    ICustomersModel,
    (id: string | undefined) => string | undefined
  >;
  getCustomerSites: Computed<
    ICustomersModel,
    (id: string) => ISite[],
    IRootStoreModel
  >;
  getCustomerTraps: Thunk<IRootStoreModel, { customerId: string }>;
  getCustomerByName: Computed<
    ICustomersModel,
    (name: string) => ICustomer | null
  >;
}

export const customersModel: ICustomersModel = {
  allCustomers: {},
  initialize: action((state, payload) => {
    state.allCustomers = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  getCustomerByName: computed((state) => (name) => {
    const foundCustomers = _.filter(
      _.values(state.allCustomers),
      (customer: ICustomer) => customer.name === name
    );

    if (foundCustomers.length > 0) {
      if (foundCustomers.length > 1) {
      }

      return foundCustomers[0];
    }

    return null;
  }),
  getCustomerName: computed((state) => (id) => {
    const noName = "-";

    if (!id || !state.allCustomers[id] || !state.allCustomers[id].name) {
      return noName;
    }

    return state.allCustomers[id].name;
  }),

  getCustomerSites: computed(
    [
      (state) => state.allCustomers,
      (state, storeState) => storeState.sites.allSites
    ],
    (allCustomers, allSites) => (id) => {
      if (!allCustomers[id]) return [];
      return _.map(allCustomers[id].sites, (siteId) => allSites[siteId]);
    }
  ),
  getCustomerTraps: thunk(async (actions, payload) => {
    const data: any = await coolremoteSDK.Customer.getTraps(payload.customerId);
    return data;
  })
};
