import { Grid, Typography } from "@material-ui/core/";
import { AccessTime } from "@material-ui/icons/";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { t } from "ttag";
import { Switch } from "../../../../cool_widgets/Switch";
import { AlertControl } from "../../../../icons";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import {
  AutoMode,
  ColdMode,
  DryIcon,
  HotMode,
  ModeFanIcon
} from "../../../../svgComponents";
import useStyle from "./UnitItem.style";

interface IObject {
  [key: string]: any;
}

const ControlItem = (props: any) => {
  const [togglePowerButton, setTogglePowerButton] = useState<boolean>(false);
  const [targetPowerVal, setTargetPowerVal] = useState<any>(null);
  let spinnerTimer: number = 0;

  const types = useStoreState((state) => state.types);
  const operationStatusesMirror = useStoreState((state) => state.operationStatusesMirror);
  const devices = useStoreState((state) => state.devices.allDevices);
  const user = useStoreState((state) => state.users.me);

  const unitPowerControl = useStoreActions(
    (state) => state.units.changePowerState
  );

  const allUnits = useStoreState(
    (state) => state.units.allUnits
  );

  const { unitId, siteId, selectItem, groupId, selectedItem, addMessage } = props;
  const item: any = allUnits[unitId] || {};
  const { operationModes, operationStatuses, temperatureScale: tempScaleTypes } = types;
  const { on: powerOnVal, off: powerOffVal } = operationStatusesMirror;
  const { temperatureScale } = user;
  const tempUnit = tempScaleTypes[temperatureScale as number] === "celsius" ? "°C" : "°F";

  const classes = useStyle();
  //aut mode scale(0.7661)
  const modeIcons: IObject = {
    COOL: <ColdMode style={{ transform: "scale(1.389)" }} className={clsx(classes.iconStyle, classes.modeIcon)} />,
    HEAT: <HotMode style={{ transform: "scale(1.2343)" }} className={clsx(classes.iconStyle, classes.modeIcon)} />,
    AUTO: <AutoMode style={{ transform: "scale(.76)" }} className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />,
    DRY: <DryIcon style={{ transform: "scale(0.59)", marginLeft: "-7px" }} className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />,
    FAN: <ModeFanIcon style={{ transform: "scale(0.6945)", marginLeft: "-3px" }} className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />
  };
  const {
    activeOperationMode,
    activeOperationStatus: itemPowerStatus,
    activeSetpoint,
    ambientTemperature,
    name,
    message,
    filter,
    isConnected,
    device,
    schedules = [] } = item;

  const deviceIsConnected = devices[device] && devices[device].isConnected;
  const isItemOn = +itemPowerStatus === +powerOnVal;
  const hasError = message && message.replace(/\s/g, "").toUpperCase() !== "OK";
  const toggleUnitPower = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const changeStatusTo: number =
      operationStatuses[itemPowerStatus] === "on" ? +powerOffVal : +powerOnVal;

    setTargetPowerVal(changeStatusTo);
    setTogglePowerButton(true);
    spinnerTimer = window.setTimeout(() => {
      setTogglePowerButton(false);
    }, 5000);

    unitPowerControl({ unitId, state: +changeStatusTo })
      .catch((error: any) => addMessage({ message: error.message }));
  };

  useEffect(() => {
    if (!togglePowerButton) {
      return;
    }
    if (
      itemPowerStatus === targetPowerVal
    ) {
      setTogglePowerButton(false);
      clearTimeout(spinnerTimer);
    }
  }, [
    togglePowerButton,
    targetPowerVal,
    itemPowerStatus,
    spinnerTimer]);

  const switchClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const selected = !selectedItem.isGroup && selectedItem.unitId === unitId && selectedItem.groupId === groupId;

  return (
    <div
      className={clsx(classes.unitStyle, {
        [classes.removedStyle]: !isConnected || !deviceIsConnected, [classes.selected]: selected
      })}
      onClick={() =>
        !isConnected || !deviceIsConnected ? {} : selectItem(siteId, { isGroup: false, unitId, groupId, type: "unit" })
      }
    >
      <Fragment>
        <div className={clsx(classes.locSec, { [classes.errorColor]: hasError && !filter })}>
          <Typography noWrap className={clsx(classes.nameStyle, { [classes.boldText]: selected })}>{name}</Typography>
        </div>
        <div className={classes.tempSec}>
          <div className={clsx(classes.iconDiv, { [classes.notVisibile]: !isItemOn || (hasError && !filter) })}>
            {modeIcons[operationModes[activeOperationMode]]}
          </div>
          <div className={classes.tempInfo}>

            <Typography className={clsx(classes.tempText, { [classes.boldText]: selected, [classes.errorColor]: hasError && !filter })}>
              {hasError && !filter ? message : (isItemOn ? `${activeSetpoint}°` : t`OFF`)}
            </Typography>
            <Typography className={classes.roomTemp}>
              {t`Now: `}{`${Math.round(ambientTemperature)}${tempUnit}`}
            </Typography>
          </div>
        </div>

        <Grid container className={classes.powerAlertsSchedulesContainer}>
          {!togglePowerButton && (
            <div className={classes.notesSec}>
              <div>{schedules.length > 0 && <AccessTime className={clsx(classes.noteIcon, { [classes.removeElements]: hasError && !filter })} />} </div>
              {(hasError || filter) && (
                <AlertControl color={filter ? "#f7b500" : "#f05146"} className={clsx(classes.noteIcon, classes.alertIcon)} />
              )}
            </div>
          )}

          {togglePowerButton && (
            <PulseLoader size={5} color="white" margin="3px" />
          )}
          <Switch
            checked={isItemOn}
            disableRipple={true}
            onClick={(e: any) => switchClick(e)}
            switchChange={(e: any) => toggleUnitPower(e)}
            value="checkedA"
            disabled={!isConnected || !deviceIsConnected}
          />
        </Grid>
      </Fragment>
    </div>
  );
};

export default ControlItem;
