import {
  Card,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import {
  Close,
  SwitchOff as SvgSwitchOff,
  SwitchOn as SvgSwitchOn
} from "../../icons/";
import useStyles from "./AlertGroup.style";

export interface IAlertGroupType {
  id: number;
  name: string;
  users: string[];
  enable: boolean;
  customer: string;
}

const AlertGroup: React.FC<any> = (props: any) => {
  const { close, editAlertGroup:  alertGroup, saveAlert, allTraps, allSites} = props;
  const isEdit = alertGroup ? true : false;

  const [name, setName] = useState(alertGroup ? alertGroup.name : "");
  const [error, setError] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [globalAlertsEnable, setGlobalAlertsEnable] = useState<any>([]);
  const [allUserTrapsEnable, setAllUsersTrapsEnable] = useState(alertGroup ? alertGroup.allUserTrapsEnable : false);
  const [trapsToAdd, setTrapsToAdd] = useState<string[]>(alertGroup && !alertGroup.allUserTrapsEnable ? alertGroup.traps : []);
  const [selectedSites, setSelectedSites] = useState<string[]>(alertGroup ? alertGroup.sites : []);

  const classes = useStyles();

  useEffect(() => {

    let globalAlerts;
    if (!isEdit) {
      globalAlerts = {
        deviceDisconnectedEnable: { isEnabled: false, title: t`Disconnect device alerts` },
        cleanFilterEnable: { isEnabled: false, title: t`Clean Filter alerts` },
        indoorUnitErrorEnable: { isEnabled: false, title: t`Unit indoor alerts` },
        outdoorUnitErrorEnable: { isEnabled: false, title: t`Unit Outdoor alerts` },
        unitDisconnectedEnable: { isEnabled: false, title: t`Unit Disconnected` }
      };
    } else {
      globalAlerts = {
        deviceDisconnectedEnable: { isEnabled: alertGroup.deviceDisconnectedEnable, title: t`Disconnect device alerts` },
        cleanFilterEnable: { isEnabled: alertGroup.cleanFilterEnable, title: t`Clean Filter alerts` },
        indoorUnitErrorEnable: { isEnabled: alertGroup.indoorUnitErrorEnable, title: t`Unit indoor alerts` },
        outdoorUnitErrorEnable: { isEnabled: alertGroup.outdoorUnitErrorEnable, title: t`Unit Outdoor alerts` },
        unitDisconnectedEnable: { isEnabled: alertGroup.unitDisconnectedEnable, title: t`Unit Disconnected` }
      };
    }
    setGlobalAlertsEnable(globalAlerts);
  }, []);

  const addTraps = (trapId: string) => {
    const temp: string[] = _.clone(trapsToAdd);
    if (_.includes(temp, trapId)) {
      _.pull(temp, trapId);
    } else {
      temp.push(trapId);
    }
    setTrapsToAdd(temp);
  };

  const handleClose = () => {
    setTrapsToAdd(
      alertGroup && !alertGroup.allUserTrapsEnable ? alertGroup.traps : []
    );
    setAllUsersTrapsEnable(alertGroup ? alertGroup.allUserTrapsEnable : false);
    setName(alertGroup ? alertGroup.name : "");
    close();
  };
  const flushAlertGroup = () => {
    setName("");
    setTrapsToAdd([]);
    setAllUsersTrapsEnable(false);
  };
  const onSelectAllClick = () => {
    if (selectedSites?.length === allSites.length) {
        setSelectedSites([]);
    }
    else {
        setSelectedSites(allSites.map((t: any) => t.id));
    }
  };
  const onSelectSiteClick = (id: any) => {
    const index = selectedSites.indexOf(id);
    const selectedTemp = [...selectedSites];
    if (index > -1) {
      selectedTemp.splice(index, 1);
      setSelectedSites(selectedTemp);
    } else {
      setSelectedSites([...selectedTemp, id]);
    }
  };

  const allSelected = selectedSites?.length === allSites.length;

  const handleAgree = () => {
    let selectedAlert = false;
    for (let alertEnabled in globalAlertsEnable) {
      if (globalAlertsEnable[alertEnabled].isEnabled === true) {
        selectedAlert = true;
      }
    }
    if (!name) {
      setError(t`Required`);
      return;
    }
    if (trapsToAdd.length === 0 && !allUserTrapsEnable && !selectedAlert) {
      setErrorMessage("At least one alert or trap is required");
      return;
    }
    if (selectedSites.length === 0) {
      setErrorMessage("At least one site is required");
      return;
    }
    setError("");
    setErrorMessage("");
    const traps = allUserTrapsEnable ? [] : trapsToAdd;
    const enablesObject: any = {};

    Object.keys(globalAlertsEnable).map((key) => {
      return enablesObject[key] = globalAlertsEnable[key].isEnabled;
    });

    isEdit
      ? saveAlert({
        id: alertGroup.id,
        name,
        enablesObject,
        traps,
        allUserTrapsEnable,
        sites: selectedSites
      })
      : saveAlert({
        name,
        enablesObject,
        traps,
        allUserTrapsEnable,
        sites: selectedSites
      });
    !isEdit && flushAlertGroup();
    close();
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>
            {alertGroup ? t`Edit alert group` : t`Add new alert group`}
          </Typography>
          <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Grid className={classes.nameContainer}>
            <Typography variant="h6" className={classes.nameTitle}>{t`Group Name`}</Typography>
            <TextField
              variant="outlined"
              id={"input-alert- group-name"}
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);
              }}
              error={!!error}
              helperText={error}
            />
          </Grid>
          <Grid container className={classes.container}>
            <Grid container direction="column" className={classes.boxContainer}>
              <Grid item spacing={2} className={classes.globalAlertsContainer}>
                <Typography
                  variant="h6"
                  className={classes.secondaryTitle}
                >{t`Global alerts`}</Typography>
                <div className={classes.overflowContainer}>
                  {Object.keys(globalAlertsEnable).map((key: any, index: number) => {
                    const alert = globalAlertsEnable[key];
                    return (
                      <Card key={index} className={classes.itemCard}>
                        <Grid container spacing={3} direction="row" className={classes.items}>
                          <Grid item xs={10}>
                            <Typography variant="body2">{alert.title}</Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.switch}>
                            <IconButton
                              aria-label="switch icon"
                              onClick={() => setGlobalAlertsEnable({ ...globalAlertsEnable, [key]: { ...alert, isEnabled: !alert.isEnabled } })}
                              className={classes.switchIcon}
                            >
                              {alert.isEnabled ? (
                                <SvgSwitchOn style={{ color: "white" }} />
                              ) : (
                                  <SvgSwitchOff style={{ color: "white" }} />
                                )}
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
                </div>
              </Grid>
              <Grid item spacing={2} className={classes.sitesContainer}>
                <Typography
                    variant="h6"
                    className={classes.secondaryTitle}
                  >{t`Sites`}</Typography>
                  <div className={classes.overflowContainer}>
                      <Grid container spacing={3} direction="row" className={classes.allSitesItem}>
                        <Grid item xs={10}>
                          <Typography>{"All sites"}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.switch}>
                          <Checkbox
                              color="default"
                              checked={allSelected}
                              onChange={onSelectAllClick}
                              inputProps={{ "aria-labelledby": `toggle all` }}
                          />
                        </Grid>
                      </Grid>
                    {allSites.map((site: any, index: number) => {
                    const isSelected = selectedSites.indexOf(site.id) > -1;
                    return (
                      <Card key={index} className={classes.itemCard}>
                        <Grid container spacing={3} direction="row" className={classes.items}>
                          <Grid item xs={10}>
                            <Typography variant="body2">{site.name}</Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.switch}>
                            <Checkbox
                              color="default"
                              onChange={() => onSelectSiteClick(site.id)}
                              checked={isSelected}
                              inputProps={{ "aria-labelledby": name }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
            <Grid item className={classes.trapsContainer}>
              <Typography
                variant="h6"
                className={classes.secondaryTitle}
              >{t`Anomalies`}</Typography>
              <div className={classes.longOverflowContainer}>
              {!_.isEmpty(allTraps) ? (
                <>
                <Grid container spacing={3} direction="row" className={classes.allAnomaliesItem}>
                  <Grid item xs={10}>
                    <Typography>{t`All custom anomalies`}</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.switch}>
                    <IconButton
                      aria-label="switch icon"
                      onClick={() => setAllUsersTrapsEnable(!allUserTrapsEnable)}
                      className={classes.switchIcon}
                    >
                      {allUserTrapsEnable ? (
                        <SvgSwitchOn style={{ color: "white" }} />
                      ) : (
                          <SvgSwitchOff style={{ color: "white" }} />
                        )}
                    </IconButton>
                  </Grid>
                </Grid>
                  {Object.values(allTraps).map((trap: any) => (
                    <Card key={trap.id} className={classes.trapsItem}>
                      <Grid container spacing={3} direction="row" className={classes.items}>
                        <Grid item xs={10}>
                          <Typography variant="body2">{trap.name}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={clsx(
                            classes.switch,
                            allUserTrapsEnable ? classes.trapsDisabled : ""
                          )}
                        >
                          <IconButton
                            aria-label="switch icon"
                            onClick={() => addTraps(trap.id)}
                            className={classes.switchIcon}
                            disabled={allUserTrapsEnable ? true : false}
                          >
                            {_.includes(trapsToAdd, trap.id) || allUserTrapsEnable ? (
                              <SvgSwitchOn style={{ color: "white" }} />
                            ) : allUserTrapsEnable ? (
                              <></>
                            ) : (
                                  <SvgSwitchOff style={{ color: "white" }} />
                                )}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                  </>
                ) : (
                    <Typography className={classes.allAnomaliesItem}> There are no custom anomalies to select</Typography>
                  )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.actionsHolder}>
          {!!errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
          <Button
            onClick={handleClose}
            onMouseUp={handleClose}
            title="Cancel"
            uppercase
            width={150}
            white
            marginRight
          >{t`Cancel`}</Button>
          <Button
            onClick={handleAgree}
            onMouseUp={handleAgree}
            title="save"
            uppercase
            width={150}
          >
            {alertGroup ? t`Update` : t`Add`}
          </Button>
        </div>
      </Dialog>
    </div >
  );
};
export default AlertGroup;
