import {
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import LightTooltip from "../../../components/Tooltip/LightTooltip";
import { QuestionMark as SvgQuestionMark } from "../../../icons/";
import { ISite } from "../../../models/Sites";
import assetsService from "../../../services/assetsService";
import useStyles from "./RegisterDevice.style";

interface IProps {
  site: ISite;
  next: any;
  setDeviceId: (id: string) => void;
  setDevicePin: (id: string) => void;
  deviceId: string;
  devicePin: string;
  selectSite: any;
  isLoading: boolean;
  setIsDisabled: (isDisabled: boolean) => void;
  className?: string;
}

const RegisterDevice: React.FC<IProps> = (props) => {
  let [errors, setErrors] = React.useState("");

  const classes: any = useStyles();

  const closeErrorBox = () => {
    setErrors("");
  };

  const errorBox = errors ? (
    <ErrorBox error={errors} onClose={closeErrorBox} />
  ) : null;

  return (
    <Grid container spacing={3} direction="row" className={props.className && classes[props.className]}>
      <Modal className={classes.loadingModal} open={props.isLoading}>
        <CircularProgress classes={{ root: classes.root }} size={240} />
      </Modal>
      <Grid item xs={6}>
        <div>
          <Typography align="center" className={classes.title} variant="h4">
            {t`Let's connect`}
          </Typography>
          <Typography align="center" className={classes.title} variant="h4">
            {t`your HVAC System`}
          </Typography>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.imgContainer}>
          <img
            src={assetsService.getImagesPath("cmnet.png")}
          ></img>
        </div>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.formWrapper}>
            <Typography variant="h5">{t`Add CoolAutomation Device`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" className={classes.textField} >
              <TextField
                value={props.deviceId}
                onChange={(event: any) => props.setDeviceId(event.target.value)}
                margin="dense"
                variant="filled"
                label={t`Device SN MAC`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LightTooltip title={t`You can find the device SN on the back of the device`}>
                        <IconButton disableRipple aria-label="Device SN" classes={{ root: classes.iconButton }}>
                          <SvgQuestionMark style={{ color: "#aaa2aa" }} />
                        </IconButton>
                      </LightTooltip>
                    </InputAdornment>
                  )
                }}
              />

            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" className={classes.textField}>
              <TextField
                value={props.devicePin}
                onChange={(event: any) => props.setDevicePin(event.target.value)}
                label={t`Device PIN`}
                margin="dense"
                variant="filled"
                className={classes.textFieldContent}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LightTooltip title={t`You can find the device PIN on the back of the device`}>
                        <IconButton disableRipple aria-label="Device Pin" classes={{ root: classes.iconButton }}>
                          <SvgQuestionMark style={{ color: "#aaa2aa" }} />
                        </IconButton>
                      </LightTooltip>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterDevice;
