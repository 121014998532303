import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  items: {
    display: "flex",
    alignItems: "center",
    padding: ".3rem"
  },
  allAnomaliesItem: {
    display: "flex",
    alignItems: "center",
    padding: "0 1rem"
  },
  triggersItem: {
    marginLeft: "1rem",
    border: "1px solid lightgray",
    margin: ".5rem"
  },
  itemCard: {
    border: "1px solid lightgray",
    margin: ".5rem"
  },
  title: {
    display: "flex",
    justifyContent: "flex-end"
  },
  switchIcon: {
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  trapsDisabled: {
    opacity: "0.5"
  },
  secondaryTitle: {
    color: "#4b1c46",
    padding: "20px 24px",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 800,
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    marginBottom: "2rem",
    backgroundColor: "rgb(236,235,239)"
  },
  container: {
    justifyContent: "space-evenly",
    marginTop: "1rem"
  },
  nameContainer: {
    margin: "2rem"
  },
  nameTitle: {
    color: "#4b1c46",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 800
  },
  plusIcon: {
    "&:hover": {
      "& $addIcon": {
        display: "none"
      },
      "& $addOnHoverIcon": {
        display: "inline"
      }
    },
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  addIcon: {
    display: "block"
  },
  addOnHoverIcon: {
    display: "none"
  },
  switch: {
    display: "flex",
    justifyContent: "flex-end"
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    },
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  trapsItem: {
    marginLeft: "1rem",
    border: "1px solid lightgray",
    margin: ".5rem"
    },
  dialog: {
    width: "100%",
    borderRadius: "8px"
  },
  paperTableContainer: {
    boxSizing: "border-box",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
    },
  tableContainer: {
    display: "flex",
    height: "100%"
    },
  tableHeadCell: {
    borderBottom: "none",
    padding: "0 15px",
    color: "#79797d",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#efeff2",
    textTransform: "uppercase",
    height: 45,
    fontSize: 13
  },
  overWritePadding: {
    fontSize: "13px",
    padding: "0 15px",
    height: "35px"
  },
  mediumWidth: { width: "60%", wordBreak: "break-word" },

  dialogActions: {
    padding: "1.5rem",
    overflowY: "hidden"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

    },
  dialogContent: {
    padding: "0",
    overflowY: "hidden",
    flex: "1 1 auto",
    // padding: 20,
    // overflowY: "auto",
    paddingBottom: 0
  },
  actionsHolder: {
  padding: 20,
  display: "flex",
  justifyContent: "flex-end"
  },
  boxContainer: {
    borderRadius: "4px",
    width: "45%"
  },
  sitesContainer: {
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    maxHeight: "300px"
  },
  globalAlertsContainer: {
    marginBottom: "1rem",
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    maxHeight: "300px"
  },
  trapsContainer: {
    border: "solid 1px #d5d2d5",
    borderRadius: "4px",
    width: "45%",
    maxHeight: "620px"
  },
  allSitesItem: {
    display: "flex",
    alignItems: "center",
    padding: "0 0.9rem 0 1rem"
  },
  errorMessage: {
    color: "#f44336",
    fontSize: "14px",
    marginRight: "30px",
    alignItems: "center",
    display: "flex"
  },
  overflowContainer: {
    overflowY : "auto",
    overflowX: "hidden",
    height: "200px"
  },
  longOverflowContainer: {
    overflowY : "auto",
    overflowX: "hidden",
    height: "510px"
  }
});
export default useStyles;
