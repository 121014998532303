import {
  Button as MButton,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { ArrowDownO, Close, Plus } from "../../icons";
import Tooltip from "../Tooltip/LightTooltip";
import styles from "./AddRule.style";

const CustomSelect = ({ notDisabledOptions, className, error, disabled, options = [], value, placeholder, onChange, optionValue = "value", label = "name", name }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const replacedValue = value === undefined ? "" : (value || "");
  const selectedValue = options.filter((option: any) => String(option[optionValue]) === String(value))[0];
  const valueName = selectedValue?.[label];

  return <Tooltip placement="bottom" title={valueName || ""}>
    <FormControl variant="outlined" className={clsx(classes.formControl, { [className]: className })}>
      <InputLabel id="demo-simple-select-outlined-label" className={classes.labelSelect}>{placeholder}</InputLabel>
      <Select
        name={name}
        displayEmpty
        disabled={disabled}
        value={replacedValue}
        onChange={onChange}
        error={error}
        variant="outlined"
        disableUnderline
        classes={{ icon: classes.arrowDownIcon }}
        IconComponent={ArrowDownO}
        className={clsx(classes.select, { [classes.disabledSelect]: options.length === 0 || disabled })}
        label={placeholder}
        MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
      >
        {_.isEmpty(options) ? <MenuItem value={"none"} disabled>{t`No Options`}</MenuItem> : <MenuItem value={""} disabled
          style={{ display: "none" }}
        ></MenuItem>}
        {options.map((option: any) => {
          if (!!notDisabledOptions && notDisabledOptions.includes(option[optionValue])) {
            return null;
          }
          return (<MenuItem key={option[optionValue]} value={option[optionValue]}>
            {option[label]}
          </MenuItem>);
        }
        )}
      </Select >
    </FormControl>
  </Tooltip>;
};

const Box = ({ title, children, small, bLabel1, bLabel2, bDisabled1, bDisabled2, action1, action2, className, addBottomMargin }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return <div id="box1" className={clsx(classes.boxContainer, { [classes.small]: small, [classes.addBottomMargin]: addBottomMargin, [className]: className })}>
    <div id="box-header1" className={classes.boxHeader}>
      <Typography className={classes.boxTitle}>{title}</Typography>
      <div>
        {action1 && <Button
          white
          width={small ? 130 : 144}
          className={clsx(classes.btn, classes.addMargin13, { [classes.disabledWhiteButton]: bDisabled1 })}
          startIcon={<Plus className={classes.plusIcon} />}
          disabled={bDisabled1}
          onClick={action1}
          onMouseDown={(event: any) => { event.preventDefault(); }}
        >
          {bLabel1}
        </Button>}
        <Button onMouseDown={(event: any) => { event.preventDefault(); }} white width={110} className={clsx(classes.btn, { [classes.disabledWhiteButton]: bDisabled2 })} disabled={bDisabled2} onClick={action2}
          startIcon={small ? <Plus className={classes.plusIcon} /> : undefined} >
          {bLabel2}
        </Button>
      </div>
    </div>
    <div className={clsx(classes.boxContent, {[classes.unitsAppliedBox] : small})}>{children}</div>
  </div >;
};

const CustomedTextField = ({ error, label, disabled, placeholder, value, width, normalMargin, className, onChange, name, type = "string" }: any, props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return <TextField
    label={label}
    disabled={disabled}
    classes={{ root: classes.label }}
    name={name}
    value={value}
    style={width ? { width } : undefined}
    onChange={onChange}
    placeholder={placeholder}
    variant="outlined"
    error={error}
    className={clsx(classes.textField, { [classes.addMargin]: !normalMargin, [className]: className })}
    type={type}
  />;
};
const GButton = ({ inGroup, selected = "and", onClick, disabled }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return <ButtonGroup style={{ borderRadius: 6, margin: inGroup ? "5px 0" : "10px 0", marginLeft: inGroup ? 13 : 30 }}
  >
    <MButton disabled={disabled} onMouseUp={() => onClick("and")} className={clsx(classes.andOrButton, { [classes.selectedAndOrButton]: selected === "and" })}>And</MButton>
    <MButton disabled={disabled} onMouseUp={() => onClick("or")} className={clsx(classes.andOrButton, { [classes.selectedAndOrButton]: selected === "or" })}>Or</MButton>
  </ButtonGroup>;
};

const UnitWrapper = ({ onRemove, siteName, systemName, unitName }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return <div className={classes.wrapperContainer}>
    <Tooltip arrow interactive placement="top-start" title={siteName + " /" + systemName + " /" + unitName}>
      <Typography className={classes.namesContainer}><span className={classes.name}>{siteName || ""}</span> <span className={classes.middle}>/</span> <span className={classes.name}>{systemName || ""}</span><span className={classes.middle}>/</span> <span className={classes.name}>{unitName || ""}</span></Typography>
    </Tooltip>
    <IconButton className={classes.removeButton} onClick={onRemove}><Close color="#fff" /></IconButton></div>;
};

const SystemWrapper = ({ onRemove, siteName, systemName }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return <div className={classes.wrapperContainer}>
    <Tooltip arrow interactive placement="top-start" title={siteName + " /" + systemName} >
      <Typography className={classes.namesContainer}><span className={classes.name}>{siteName || ""}</span> <span className={classes.middle}>/</span> <span className={classes.name}>{systemName}</span></Typography>
    </Tooltip>
    <IconButton className={classes.removeButton} onClick={onRemove}><Close color="#fff" /></IconButton></div>;
};

const TextInput = ({
  id,
  label,
  error,
  className,
  onChange,
  ...props
}: any) => {
  const classes = clsx(
    "input-group",
    {
      "animated shake error": !!error
    }
    // className
  );
  return (
    <div className={classes}>
      <label htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        className="text-input"
        onChange={onChange}
        {...props}
      />
      <p>]{error} </p>
    </div>
  );
};

export { CustomSelect, Box, CustomedTextField, GButton, SystemWrapper, UnitWrapper, TextInput };
