// / <reference types="googlemaps" />
/* global google */

import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IGetUnitStatsResult } from "../../models/Units";
import ExportUtils from "../../utils/ExportUtils";
import UnitStatsGraph from "./UnitStatsGraph";
import UnitStatsSlider from "./UnitStatsSlider";
import UnitStatsTable from "./UnitStatsTable";

const MAX_ALLOWED_STATS_SELECTIONS = 6,
  MAX_ALLOWED_GAP_PRO = 1000 * 60 * 20, // (20 mins)
  AUTOUPDATE_INTERVAL = 1000 * 60, // one minute
  STATIC_CODES = {
    ALERTS_CODE: "53",
    ON_OFF_CODE: "48",
    ROOM_TEMP_CODE: "49",
    SETPOINT_CODE: "50",
    MODE_CODE: "51",
    FAN_CODE: "52",
    OUTDOOR_TEMP: "55",
    SITE_TEMP: "57"
  },
  paramsColorsSet: any = ["#7a6095", "#35a8e0", "#00b259", "#ef3b2f", "#f8b133", "#7f7182"];

const UnitStats: React.FC<any> = ({ unit, alerts, setOutdoorTemp, setSelectedTime, setReFetchUnit, isLoading, setIsLoading, selectedTime: { selectedStartTime = null, selectedEndTime = null }, setExternalTemp }) => {
  const location: any = useLocation();
  const { state } = location;
  const history = useHistory();
  const getUnitParamsAndStats = useStoreActions((a) => a.units.getUnitParamsAndStats);
  const getSystemProData = useStoreActions((a) => a.systems.getSystemProData);
  const errorTypes = useStoreState((state) => state.serviceErrorTypes);
  const getUnits = useStoreActions((actions) => actions.units.getUnits);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const setStatsUpdate = useStoreActions((a) => a.setStatsUpdate);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale || 0);
  const userMeasurementUnits = useStoreState((s) => s.users.me.measurementUnits || 1);
  const types = useStoreState((s) => s.types || {});
  const unitTypesMirrror = useStoreState((s) => s.unitTypesMirrror);
  const serviceParams = useStoreState((s) => s.serviceParams);
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const enums = useStoreState((state) => state.serviceParamTypes);
  const getSystem = useStoreState((state) => state.systems.getSystem);
  const [isSystemLoading, setIsSystemLoading] = useState(false);
  const [colorSet, setColorSet] = useState<any>([...paramsColorsSet]);
  const [paramsColors, setParamsColors] = useState<any>({});
  const [statDataCollection, setStatDataCollection] = useState<any>([]);
  const [autoupdate, setAutoupdate] = useState(false);
  const [siteTempIsLoading, setSiteTempIsLoading] = useState<boolean>(true);
  const [paramsTable, setParamsTable] = useState<any>({});
  const [statsResults, setStatsResults] = useState<any>(null);
  const defaultDateNow = new Date().getTime();
  const defaultDatePast = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
  const [callTimer, setCallTimer] = useState<any>(null);
  const getSite = useStoreState((s) => s.sites.getSite);
  const [siteExternalTemps, setSiteTemps] = useState<any>({});
  const [alertsData, setAlertsData] = useState<any>({});
  const [unitLineQuality] = useState<any>({});
  const [measuredCodes, setMeasuredCodes] = useState<any[]>([]);

  const allSystems = useStoreState((s) => s.systems.allSystems);
  const getUnitName = useStoreState((u) => u.units.getUnitName);
  const getUnitType = useStoreState((u) => u.units.getUnitType);
  const doStatsUpdate = useStoreState((u) => u.doStatsUpdate);
  const getTemperatureScaleDisplayPlainText = useStoreState(
    (s) => s.users.getTemperatureScaleDisplayPlainText
  );
  const getUnitTypeByTypes = useStoreState((u) => u.units.getUnitTypeByTypes);

  const system = unit.system && allSystems[unit.system];
  const hvacBrands = useStoreState((state) => state.types.hvacBrands);
  const brandName = system && _.find(hvacBrands, { value: system.brandNum })?.name || "UNKNOWN";

  const unitId = unit.id;
  const unitType = getUnitType(unit.id);
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  const selectedSiteId = unit?.site;
  const timezone = getSite(selectedSiteId)?.timezone || "";
  const showHideAllParams = useCallback((isChecked: boolean, paramsTable: any) => {
    const rows = { ...paramsTable };

    Object.keys(rows).forEach((key: any) => {
      const obj = rows[key];
      if (!obj) {
        return;
      }
      obj.isShowing = isChecked;
      if (!isChecked) {
        obj.isChecked = false;
      }
    });
    if (!isChecked) {
      setColorSet([...paramsColorsSet]);
    }
    setParamsTable(rows);
  }, []);
  const showHideParam = useCallback((code: any, paramsTable: any) => {
    const oldVal = !!paramsTable[code].isShowing;
    setParamsTable({ ...paramsTable, [code]: { ...paramsTable[code], isShowing: !oldVal, isChecked: false } });
  }, []);

  const prepareUserPrefData = () => {
    if (_.isEmpty(paramsTable)) {
      return;
    }
    const showingParams = Object.values(paramsTable).filter((row: any) => row?.isShowing);
    const data = showingParams.map((row: any) => [row.code, row.isChecked]);
    let userPrefObj: any = JSON.parse(localStorage.getItem("recentParamsO") as string);
    if (userPrefObj) {
      userPrefObj[brandName] = {
        ...userPrefObj[brandName],
        [unitType]: data
      };
    }
    else {
      userPrefObj = {
        [brandName]: {
          [unitType]: data
        }
      };
    }
    localStorage.setItem("recentParamsO", JSON.stringify(userPrefObj));
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    prepareUserPrefData();
  }, [paramsTable, measuredCodes]);

  useEffect(() => {
    if (!_.isNil(state) && state.unitId === unit.id && !!state?.time) {
      const { time } = state;
      const diff = moment.now() - time;
      // setSelectedStartTime(time - 43200 * 1000);
      // setSelectedEndTime(diff < 43200000 ? moment.now() : (time + 43200000));
      setSelectedTime({ selectedStartTime: time - 43200 * 1000, selectedEndTime: diff < 43200000 ? moment.now() : (time + 43200000) });
    } else {
      if (!selectedStartTime || !selectedEndTime) {

        // setSelectedStartTime(defaultDatePast);
        // setSelectedEndTime(defaultDateNow);
        setSelectedTime({ selectedStartTime: defaultDatePast, selectedEndTime: defaultDateNow });

      }
      history.replace({
        pathname: `/unit-stats/${unit.id}`,
        state: { alertCheck: false }
      });
    }
  }, [unit.id]);

  const updateParamRow = useCallback((code: any) => {
    const oldVal = paramsTable[code].isChecked;
    const numOfSelected = calcData.numOfSelected;
    if (!oldVal && numOfSelected >= MAX_ALLOWED_STATS_SELECTIONS) {
      return;
    }
    setParamsTable({ ...paramsTable, [code]: { ...paramsTable[code], isChecked: !oldVal } });

    if (!oldVal) {
      // if (selectedParams.length < MAX_ALLOWED_STATS_SELECTIONS) {
      const colorSetTemp = colorSet;
      const newColor = colorSetTemp.pop();
      setParamsColors({ ...paramsColors, [code]: newColor });
      setColorSet(colorSetTemp);
      // }
    } else {
      const { [code]: d2, ...otherParamColors } = paramsColors;
      setColorSet([...colorSet, d2]);
      setParamsColors(otherParamColors);
    }

  }, [paramsTable, colorSet, paramsColors]);

  const prepareTableData = (data: any, currentObj: any) => {

    const unitSupportedParams = !data ? null : Object.keys(data);
    if (!data || _.isEmpty(serviceParams) || !unitSupportedParams?.length) {
      setParamsTable({});
      setMeasuredCodes([]);
      return;
    }

    let memo: any = null;
    let recentParams: any = null;
    let checkedIndex = 0;
    const userPrefObj: any = JSON.parse(localStorage.getItem("recentParamsO") as string);
    const measuredArr: any = [];
    const unMeasuredArr: any = [];
    const checkedParams: any = [];
    const paramsColorsTemp: any = {};
    const colorSetTemp = [...paramsColorsSet];

    if (userPrefObj && userPrefObj[brandName] && userPrefObj[brandName][unitType] && brandName && unitType && unitId) {
      recentParams = new Map(userPrefObj[brandName][unitType]);
      memo = Object.fromEntries(recentParams);
    }

    const tableRows: any = {};
    const withQuality = +unit.type === 2;
    if (withQuality) {
      const isQualityShowing = (!memo || memo.hasOwnProperty("lineQuality"));

      if (memo && memo.hasOwnProperty("lineQuality") && memo["lineQuality"]) {
        checkedParams.push("lineQuality");
        if (checkedIndex < MAX_ALLOWED_STATS_SELECTIONS - 1) {
          paramsColorsTemp["lineQuality"] = colorSetTemp.pop();
          checkedIndex++;
        }
      }
      tableRows["lineQuality"] = {
        code: "lineQuality",
        editable: false,
        name: "Line Quality",
        value: "",
        measurementUnits: "",
        slider: "",
        showInGraph: true,
        hvac_param_name: "lineQuality",
        plottable: true,
        isShowing: isQualityShowing,
        enumVal: "",
        value_type: "",
        enum: "",
        isChecked: false
      };
      measuredArr.push("lineQuality");
    }
    unitSupportedParams && !_.isEmpty(unitSupportedParams) && unitSupportedParams.forEach((code: any) => {
      const {
        min: minRanges = 0,
        max: maxRanges = 0
      } = data[code];
      const value = currentObj[code];

      const servParam = serviceParams[code];
      if (!servParam) {
        return;
      }
      const {
        showInGraph = true,
        hvac_param_name = "",
        plotable,
        data_unit_of_measurement: measurementUnit = "",
        min = 0,
        max = 0,
        title = "",
        enum: enumName = null,
        editable,
        value_type,
        techInfo
      } = servParam;

      let isChecked = false;
      const isShowing = true; // (!memo || memo.hasOwnProperty(code));

      if (memo && memo.hasOwnProperty(code) && memo[code]) {
        isChecked = true;
        if (checkedIndex < MAX_ALLOWED_STATS_SELECTIONS - 1) {
          const color = colorSetTemp.pop();
          paramsColorsTemp[code] = color;
          checkedIndex++;
        }
      }

      if (!showInGraph) {
        tableRows[code] = null;
        return;
      }

      const measuredParam: boolean = servParam
        ? !_.isUndefined(plotable)
          ? plotable
          : true
        : false;

      if (measuredParam) {
        measuredArr.push(code);
      }
      else {
        unMeasuredArr.push(code);
      }

      let measurementUnits: string = measurementUnit;

      if (temperatureScale === 2 && measurementUnit === "°C") {
        measurementUnits = "°F";
      }
      if (userMeasurementUnits === 2 && measurementUnit === "kg/cm2") {
        measurementUnits = "PSI";
      }
      if (measurementUnit === "MPa" && userMeasurementUnits === 2) {
        measurementUnits = "PSI";
      }

      let enumVal: any = null;

      if (enumName) {
        enumVal = enums[enumName][value];
      }
      const slider = measuredParam ? <UnitStatsSlider data={{
        code,
        slider: {
          min,
          max,
          selectionMin: minRanges,
          selectionMax: maxRanges,
          value
        },
        value,
        name: title
      }} index={`slider${code}`} /> : <span />;

      tableRows[code] = {
        code,
        editable,
        name: title,
        value,
        measurementUnits,
        slider,
        showInGraph,
        hvac_param_name,
        plottable: measuredParam,
        isShowing,
        isChecked,
        enumVal,
        value_type,
        enum: enumName,
        techInfo
      };
    });

    setParamsTable(tableRows);
    setParamsColors(paramsColorsTemp);
    setColorSet(colorSetTemp);
  };
  const prepareGraphsData = (statsData: any) => {
    const { calculatedMode } = types;
    const statDataCollectionTemp: any = [];
    const modePoint: any = {};

    for (let p of Object.values(calculatedMode)) {
      modePoint[p as string] = 0;
    }

    statDataCollectionTemp.push({
      timestamp: selectedStartTime,
      noData: 0.5
    });

    statsData.forEach((statPoint: any, statPointIndex: any) => {
      if (statPoint) {

        let point = { ...statPoint, noData: 0.5 };

        if (
          statPoint &&
          (statPoint.hasOwnProperty("calculated_mode") && statPoint["calculated_mode"] !== undefined)
        ) {
          const finalMode = statPoint["calculated_mode"];
          const modePointTemp = { ...modePoint, [calculatedMode[finalMode]]: 0.5 };
          point = { ...point, ...modePointTemp };
        }

        statDataCollectionTemp.push({
          ...point
        });

        if (
          statsData[statPointIndex + 1] &&
          statsData[statPointIndex + 1].timestamp >= statPoint.timestamp + MAX_ALLOWED_GAP_PRO
        ) {
          statDataCollectionTemp.push({
            timestamp: statPoint.timestamp + 2,
            noData: 0.5
          });
        }

      }

    });

    statDataCollectionTemp.push({
      timestamp: selectedEndTime,
      noData: 0.5
    });

    setStatDataCollection(statDataCollectionTemp);
  };

  const fetchStatsData = (unitId: string, startTime: number, endTime: number) => {
    setIsLoading(true);
    getUnitParamsAndStats({
      unitId,
      startTime,
      endTime
    })
      .then((resp: any) => {
        const currentobj = resp?.current && resp?.current[0];
        prepareGraphsData(resp.entries);
        prepareTableData(resp.ranges, currentobj);
        setStatsResults(resp);
        if (currentobj) {
          setExternalTemp(currentobj[STATIC_CODES.SITE_TEMP]);
          setOutdoorTemp(currentobj[STATIC_CODES.OUTDOOR_TEMP]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (doStatsUpdate) {
      reFetchData();
    }
  }, [doStatsUpdate]);

  useEffect(() => {
    if (!unit?.id || !selectedStartTime || !selectedEndTime || !temperatureScale) {
      return;
    }
    setParamsTable({});
    fetchStatsData(unit.id, selectedStartTime, selectedEndTime);

  }, [unit.id, selectedStartTime, selectedEndTime, temperatureScale]);

  useEffect(() => {
    getUnits()
      .then(() => {
        setReFetchUnit(true);
      });
  }, [temperatureScale]);

  const reFetchData = useCallback(() => {
    fetchStatsData(unit.id, selectedStartTime, selectedEndTime);
    setStatsUpdate(false);
  }, []);

  const onRefresh = useCallback(() => {
    if (!selectedStartTime) {
      return;
    }
    onDateRangeChange({ startDate: new Date(selectedStartTime), endDate: new Date() });
    setStatsUpdate(true);
  }, [selectedStartTime]);

  const isToday = useCallback((date: any) => {
    const today = new Date(moment().tz(timezone).format("llll"));
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }, []);

  useEffect(() => {
    if (!isToday(new Date(moment(selectedEndTime).tz(timezone).format("llll")))) {
      setAutoupdate(false);
    }
  }, [selectedEndTime, selectedStartTime]);

  useEffect(() => {
    const alertsDataTemp: any = [];
    alerts &&
      alerts.forEach((alert: any) => {

        let { errorDescription, timestamp, severity, description, status, clearTime, errorCode, time } = alert;

        if (timestamp >= selectedStartTime && timestamp <= selectedEndTime) {

          const alertData = {
            timestamp,
            description,
            errorDescription,
            alerttype: severity.name,
            errorCode,
            color: severity.color,
            name: "error",
            clearTime,
            status,
            time,
            y: 18,
            z: 100
          };

          alertsDataTemp.push(alertData);
        }
      });
    setAlertsData(alertsDataTemp);
  }, [alerts, selectedStartTime, selectedEndTime]);

  const onDateRangeChange = useCallback(async (dateRange: {
    startDate?: Date | undefined;
    endDate?: Date | undefined;
  }) => {
    if (!_.isUndefined(dateRange.startDate) && !_.isUndefined(dateRange.endDate)) {
      const timezoneOffset = moment().tz(timezone).utcOffset() * 60 * 1000;
      const checkIsToday = isToday(dateRange.endDate);
      const currentHourMinsArray = moment().tz(timezone).format("HH:mm").split(":");
      const startHoursArray = moment(dateRange.startDate).format("HH:mm").split(":");
      const startTime = Date.UTC(dateRange.startDate.getFullYear(),
        dateRange.startDate.getMonth(), dateRange.startDate.getDate(), +startHoursArray[0], +startHoursArray[1]) - timezoneOffset;
      const endTime = checkIsToday ? Date.UTC(dateRange.endDate.getFullYear(),
        dateRange.endDate.getMonth(), dateRange.endDate.getDate(), +currentHourMinsArray[0], +currentHourMinsArray[1], 0) - timezoneOffset :
        Date.UTC(dateRange.endDate.getFullYear(),
          dateRange.endDate.getMonth(), dateRange.endDate.getDate(), 23, 59, 59) - timezoneOffset;

      // setSelectedStartTime(startTime);
      // setSelectedEndTime(endTime);
      setSelectedTime({ selectedStartTime: startTime, selectedEndTime: endTime });
    }
  }, []),
    exportFile = useCallback(async (source: string) => {
      if (source === "unit") {
        let rows = statsResults.entries,
          codeHeadersArray = ExportUtils.buildUniqueCodesHeader(rows),
          csvHeadersArray = ExportUtils.buildTitlesHeader(
            codeHeadersArray,
            serviceParams,
            getTemperatureScaleDisplayPlainText()
          ),
          // insert the header in csvContent
          csvContent = "data:text/csv;charset=utf-8," + csvHeadersArray.join(",");
        csvContent += "\r\n";
        csvContent += ExportUtils.buildContent(
          rows,
          unit.name,
          unitTypesMirrror[unit.type] || "--",
          codeHeadersArray,
          serviceParams,
          timezone,
          dateTimeFormat
        );
        ExportUtils.downloadFile(csvContent);
      }
      if (source === "system" && !_.isNil(unit.system)) {
        setIsSystemLoading(true);
        const system = allSystems[unit.system];
        if (!_.isNil(system)) {
          // get all unique system units ids of system of the unit
          // const systemUnitsIds = Array.from(new Set(system.units)),
          const rows: Array<{ unitName: string; unitType: string; unitRows: any[] }> = [];
          getSystemProData({ systemId: system.id, data: { startTime: selectedStartTime, endTime: selectedEndTime } })
            .then((data: any) => {
              const unitIds = Object.keys(data);
              unitIds.forEach((unitId: string) => {
                const unitName = getUnitName(unitId);
                const unitType = getUnitTypeByTypes(unitId);
                const unitTypeParsed = unitType === "service" ? "indoor" : unitType;

                rows.push({ unitName, unitType: unitTypeParsed, unitRows: data[unitId].proData });
              });

              let codeHeadersArray: string[] = [];
              rows.forEach((row) => {
                codeHeadersArray = _.union(
                  codeHeadersArray,
                  ExportUtils.buildUniqueCodesHeader(row.unitRows)
                );
              });
              let csvHeadersArray = ExportUtils.buildTitlesHeader(
                codeHeadersArray,
                serviceParams,
                getTemperatureScaleDisplayPlainText()
              );
              let csvContent = "data:text/csv;charset=utf-8," + csvHeadersArray.join(",");
              csvContent += "\r\n";

              rows.forEach((row) => {

                csvContent += ExportUtils.buildContent(
                  row.unitRows,
                  row.unitName,
                  row.unitType,
                  codeHeadersArray,
                  serviceParams,
                  timezone,
                  dateTimeFormat
                );
              });
              setIsSystemLoading(false);
              ExportUtils.downloadFile(csvContent);
            }).catch((err: any) => {
              setIsSystemLoading(false);
              addMessage({ message: err.message });
            });
        }
      }
    }, [statsResults]),
    startAutoUpdate = () => {
      if (autoupdate && !callTimer) {
        setCallTimer(
          setTimeout(() => {
            onRefresh();
            clearTimeout(callTimer);
            setCallTimer(null);
          }, AUTOUPDATE_INTERVAL)
        );
      }
    };

  useEffect(() => {
    if (autoupdate) {
      startAutoUpdate();
    } else {
      clearTimeout(callTimer);
      setCallTimer(null);
    }
  }, [autoupdate, onRefresh, callTimer]);

  const calcData: any = Object.values(paramsTable).reduce((data: any, row: any) => {
    if (row?.isShowing) {
      data.showenParams++;
    }
    if (row?.isChecked) {
      data.numOfSelected++;
    }
    return data;
  }, { showenParams: 0, numOfSelected: 0 });

  return (
    <Grid container spacing={0} style={{ padding: "16px 0" }}>
      <Grid item xs={5}>
        <UnitStatsTable
          paramsTable={paramsTable}
          endTime={selectedEndTime}
          isToday={isToday}
          onRefresh={onRefresh}
          isLoading={isLoading}
          updateTime={statsResults?.current[0]?.timestamp}
          autoupdate={autoupdate}
          setAutoupdate={setAutoupdate}
          updateMeasuredRows={setMeasuredCodes}
          numOfShowing={calcData.showenParams}
          numOfSelected={calcData.numOfSelected}
          showHideAllParams={showHideAllParams}
          showHideParam={showHideParam}
          updateParamRow={updateParamRow}
          withQuality={+unit.type === 2}
          unit={unit.id}
          reFetchData={reFetchData}
          maxAllowedStats={MAX_ALLOWED_STATS_SELECTIONS}
          timezone={timezone}
        />
      </Grid>
      <Grid item={true} xs={7}>
        <UnitStatsGraph
          isToday={isToday}
          startTime={selectedStartTime}
          endTime={selectedEndTime}
          unitStatsDataMap={statDataCollection}
          types={types}
          onDateRangeChange={onDateRangeChange}
          onChipDelete={updateParamRow}
          onRefresh={onRefresh}
          isLoading={isLoading}
          isSystemLoading={isSystemLoading}
          exportFile={exportFile}
          isNotSystemUnit={_.isNil(unit.system)}
          paramsTable={paramsTable}
          paramsColors={paramsColors}
          paramsMap={serviceParams}
          staticCodes={STATIC_CODES}
          alertsData={alertsData}
          unitType={getUnitTypeByTypes(unit.id)}
          temperatureScale={temperatureScale}
          siteExternalTemps={siteExternalTemps}
          disableDatepicker={!displayFlags.enableUnitDiagnosticsDatepicker}
          disableExport={!displayFlags.enableUnitDiagnosticsExport}
          qualityData={unitLineQuality}
          siteTempIsLoading={siteTempIsLoading}
          serviceParams={serviceParams}
          enums={enums}
          timezone={timezone}
        />
      </Grid>
    </Grid>
  );
};

export default UnitStats;
