import { Button, IconButton, Typography } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { t } from "ttag";
import {
  Anomalies as SvgAnomalies,
  Audit as SvgAudit,
  Commissioning as SvgCommissioning,
  Control as SvgControl,
  Dashboard,
  Diagnostics as SvgDiagnostics,
  Notifications as SvgAlerts,
  Settings as SvgSettings,
  Site as SvgSite,
  Statistics as SvgStatistics,
  SupportTicket,
  SvgExpandLess,
  SvgExpandMore,
  SystemSettings,
  User as SvgUser
} from "../../icons/";
import ReportProblem from "../../screens/ReportProblem/ReportProblem";
import assetsService from "../../services/assetsService";

import packageJSON from "../../../package.json";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import NavigationMenuItem from "./NavigationMenuItem";
import useStyles from "./ServiceNavigationBar.style";

const ServiceNavigationBar: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const canLoggedInUserViewTriggers = useStoreState((s) => s.users.canLoggedInUserViewTriggers);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const selectedUnitId = useStoreState((s) => s.selections.selections.unitId);
  const lastSelectedUnitId = useStoreState((s) => s.selections.selections.lastSelectedUnitId);
  const selectedSystemId = useStoreState((s) => s.selections.selections.systemId);
  const selections = useStoreState((state) => state.selections.selections);
  const setSelectedAlertObj = useStoreActions((actions) => actions.setSelectedAlert);

  const selectedSystems = useStoreState((s) => s.selections.getSystemsBySelection);

  const getUnitsBy = useStoreState((state) => state.units.getUnitsBy);
  const displayFlags = useStoreState((state) => state.users.displayFlags);

  let defaultSystemId = useStoreState(
    (s) => s.systems.allSystems[Object.keys(s.systems.allSystems)[0]]?.id
  );

  const dropDownNavigation = useStoreState((s) => s.isDropDownOpen);
  const setDropDownNavigation = useStoreActions((a) => a.setDropDownNavigation);

  selections.siteId && selectedSystems.length && (defaultSystemId = selectedSystems[0].id);

  if (selections.systemId) {
    const systemUnits = getUnitsBy("system", selections.systemId, { type: "all" });
  }

  const [openReport, setOpenReport] = useState<boolean>(false);

  const goToDashboardLogo = () => {
    updateSelections({
      type: "customer",
      data: null

    });
    history.push("/dashboard");
  };
  const Play = () => <PlayArrow className={classes.playIcon} />;

  return (
    <div className={classes.navigationBarContainer}>
      <Button
        aria-label="app logo"
        className={classes.navLogo}
        onMouseUp={goToDashboardLogo}
        classes={{ label: classes.label }}
      >
        <img src={assetsService.getResourcePath("logo.png")} className={classes.navIcon} />
      </Button>
      <div className={classes.itemsContainer}>

        <NavigationMenuItem
          Icon={Dashboard}//SvgDashboard}
          name={t`Dashboard`}
          path={"/dashboard"}
          onNavigate={() => {
            updateSelections({ type: "system", data: null });
          }}
        />
        <NavigationMenuItem
          Icon={SvgControl}
          name={t`Control`}
          path={"/control"}
          disabled={!displayFlags.enableControl}
        />
        <NavigationMenuItem
          Icon={SvgDiagnostics}
          name={t`System Diagnostics`}
          path={`/system-diagnostics/${selectedSystemId ? selectedSystemId : ""}`}
        />
        <NavigationMenuItem
          Icon={SvgStatistics}
          name={t`Unit Diagnostics`}
          path={`/unit-stats/${selectedUnitId ? selectedUnitId : ""}`}
        />
        <NavigationMenuItem Icon={SvgAnomalies} name={t`Anomaly Rules`} path={"/anomalies-settings"} disabled={!displayFlags.enableAnomalies} />
        {displayFlags.showTrapTemplates && <NavigationMenuItem Icon={SvgAnomalies} name={t`Anomaly templates`} path={"/anomaly-templates-settings"} />}
        <NavigationMenuItem Icon={SvgAlerts} name={t`Alert Log`} path={"/alerts"} onNavigate={() => {
          setSelectedAlertObj(null);
        }} />
        <NavigationMenuItem Icon={SvgAudit} name={t`Audit Log`} path={"/audits"} />
        <NavigationMenuItem Icon={SvgSite} name={t`Site Management`} path={"/site-management"} disabled={!displayFlags.enableSiteManagement} />
        <NavigationMenuItem Icon={SvgUser} name={t`User Management`} path={"/user"} disabled={!displayFlags.enableUserManagement} />
        <NavigationMenuItem
              Icon={SvgSettings}
              name={t`Settings`}
              disabled={!displayFlags.enableSettings}
              endIcon={[SvgExpandMore, SvgExpandLess]}
              dropdown={[
                {
                  name: `Notifications`,
                  path: "/settings/alerts"
                }]}
              showDropDown={dropDownNavigation.settings}
              setShowDropDown={() => setDropDownNavigation("settings")}
            />
        <NavigationMenuItem
              Icon={SvgCommissioning}
              name={t`Commissioning`}
              disabled={!displayFlags.enableScripts}
              endIcon={[SvgExpandMore, SvgExpandLess]}
              dropdown={[
                {
                  name: `Procedures`,
                  path: "/commissioning-prodecuders"
                },
                {
                  name: "Logs",
                  path: "/commissioning-logs"
                }]}
              showDropDown={dropDownNavigation.commissioning}
              setShowDropDown={() => setDropDownNavigation("commissioning")}
            />
              <NavigationMenuItem
                  Icon={SupportTicket}
                  name={t`Open Support Ticket`}
                  path={""}
                  onClick={() =>  setOpenReport(true)}
                />

          {openReport && (
            <ReportProblem openReport onClose={() => setOpenReport(false)}/>
          )}
      </div>

      <Typography variant="caption" className={classes.versionText}>
        Version {packageJSON.version}
      </Typography>
    </div>
  );
};

export default ServiceNavigationBar;
