import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import InfoIcon from "@material-ui/icons/Info";
import Label from "@material-ui/icons/Label";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { ArrowDownO, CheckboxChecked, Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import styles from "./AddEditScript.style";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  node?: any;
  className?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#545964",
      fontSize: "14px",
      width: "100%",
      minHeight: "40px",
      "&:hover > $content": {
        backgroundColor: "transparent"
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, #fff)`,
        color: "var(--tree-view-color)"
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent"
      }
    },
    content: {
      color: "#545964",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      paddingRight: "10px",
      fontWeight: 400,
      "$expanded > &": {
        fontWeight: 400
        // backgroundColor: "rgba(170, 162, 170, 0.1)"

      }
    },
    group: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "31px"
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
      padding: 0,
      display: "flex"
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: "9px",
      paddingLeft: 0
    },
    labelIcon: {
      marginRight: "10px"
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
      fontSize: 15
    },
    iconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "5px"
    }
  })
);

const Checkbox1 = (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
    {...props}
  />;
};

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={clsx(classes.labelRoot, { [className]: className })}>
          {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
          {node && node}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles(
  createStyles({
    root: {
      height: "100%",
      flexGrow: 1,
      maxWidth: 429,
      maxHeight: "calc(100vh - 352px)",
      overflow: "auto",
      background: "#fff",
      padding: "20px"
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      alignItems: "center",
      paddingTop: 10
    },
    indeterminate: {
      color: "#fff"
    },
    indetCheckbox: {
      "& > span": {
        background: "red !important",
        border: "1px solid gray"
      },
      "& svg": { width: "23px", height: "23px" }
    },
    smallCheckbox: {
      marginRight: "10px",
      padding: 0,
      "& span": { width: "18px", height: "18px", borderRadius: "3px", backgroundColor: "#fff" }
    },
    labelStyle: {
      marginLeft: "-19px",
      "& p": { fontWeight: "bold" }
    },
    treeHeader: {
      borderBottom: "solid 1px #d5d2d5",
      width: "1005",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      height: "60px",
      backgroundColor: "#f6f6f7",
      alignItems: "center"
    },
    headerTitle: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#29132e"
    },
    customerIconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "13px",
      "& div": { display: "none" },
      marginLeft: "10px"
    },
    customerGroup: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "21px"
    },
    label: {
      color: "#545964",
      padding: 0
    },
    customerContent: {
      // backgroundColor: "rgba(170, 162, 170, 0.1)"
    },
    closeIcon: { padding: "6px", marginRight: "-6px" },
    arrowIcon: { marginLeft: "7px", marginRight: "2px" },
    rotateArrow: { transform: "rotate(-90deg)" },
    arrowContainer: { minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }
    , errorMessage: {
      color: "#f44336",
      fontSize: "14px",
      marginRight: "10px",
      paddingBottom: "7px"
    },
    bottomContainer: {
      backgroundColor: "#fff",
      display: "flex",
      flexFlow: "column nowrap",
      position: "absolute",
      justifyContent: "center",
      width: "100%",
      bottom: "20px",
      padding: "0px 20px",
      paddingTop: 10,
      alignItems: "flex-start"
    },
    startMessage: {
      fontSize: 14,
      marginBottom: 20
    }
  })
);

export default ({ sitesWithSystems, allSites, customers, allSystems, allUnits, onSave, close, previouslySelectedUnitsIds }: any) => {
  const classes = useStyles();

  const [selectedUnits, setSelectedUnits] = useState<any>({});
  const [selectedSystems, setSelectedSystems] = useState<any>({});
  const [showError, handleShowError] = useState<boolean>(false);
  const expanded = [`customer-${(Object.keys(customers))[0]}`];
  const getUnitById = useStoreState((state) => state.units.getUnitById);

  useEffect(() => {
    if (previouslySelectedUnitsIds?.length){
      let mappedPreviously: any = {};
      previouslySelectedUnitsIds.forEach((key: any) => {
        const unit = getUnitById(key);
        if (unit && unit.system){
          expanded.push(`system-${unit.system}`);
        }
        if (unit && unit.site){
          expanded.push(`site-${unit.site}`);
        }
        return mappedPreviously[key] = true; });
      setSelectedUnits(mappedPreviously);
    }
  }, [previouslySelectedUnitsIds]);

  const unitsNodes = (units: any) => {

    const items = [];
    for (const unitId in units) {
      const unit = units[unitId];
      const { name, system } = unit;
      const disabled = selectedSystems[system];
      const checked = !!(selectedUnits[unitId] || selectedSystems[system]);

      unit && items.push(
        <StyledTreeItem
          key={`unit-${unitId}`}
          nodeId={`unit-${unitId}`}
          labelText={name}
          style={{ opacity: disabled ? 0.5 : 1 }}
          node={
            <Checkbox1
              disabled={disabled}
              checked={checked}
              onChange={(event: any) => {
                const { target: { checked } } = event;
                if (checked) {
                  selectedUnits[unitId] = true;
                  setSelectedUnits({ ...selectedUnits });
                  return;
                }

                delete selectedUnits[unitId];
                setSelectedUnits({ ...selectedUnits });
              }
              }
            />}
          color="#545964"
          bgColor="#fff"
        />);
    }
    return items;
  };

  const systemsNodes = (systems: any) => {

    const items: any = [];
    for (const i in systems) {
      const systemId = systems[i];
      const system = allSystems[systemId];
      const { name, unitsObject } = system;
      const checked = selectedSystems[systemId];
      const indeterminate = !checked && Object.keys(selectedUnits).some((unitId) => unitsObject[unitId]);

      items.push(
        <StyledTreeItem
          key={`system-${systemId}`}
          nodeId={`system-${systemId}`}
          labelText={name}
          node={
            <Checkbox1
              checked={checked}
              indeterminate={indeterminate}
              onChange={(event: any) => {
                const { target: { checked } } = event;
                if (checked) {
                  selectedSystems[systemId] = true;

                  Object.keys(selectedUnits).forEach((unitId: string) => {
                    if (unitsObject[unitId]) {
                      delete selectedUnits[unitId];
                    }
                  });
                  setSelectedUnits({ ...selectedUnits });
                  setSelectedSystems({ ...selectedSystems });
                  return;
                }

                delete selectedSystems[systemId];
                setSelectedSystems({ ...selectedSystems });
              }
              }
            />}
          color="#545964"
          bgColor="#fff"
        >
          {unitsNodes(unitsObject)}
        </StyledTreeItem>
      );
    }
    return items;
  };

  const sitesNodes = (sitesArr: any) => {
    const sites = Object.values(sitesArr || {});
    const items = [];

    for (const i in sites) {
      const siteId: any = sites[i];
      if (allSites[siteId]){
        const { id, name } = allSites[siteId];
        const systems = sitesWithSystems[id] || {};
        const systemsArray = Object.keys(systems);
        const isAllSystemsChecked = systemsArray.every((systemId: string) => selectedSystems[systemId]);
        const checked = isAllSystemsChecked;
        const indeterminate = systemsArray.some((systemId: string) => selectedSystems[systemId]);

        id && items.push(
          <StyledTreeItem
            key={`site-${id}`}
            nodeId={`site-${id}`}
            labelText={name}
            node={systems.length > 0 ? <Checkbox1
              indeterminate={!checked && indeterminate}
              checked={checked}
              onChange={(event: any) => {

                if (!event.target.checked) {
                  selectedSystems.forEach((systemId: string) => {
                    if (systems[systemId]) {
                      delete selectedSystems[systemId];
                    }
                  });
                  setSelectedSystems(selectedSystems);
                } else {
                  setSelectedSystems({ ...selectedSystems, ...systems });
                }
              }}
            /> : undefined}
            color="#545964"
            bgColor="#fff"
          >
            {systemsNodes(systemsArray)}
          </StyledTreeItem>);
      }
    }
    return items;
  };

  const customersNodes = () => {

    const items = [];
    for (const customer in customers) {
      const customerObject = customers[customer];
      const { sites, id, name } = customerObject;

      customerObject && items.push(
        <StyledTreeItem
          key={`customer-${id}`}
          nodeId={`customer-${id}`}
          labelText={name}
          classes={{ iconContainer: classes.customerIconContainer, group: classes.customerGroup, label: classes.label, content: classes.customerContent }}
          color="#545964"
          bgColor="#fff"
        >{sitesNodes(sites)}</StyledTreeItem>);
    }

    return items;
  };

  const validate = (units: any, systems: any) => {
    handleShowError(false);

    if (_.isEmpty(units) && _.isEmpty(systems)) {
      handleShowError(true);
      return;
    }

    onSave(Object.keys(units), Object.keys(systems));
  };

  return (
    <>
      <TreeView
        className={classes.root}
        defaultExpanded={expanded}
        defaultCollapseIcon={<><div className={classes.arrowContainer} /> <ArrowDownO className={classes.arrowIcon} /> </>}
        defaultExpandIcon={<><div className={classes.arrowContainer} /> <ArrowDownO className={clsx(classes.arrowIcon, classes.rotateArrow)} /></>}
        defaultEndIcon={<div className={classes.arrowContainer} />}
      >
        {customersNodes()}
      </TreeView>
      <div className={classes.bottomContainer}>
        {showError && <Typography className={classes.errorMessage}>{t`Please select one unit or system at least`}</Typography>}
        <Typography className={classes.startMessage}>{t`Click Start button to run procedure. You can later track the status of this procedure in the commissioning log tab.`}</Typography>
        <div className={classes.actions}>
          <Button uppercase width={130} onClick={close} white marginRight>{t`Cancel`}</Button>
          <Button uppercase width={130} onClick={() => validate(selectedUnits, selectedSystems)}>{t`Start`}</Button>
        </div>
      </div>
    </>
  );

};
