import { MenuItem, Popover, Select, Typography } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { Arrow as SvgArrow } from "../../icons/";
import useStyles from "./MenuDropDown.style";

const MenuDropDown = (props: any) => {
  const classes = useStyles();
  const { value, options, onChange, emptyOption, setDevice, isEmptyOptionDisabled = false } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  return (
    <Select
      variant="filled"
      value={value}
      onChange={handleChange}
      className={classes.selectContainer}
      classes={{
        filled: classes.selectStyle,
        icon: classes.arrowIcon,
        select: classes.focusStyle,
        iconFilled: classes.iconStyle
      }}
      displayEmpty
      disableUnderline
      MenuProps={{
        classes: { paper: classes.paperStyle },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: -10,
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      IconComponent={SvgArrow}
      // classes={{ icon: classes.arrowIcon }}
      disabled={options.length === 1}
    >
      {options &&
        options.map((option: any) => {
          return (
            <MenuItem key={option.key} value={option.value} classes={{ selected: classes.selected }} className={classes.optionStyle}>
              <Typography className={classes.optionText}>{option.name}</Typography>
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default MenuDropDown;
