import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Typography } from "@material-ui/core/";
import { Services as SdkServices } from "coolremote-sdk";
import {
  addDays,
  sub
} from "date-fns";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { checkDateInRange } from "../../services/timeService";
import ExportUtils from "../../utils/ExportUtils";
import AlertInfo from "./AlertInfo";
import useStyles from "./Alerts.style";
import AlertsTable from "./AlertsTable";

const getErrorDescription = (errorCodeTypes: any = {}, brand: any, errorCode: any) => {
  if (!brand && brand !== 0) {
    return "";
  }

  const brandsErrors = errorCodeTypes[brand] || {};
  if (_.isEmpty(brandsErrors)) {
    return "";
  }

  return brandsErrors[errorCode] || brandsErrors[+errorCode] || "";
};

const Alerts: React.FC<any> = (props: any) => {

  const selectedAlertObj = useStoreState((state) => state.selectedAlert);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const [selectedAlert, setSelectedAlert] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [] = useState<boolean>(true);
  const classes = useStyles();
  const eventClearTypesMirror = useStoreState((state) => state.eventClearTypesMirror);
  const types = useStoreState((state) => state.types);
  const eventTypesMirror = useStoreState((state) => state.eventTypesMirror);
  const eventStatusTypesMirror = useStoreState((state) => state.eventStatusTypesMirror);
  const getAlertFeildsNamesAndIds = useStoreState((state) => state.alerts.getAlertFeildsNamesAndIds);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const allCustomers = useStoreState((state) => state.customers.allCustomers);
  const allOpenAlerts = useStoreState((state) => state.alerts.allOpenAlerts);
  const errorTypes = useStoreState((state) => state.serviceErrorTypes);
  const getSystem = useStoreState((state) => state.systems.getSystem);
  const allSites = useStoreState((state) => state.sites.allSites);

  const localStoredRowsPerPage = Number(localStorage.getItem("AlertsRowsPerPage")) || 10;
  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(localStoredRowsPerPage);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<any>({ codes: [], statuses: [], types: [], description: [] });
  const [deleteFlag, setDeleteFlag] = useState<boolean>(false);
  const [firstload, setFirstLoad] = useState<string>("processing");
  const [openTroubleshoot, setOpenTroubleshoot] = useState<boolean>(false);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const selections = useStoreState((s) => s.selections.selections);
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const [filteredAlerts, setFilteredAlerts] = useState<any>([]);
  const [errorCodeArray, setErrorCodesArray] = useState<any>([]);
  const [descriptionArray, setDescriptionArray] = useState<any>([]);
  const [alertTypeArray, setAlertTypeArray] = useState<any>([]);
  const [statusesArray, setStatusesArray] = useState<any>([]);
  const [preSelectedAlert, setPreSelectedAlert] = useState<any>(true);
  const [file, setFile] = useState<any>(null);

  const { eventTypes, unitTypes } = types;
  setUnitUpdateStatus({ status: "" });

  useEffect(() => {
    if (!resetFilters) {
      // updateSelections({ type: "site", data: null });
      setResetFilters(true);
      setFirstLoad("init");
    }
  }, [resetFilters]);

  useEffect(() => {
    if (selectedAlertObj && selectedAlertObj.id) {

      const {
        timestamp,
        unitIds,
        systemIds,
        siteId
      } = selectedAlertObj;
      const evDate = new Date(timestamp);
      const endDate = addDays(evDate, 1);
      const startDate = sub(evDate, { hours: 24 });

      updateSelections({ type: "time", data: { startDate, endDate } });
      if (unitIds && unitIds.length === 1) {
        updateSelections({ type: "unit", data: unitIds[0] });
      }
      else if (systemIds && systemIds.length === 1) {
        updateSelections({ type: "system", data: systemIds[0] });
      }
      else if (siteId) {
        updateSelections({ type: "site", data: siteId });
      }

      return;
    }
    if (!selections?.dateRange?.endDate || !selections?.dateRange?.startDate) {
      updateSelections({
        type: "time",
        data: {
          startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
          endDate: new Date()
        }
      });
    }

    // updateSelections({
    //   type: "system",
    //   data: null
    // });
  }, [allOpenAlerts, selectedAlertObj]);

  useEffect(() => {
    if (!selections.dateRange) {
      return;
    }

    const startTime = Date.UTC(selections.dateRange?.startDate.getFullYear(), selections.dateRange?.startDate.getMonth(), selections.dateRange?.startDate.getDate()) - 32400000;
    const endTime = Date.UTC(selections.dateRange?.endDate.getFullYear(), selections.dateRange?.endDate.getMonth(), selections.dateRange?.endDate.getDate(), 23, 59, 59) + 32400000;

    (async function () {
      setIsDisabled(true);
      setRows([]);
      firstload === "init" && setFirstLoad("processing");

      const allAlertsByTime = await getAlertsByFilters({ startTime, endTime, type: types.applications.service });

      const sortedAlerts = _.orderBy({ ...allAlertsByTime, ...allOpenAlerts }, ["eventTime"], ["desc"]);

      firstload !== "done" && setFirstLoad("done");
      const rowsToRender: any = [];
      const errorCodesArray: any[] = [];
      const alertTypeArray: any[] = [];
      const descriptionArray: any[] = [];
      const statusesArray: any[] = [];
      sortedAlerts.forEach((alert: any) => {
        const isUnitsDisconnectedType = eventTypes.unitsDisconnected === alert.type;
        const isUnitDisconnectedType = eventTypes.unitDisconnected === alert.type;
        const isEntitiesNoDateType = eventTypes.entitiesHealthLowIstat === alert.type
          || eventTypes.entitiesHealthLowOstat === alert.type
          || eventTypes.entitiesHealthLowPstat === alert.type
          || eventTypes.entitiesHealthLowLqstat === alert.type
          || eventTypes.entitiesHealthLowSstat === alert.type;

        const isEntityNoDataType = eventTypes.entityHealthLowIstat === alert.type
          || eventTypes.entityHealthLowOstat === alert.type
          || eventTypes.entityHealthLowPstat === alert.type
          || eventTypes.entityHealthLowLqstat === alert.type
          || eventTypes.entityHealthLowSstat === alert.type;

        const namesAndIds = getAlertFeildsNamesAndIds({ type: alert.type, resources: alert.resources, status: alert.status });
        const { eventNames, eventIds } = namesAndIds;

        if (eventIds.unitId && !allUnits[eventIds.unitId]?.isVisible) {
          return;
        }

        if (!eventIds.siteId || isUnitDisconnectedType || isEntityNoDataType) {
          return;
        }

        const timezone = allSites[eventIds.siteId]?.timezone || "";

        const status = (eventStatusTypesMirror[alert.status].split(/(?=[A-Z])/)).map((word: string) => {
          word = word[0].toUpperCase() + word.substr(1);
          return word;
        }).join(" ") || "";

        if (status !== "Open" && !checkDateInRange(moment(selections.dateRange?.startDate).format("MM/DD/YYYY"),
          moment(selections.dateRange?.endDate).format("MM/DD/YYYY"),
          moment(alert.eventTime).tz(timezone).format("MM/DD/YYYY"))
        ) {
          return;
        }

        const { data, type } = alert;
        const errorCode = eventTypes.indoorUnitError === type ||
          eventTypes.outdoorUnitError === type ? data : "";
        let errorDescription = "";

        if ((errorCode || errorCode === 0) && eventIds.systemId) {
          const { brandNum } = getSystem(eventIds.systemId) || {};
          errorDescription = getErrorDescription(errorTypes.errorCodeTypes, brandNum, errorCode);
          errorDescription = errorDescription;
        }

        rowsToRender.push({
          type: alert.type,
          acknowledgedTime: alert.acknowledgedTimestamp ? moment(alert.acknowledgedTimestamp).tz(timezone).format(`${dateFormat}   ${timeFormat}`) : false,
          time: moment(alert.eventTime).tz(timezone).format(`${dateFormat}   ${timeFormat}`),
          clearTime: moment(alert.clearTime).tz(timezone).format(`${dateFormat}   ${timeFormat}`),
          status,
          alertType: alert.type === 1 ? "Anomaly" : (eventTypesMirror[alert.type].split(/(?=[A-Z])/)).map((word: string) => {
            word = word[0].toUpperCase() + word.substr(1);
            return word;
          }).join(" "),
          siteName: eventNames.siteName,
          unitName: eventNames.unitName,
          deviceName: eventNames.deviceName,
          systemName: eventNames.systemName,
          unitIds: eventIds.unitIds,
          customerName: allCustomers[alert.customer]?.name || "",
          clearReason: alert.clearReason ? (eventClearTypesMirror[alert.clearReason] || "").replace(/trap/g, "Anomaly").split(/(?=[A-Z])/).map((word: string) => {
            word = word[0]?.toUpperCase() + word.substr(1);
            return word;
          }).join(" ") : "",
          userText: !isEntitiesNoDateType ? alert.userText : "",
          shortId: alert.shortId,
          alertItemContainerIds: { ...eventIds, customerId: alert.customer },
          description: alert.trapDescription,
          errorCode: isUnitsDisconnectedType || isEntitiesNoDateType ? "" : alert.data,
          timestamp: alert.eventTime,
          trapTemplateId: alert?.trapTemplateId || "",
          id: alert.id,
          errorDescription
        });
      });

      setErrorCodesArray(errorCodesArray);
      setStatusesArray(statusesArray);
      setAlertTypeArray(alertTypeArray);
      setDescriptionArray(descriptionArray);
      setRows(rowsToRender);
      setOpen(true);
      setIsDisabled(false);
    })();
  }, [selections.dateRange, deleteFlag, timeFormat, dateFormat]);
  useEffect(() => {
    setPage(0);
    if (!rows.length) {
      setFilteredAlerts([]);
      return;
    }

    const filteredAlerts = getFilteredAlerts(rows);
    console.log("filteredAlerts  ", filteredAlerts);
    setFilteredAlerts(filteredAlerts);
  }, [selections, selectedFilters, rows]);

  useEffect(() => {
    if (preSelectedAlert && !_.isEmpty(selectedAlertObj) && !_.isEmpty(filteredAlerts)) {

      const alertIndex = _.findIndex(filteredAlerts, { id: selectedAlertObj.id });
      const pageNum = Math.ceil((alertIndex + 1) / rowsPerPage);
      setSelectedAlert(filteredAlerts[alertIndex]);
      setPage(pageNum - 1);
    }
  }, [filteredAlerts, rowsPerPage]);

  const closeTroubleshootFile = () => {
    setOpenTroubleshoot(false);
    setFile(null);
  };
  const clearAllFilters = () => {
    setSelectedFilters({ codes: [], statuses: [], types: [], description: [] });
  };
  const selectUnit = (unitId: string) => {
    updateSelections({ type: "unit", data: unitId });
  },
    onRowSelect = (alert: any) => {
      setSelectedAlert(alert);
      setOpen(true);
    },
    setPageNum = (num: number) => {
      setPage(num);
    },
    setNumPerPage = (num: number) => {
      setRowsPerPage(num);
      localStorage.setItem("AlertsRowsPerPage", String(num));
    },
    updateFilters = (newFilters: any) => {
      setSelectedFilters({ ...selectedFilters, ...newFilters });
      setPage(0);
    },
    itemGotDeleted = () => {
      setSelectedAlert(null);
      setDeleteFlag(!deleteFlag);
    },
    addResolvedDescription = (resolvedDescription: string, id: string) => {
      const foundIndex = rows.findIndex((x: any) => x.id === id);
      rows[foundIndex] = { ...rows[foundIndex], userText: resolvedDescription };
      setRows([...rows]);
      setSelectedAlert(rows[foundIndex]);
    },
    setAcknowledged = (ackAlert: any) => {
      const changedAckAlerts = filteredAlerts.map((alert: any) => {
        let selectedAlert = null;
        if (alert.id === ackAlert.id) {
          alert.status = ackAlert.data ? "Acknowledged" : "Open";
          selectedAlert = alert;
        }
        return alert;
      });
      setFilteredAlerts(getFilteredAlerts(changedAckAlerts));
      setSelectedAlert(selectedAlert);
    },
    convertDataToCSV = () => {
      let csvRows: any = [];
      const headers =
        "DATE & TIME,CLOSE DATE & TIME,UNIT NAME,SITE NAME,SYSTEM NAME,DESCRIPTION,ALERT STATUS,ERROR CODE,ERROR DESCRIPTION,ALERT TYPE";
      for (const row of filteredAlerts) {
        const description = (row.description || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const unitName = (row.unitName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const siteName = (row.siteName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const systemName = (row.systemName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const errorDescription = (row.errorDescription).replace(/(\r\n|\n|\r|,)/gm, " ");

        const customeRow: any = [
          row.time,
          row.status !== "Open" ? row.clearTime || "" : "",
          unitName,
          siteName,
          systemName,
          description,
          row.status,
          row.errorCode,
          errorDescription,
          row.alertType
        ];

        csvRows.push(customeRow.join(","));
      }

      const csvContent = "data:text/csv;charset=utf-8," + headers + "\n" + csvRows.join("\n");
      ExportUtils.downloadFile(csvContent, `Alerts Log`);
    };
  // Apply filters and selections to alerts
  const getFilteredAlerts = (alerts: any[]) => {
    function applyFilters(alerts: any[]) {
      return _(alerts)
        .filter((alert) => {
          return selectedFilters.codes.length
            ? selectedFilters.codes.includes(alert.errorCode)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.types.length
            ? selectedFilters.types.includes(alert.alertType)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.statuses.length
            ? selectedFilters.statuses.includes(alert.status)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.description.length
            ? selectedFilters.description.includes(alert.description)
            : true;
        })

        .value();
    }
    function applySelections(alerts: any[]) {
      return _(alerts)
        .filter((alert: any) =>
          selections.unitId ? alert.alertItemContainerIds.unitId ? alert.alertItemContainerIds.unitId === selections.unitId : (alert.alertItemContainerIds.unitIds.length > 1 && alert.alertItemContainerIds.unitIds.includes(selections.unitId)) : true
        )
        .filter((alert: any) =>
          selections.siteId ? alert.alertItemContainerIds.siteId === selections.siteId : true
        )
        .filter((alert: any) =>
          selections.systemId ? alert.alertItemContainerIds.systemId ? alert.alertItemContainerIds.systemId === selections.systemId :
            (alert.alertItemContainerIds.systemIds.length > 1 && alert.alertItemContainerIds.systemIds.includes(selections.systemId)) : true
        )
        .filter((alert: any) =>
          selections.systemId && _.includes(selections.systemId, "_") ?
            allUnits[alert.alertItemContainerIds.unitId].line.toString() === selections.systemId.split("_")[0] && allUnits[alert.alertItemContainerIds.unitId].device === selections.systemId.split("_")[1] :
            true

        )
        .filter((alert: any) =>
          selections.customerId
            ? alert.alertItemContainerIds.customerId === selections.customerId
            : true
        )
        .orderBy(["timestamp"], ["desc"])
        .value();
    }

    function onlyUnique(value: any, index: any, self: any) {
      return self.indexOf(value) === index;
    }

    const updateFiltersVals = (rows: any) => {
      const errorCodeArr: any = [];
      const statusArr: any = [];
      const alertTypesArr: any = [];
      const descriptionArr: any = [];

      for (let rowIndex in rows) {
        const { status, errorCode, alertType, description } = rows[rowIndex];
        if (!!errorCode) { errorCodeArr.push(errorCode); }
        if (!!status) { statusArr.push(status); }
        if (!!alertType) { alertTypesArr.push(alertType); }
        if (!!description) { descriptionArr.push(description); }
      }
      setErrorCodesArray(errorCodeArr.filter(onlyUnique));
      setStatusesArray(statusArr.filter(onlyUnique));
      setAlertTypeArray(alertTypesArr.filter(onlyUnique));
      setDescriptionArray(descriptionArr.filter(onlyUnique));
    };

    const filteredRows = applyFilters(applySelections(alerts));
    updateFiltersVals(filteredRows);
    return filteredRows;
  };

  const generateReport = (templateId: string, unitId: string) => {

    SdkServices.generateTroubleshootReport(templateId, unitId)
      .then((report: any) => {
        setFile(report);
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };
  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          showDateRangePicker
        />
        <Grid className={classes.contentHeaderContainer}>
          <Button
            disableRipple
            variant="contained"
            className={classes.shareButton}
            startIcon={
              <Icon style={{ transform: "rotateY(180deg)", marginTop: "-3px" }}>reply</Icon>
            }
            onMouseUp={convertDataToCSV}
          >
            {t`Share Report`}
          </Button>
        </Grid>
        <Grid
          container
          style={{ height: "calc(100% - 140px)", margin: "unset", padding: "1rem", paddingTop: 0 }}
        >
          <Grid item xs={9} style={{ height: "100%", paddingRight: "20px" }}>
            <Card className={classes.content}>
              <AlertsTable
                rows={filteredAlerts}
                onRowSelect={onRowSelect}
                setPage={setPageNum}
                page={page}
                setRowsPerPage={setNumPerPage}
                rowsPerPage={rowsPerPage}
                isDisabled={isDisabled}
                selectedAlert={selectedAlert && selectedAlert.id}
                setSelectedAlert={setSelectedAlert}
                filterValues={{
                  codes: errorCodeArray,
                  statuses: statusesArray,
                  types: alertTypeArray,
                  description: descriptionArray
                }}
                appliedFilters={selectedFilters}
                getSelectedFilters={updateFilters}
                style={{ height: "100%" }}
                clearAllFilters={clearAllFilters}
                preSelectedAlert={preSelectedAlert}
                setPreSelectedAlert={setPreSelectedAlert}
                unitTypes={unitTypes}
                allUnits={allUnits}
              />
            </Card>
          </Grid>

          <Grid item xs={3} className={classes.alertInfoContainer}>
            <Card className={classes.content}>
              <AlertInfo
                open={!!(open && rows.length)}
                alert={selectedAlert || {}}
                selectUnit={selectUnit}
                itemGotDeleted={itemGotDeleted}
                addResolvedDescription={addResolvedDescription}
                setAcknowledged={setAcknowledged}
                setOpenTroubleshoot={setOpenTroubleshoot}
                generateReport={generateReport}
              />
            </Card>
          </Grid>
        </Grid>
      </div>

      {
        openTroubleshoot &&
        <Dialog onClose={closeTroubleshootFile} aria-labelledby="customized-dialog-title" open={openTroubleshoot}>
          <DialogTitle id="customized-dialog-title">
            {t`Troubleshoot guidance`}
          </DialogTitle>
          <DialogContent dividers>
            {!!file ? <div dangerouslySetInnerHTML={{ __html: file }} /> : <span />}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeTroubleshootFile}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};
export default Alerts;
