import {
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { Field } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Arrow as SvgArrow } from "../../icons/";
import { useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { ISystem } from "../../models/Systems";
import UserDetailsField from "./UserDetailsField";
import { makeCheckedSites, makeCheckedSystems } from "./UserHelpers";
import useStyles from "./UserSettings.style";

const controlUserPermissions = [
  {
    value: 0,
    label: t`Customer administrator`
  },
  {
    value: 1,
    label: t`Specific site/s administrator`
  }
];

const UserSettings: React.FC<any> = ({ user, values, setFieldValue, newUser = false, inviteToControl = false, inviteEdit = false }) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState<string>("");
  const [onlyAdminAllowed, setOnlyAdminAllowed] = useState<boolean>(false);
  const customerName = useStoreState((s) => s.customers.getCustomerName(user.customer));
  const sites = useStoreState((s) => s.customers.getCustomerSites(user.customer));
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const systems = _.map(allSystems);
  const updateCheckedSites = (id: string) => {
    setFieldValue("checkedSites", {
      ...values.checkedSites,
      [id]: !values.checkedSites[id]
    });
  };
  const updateCheckedSystems = (id: string) => {
    setFieldValue("checkedSystems", {
      ...values.checkedSystems,
      [id]: !values.checkedSystems[id]
    });
  };
  const selectSite = (siteId: string) => {
    setSelectedId(selectedId === siteId ? "" : siteId);
  };

  useEffect(() => {
    setOnlyAdminAllowed(inviteToControl);
  }, [inviteToControl]);
  // Initialize checked sites on first render
  useEffect(() => {
    setFieldValue("checkedSites", makeCheckedSites(user, sites, values.adminRole));
    setFieldValue("checkedSystems", makeCheckedSystems(user, _.map(systems), values.adminRole));
  }, []);

  // Update checked sites on role change
  useEffect(() => {
    setFieldValue("checkedSites", makeCheckedSites(user, sites, values.adminRole));
    setFieldValue("checkedSystems", makeCheckedSystems(user, _.map(systems), values.adminRole));
  }, [values.adminRole]);
  const permissionOptions = [
    {
      value: 0,
      label: t`Customer Administrator`
    },
    {
      value: 1,
      label: t`Site Administrator`
    }
  ];

  return (
    <Grid className={classes.permissionsContainer}>
      <Field
        label={t`Customer Name`}
        disabled={true}
        value={customerName}
        component={UserDetailsField}
      />

      <FormControl variant="outlined" className={classes.select}>
        <Field
          disabled={inviteEdit}
          name="adminRole"
          value={values.adminRole}
          component={Select}
          options={onlyAdminAllowed ? controlUserPermissions : permissionOptions}
          classes={{ icon: classes.arrowIcon }}
          IconComponent={SvgArrow}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        />
      </FormControl>
      {(values.adminRole === 0 || values.adminRole === 1) && (
        <div className={clsx(classes.tableContainer, classes.largerTableContainer, { [classes.matchHeight]: newUser })}>
          <TableContainer >
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.tableHeadStyle}>{t`ALLOW`}</TableCell>
                  <TableCell align="left" className={classes.tableHeadStyle}>{t`SITE NAME`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sites.map((site: ISite, index) => {
                  if (!site) {
                    return null;
                  }
                  return (
                    <TableRow
                      key={index}
                      style={index % 2 ? { background: "#fafafa" } : { background: "white" }}
                    >
                      <TableCell align="left" className={classes.checkboxCell}>
                        <Field
                          name="checkedSites"
                          value={values?.checkedSites[site?.id]}
                          as={() => (
                            <Checkbox
                              disabled={!values.adminRole || inviteEdit}
                              checked={values.checkedSites[site.id]}
                              onChange={() => updateCheckedSites(site.id)}
                              color="default"
                              classes={{ root: classes.grayCheckbox }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellStyle}>{site?.name}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <div className={clsx(classes.sitesContainer, { [classes.matchHeight]: newUser })}>
        {values.adminRole === 2 &&
          sites.map((site: ISite, index) => {
            if (!site) {
              return null;
            }
            return (
              <div key={`site-${index}`}>
                <div className={classes.collapseHeader} onClick={() => selectSite(site.id)}>
                  <Typography className={classes.collapseTitle}>{site.name}</Typography>
                  <span className={classes.spanHeight}>
                    <i className={`fas ${selectedId === site.id ? "fa-chevron-down" : "fa-chevron-right"}`} />
                  </span>
                </div>
                <Collapse in={selectedId === site.id} className={classes.tablesContainer}>
                  <div className={clsx(classes.tableContainer, { [classes.matchTableHeight]: newUser })}>
                    <TableContainer >
                      <Table stickyHeader aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" className={classes.tableHeadStyle}>{t`ALLOW`}</TableCell>
                            <TableCell align="left" className={classes.tableHeadStyle}>{t`SYSTEM NAME`}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {systems.map((system: ISystem, index) => {
                            if (_.includes(site.devices, system.device)) {
                              return (
                                <TableRow
                                  key={index}
                                  style={index % 2 ? { background: "#fafafa" } : { background: "white" }}
                                >
                                  <TableCell align="left" className={classes.checkboxCell}>
                                    <Field
                                      name="checkedSystems"
                                      value={values.checkedSystems[system.id]}
                                      as={() => (
                                        <Checkbox
                                          checked={values.checkedSystems[system.id]}
                                          onChange={() => updateCheckedSystems(system.id)}
                                          color="default"
                                          classes={{ root: classes.grayCheckbox }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCellStyle}>{system.name}</TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Collapse>
              </div>
            );
          })}
      </div>
    </Grid>
  );
};

export default UserSettings;
