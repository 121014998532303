import {
    Button,
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    MenuItem,
    MobileStepper,
    Select,
    Theme,
    Typography
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import clsx from "clsx";
import { Field, Formik, FormikProps } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Collapse from "../../components/Collapse/Collapse";
import CoolButton from "../../cool_widgets/Button";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import ManuallySetSystems from "./Device/ManuallySetSystems";
import SystemDetails from "./Device/SystemDetails";
import UnitsToSystemsMapTable from "./Device/UnitsToSystemMapTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "25rem",
            margin: "1rem"
        },
        dropdownStyle: {
            width: "10rem",
            marginLeft: "1rem"
        },
        selectBox: {
            width: "20rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        content: {
            height: "100%",
            minHeight: "30rem"
        },
        disabled: {
            display: "none"
        },
        collapse: {
            border: "1px solid grey",
            borderRadius: "3px"
        },
        progressBox: {
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",

        },
        saveBtn: {
            display: "flex",
            padding: "1rem",
            justifyContent: "flex-end"
        },
        noBorder: {
            border: "none"
        },
        iconBtnStyle: {
            width: 30,
            height: 30,
            borderRadius: 6,
            padding: 0
        },
        add: { margin: "1rem" }
    }));

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
    updateSystemName: (id: string, name: string) => void;
}

const ImportModel: React.FC<any> = (props) => {
    const {
        updateSystemName,
        create,
        allErrors,
        handleClose,
        updateSystem,
        removeSystem,
        steps,
        isLoaded,
        isNew,
        allInfo,
        handleSiteClose
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [error, setError] = useState<any>("");
    const capacityMeasurementUnitTypes = useStoreState(
        (state) => state.types.capacityMeasurementUnitTypes
    );
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [systems, setSystems] = React.useState<any[]>([]);
    const getDeviceSystems = useStoreState(
        (state) => state.devices.getDeviceSystems
    );
    const getSystems = useStoreActions((action) => action.systems.getSystems);
    const maxSteps = steps;
    useEffect(() => {
        (async function () {
            const systems = await getSystems();
            setSystems(systems);
        })();
    }, []);
    const handleNext = async () => {
        setIsDisabled(true);
        if ((activeStep === 0 && !isNew) || (activeStep === 2 && isNew)) {
            if (allInfo.detectedSystems.length === allInfo.systemsToSave.length) {
                const errors = await updateSystem();
                if (!errors.systemsErrors.length) {
                    if ((steps === 3 && isNew) || steps === 1 && !isNew) {
                        handleCloseModal();
                    }
                    else {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }
            else {
                if (allInfo.detectedSystems.length > allInfo.systemsToSave.length) {
                    setError(`${allInfo.detectedSystems.length - allInfo.systemsToSave.length}` + t` systems was detected please add systems`);
                }
                if (allInfo.detectedSystems.length < allInfo.systemsToSave.length) {
                    const dif = allInfo.systemsToSave.length - allInfo.detectedSystems.length;
                    const item = dif === 1 ? "system" : "systems";
                    setError(t`please remove ` + `${dif} ${item}`);

                }
            }

        }
        else {
            if (activeStep === maxSteps - 1) {
                handleCloseModal();

            }
            else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        setIsDisabled(false);

    };

    const handleBack = () => {
        if (error) {
            setError("");
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const isSystemDaikin = (system: ISystem | null | undefined) => {
        // Sorry for that. It's not me, it's typescript.
        return system && system.brandNum === 1 ? true : false;
    };
    const SystemSchema = Yup.object().shape({
        name: Yup.string().test("validateName",
            "name must be different from existing systems", (value) => {
                const names = allInfo.systemsToSave.map((system: any) => system.name);
                return !_.includes(names, value);
            }).required("Required"),
        brand: Yup.number().required("Required").typeError("Required"),
        line: Yup.number().required("Required").typeError("Required"),
        capacity: Yup.number(),
        series: Yup.string(),
        type: Yup.string()
    });

    const initialValues: any = {
        name: "",
        brand: null,
        line: undefined,
        series: "",
        type: "",
        capacityMeasurementUnits:
            capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]],
        capacity: 0
        // device: foundSystems[0] ? foundSystems[0].device : ''
    };
    const createSystem = async (values: any, actions: any) => {
        create(values);
        setOpen(false);

    };
    const handleCloseModal = () => {
        handleClose();
        handleSiteClose && handleSiteClose();

    };
    return (
        <div>
            {(isDisabled) ? <div className={classes.progressBox}>
                <CircularProgress />
            </div> :
                <>
                    <div className={clsx(allErrors.generalErrors.length ? "" : classes.content, !isLoaded ? "" : classes.disabled)}>

                        {(activeStep === 0 && isNew) && (
                            <>
                                <Typography>
                                    {allErrors.sitesErrors.map((error: any, index: any) =>
                                    (
                                        <Typography key={index}>
                                            {error}
                                        </Typography>
                                    )
                                    )}
                                    {allInfo.sites.map((site: any, index: number) => (
                                        <Typography key={index}>
                                            {t`site ` + `${site.name}` + t` was created`}
                                        </Typography>
                                    ))}
                                    {steps === 1 ? allErrors.devicesErrors.map((error: any, index: any) =>
                                    (
                                        <Typography key={index}>
                                            {error}
                                        </Typography>
                                    )
                                    ) : ""}
                                </Typography>
                            </>
                        )}
                        {(activeStep === 1 && isNew) && (
                            <>
                                <Typography>
                                    {allErrors.devicesErrors.length ? allErrors.devicesErrors.map((error: any, index: any) =>
                                    (
                                        <Typography key={index}>
                                            {error}
                                        </Typography>
                                    )
                                    ) : ""}
                                    {allInfo.devices.map((device: any, index: number) => (
                                        <Typography key={index}>
                                            {t`device` + device.serial + t` was created`}
                                        </Typography>
                                    ))}
                                </Typography>
                            </>
                        )}
                        {(((activeStep === 0 && !isNew) || (activeStep === 2 && isNew)) && !allErrors.generalErrors.length)
                            && (
                                <>
                                    {allErrors.systemsErrors.length ? (
                                        <Typography> {allErrors.systemsErrors.length ? t`Systems:` : ""}
                                            {allErrors.systemsErrors.map((error: any, index: any) =>
                                            (
                                                <Typography key={index}>
                                                    {error}
                                                </Typography>
                                            )
                                            )}

                                        </Typography>
                                    ) : ""
                                    }
                                    {allErrors.sitesErrors.length && (activeStep === 0 && isNew) ? (
                                        <Typography> {allErrors.sitesErrors.length ? t`Sites:` : ""}
                                            {allErrors.sitesErrors.map((error: any, index: any) =>
                                            (
                                                <Typography key={index}>
                                                    {error}
                                                </Typography>
                                            )
                                            )}

                                        </Typography>
                                    ) : ""
                                    }
                                    {allErrors.devicesErrors.length ? (
                                        <Typography> {allErrors.devicesErrors.length ? t`Devices:` : ""}
                                            {allErrors.devicesErrors.map((error: any, index: any) =>
                                            (
                                                <Typography key={index}>
                                                    {error}
                                                </Typography>
                                            )
                                            )}

                                        </Typography>
                                    ) : ""
                                    }

                                    {(allErrors.unitsErrors.length && (steps === 1 && !isNew || steps === 3 && isNew)) || (allErrors.unitsErrors.length && (maxSteps !== 4 && isNew)) ? (
                                        <Typography> {allErrors.unitsErrors.length ? t`Units` : ""}
                                            {allErrors.unitsErrors.map((error: any, index: any) =>
                                            (
                                                <Typography key={index}>
                                                    {error}
                                                </Typography>
                                            )
                                            )}

                                        </Typography>
                                    ) : ""
                                    }

                                    {allInfo.systemsToSave.map((system: any, index: number) => (
                                        <div className={classes.select} key={system.name}>
                                            <div className={classes.selectBox}>
                                                {system.name}
                                                < Select
                                                    value={system.newName}
                                                    onChange={(event: any) => {
                                                        updateSystemName(event.target.value, index);

                                                        // _paramValueFunc('');
                                                    }}
                                                    displayEmpty
                                                    variant="filled"

                                                    className={classes.dropdownStyle}
                                                // IconComponent={SvgArrow}
                                                // classes={{ icon: classes.arrowIcon }}
                                                >
                                                    <MenuItem value="" disabled>{t`Select System`}</MenuItem>
                                                    {_.map(
                                                        allInfo.detectedSystems,
                                                        (foundSystem: any) => (
                                                            <MenuItem key={foundSystem.id} value={foundSystem.id}>
                                                                {foundSystem.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>

                                            </div>
                                            <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => removeSystem(system.name)}
                                            //  classes={{ root: classes.overWriteIcon }}
                                            >
                                                <DeleteIcon
                                                //   className={iconStyle}
                                                />
                                            </IconButton>
                                        </div>
                                    ))}
                                    {
                                        allInfo.systemsToSave.length ? "" : (allInfo.detectedSystems.length + t`detected please add your systems`)
                                    }
                                    {(open && allInfo.detectedSystems.length !== 0) && (
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={createSystem}
                                            enableReinitialize={true}
                                            validationSchema={SystemSchema}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                        >
                                            {({ handleSubmit, errors, touched, values, setFieldValue, ...restFormikProps }) => {
                                                return (
                                                    <form onSubmit={handleSubmit} style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        flex: 1,
                                                        height: "100%",
                                                        border: "solid 1px #d5d2d5",
                                                        marginBottom: "1rem"
                                                    }}>
                                                        <SystemDetails
                                                            className={classes.noBorder}
                                                            classes={classes}
                                                            values={values}
                                                            mode={1}
                                                            isSystemDaikin={isSystemDaikin}
                                                            setFieldValue={setFieldValue}
                                                            errors={errors}
                                                            touched={touched}
                                                            {...restFormikProps}
                                                            {...props}
                                                        />
                                                        <div className={classes.saveBtn}>
                                                            <CoolButton

                                                                title="save system"
                                                                type="submit"
                                                                width={150}
                                                                uppercase

                                                            >
                                                                {t`Add`}
                                                            </CoolButton>
                                                        </div>
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    )
                                    }
                                    {
                                        (allErrors.generalErrors.length === 0 &&
                                            !open
                                        )
                                        && (
                                            <CoolButton
                                                className={classes.add}
                                                onMouseUp={() => setOpen(true)}
                                                disabled={allInfo.detectedSystems.length <= allInfo.systemsToSave.length}
                                            >{t`Add new system`}</CoolButton>
                                        )
                                    }
                                    {/* {(maxSteps !== 4 && isNew) && allErrors.unitsErrors.map((error: any, index: any) =>
                                        (
                                            <Typography key={index}>
                                                {error}
                                            </Typography>
                                        )
                                    )} */}
                                    {error && (
                                        <div>{error}</div>
                                    )}
                                </>
                            )
                        }

                        {((activeStep === 1 && !isNew) || (activeStep === 3 && isNew)) && (
                            <>
                                <Typography>
                                    {allErrors.unitsErrors.length ? t`Units` : ""}
                                </Typography>
                                {allErrors.unitsErrors.map((error: any, index: any) =>
                                (
                                    <Typography key={index}>
                                        {error}
                                    </Typography>
                                )
                                )}
                                {/* {allInfo.units.map((unit: any, index: number) => (
                            <Typography key={index}>
                                {t`unit ${unit.privateId} was c`}
                            </Typography>
                        ))} */}
                                {(allInfo.units.length && allInfo.devices.length) && (
                                    allInfo.devices.map((device: any, index: number) => (
                                        <Collapse title={t`Device ${device.serial}`}
                                            className={classes.collapse} key={index}>
                                            <ManuallySetSystems
                                                mode={0}
                                                site={device.site}
                                                deviceId={device.id}
                                                import={true}
                                                deviceSystems={getDeviceSystems(device.id)}
                                                closeModal={handleCloseModal}
                                            />
                                        </Collapse>
                                    ))

                                )
                                }
                            </>)}
                    </div>
                    {allErrors.generalErrors.length ? allErrors.generalErrors.map((error: any, index: number) => (
                        <Typography key={index}>
                            {error}
                        </Typography>
                    )) :
                        ((activeStep === 1 && !isNew) || (activeStep === 3 && isNew)) ? "" :
                            <MobileStepper
                                className={!isLoaded ? "" : classes.disabled}
                                steps={maxSteps}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={
                                    <CoolButton uppercase width={150} onMouseUp={handleNext} >
                                        {(activeStep === maxSteps - 1) ? t`Close` : t`Next`}
                                        <KeyboardArrowRight />
                                    </CoolButton>
                                }
                                backButton={
                                    <CoolButton uppercase white marginRight width={150} onMouseUp={handleBack} disabled={activeStep === 0}>
                                        <KeyboardArrowLeft />
                                        {t`Back`}
                                    </CoolButton>
                                }
                            />
                    }
                </>
            }
        </div >
    );
};

export default ImportModel;
