import { fade } from "@material-ui/core/styles/colorManipulator";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      flexFlow: "column nowrap",
      maxHeight: "100%",
      height: "100%"
      // background: 'linear-gradient(180deg, rgba(22,18,32,1) 0%, rgba(58,24,58,1) 100%)',
    },
    gridStyle: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      width: "100%"
      // maxWidth: "768px"
    },
    iconStyle: { fontSize: "22px", color: "white" },
    fieldContainerStyle: { width: "80%", overflow: "auto" },
    typographyMargin: { marginBottom: "-10px" },
    testclass: {
      width: "50px",
      minWidth: "60px"
    },
    schedulesContainer: {
      display: "flex",
      flexFlow: "row wrap",
      width: "calc(100% - 9px)",
      flex: 1,
      padding: "0 20px",
      boxSizing: "border-box",
      overflowY: "auto",
      justifyContent: "flex-start",
      alignContent: "flex-start",
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.26)"
      }
    },
    scheduleHeader: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: fade("#fdfdfe", 0.2),
      width: "100%",
      height: "50px",
      borderRadius: "6px",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: "10px",
      boxSizing: "border-box"
    },
    scheduleTitle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flex: 5,
      "& *": {
        fontSize: "18px",
        fontWeight: "500",
        color: "white",
        marginRight: "7px"
      }
    },
    scheduleControl: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "20%"
    },
    scheduleBody: {
      display: "flex",
      flex: 1,
      height: "235px",
      flexDirection: "column",
      backgroundColor: fade("#fdfdfe", 0.1),
      width: "100%",
      borderRadius: "6px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: "10px",
      paddingBottom: "20px",
      paddingTop: "20px",
      boxSizing: "border-box"
    },
    scheduleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "12px 0",
      minHeight: "max-content",
      maxWidth: "45%",
      maxHeight: "322px",
      marginRight: "20px"
    },
    bodyRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flex: 1,
      height: "100%"
    },
    timeSection: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: "100%",
      justifyContent: "space-between",
      margin: "10px 10px 15px 0"
    },
    setPointSection: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: "15px"
    },
    setPointStyle: {
      color: "white",
      fontSize: "50px"
    },
    timeStyle: {
      color: "white",
      fontSize: "16px"
    },
    tempSymbolStyle: {
      color: "white",
      fontSize: "30px"
    },
    dividerStyle: {
      backgroundColor: fade("#fdfdfe", 0.1)
    },
    controlSec: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%"
    },
    scheduleStatusStyle: {
      color: "white",
      fontSize: "18px"
    },
    noSchedulesStyle: {
      fontSize: "30px",
      color: "white",
      marginTop: "20px",
      width: "100%",
      textAlign: "center"
    },
    longNamestyle: {
      maxWidth: "11ch",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden !important",
      display: "inline-block"
    },
    rowContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      minHeight: "120px",
      paddingBottom: 16
    },
    setpointContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },

    inputWrapperStyle: {
      marginTop: 0,
      marginBottom: "10px",
      fontSize: "30px",
      "& *": { borderRadius: "6px" },
      "&  div": {
        border: "1px solid rgba(253, 253, 254, 0.3)"
      },
      "& input:focus + fieldset": {
        borderWidth: "1px !important"
      },
      "& input": {
        color: "white",
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        padding: "16px 0px",
        paddingLeft: "20px"
      },
      "& label": {
        color: "white"
      }
    },
    inputClass: {
      "& input": {
        color: "#fdfdfe",

        height: "50px",
        padding: 0,
        paddingLeft: "15px",
        paddingRight: "15px"
      },
      fontSize: "16px",
      color: "#fdfdfe",
      "& input:disabled + fieldset": {
        borderColor: `${"#fdfdfe"} !important`
      },
      "& ::placeholder": {
        color: "#fdfdfe",
        opacity: 0.5,
        fontSize: "16px",
        lineHieght: "normal",
        fontWeight: "normal"
      },
      "& label": {
        color: `${"#fdfdfe"} !important`
      },
      "& div": {
        maxHeight: "40px"
      }
    },
    removeLeftMargin: {
      marginLeft: 0
    },
    deleteIcon: {
      fontSize: "1rem",
      cursor: "pointer",
      color: "initial",
      "&:hover": {
        color: "red"
      }
    },
    notEditable: {
      opacity: 0.6
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
    editIcon: {
      width: 19,
      height: 20
    },
    bigIconBtnStyle: {
      width: 40,
      height: 40,
      borderRadius: 6,
      padding: 0
    }
  });
