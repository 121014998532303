import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
import { Field, Formik } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as yup from "yup";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import InputField from "../../screens/SiteManagement/InputField";
import useStyles from "./EditParamPopUp.style";

export default function EditParamPopUp(props: any) {
    const [isLoadingDone, setIsLoadingDone] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const { row, unit, reload, open, onClose, setValue, editedRowIndex, newValue } = props;
    const {addMessage} = useStoreActions((action) => action.errorMessage);
    const [timer, setTimer] = useState<any>();
    const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
    const setParamValue = useStoreActions((a) => a.units.setParamValue);
    const classes = useStyles();

    // useEffect(() => {
    //     // let timeout: any = null;
    //     // if (isSaved) {
    //     const timeout = setTimeout(() => {
    //         reload && reload();
    //     }, 60000);
    //     // }
    //     return () => clearTimeout(timeout);
    // }, [isSaved]);

    const handleClose = () => {
        onClose();
    };
    const initialValues: any = {
        value: !_.isNil(newValue) ? parseInt(newValue) : (row && !row.enumVal) ? row.value : 0,
        enumVal: (!_.isNil(newValue) && row && row.enumVal) ? Object.keys(serviceParamTypes[row.enum]).find((key) => serviceParamTypes[row.enum][key] === newValue) :
            row && row.enumVal ?
                Object.keys(serviceParamTypes[row.enum]).find((key) => serviceParamTypes[row.enum][key] === row.enumVal) : null
    };
    const ValueSchemaString = yup.object().shape({
        value: yup.string()
    });
    const ValueSchemaNum = yup.object().shape({
        value: yup.number()
    });
    const ValueSchemaENum = yup.object().shape({
        enumVal: yup.number()
    });
    const ValueSchemaCondition = yup.object().shape({
        value: yup.number().moreThan(row ? row.slider.min - 1 : 0, row ? `Value should be >= ${row.slider.min}` : "")
            .lessThan(row ? row.slider.max + 1 : 0, row ? `Value should be =< ${row.slider.max}` : "")
    });
    const onFormSubmit = (values: any) => {
        handleClose();
        // setIsLoadingDone(true);
        setParamValue({
            id: unit, serviceParamCode: row.code, value: parseInt(!_.isNil(values.enumVal) ? values.enumVal : values.value)
        }).then(() => {

            setValue(!_.isNil(values.enumVal) ?
            serviceParamTypes[row.enum][values.enumVal] : values.value, editedRowIndex);
            // setIsSaved(true);

            // setIsLoadingDone(false);

        })
        .catch((err: any) => addMessage({message: err.message}));
    };
    // const ValueSchemaFloat= yup.object().shape({
    //     value: yup.number(),
    //     enumVal: yup.number(),
    // });
    return (
                <Dialog
                    key={`input-name ${row?.name}`}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    aria-describedby="form-dialog-description"
                    maxWidth="md"
                    fullWidth
                    classes={{ paper: classes.dialogStyle }}
                >
                <div className={classes.dialogHeader}>
                        <Typography className={classes.headerTitle}>{row?.name}</Typography>
                        <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
                            <Close color="#7f7692" />
                        </IconButton>
                </div>
                    <div className={classes.dialogContent}>
                        {
                            isLoadingDone ?
                                <Grid
                                    className={classes.progress}
                                >
                                    <CircularProgress />
                                </Grid>
                                :
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onFormSubmit}
                                    enableReinitialize={true}
                                    validationSchema={
                                        row && row?.value_type === 1 ? ValueSchemaString :
                                            (row?.enum ? ValueSchemaENum :
                                                (row && row.slider && row?.value_type === 2) ? ValueSchemaCondition :
                                                    (row && row.slider && row?.value_type === 3) ? ValueSchemaCondition :
                                                        ValueSchemaNum
                                            )
                                    }
                                    validateOnChange={false}
                                    validateOnBlur={false}

                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        values,
                                        resetForm,
                                        ...restFormikProps
                                    }) => (
                                            <form onSubmit={handleSubmit}>
                                                <span className={classes.valueTitle}>{t`Current value`}</span>
                                                <div className={classes.oldValueBox}>
                                                    {_.isNil(newValue) ? row?.enum ? row.enumVal : row.value :
                                                        newValue
                                                    }
                                                </div>

                                                <span className={classes.valueTitle}>{t`Set value`}</span>

                                                {
                                                    row?.enum ?

                                                        <Field
                                                            value={values.enumVal}
                                                            name="enumVal"
                                                            options={
                                                                Object.values(serviceParamTypes[row.enum]).filter((val) => {
                                                                    return val !== "UNKNOWN";
                                                                }).map((val: any, index: number) => (
                                                                    {
                                                                        value: index,
                                                                        label: val
                                                                    }
                                                                ))
                                                            }
                                                            error={errors.enumVal && touched.enumVal ? true : false}
                                                            helperText={errors.enumVal && touched.enumVal ? errors.enumVal : ""}
                                                            component={Select}
                                                            // classes={{ icon: classes.arrowIcon }}
                                                            // IconComponent={SvgArrow}
                                                            variant="outlined"
                                                            margin="none"
                                                            // className={classes.addMargin}
                                                            style={{ maxHeight: "44px", height: "44px" }}
                                                        />
                                                        :
                                                        <Field
                                                            name={"value"}
                                                            value={values.value}
                                                            component={InputField}
                                                            variant="outlined"
                                                            error={errors.value && touched.value ? true : false}
                                                            helperText={errors.value && touched.value ? errors.value : ""}
                                                            type={row?.value_type === 1 ? "string" : "number"}
                                                            step={row?.value_type === 3 ? "0.01" : "0"}
                                                        />

                                                }
                                                <div className={classes.dialogActions}>
                                                    <Button
                                                        onClick={handleClose}
                                                        width={130} uppercase
                                                        white
                                                        marginRight
                                                    >
                                                        {t`Cancel`}
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        width={130} uppercase
                                                        onMouseDown={(event: any) => event.preventDefault()}
                                                    >
                                                        {t`Update System`}
                                                    </Button>
                                                </div>
                                            </form>)}
                                </Formik>
                        }
                    </div>
                </Dialog >
    );
}
