import _ from 'lodash';
import React, { useState } from 'react';
import useStyles from './Collapse.style';

interface ICollapseProps {
  title: string | null;
  hidden?: boolean;
  isOpen?: boolean;
  showFirst?: boolean;
  secondTitle?: string;
  className?: string;
}


export default function Collapse(props: React.PropsWithChildren<ICollapseProps>) {
  const classes = useStyles();

  const { hidden, title, secondTitle } = props;
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen ?? false);

  const openStatus = isOpen ? 'fa-chevron-down' : 'fa-chevron-right';
  const onToggle = () => {
    setIsOpen(!isOpen);
  }
  return (
    <div className={props.className}>
      {
        title ?
          <>
            <div className={classes.item} onClick={() => onToggle()}>
              <div className={classes.titleContainer}>
                <label className={classes.title}>{title}</label>
                <div onClick={onToggle}>
                  <span >
                    <i className={`fas ${openStatus} ${classes.collapsibleIcon}`} />
                  </span>
                </div>
              </div>
              <label className={classes.secondTitle}>{secondTitle}</label>
            </div>
            <div hidden={!isOpen}>
              {props.children}
            </div>
          </>
          :
          <div>
            {props.children}
          </div>
      }
    </div>
  );
}


