import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import {
  CoolModeOff as SvgCoolModeOff,
  CoolModeOn as SvgCoolModeOn,
  Disconnect as SvgDisconnect,
  HeatModeOff as SvgHeatModeOff,
  HeatModeOn as SvgHeatModeOn,
  OnGrey as SvgPowerOff,
  OnWhite as SvgPowerOn
} from "../../icons/";
import { EditIcon } from "../../logos";
import { unitActions } from "../../models/actions/UnitActions";
import { IDevice } from "../../models/Devices";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import assetsService from "../../services/assetsService";
import ManuallySetSystems from "./Device/ManuallySetSystems";
import ManuallySetOther from "./ManuallySetOther";
import { systemViewStyle } from "./SystemView.style";
import UnitsTable from "./UnitsTable";

const CreateOtherUnitPopUp = (props: any) => {
  const useStyles = makeStyles(systemViewStyle);
  const classes = useStyles();
  const {device, system, close, onAdd} = props;
  const [value, setValue] = useState<string>("");
  const [touched, setTouched] = useState<boolean>(false);

  const handleInputChange = (event: any) => {
    !touched && setTouched(true);
    setValue(event.target.value);
  };

  const handleOnAdd = () => {
    if (!value){
      !touched && setTouched(true);
      return;
    }
    onAdd(device.id, system.line, value);
  };

  return <Dialog
        open={true}
        onClose={close}
        aria-labelledby="create-other-unit-dialog"
        classes={{ paper: classes.otherUnitDialog}}
      >
        <TextField
          label={t`Unit Name`}
          variant="outlined"
          className={classes.unitTxtFeild}
          value={value}
          onChange={handleInputChange}
          error={touched && !value}
          />
        <div className={classes.actionsHolder}>
          <Button uppercase width={150} white marginRight onClick={close}>{t`Cancel`}</Button>
          <Button uppercase width={150} onClick={handleOnAdd}>{t`Add`}</Button>
        </div>
      </Dialog>;
};

export {CreateOtherUnitPopUp};
