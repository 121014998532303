import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import daysListStyles from "./daysList.style";

const daysS: any = {
    Friday: "Fri",
    Monday: "Mon",
    Saturday: "Sat",
    Sunday: "Sun",
    Thursday: "Thu",
    Tuesday: "Tue",
    Wednesday: "Wed"
  };

const DaysList = (props: any) => {
  const { days, activeDays, action, disablePointer = false} = props;
  const useStyles = makeStyles(daysListStyles);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {days.map((day: any, index: number) => {
        return (
          <div
            className={clsx(classes.dayStyle, {
              [classes.activeDayStyle]: activeDays.indexOf(day) > -1
            , [classes.disableClickPointer]: disablePointer})}
            key={index}
            onClick={() => action(day)}
          >
            <Typography>{daysS[day]}</Typography>
          </div>
        );
      })}
    </div>
  );
};

export default DaysList;
