import _ from 'lodash';
import React, { useEffect } from 'react';
import { t } from 'ttag';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  makeStyles
} from '@material-ui/core';
import { useStoreActions, useStoreState } from '../../../models/RootStore';
import { IDevice } from '../../../models/Devices';


interface IProps {
  device: IDevice;
  className?: any;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    color: "#79797d",
    height: "45px",
    padding: "0 15px",
    fontSize: "13px",
    fontWeight: "bold",
    lineHeight: "normal",
    borderBottom: "none",
    letterSpacing: "normal",
    backgroundColor: "#efeff2",
    textTransform: "uppercase"
  },
  tableCell: {
    color: "#79797d",
    height: "35px",
    padding: "0 15px",
    fontSize: "13px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  }
});

export default (function DeviceSystems(props: IProps) {
  const classes = useStyles();
  const getDeviceUnits = useStoreState(state => state.devices.getDeviceUnits);
  const types = useStoreState(state => state.types);
  const { hvacBrands, unitTypes } = types;

  const deviceSystems = useStoreState(state =>
    state.devices.getDeviceSystems(props.device.id)
  );

  return (
    <Paper>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>{t`System name`}</TableCell>
            <TableCell className={classes.tableHead} align="left">{t`Brand`}</TableCell>
            <TableCell className={classes.tableHead} align="center">{t`Outdoor units`}</TableCell>
            <TableCell className={classes.tableHead} align="center">{t`Indoor units`}</TableCell>
            <TableCell className={classes.tableHead} align="center">{t`Line`}</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {deviceSystems.length ? deviceSystems.map((system: any) => (
            <TableRow key={system.id}>
              <TableCell component="th" scope="row" className={classes.tableCell}>
                {system.name}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>{_.find(hvacBrands, { value: system.brandNum }) ? _.find(hvacBrands, { value: system.brandNum }).name : ''}</TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {getDeviceUnits(props.device.id, system.id, 'outdoor').length}

              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {getDeviceUnits(props.device.id, system.id, 'service').length}

              </TableCell>
              <TableCell align="center" component="th" scope="row" className={classes.tableCell}>
                {system.line}
              </TableCell>
            </TableRow>
          )) : <Typography>{t`No systems detected`}</Typography>
          }
        </TableBody>
      </Table>
      {/* {!deviceSystems.length && (
       
      )} */}
    </Paper>
  );

});
