import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogStyle: { width: 550, height: 350, padding: 10 },

    tableCell: {
      fontSize: "0.8125rem !important",
      // padding: "0.2rem 0.2rem 0.2rem 0",
      padding: 0,
      textAlign: "center",
      // height: "3rem",
      height: "45px",
      "&:last-child": {
        paddingRight: "20px"
      }
    },
    valueTitle: {
      marginBottom: "5px",
      display: "flex"
    },
    tableCellAlignLeft: {
      fontSize: "0.8125rem !important",
      padding: "0 10px",
      paddingRight: "15px",
      textAlign: "left",
      width: "1%",
      whiteSpace: "nowrap"
    },
    nameCell: {
      fontSize: "0.8125rem !important",
      padding: "0 10px",
      paddingRight: "15px",
      textAlign: "left",
      width: "25ch",
      minWidth: "25ch",
      maxWidth: "25ch",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },

    "tableCell:last-child": {
      paddingRight: "1rem"
    },

    "tableCell:nth-last-child(2)": {
      paddingRight: "2rem"
    },

    tableRowUnplottable: {
      backgroundColor: "rgb(249, 249, 249)"
    },

    title: {
      padding: "0.5rem 0",
      color: "#4b1c46"
    },

    lastUpdatedTitle: {
      fontSize: "0.7rem",
      color: "#aaa2aa",
      marginRight: "5px"
    },

    thumbValue: {
      color: "rgb(75, 28, 70)",
      marginTop: "-5px",
      fontSize: "1.3rem"
    },

    thumbSelection: {
      color: "rgb(240, 81, 70)",
      marginTop: "-2px",
      fontSize: "1.7rem",
      opacity: "0.7"
    },

    autoRefreshLabel: {
      fontSize: "0.85rem"
    },
    autoUpdate: {
      minWidth: "8rem"
    },
    removePaddingRight: {
      paddingRight: "5px"
    },
    grayCheckbox: {
      "&$checked": {
        color: "#736973"
      }
    },
    popper: {
      marginBottom: "-20px"
    },
    dialogTitle: {
      borderBottom: "solid 1px #d5d2d5",

      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
      paddingRight: "5px",
      height: "60px",
      backgroundColor: "#f6f6f7",
      alignItems: "center",
      width: "350px"
    },
    addIcon: {
      color: "#aaa2aa",
      width: "28px",
      height: "28px"
    },
    paramsPopupButton: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "15px"
    },
    paramsPopupList: {
      width: "350px",
      overflow: "auto",
      height: "400px",
      paddingRight: 20,
      paddingLeft: 10
    },
    parametersHeader: {
      width: "154px",
      display: "flex",
      alignItems: "center"
    },
    autoUpdateContainer: {
      display: "flex",
      alignItems: "center"
    },
    smallCell: {
      maxWidth: "80px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    moveCursor: { cursor: "move !important" },
    yesBtn: {
      marginRight: "17px"
    },
    dialogActions: {
      padding: 20,
      display: "flex",
      justifyContent: "flex-end"
    },
    titleContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #d5d2d5",
      padding: "16px 9px 16px 24px",
      color: "#29132e"
    },
    tableCellValue: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "-webkit-fill-available"
    },
    oldValueBox: {
      border: "1px solid #aaa2aa",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      marginBottom: "1rem",
      borderRadius: "3px",
      paddingLeft: "0.8rem"
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
    dialogHeader: {
      paddingLeft: 20,
      paddingRight: 13,
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
    headerTitle: {
      fontFamily: "RobotoMedium",
      color: "#29132e",
      lineHeight: "normal",
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "normal"
    },
    dialogContent: {
      flex: "1 1 auto",
      padding: 20,
      overflowY: "auto",
      paddingBottom: 0
    }
  })
);

export default useStyles;
