import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  dialogStyle: { width: 550, height: 220, padding: 10 },
  agreeBtn: {
    backgroundColor: "rgba(41, 19, 46, 0.95)",
    color: "white",
    padding: '5px 50px',
    fontSize: '16px',
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: "rgba(41, 19, 46, 0.95)",
      color: "white"
    }
  },
  view: {
    minWidth: "400px",
    minHeight: "100px",
    "& p": {
      fontSize: '18px'
    }
  },
  errorBox: {
    fontSize: "20px",
    fontFamily: "Helvetica Neue Helvetica Arial sans-serif",
    background: "rgba(1, 13, 19, 0.9)",
    height: "100%",
    width: "100%",
    zIndex: 100
  },
  modalScreen: {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  header: {
    display: "block",
    minHeight: "50px",
    width: "100%",
    position: "relative",
    color: "white"
  },

  title: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  main: {
    minHeight: "50px",
    backgroundColor: "white"
  },

  content: {
    lineHeight: "30px",
    verticalAlign: "middle",
  }
});
export default useStyles;
