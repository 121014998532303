import {
    Dialog,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import {
    Customer as sdkCustomer
} from "coolremote-sdk";
import { Field, Formik } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import moment from "moment";
import { tz } from "moment-timezone";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { Arrow as SvgArrow, Close } from "../../icons/";
import { EditIcon } from "../../logos";
import { useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import { countries } from "./countryList";
import ImportExcel from "./ImportExcel";
import InputField from "./InputField";
import useStyles from "./Site.style";
import { stateList } from "./stateList";

export default function Site(props: any) {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(null);
    const allCustomers = useStoreState((state) => state.customers.allCustomers);
    const { customerId } = useStoreState((state) => state.selections.selections);
    const { site, save } = props;

    const classes = useStyles();

    const SiteSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        customer: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        postalCode: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
        state: Yup.string().when("country", {
            is: (value) => value && value === "US",
            then: Yup.string().required(
                "Required"
            ),
            otherwise: Yup.string()
        }),
        timezone: Yup.string().required("Required")

    });

    const initialValues: any = {
        name: site ? site.name : "",
        customer: site ? site.customer : "",
        city: site ? site.city : "",
        address: site ? site.address : "",
        postalCode: site ? site.postalCode : "",
        country: site ? site.country : "",
        state: site ? site.state : "",
        timezone: site ? site.timezone : ""
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAgree = (values: any, actions: any) => {
        if (values.country !== "US") {
            values.state = "";
        }
        save(values);
        setOpen(false);
    };
    const errorBox = error ? (
        <ErrorBox error={error} onClose={() => setError(null)} />
    ) : null;

    const countryOptions = countries.map((country: any) => ({
        value: country.code,
        label: country.name
    }));
    const timeZones = tz.names().map((timezone: any) => ({
        value: timezone,
        label: `${timezone}  ${tz(timezone).format("Z")}`
    }));
    const stateOptions = stateList.map((state: any) => ({
        value: state.value,
        label: state.label
    }));
    const customers = Object.values(allCustomers).map((customer: any) => ({
        value: customer.id,
        label: customer.name
    }));

    return (
        <div>
            {site ?
                <LightTooltip title="Edit Site">
                    <IconButton disableRipple onClick={handleClickOpen} className={classes.iconBtnStyle} >
                        <EditIcon className={classes.svgIcon} />
                    </IconButton>
                </LightTooltip> :
                <Button
                    className={classes.headerButton}
                    onClick={handleClickOpen}
                >
                    {t`Add New Site`}
                </Button>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <div className={classes.titleContent}>
                    <Typography className={classes.headerTitle}>{site ? t`Edit site` : t`Add New Site`}</Typography>
                    <IconButton disableRipple onClick={() => setOpen(false)} className={classes.iconBtnStyle}>
                        <Close color="#7f7692" />
                    </IconButton>
                </div>
                <div className={classes.dialogContent}>
                    {errorBox}
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            <div className={classes.imgContainer}>
                                <img
                                    src={assetsService.getImagesPath("cmnet.png")}
                                    className={classes.imgStyle}
                                ></img>
                            </div>
                            {
                                !site && <ImportExcel isNew={true} handleClose={handleClose} />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleAgree}
                                enableReinitialize={true}
                                validationSchema={SiteSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                {({ handleSubmit, errors, touched, values, setFieldValue, ...restFormikProps }) => {

                                    return (
                                        <form >
                                            <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`Customer`}</Typography>
                                                <Field
                                                    name="customer"
                                                    options={customers}
                                                    component={Select}
                                                    variant="outlined"
                                                    error={((errors.customer && touched.customer)) ? true : false}
                                                    helperText={(errors.customer && touched.customer) ? errors.customer : ""}
                                                    classes={{ icon: classes.arrowIcon }}
                                                    IconComponent={SvgArrow}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`Site Name`}</Typography>
                                                <Field
                                                    value={values.name}
                                                    name="name"
                                                    component={InputField}
                                                    error={((errors.name && touched.name)) ? true : false}
                                                    helperText={(errors.name && touched.name) ? errors.name : ""}
                                                />
                                            </div>
                                            <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`Street Address`}</Typography>
                                                <Field
                                                    value={values.address}
                                                    name="address"
                                                    component={InputField}
                                                    error={((errors.address && touched.address)) ? true : false}
                                                    helperText={(errors.address && touched.address) ? errors.address : ""}
                                                />
                                            </div>
                                            <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`City`}</Typography>
                                                <Field
                                                    value={values.city}
                                                    name="city"
                                                    component={InputField}
                                                    error={((errors.city && touched.city)) ? true : false}
                                                    helperText={(errors.city && touched.city) ? errors.city : ""}
                                                />
                                            </div>
                                            <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`Zip Code`}</Typography>
                                                <Field
                                                    value={values.postalCode}
                                                    name="postalCode"
                                                    component={InputField}
                                                    error={((errors.postalCode && touched.postalCode)) ? true : false}
                                                    helperText={(errors.postalCode && touched.postalCode) ? errors.postalCode : ""}
                                                />
                                            </div>
                                            <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`Country`}</Typography>
                                                <Field
                                                    // value={values.country}
                                                    options={countryOptions}
                                                    name="country"
                                                    component={Select}
                                                    variant="outlined"
                                                    error={((errors.country && touched.country)) ? true : false}
                                                    helperText={(errors.country && touched.country) ? errors.country : ""}
                                                    classes={{ icon: classes.arrowIcon }}
                                                    IconComponent={SvgArrow}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                />
                                            </div>
                                            {values.country === "US" && <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`State`}</Typography>
                                                <Field
                                                    // value={values.state}
                                                    options={stateOptions}
                                                    name="state"
                                                    component={Select}
                                                    variant="outlined"
                                                    error={((errors.state && touched.state)) ? true : false}
                                                    helperText={(errors.state && touched.state) ? errors.state : ""}
                                                    classes={{ icon: classes.arrowIcon }}
                                                    IconComponent={SvgArrow}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                />
                                            </div>}
                                            <div className={classes.inputFieldWithLabel}>
                                                <Typography>{t`Time zone`}</Typography>
                                                <Field
                                                    options={timeZones}
                                                    name="timezone"
                                                    component={Select}
                                                    variant="outlined"
                                                    placeholder=""
                                                    error={((errors.timezone && touched.timezone)) ? true : false}
                                                    helperText={(errors.timezone && touched.timezone) ? errors.timezone : ""}
                                                    className={classes.select}
                                                    classes={{ icon: classes.arrowIcon }}
                                                    IconComponent={SvgArrow}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                />
                                            </div>

                                            <div className={classes.modalActions}>
                                                <Button
                                                    title="close edit"
                                                    onClick={handleClose}
                                                    onMouseUp={handleClose}
                                                    white
                                                    uppercase
                                                    width={150}
                                                    marginRight
                                                >
                                                    {t`Cancel`}
                                                </Button>
                                                <Button
                                                    title="save site"
                                                    uppercase
                                                    width={150}
                                                    onClick={handleSubmit}
                                                    onMouseUp={handleSubmit}
                                                >
                                                    {t`Save`}
                                                </Button>
                                            </div>

                                        </form>
                                    );
                                }}
                            </Formik>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div>
    );
}
