import React, { useState } from 'react';
import { FieldProps } from 'formik';
import { TextFieldProps } from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import useStyles from './UserDetailsField.style';

const PhoneField: React.FC<FieldProps & TextFieldProps> = ({ field, meta, form, ...props }) => {
  const [errorText, setErrorText] = useState<string>('');
  const [phone, setPhone] = useState('');

  const classes = useStyles();
  return (
    <MuiPhoneNumber
      defaultCountry={'us'}
      error={props.error}
      helperText={props.helperText}
      {...field}
      {...props}
      variant="filled"
      margin="normal"
      FormHelperTextProps={{
        classes: {
          contained: classes.helperTextStyle
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          shrink: classes.shrinkStyle
        }
      }}
      InputProps={{
        classes: {
          input: classes.inputStyle,
          root: classes.rootStyle
        }
      }}
    />
  );
};

export default PhoneField;
