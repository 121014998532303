import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // width: '85%',
    height: "100%",
    padding: "0 20px",
    paddingBottom: "20px",
    background: "#fff",
    display: "flex",
    borderRadius: "4px"
  },
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  paginationHeader: {
    overflow: "hidden"
  },
  tableMenu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableHeadCell: {
    border: "none",
    padding: "0 15px",
    fontSize: "13px",
    color: "#79797d",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#efeff2",
    height: 45
  },
  tableCell: {
  fontWeight: "normal",
  lineHeight: "normal",
  letterSpacing: "normal", padding: "0 15px", fontSize: "13px", height: "35px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }
, grayCheckbox: {
  "&$checked": {
    color: "#736973"
  }},
  itemButton: {
    display: "flex",
    alignItems: "center"
  },
  iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
}));

export default useStyles;
