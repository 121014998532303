import {
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import { Add, FolderOpen } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { Close } from "../../icons";
import { IDevice } from "../../models/Devices";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ManuallySetSystems from "./Device/ManuallySetSystems";
import DeviceCard from "./DeviceCard";
import ExportExcel from "./ExportExcel";
import ImportExcel from "./ImportExcel";
import Site from "./Site";
import useStyles from "./SiteView.style";
import SystemsView from "./SystemsView";

export default (function SiteView(props: any) {
  const { openFoldersDialog, site } = props;
  const classes = useStyles();
  const [isNew, setNew] = React.useState<string>("");
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const deleteSite = useStoreActions((action) => action.deleteSite);
  const updateSite = useStoreActions((action) => action.updateSite);
  const allPowerMeters = useStoreState((state) => state.powerMeters.allPowerMeters);

  if (_.isUndefined(site)) {
    return null;
  }

  const siteDevices = Object.values(allDevices).filter((device) => device.site === site.id);

  const createNewSystem = (device: IDevice) => {
    return (
      <Dialog
        onClose={_.noop}
        aria-labelledby="simple-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Add New System`}</Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => setNew("")}><Close color="#7f7692" /></IconButton>
        </div>
        <div className={classes.dialogContent}>
          <ManuallySetSystems
            mode={1}
            closeModal={() => setNew("")}
            site={site}
            deviceId={device ? device.id : ""}
          />
        </div>
      </Dialog>
    );
  };

  const updateSelectedSite = (updatedSite: any) => {
    const updatedData: any = {
      name: updatedSite.name,
      country: updatedSite.country,
      city: updatedSite.city,
      state: updatedSite.state,
      address: updatedSite.address,
      postalCode: updatedSite.postalCode,
      timezone: updatedSite.timezone
    };
    const newAddress = "" + updatedSite.country + updatedSite.city + updatedSite.state + updatedSite.address + updatedSite.postalCode;
    const prevAddress = "" + site?.country + site?.city + site?.state + site?.address + site?.postalCode;
    if (newAddress !== prevAddress) {
      updatedData.lat = 0;
      updatedData.lon = 0;
    }
    updateSite({ siteId: site.id, updatedData }).catch((err: any) => { });
  };

  return (
    <>
      {/* Site info bar   */}
      <Grid item xs={12}>
        <Card
          elevation={0}
          className={classes.siteBar}
          style={{ backgroundColor: "rgb(246, 245, 246, 0.5)" }}
        >
          <div style={{ margin: "5px 0" }}>
            <Typography className={classes.siteNameStyle}>{site.name}</Typography>
            <Typography className={classes.siteAddressStyle}>
              {`${site.address && site.address}${site.city && ", " + site.city}${(site.country === "US" && site.state) ?
                ", " + site.state : ""}${site.country !== "US" ? ", " + site.country : ""
                }${site.postalCode && ", " + site.postalCode}`}
            </Typography>
          </div>
          <div className={classes.siteActionsContainer}>

            <Button
              disableRipple
              variant="contained"
              color="default"
              onMouseUp={() => props.openAddDevice(site.id)}
              style={{ margin: "0 1rem 0 1rem", boxShadow: "0" }}
              classes={{ contained: classes.addDeviceBtn }}
            >
              {t`+ Add Device`}
            </Button>
            {/* </div > */}
            <div className={clsx(classes.siteActions, classes.smallActionBox)}>
              <Tooltip arrow title={t`file library`}>
                <IconButton disableRipple onClick={() => openFoldersDialog(site.id)} className={classes.iconBtnStyle}>
                  <FolderOpen className={classes.folderIcon} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.siteActions}>
              <ImportExcel site={site} />
              <ExportExcel site={site} />
            </div>

            <div className={classes.siteActions}>
              <Site site={site} save={updateSelectedSite} />
              <Delete
                type={t`Site`}
                object={site}
                detach={deleteSite}
                className={classes.deleteSite}
                buttonClass={classes.iconButtonStyle}
              />
            </div>
          </div>

        </Card>
      </Grid >
      <Grid item xs={12}>
        {/* Device Card*/}
        {siteDevices.map((device: IDevice) => {
          return (
            <Card elevation={0} key={`device-${device.id}`} className={classes.card}>
              <DeviceCard device={device} allPowerMeters={allPowerMeters || []} />
              {/* Systems card  */}
              <Grid item xs={10} className={classes.systemsContainer}>
                <SystemsView deviceId={device.id} allPowerMeters={allPowerMeters || []} />
                <Grid
                  item
                  // xs={1}
                  className={classes.addSystemContainer}
                  onClick={() => setNew(device.id)}
                >
                  <Button disableRipple className={classes.addNewSystem}>
                    <Add className={classes.addIcon} />
                  </Button>
                </Grid>
              </Grid>
              {isNew === device.id && createNewSystem(device)}
            </Card>
          );
        })}
      </Grid>
    </>
  );
});
