import { Card, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  OnGrey as SvgPowerOff,
  OnWhite as SvgPowerOn
} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import ModeMenu from "../ModeMenu/ModeMenu";
import useStyles from "./UnitControl.style";

interface IProps {
  unit: IUnit;
  temperatureScale: number | undefined;
  device: any;
}
interface IObject {
  [key: string]: any;
}

export default function UnitControl(props: IProps) {
  const classes = useStyles();

  const unitProp = props.unit;
  const { device } = props;
  const setActiveSetpoint = useStoreActions((action) => action.units.setActiveSetpoint);
  const unitTogglePower = useStoreActions((action) => action.units.togglePower);
  const setActiveOperationMode = useStoreActions((action) => action.units.setActiveOperationMode);
  const userTemperatureScale = useStoreState((s) => s.users.me.temperatureScale || 1);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const serviceTypes = useStoreState((s) => s.types);
  const unit = allUnits[unitProp.id];

  const defaultMin = 0;
  const defaultMax = 100;
  const [minValue, setMinValue] = useState<number>(defaultMin);
  const [maxValue, setMaxValue] = useState<number>(defaultMax);
  const [unitTemp, setUnitTemp] = useState<any>(0);
  const [timer, setTimer] = useState<any>();
  const [changeTemp, setChangeTemp] = useState<boolean>(false);
  const {
    activeOperationStatus: unitOperationStatus,
    activeSetpoint,
    supportedOperationModes: supportedModes,
    enableCoolMode,
    enableHeatMode,
    enableAutoMode,
    activeOperationMode,
    temperatureLimits,
    enableSetpoint,
    enableMode,
    enableOnState,
    enableOnoff
  } = unit;

  const enabledMode: IObject = {
    0: enableCoolMode,
    1: enableHeatMode,
    2: enableAutoMode
  };

  useEffect(() => {
    setUnitTemp(activeSetpoint);
  }, [activeSetpoint]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    if (changeTemp) {
      let newTimer: NodeJS.Timeout = setTimeout(() => {
        setActiveSetpoint({ id: unit.id, setpoint: Number(unitTemp) });
      }, 1000);
      setTimer(newTimer);
      setChangeTemp(false);
    }
  }, [unitTemp]);

  useEffect(() => {

    if (!enabledMode[activeOperationMode]){
      setMinValue(defaultMin);
      setMaxValue(defaultMax);
    }else{

      setMinValue(temperatureLimits[activeOperationMode][0] || defaultMin);
      setMaxValue(temperatureLimits[activeOperationMode][1] || defaultMax);

      if (activeSetpoint && activeSetpoint > maxValue){
        setActiveSetpoint({ id: unit.id, setpoint: Number(maxValue) });
      }
      if (activeSetpoint && activeSetpoint < minValue){
        setActiveSetpoint({ id: unit.id, setpoint: Number(minValue) });
      }
    }
  }, [unit, temperatureLimits, activeSetpoint, activeOperationMode, enableCoolMode, enableHeatMode, enableAutoMode]);

  const temperatureChange = (num: any) => {
    setChangeTemp(true);
    if (unitTemp + num <= maxValue && unitTemp + num >= minValue){
      setUnitTemp(unitTemp + num);
    }
  };

  const togglePower = () => {
    // setUnitOperationStatus(toggleStates[unitOperationStatus]);
    unitTogglePower({
      id: unit.id,
      activeOperationStatus: unit.activeOperationStatus
    });
    // setStatsUpdate(true);
  };
  const switchMode = (mode: any) => {
    setActiveOperationMode({ id: unit.id, mode });
    // setStatsUpdate(true);
  };

  return (
    <Card className={!unit.isConnected ? clsx(classes.card, classes.disableClicks) : classes.card}>
      <div className={classes.controlContainer}>
        <div className={clsx(classes.controlWrapper, {[classes.disableClick]: !device.isConnected || enableSetpoint})}  onClick={() => temperatureChange(1)}>
          <i className={clsx(`fas fa-chevron-up`, classes.chevron)} />
        </div>
          <Typography className={classes.Number}>
            <span>{unitTemp}</span>
            <span className={classes.temperatureScale}>
              {userTemperatureScale === 1 ? "°C" : "°F"}
            </span>
          </Typography>
          <div className={clsx(classes.controlWrapper, {[classes.disableClick]: !device.isConnected || enableSetpoint})} onClick={() => temperatureChange(-1)}>
            <i className={clsx(`fas fa-chevron-down`, classes.chevron)} />
          </div>
      </div>
      <div className={classes.controlContainer}>
        <ModeMenu
          onClick={switchMode}
          unit={unit}
          supportedModes={supportedModes}
          serviceTypes={serviceTypes}
          disabled={!device.isConnected || enableMode}
        />
      </div>
      <div className={classes.controlContainer}>
        <IconButton disabled={!device.isConnected || enableOnoff || (unitOperationStatus === 2 && enableOnState)} onClick={togglePower} className={clsx(classes.powerButton)}>
          {unitOperationStatus === 1 ? (
            <div className={classes.powerOn}>
              <SvgPowerOn transform="scale(1.5)" className={classes.powerButtonSVG} />
            </div>
          ) : (
              <div className={classes.powerOff}>
                <SvgPowerOff transform="scale(1.5)" className={classes.powerButtonSVG} />
              </div>
            )}
        </IconButton>
        {/* <IconButton onClick={togglePower} className={clsx(classes.powerButton, unit.activeOperationStatus === 1 ? classes.powerOn : classes.powerOff)}>
          {unit.activeOperationStatus === 1 ? (
            <SvgPowerOff transform='scale(1.5)' />
          ) : (
              <SvgPowerOn transform='scale(1.5)' />
            )}
        </IconButton> */}
      </div>
    </Card>
  );
}
