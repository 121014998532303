import { Action, action, actionOn, ActionOn, computed, Computed, debug, memo, Thunk, thunk } from "easy-peasy";
import _, { split } from "lodash";
import { stateList } from "../screens/SiteManagement/stateList";
import { ICustomer } from "./Customers";
import { IRootStoreModel } from "./RootStore";
import { ISite } from "./Sites";
import { ISystem } from "./Systems";
import { IUnit } from "./Units";

export interface ISelections {
  customerId: string | null;
  siteId: string | null;
  systemId: string | null;
  unitId: string | null;
  lastSelectedUnitId: string | null;
  dateRange: IDateRange | null; // null - no date range selected
  isFromMap: boolean;
  deviceId: string | null;
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

interface ISelection {
  type: "customer" | "site" | "system" | "unit" | "device" | "time" | "alertClickType";
  data: any; //string | IDateRange | null;
}

export interface ISelectionsModel {
  selections: ISelections;
  updateSelections: Thunk<ISelectionsModel, ISelection, any>;
  setSelections: Action<ISelectionsModel, Partial<ISelections>>;
  getCurrentSelection: Computed<ISelectionsModel>;
  getCustomersBySelection: Computed<ISelectionsModel, ICustomer[], IRootStoreModel>;
  getSitesBySelection: Computed<ISelectionsModel, ISite[], IRootStoreModel>;
  getSitesIdsBySelection: Computed<ISelectionsModel, string[], IRootStoreModel>;

  getSystemsBySelection: Computed<ISelectionsModel, any[], IRootStoreModel>;
  getUnitsBySelection: Computed<
    ISelectionsModel,
    (type: "indoor" | "outdoor" | "control" | "bsBox" | "other", searchText?: string) => IUnit[],
    IRootStoreModel
  >;
  getControlUnitsBySite: Computed<ISelectionsModel, (siteId: string, storedUnits: any) => any, IRootStoreModel>;
}

export const selectionsModel: ISelectionsModel = {
  selections: {
    customerId: null,
    siteId: null,
    systemId: null,
    unitId: null,
    lastSelectedUnitId: null,
    dateRange: null,
    isFromMap: false,
    deviceId: null
  },
  updateSelections: thunk((actions, payload, { getStoreState }) => {

    if (payload.type === "time") {
      actions.setSelections({ dateRange: payload.data as IDateRange });
      return;
    }

    let newSelection: any = {
      customerId: null,
      siteId: null,
      systemId: null,
      unitId: null,
      deviceId: null
    };

    const rootState: any = getStoreState();
    const oldSelections = rootState.selections.selections;

    switch (payload.type) {

      case "unit":
        const unit = rootState.units.allUnits[payload.data];
        if (!_.isEmpty(unit)) {
          newSelection.customerId = unit.customer ? unit.customer : oldSelections.customerId;
          newSelection.siteId = unit.site ? unit.site : oldSelections.siteId;
          newSelection.systemId = unit.system ? unit.system : oldSelections.systemId;
          newSelection.unitId = payload.data;
        }
        else {
          newSelection = { ...oldSelections, unitId: null };
        }
        break;
      case "system":
        const sys = rootState.systems.allSystems[payload.data];
        if (!_.isEmpty(sys)) {
          newSelection.customerId = sys.customer ? sys.customer : oldSelections.customerId;
          newSelection.siteId = sys.site ? sys.site : oldSelections.siteId;
          newSelection.systemId = payload.data;
        }
        else {
          const unassignedUnits: any = Object.values(rootState.units.allUnits).filter(
            (unit: any) => _.isNil(unit.system)
          );
          let systems: any = {};
          for (let unit of unassignedUnits) {
            if (!systems[unit.line + "_" + unit.device]) {
              systems[unit.line + "_" + unit.device] = [unit];
            }
            else {
              systems[unit.line + "_" + unit.device].push(unit);
            }
          }
          if (systems[payload.data]) {
            newSelection.siteId = systems[payload.data][0].site ? systems[payload.data][0].site : oldSelections.siteId;
            const site = rootState.sites.allSites[newSelection.siteId];
            newSelection.customerId = site ? site.customer : oldSelections.customerId;
            newSelection.systemId = payload.data;
          }
          else {
            newSelection = { ...oldSelections, unitId: null, systemId: null };

          }
        }
        break;
      case "site":
        const site = rootState.sites.allSites[payload.data];
        if (!_.isEmpty(site)) {
          newSelection.customerId = site.customer ? site.customer : oldSelections.siteId;
          newSelection.siteId = payload.data;
        }
        else {
          newSelection = { ...oldSelections, unitId: null, systemId: null, siteId: null };
        }
        break;
      case "customer":
        newSelection.customerId = payload.data;
        break;
      default:
        break;

    }

    actions.setSelections(newSelection);
  }),

  setSelections: action((state, payload) => {
    state.selections = { ...state.selections, ...payload };
  }),

  getCurrentSelection: computed([(state) => state.selections], (selections) => {
    if (selections.unitId) {
      return { type: "unitId", id: selections.unitId };
    }
    if (selections.systemId) {
      return { type: "systemId", id: selections.systemId };
    }
    if (selections.siteId) {
      return { type: "siteId", id: selections.siteId };
    }
    if (selections.customerId) {
      return { type: "customerId", id: selections.customerId };
    } else {
      return { type: "customerId", id: "all" };
    }
  }),

  getCustomersBySelection: computed(
    [(state) => state.selections, (state, storeState) => storeState.customers.allCustomers],
    (selections, allCustomers) => {
      return _.orderBy(
        Object.values(allCustomers),
        [(customer) => customer.name?.toUpperCase()],
        ["asc"]
      );
    }),

  getSitesBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.allCustomers
    ],
    (selections, allSites, allCustomers) => {
      return _.orderBy(
        Object.values(allSites).filter((site) =>
          selections.customerId
            ? _.includes(allCustomers[selections.customerId].sites, site.id)
            : true
        ),
        [(site) => site.name?.toUpperCase()],
        ["asc"]
      );
    }),
  getSitesIdsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.allCustomers
    ],
    (selections, allSites, allCustomers) => {

      const sitesIds: any = [];
      Object.values(allSites).forEach((site: any) => selections.customerId === site.customer && sitesIds.push(site.id));

      return _.orderBy(sitesIds,
        [(siteId) => allSites[siteId]?.name?.toUpperCase()],
        ["asc"]
      );

    }),
  getSystemsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.customers.allCustomers,
      (state, storeState) => storeState.units.allUnits

    ],
    (selections, allSites, allSystems, allDevices, allCustomers, allUnits) => {

      return _.orderBy(
        Object.values(allSystems).filter((system) => {
          const systemDevice = allDevices[system.device];
          if (_.isUndefined(systemDevice)) { return false; }
          const systemSite = allSites[systemDevice.site];
          if (_.isUndefined(systemSite)) { return false; }
          const systemCustomerId = systemSite.customer;

          return (
            (selections.customerId ? systemCustomerId === selections.customerId : true) &&
            (selections.siteId ? systemSite.id === selections.siteId : true)
          );

        }),
        [(system) => system.name?.toUpperCase()],
        ["asc"]
      );
    }),

  getUnitsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites
    ],
    (selections, allUnits, allDevices, allSites) => (type, searchText = "") => {
      const filterType = type === "indoor" ? 3 : (type === "bsBox" ? 4 : type === "control" ? 1 : type === "other" ? 5 : 2);

      return Object.values(allUnits).filter((unit: any) => {
        if (!unit.isVisible) {
          return false;
        }

        const unitDevice = allDevices[unit.device];
        if (_.isUndefined(unitDevice)) { return false; }
        const unitSite = allSites[unitDevice.site];
        if (_.isUndefined(unitSite)) { return false; }
        const unitCustomerId = unitSite.customer;
        if (_.isUndefined(unitCustomerId)) { return false; }
        return (
          unit.type === filterType &&
          (selections.customerId ? unitCustomerId === selections.customerId : true) &&
          (selections.siteId ? unitSite.id === selections.siteId : true) &&
          (selections.systemId ? (unit.system === selections.systemId) : true) &&
          (searchText && searchText.length ? unit.name.toLowerCase().includes(searchText) : true) &&
          (type === "control" ? (!unit?.serviceUnits?.length) : true)
        );
      });
    }
  ),
  getControlUnitsBySite: computed(
    [
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.types
    ],
    (allUnits, allDevices, allSites, types) => (siteId: any, storedUnits: any = new Map()) => {
      const { unitTypes = {} } = types;
      let siteUnits: any = new Map();

      Object.values(allUnits).forEach((unit: any, index: number) => {
        if (!unit.site || (unit.type !== unitTypes.indoor && unit.type !== unitTypes.sensor) || unit.site !== siteId || !unit.isVisible) {
          return;
        }

        const isRelatedServiceUnitHidden = (unit.serviceUnits || []).some((unitId: string) =>
          !allUnits[unitId]?.isVisible);

        if (isRelatedServiceUnitHidden) {
          return;
        }

        siteUnits.set(unit.id, true);
        storedUnits.set(unit.id, true);

      });

      Array.from(storedUnits).forEach((unit: any) => {
        const fullUnit = allUnits[unit[0]];
        if (!fullUnit || !siteUnits.has(unit[0]) || !allUnits[unit[0]]?.isVisible) {
          storedUnits.delete(unit[0]);
        }

        if (fullUnit) {
          const isRelatedServiceUnitHidden = (fullUnit.serviceUnits || []).some((unitId: string) =>
            !allUnits[unitId]?.isVisible);
          if (isRelatedServiceUnitHidden) {
            storedUnits.delete(unit[0]);
          }
        }
      });

      return Array.from(storedUnits);
    }
  )
};
