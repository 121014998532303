import { makeStyles, withStyles } from "@material-ui/core";
import Slider, { Mark } from "@material-ui/core/Slider";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";

// TODO: Use JS styles instead.

const useStyles = makeStyles((theme) => ({
  thumbValue: {
    color: "rgb(75, 28, 70)",
    marginTop: "-5px",
    fontSize: "1.3rem",
    zIndex: 1
    // fontWeight: 900
  },

  thumbSelection: {
    color: "rgb(240, 81, 70)",
    marginTop: "-2px",
    fontSize: "1.7rem",
    opacity: "0.7",
    fontWeight: 100
  },
  popper: {
    marginBottom: 6
  },

  thumb: {
    height: 0,
    width: 0,
    marginLeft: 1
  },
  rail: {
    height: 0,
    opacity: 0.5,
    backgroundColor: "#bfbfbf"
  },
  marked: {
    margin: "0.6rem 0.7rem 0.7rem 0"
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 5,
    width: 1,
    marginTop: -3,
    fontWeight: 100
  }
}));

interface IUnitStatsSlider {
  data: any;
  index?: string;
}

const UnitStatsSlider: React.FC<IUnitStatsSlider> = ({
  data: { slider, code, value, name },
  index
}) => {
  const classes = useStyles();
  const {
    thumb,
    rail,
    marked,
    mark
  } = classes;

  if (slider.max === slider.min) {
    return <></>;
  }

  // Prepare sane ranges
  const sliderMin =
    slider.selectionMin < slider.min ? Math.round(slider.selectionMin / 1.2) : slider.min;
  const sliderMax =
    slider.selectionMax > slider.max ? Math.round(slider.selectionMax * 1.2) : slider.max;

  // Generate marks.
  const steps = 20;
  const stepLength = (sliderMax - sliderMin) / steps;
  let marks: any = [];

  marks.push({
    key: "minValMark",
    value: sliderMin,
    label: sliderMin
  });
  for (let i = 1; i < steps; i++) {
    marks.push({
      label: "",
      value: sliderMin + i * stepLength
    });
  }
  marks.push({
    key: "maxValMark",
    value: sliderMax,
    label: sliderMax
  });

  const TriangleThumbComponent = (props: any) => {
    let whoAmI = 1; // 0 - left bar | 1 - value marker | 2 - right bar.

    // name === 'Compressor Frequency' && //console.log('props :', props, value, slider);

    // Special handling for overlapping values.
    if (
      (slider.value === slider.selectionMin || slider.value === slider.selectionMax) &&
      props["data-index"] === 1
    ) {
      whoAmI = 1;
    } else if (props["aria-valuenow"] === slider.selectionMin) {
      whoAmI = 0;
    } else if (props["aria-valuenow"] === slider.selectionMax) {
      whoAmI = 2;
    }

    if (whoAmI === 1) {
      return (
        <Tooltip arrow key={`tt-${index}`} title={slider.value} placement="top" classes={{ popper: classes.popper }}>
          <span {...props} className={clsx(props.className, classes.thumbValue)}>
            &#9660;
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          arrow
          key={`tt2-${index}`}
          title={whoAmI === 0 ? slider.selectionMin : slider.selectionMax}
          placement="top"
          classes={{ popper: classes.popper }}
        >
          <span {...props} className={clsx(props.className, classes.thumbSelection)}>
            |
          </span>
        </Tooltip>
      );
    }
  };

  return (
    <Slider
      key={`sliderChild-${index}`}
      classes={{
        thumb,
        rail,
        marked,
        mark
      }}
      track={false}
      aria-labelledby="track-false-range-slider"
      min={sliderMin}
      max={sliderMax}
      value={[slider.selectionMin, slider.value, slider.selectionMax]}
      defaultValue={[slider.selectionMin, slider.value, slider.selectionMax]}
      // valueLabelDisplay="on"
      marks={marks}
      step={stepLength}
      ThumbComponent={TriangleThumbComponent}
    />
  );

};

export default UnitStatsSlider;
