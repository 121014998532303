import {
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { t } from "ttag";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import { auditActionMap } from "./AuditsActionMap";
import useStyles from "./AuditsList.style";

const dataPropertiesReplacement: { [key: string]: any } = {
    isRegistered: "Connected",
    isExpired: "Expired"
},
    notReadable = [
        "customer",
        "isAcceptedTOU",
        "_id",
        "__v",
        "user",
        "userLocationsMetadata",
        "password",
        "randId",
        "id",
        "device",
        "admin",
        "lat",
        "lon",
        "fileId"
    ];

export default function AuditsDataPopUp(props: any) {
    const { audit } = props,
        { source, customerName, actionId, date, user, siteName, deviceName, unitName, line, data } = audit,

        { operationStatuses, operationModes, fanModes, swingModes, temperatureScale, actionSources } = useStoreState((s) => s.types),
        classes = useStyles(),
        timeFormat = useStoreState((state) => state.users.timeFormat),

        valueMapping = (value: number, action: string) => {
            const lowerCaseAction = action.toLowerCase();
            if (lowerCaseAction.includes("setpoint")) { return value; }
            if (lowerCaseAction.includes("operationstatus")) { return operationStatuses[value] || value; }
            if (lowerCaseAction.includes("operationmode")) { return operationModes[value] || value; }
            if (lowerCaseAction.includes("fanmode")) { return fanModes[value] || value; }
            if (lowerCaseAction.includes("swingmode")) { return swingModes[value] || value; }
            return value;
        },
        valueTitleMapping = (action: string) => {
            const lowerCaseAction = action.toLowerCase();
            if (lowerCaseAction.includes("setpoint")) { return "Setpoint"; }
            if (lowerCaseAction.includes("operationstatus")) { return "Operation Status"; }
            if (lowerCaseAction.includes("operationmode")) { return "Operation Mode"; }
            if (lowerCaseAction.includes("fanmode")) { return "Fan Mode"; }
            if (lowerCaseAction.includes("swingmode")) { return "Swing Mode"; }
            return "value";
        },
        replaceTitle = (property: string) => {
            const replacement = dataPropertiesReplacement[property];
            if (replacement) { return replacement; }
            const title = property
                .split(/(?=[A-Z])/)
                .join(" ")
                .trim();
            return title;
        },
        getActionText = (actionId: string) => {
            const action: any = auditActionMap[actionId]?.name;
            if (action) {
                return action;
            }
            const actionArray = actionId.split(/(?=[A-Z])/);
            if (["update", "create", "accept", "delete", "remove"].includes(actionArray[0])) {
                actionArray[0] = actionArray[0] + "d";
            }
            if (["add"].includes(actionArray[0])) {
                actionArray[0] = actionArray[0] + "ed";
            }

            actionArray[0] = actionArray[0].charAt(0).toUpperCase() + actionArray[0].substring(1);
            return actionArray.join(" ").trim();
        },
        convertDate = (stringDate: string) => {
          const {timezone} = audit;
          const date = timezone ? moment(stringDate).tz(timezone).format(`ll ${timeFormat}`) :  moment(stringDate).format(`ll ${timeFormat}`);
          return date;
        },
        DataRow = ({ property, content, action, index }: any) => {
            const contentText = typeof content === "boolean" ? (content ? "yes" : "no") : content;

            return (
                <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
                    <Typography className={classes.rowTitle}>
                        {property === "value" ? `${valueTitleMapping(action)}` : `${replaceTitle(property)}`}
                    </Typography>
                    <Typography>
                        {property === "value"
                            ? `${valueMapping(contentText, action)}`
                            : property === "temperatureScale"
                                ? `${temperatureScale[contentText]}`
                                : `${contentText}`}
                    </Typography>
                </div>
            );
        },
        MainDataRow = ({ title, content, index }: any) => (
            <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
                <Typography className={classes.rowTitle}>{`${title}`}</Typography>
                <Typography>{`${content}`}</Typography>
            </div>
        ),
        getTimeFromMins = (mins: number) => {

            const h = mins / 60 | 0,
                m = mins % 60 | 0;
            return moment.utc().hours(h).minutes(m).format("hh:mm A");
        };
    let countedIndex = 0;
    const auditSource = (source: number) => {
        switch (source) {
            case +actionSources.user: return t`Application User`;
            case +actionSources.schedule: return t`Scheduled Operation`;
            case +actionSources.homeAutomation: return t`Home automation / BMS`;
            case +actionSources.hvac: return t`HVAC`;
            default: return t`Unknown source`;
        }
    };

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <div className={classes.titleContainer}>
                        <Typography className={classes.headerTitle}>{t`Audit Data`}</Typography>
                        <IconButton disableRipple onClick={() => props.setIsSeen(null)} className={classes.iconBtnStyle}>
                            <Close color="#7f7692" />
                        </IconButton>
                </div>
                <div className={classes.dialogContent}>
                        <Card className={classes.container}>
                            <MainDataRow title="Source" content={auditSource(+source)} index={1} />
                            <MainDataRow title="Customer" content={customerName} index={0} />
                            <MainDataRow title="Action" content={getActionText(actionId).replace(/Trap/g, "Trigger").replace(/Event/g, "Alert")} index={1} />
                            <MainDataRow title="Date" content={convertDate(date)} index={0} />
                            <MainDataRow title="User" content={user} index={1} />
                            <MainDataRow title="Site" content={siteName} index={0} />
                            <MainDataRow title="Device" content={deviceName} index={1} />
                            <MainDataRow title="Unit" content={unitName} index={0} />
                            {line &&
                                <MainDataRow title="Line" content={line} index={0} />
                            }
                            {data &&
                                Object.keys(data).map((key: any) => {
                                    let content = data[key];
                                    if (
                                        typeof content !== "string" &&
                                        typeof content !== "number" &&
                                        typeof content !== "boolean"
                                    ) {
                                        return null;
                                    }

                                    if (notReadable.includes(key)) {
                                        return null;
                                    }
                                    if (typeof content === "number" && (key === "powerOffTime" || key === "powerOnTime")) {
                                        content = getTimeFromMins(content);
                                    }
                                    countedIndex++;
                                    return (
                                        <DataRow
                                            key={`audit-data-${countedIndex}`}
                                            property={key}
                                            content={content}
                                            action={actionId}
                                            index={countedIndex}
                                        />
                                    );
                                })}
                        </Card>
                </div>
            </Dialog>
        </div>
    );
}
