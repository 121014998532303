import { Alert, AlertTitle } from "@material-ui/lab/";
import { xhr, xhr as SdkXhr } from "coolremote-sdk";
import queryString from "query-string";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation
} from "react-router-dom";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import Dashboard from "../Dashboard/Dashboard";
import DashboardUnit from "../Dashboard/DashboardUnit";
import Impersonate from "../Impersonate/Impersonate";
import Login from "../Login/Login";
import SiteManagement from "../SiteManagement/SiteManagement";
import TrapsList from "../Traps/TrapsList";
import UnitsEdits from "../UnitsEdit/UnitsEdit";
import UserList from "../UserManagement/UserList";

// Non 'JS Styles' CSS imports.
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import "rc-slider/assets/index.css";
import "react-flags-select/css/react-flags-select.css";
import { StaticContext } from "react-router";
import "react-table/react-table.css";
import { ErrorStoreMessage } from "../../components";
import AddRule from "../../components/AddRule";
import { Loader } from "../../components/Loader";
import Loading from "../../components/Loading/Loading";
import Alerts from "../Alerts/Alerts";
import AnomalyTemplatesList from "../AnomalyTemplatesList/AnomalyTemplatesList";
import Audits from "../Audits/AuditsList";
import CommissioningList from "../Commissioning/CommissioningList";
import CommissioningLogs from "../CommissioningLogs/CommissioningLogs";
import Control from "../Control/Control";
import ForgotPassword from "../Login/ForgotPassword";
import ResetPassword from "../Login/ResetPassword";
import ResetPasswordWithToken from "../Login/ResetPasswordWithToken";
import ResetSuccess from "../Login/ResetSuccess";
import AlertSettings from "../Settings/AlertSettings";
import SystemDiagnostics from "../SystemDiagnostics/SystemDiagnostics";

const PrivateRoute: React.FC<React.FC<RouteComponentProps<any, StaticContext, any>> & any> = ({
  children,
  ...rest
}) => {
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const isInitializesd = useStoreState((s) => s.isInitialized);
  const location = useLocation();
  const doLogout = useStoreActions((a) => a.doLogout);
  const startLoader = useStoreActions((a) => a.loader.startLoader);
  const finishLoader = useStoreActions((a) => a.loader.finishLoader);
  const adminLogin = useStoreActions((a) => a.adminLogin);

  const validToken = xhr.getToken();
  const localStorageToken = localStorage.getItem("token");
  const values = queryString.parse(rest.location.search);
  const username: any = values && values.username || "";
  const pathname = rest.location.pathname;

  useEffect(() => {
    if (isInitializesd || !isLoggedIn) {
      finishLoader();
    }
    if (!isInitializesd && isLoggedIn) {
      startLoader();
    }
  }, [isInitializesd, isLoggedIn]);

  useEffect(() => {
    if (pathname === "/dashboard" && username && isLoggedIn && isInitializesd) {
      adminLogin(username).catch(() => {
        doLogout();
        finishLoader();
      });
    }
  }, [isLoggedIn, isInitializesd]);

  if (isInitializesd && (!validToken || !localStorageToken)) {
    doLogout();
    finishLoader();
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    );
  }

  return isLoggedIn ? (
    isInitializesd ? (
      <Route {...rest}>{children}</Route>
    ) : (
      <Loading />
    )
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: { from: location }
      }}
    />
  );
};

export default function Routes() {
  const getUserTree = useStoreActions((action) => action.getUserTree);
  const setLoggedIn = useStoreActions((action) => action.setLoggedIn);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const isLoaderShowing = useStoreState((s) => s.loader.isLoading);
  const finishLoader = useStoreActions((a) => a.loader.finishLoader);
  const initWalkme = useStoreActions((actions) => actions.walkMeInit);
  const messageObj = useStoreState((s) => s.errorMessage.message);
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const setDropDownNavigation = useStoreActions((a) => a.setDropDownNavigation);
  const themeFile = useStoreState((s) => s.theme);
  const prepareTheme = useStoreActions((a) => a.prepareTheme);

  initWalkme();
  const initialize = async () => {
    const token = localStorage.getItem("token");
    if (isLoggedIn && token) {
      SdkXhr.setToken(token);

      try {
        await getUserTree();
      } catch (error) {
        setLoggedIn(false);
        finishLoader();
      }
    } else {
      setLoggedIn(false);
      finishLoader();
    }
  };

  useEffect(() => {
    if (!themeFile) {
      prepareTheme();
    }
  }, [themeFile]);

  useEffect(() => {
    if (window.location.pathname.includes("commissioning")) { setDropDownNavigation("commissioning"); }

  }, []);

  useEffect(() => {
    initialize();
  }, [isLoggedIn]);

  // if (!isInitializesd) return <Loading />;
  const unitUpdateStatusUpdate = (id: string) => {
    setUnitUpdateStatus({ status: id ?? "" });
  };

  if (!themeFile) {
    return <></>;
  }

  const theme = createMuiTheme(themeFile);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Loader showLoader={isLoaderShowing} />

        <Router>
          <Switch>
            <Route exact={true} path="/forgot-password/" component={ForgotPassword} />
            <Route exact={true} path="/reset-password" component={ResetPassword} />
            <Route exact={true} path="/reset-password/:token" component={ResetPasswordWithToken} />
            <Route exact={true} path="/reset-success" component={ResetSuccess} />
            <Route exact={true} path="/impersonate/:token" component={Impersonate} />

            <Route exact={true} path="/" component={Login} />
            <PrivateRoute
              exact
              path="/dashboard"
            >
              <Dashboard />
            </PrivateRoute>
            {displayFlags.enableControl && <PrivateRoute exact path="/control">
              <Control unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
            </PrivateRoute>}

            {displayFlags.enableScripts && <PrivateRoute exact path="/commissioning-prodecuders">
              <CommissioningList />
            </PrivateRoute>}
            {displayFlags.enableScripts && <PrivateRoute exact path="/commissioning-logs">
              <CommissioningLogs />
            </PrivateRoute>}

            <PrivateRoute
              exact
              path="/system-diagnostics/:systemId?"
            >
              <SystemDiagnostics />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/unit-stats/:unitId"
            >
              <DashboardUnit unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
            </PrivateRoute>
            <PrivateRoute exact path="/unit-stats/">
              <DashboardUnit />
            </PrivateRoute>

            {displayFlags.showTrapTemplates && <PrivateRoute exact path="/anomaly-templates-settings">
              <AnomalyTemplatesList />
            </PrivateRoute>}

            {displayFlags.enableSiteManagement && <PrivateRoute
              exact
              path="/site-management"
            >
              <SiteManagement unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
            </PrivateRoute>}

            {displayFlags.enableUserManagement && <PrivateRoute
              exact
              path="/user"
            >
              <UserList />
            </PrivateRoute>}

            {displayFlags.enableSettings && <PrivateRoute
              exact
              path="/settings/alerts"
            >
              <AlertSettings />
            </PrivateRoute>}

            {displayFlags.enableAnomalies && <PrivateRoute exact path="/anomalies-settings">
              <TrapsList />
            </PrivateRoute>}

            <PrivateRoute exact path="/alerts">
              <Alerts />
            </PrivateRoute>
            <PrivateRoute exact path="/audits">
              <Audits />
            </PrivateRoute>
            <PrivateRoute exact path="/edit-units">
              <UnitsEdits />
            </PrivateRoute>
            <Redirect to="/" />
          </Switch>
        </Router>
        {messageObj.message && (<ErrorStoreMessage message={messageObj} />)}
      </div>
    </MuiThemeProvider>
  );
}
