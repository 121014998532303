import { fade, makeStyles, Theme } from "@material-ui/core";
import { BorderRight } from "@material-ui/icons";
import { createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noteContainer: {
            height: "125px",
            boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
            border: "solid 1px #d5d2d5",
            backgroundColor: "#fefefe",
            borderRadius: 8,
            marginBottom: 9,
            padding: 16
        },
        supportDataSection: {
            width: "100%",
            height: "30px",
            // padding: "3px 10px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between"
        },
        noteBox: {
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            "& *": {
                fontSize: "22px"
            }
        },
        deleteButton: {
            width: "20px",
            height: "20px",
            alignSelf: "flex-end"
        },
        confirmDelete: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundSize: "cover",
            backdropFilter: "blur(6px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        confirmButtonsDiv: {
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center"
        },
        lightData: {
            fontSize: 16,
            lineHeight: 1.69,
            letterSpacing: "normal",
            color: "#545964",
            marginLeft: 14
        },
        username: {
            opacity: 0.89,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "normal",
            color: "#29132e",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "20ch"
        },
        noteMsg: {
          fontSize: 14,
          lineHeight: 1.71,
          letterSpacing: "normal",
          color: "#545964"
        },
        iconBtnStyle: {
          width: 30,
          height: 30,
          borderRadius: 6,
          padding: 0
        }
    })
);

export default useStyles;
