import { TableContainer, TableFooter, TablePagination } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Alert as SvgAlert } from "../../icons/";
import { useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import assetsService from "../../services/assetsService";
import { systemTableStyle } from "./SystemTable.style";

const columns = [
  { id: "name", label: t`NAME`, minWidth: true /* minWidth: 18ch*/ },
  { id: "hasAlerts", label: t`ALERTS` /* minWidth: 100*/ },
  { id: "brand", label: t`SYSTEM`, minWidth: true /* minWidth: 18ch*/ },
  { id: "series", label: t`SERIES` /* minWidth: 170*/ },
  { id: "capacity", label: t`CAPACITY` /* minWidth: 170*/ },
  { id: "outdoor", label: t`ODU`, minWidth: true /* minWidth: 18ch*/ },
  { id: "indoor", label: t`IDU`, minWidth: true /* minWidth: 18ch*/ },
  { id: "siteName", label: t`SITE`, minWidth: true /* minWidth: 18ch*/ },
  { id: "filler", lavel: "" /* minWidth: 170*/ }
];
interface IData {
  id: string;
  name: string;
  siteName: string;
  indoor: number;
  outdoor: number;
  brand: number;
  series: string;
  hasAlerts: boolean;
  capacityMeasurementUnits: any;
  capacity: any;
}
interface IProps {
  onSystemClick: (systemId: string) => void;
  classes?: any;
}
const SystemTable: React.FC<IProps> = (props: IProps) => {
  const [counts, setCounts] = useState<any>({ outdoors: 0, indoors: 0 });
  const [rows, setRows] = useState<any>([]);

  const { classes } = props,
    { alertIcon, tableWrapper, tableCell, tableRow, brandLogo } = classes,
    getUnitsBy = useStoreState((state) => state.units.getUnitsBy),
    selectedSystems = useStoreState((s) => s.selections.getSystemsBySelection),
    allSites = useStoreState((state) => state.sites.allSites),
    allDevices = useStoreState((state) => state.devices.allDevices);
  const getAlertsByFilter = useStoreState((state) => state.alerts.getAlertsByFilter);
  const capacityMeasurementUnitTypes = _.invertBy(
    useStoreState((state) => state.types.capacityMeasurementUnitTypes)
  );

  useEffect(() => {
    const tempRows: any = [];
    let tempIndoor = 0;
    let tempOutdoor = 0;
    selectedSystems.forEach((system: ISystem) => {
      const { id } = system,
        outdoor = getUnitsBy("system", id, { type: "outdoor" }).length,
        indoor = [...getUnitsBy("system", id, { type: "indoor" }), ...getUnitsBy("system", id, { type: "other" })].length,
        name = system.name ? system.name : "",
        brand = system.brandNum ? system.brandNum : 999,
        series = system.series ? system.series : "",
        siteId = allDevices[system.device].site,
        siteName = allSites[siteId].name,
        hasAlerts = !!getAlertsByFilter({ systemId: system.id }).length,
        capacityMeasurementUnits = (system.capacity && system.capacityMeasurementUnits) ? capacityMeasurementUnitTypes[system.capacityMeasurementUnits] : "",
        capacity = system.capacity ? system.capacity : "";
      tempRows.push({ id, name, siteName, indoor, outdoor, brand, series, hasAlerts, capacityMeasurementUnits, capacity });
      tempIndoor += indoor;
      tempOutdoor += outdoor;
    });
    setRows(tempRows);
    setCounts({ outdoors: tempOutdoor, indoors: tempIndoor });
  }, [selectedSystems]);

  const [page, setPage] = React.useState(0),
    [rowsPerPage, setRowsPerPage] = React.useState(10),
    handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage = (event: { target: { value: React.ReactText } }) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

  return (
    <div className={classes.pageContainer}>
      {false && rows.length > 10 /* rowsPerPage*/ && (
        <TableFooter className={classes.systemTableFooter}>
          <TableRow className={classes.removeBottomBorder}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              colSpan={7}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page"
              }}
              nextIconButtonProps={{
                "aria-label": "next page"
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      )}
      <TableContainer className={tableWrapper}>
        <Table stickyHeader aria-label="a dense table">
          <colgroup>
            <col style={{ width: "150px" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "150px" }} />

          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column: any, index) => (
                <TableCell
                  key={index}
                  // className={tableCell}
                  align={column.id === "name" || "siteName" ? "left" : "center"}
                  classes={{ root: classes.tableHeadCell }}
                >
                  {column.label === "ALERTS" ? "" : column.label}
                  {column.label === "SYSTEM" ? ` (${rows.length})` : ""}
                  {column.label === "OUTDOORS" ? ` (${counts.outdoors})` : ""}
                  {column.label === "INDOORS" ? ` (${counts.indoors})` : ""}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => props.onSystemClick(row.id)}
                  className={tableRow}
                >
                  {columns.map((column: any, index) => {
                    const rowVal = row[column.id],
                      value =
                        column.id === "brand" ? (
                          <img
                            width={60}
                            height={25}
                            src={assetsService.getBrandLogo(`${rowVal}-grey.svg`)}
                            onError={(event: any) =>
                              (event.target.src = assetsService.getBrandLogo("empty.png"))
                            }
                            alt={rowVal}
                            className={brandLogo}
                          />
                        ) : column.label === "ALERTS" && row.hasAlerts ? (
                          <SvgAlert className={alertIcon} />
                        ) : (
                              column.label === "CAPACITY" ? `${rowVal} ${row.capacityMeasurementUnits}` :
                                rowVal
                            );
                    return (
                      <TableCell
                        size="small"
                        key={index}
                        className={clsx(tableCell, { [classes.minWidthStyle]: column.minWidth })}
                        align={column.id === "name" || "siteName" ? "left" : "center"}
                      >
                        {column.format && typeof value === "number" ? column.format(value) :
                          (column.minWidth && value && value.length > 30 ? value.substr(0, 30) + "\u2026"
                            : value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default withStyles(systemTableStyle)(SystemTable);
