import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  item: {
    color: "black",
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "50px",
    position: "relative",
    padding: "0.5rem",
    fontSize: "30px"
  },

  onClick: {
    flexGrow: 9
  },

  title: {
    verticalAlign: "middle",
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "50px",
    position: "relative",
    // padding: "0.5rem",
    fontSize: "1.5rem",
    justifyContent: "center",
    // display: "inline",
    padding: "0 0.5rem"
  },

  collapsibleIcon: {
    fontSize: "1.5rem",
    paddingLeft: "0.75rem"
  },

  showChild: {
    maxHeight: "2rem"
  },

  secondTitle: {
    fontSize: "15px"
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  "@media (max-width: 800px)": {
    item: {
      flexDirection: "column"
    }
  }
});

export default useStyles;
