import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useLoadScript } from "@react-google-maps/api";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useRouteMatch } from "react-router";
import { t } from "ttag";
import { NotesAndAttachments } from "../../components/";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import SystemOverview from "../../components/SystemOverview/SystemOverview";
import TemperatureInfo from "../../components/TemperatureInfo/TemperatureInfo";
import UnitControl from "../../components/UnitControl/UnitControl";
import UnitInfo from "../../components/UnitInfo/UnitInfo";
import UnitStats from "../../components/UnitStats/UnitStats";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnitAffiliation } from "../../models/Units";
import AlthermaUnitSettings from "../../screens/AlthermaUnitSettings/AlthermaUnitSettings";
import { downloadAs } from "../../services/download";
import useStyles from "./DashboardUnit.style";

const DashboardUnit: React.FC<RouteComponentProps<any> & any> = (props) => {
  // Get history from hook
  const history = useHistory(),
    classes = useStyles(),
    // Get unit id from path
    match = useRouteMatch<{ unitId: string }>();
  let { unitId } = match.params;

  if (!unitId || unitId === "undefined") {
    unitId = "";
  }
  const getUnit = useStoreState((s) => s.units.getUnit);
  const getUnitFromStore = useStoreState((s) => s.units.getUnit);
  const getUnitSystemBrand = useStoreState((state) => state.units.getUnitSystemBrand);

  const { customerId = "", deviceId = "", siteId = "", systemId = "" }: IUnitAffiliation | any = useStoreState((s) =>
    s.units.getUnitAffiliation(unitId)
  );
  const unitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const refreshUnit = useStoreActions((a) => a.units.refreshUnit);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const getUnitNotes = useStoreActions((actions) => actions.notes.getUnitNotes);
  const newNote = useStoreActions((actions) => actions.notes.newNote);
  const deleteNote = useStoreActions((actions) => actions.notes.deleteNote);
  const getUnitFiles = useStoreActions((actions) => actions.files.getUnitFiles);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const allSites = useStoreState((state) => state.sites.allSites);
  const unitCustomer = allCustomers[customerId] || null;
  const unitSite = useStoreState((s) => s.sites.getSite(siteId));
  const unitDevice = useStoreState((s) => s.devices.getDevice(deviceId));
  const allAlerts = useStoreState((state) => state.alerts.allAlerts);
  const alerts = useStoreState((state) => state.alerts.parsedAlerts());
  const unitSystem = useStoreState((s) => s.systems.getSystem(systemId));
  const currentUser = useStoreState((s) => s.users.me);
  const selections = useStoreState((s) => s.selections.selections);
  const allUsers = useStoreState((state) => state.users.users);
  const uploadFile = useStoreActions((action) => action.files.uploadFile);
  const updateFilesInfo = useStoreActions((action) => action.files.updateFilesInfo);
  const getUnitParamsAndStats = useStoreActions((a) => a.units.getUnitParamsAndStats);
  const types = useStoreState((state) => state.types);
  const [outdoorTemp, setOutdoorTemp] = useState("--");
  const [reFectUnit, setReFetchUnit] = useState<boolean>(false);
  const [externalTemp, setExternalTemp] = useState<any>(null);
  const [unitAlerts, setUnitAlerts] = useState<any>([]);
  const [unit, setUnit] = useState<any>(null);
  const [brandName, setBrandName] = useState<any>(null);
  const [allObj, setAllObj] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const initializeAlerts = useStoreActions((action) => action.alerts.initialize);
  const [selectedTime, setSelectedTime] = useState<any>({}); //time picker on graph
  const [notesAnch, setNotesAnch] = useState<any>(null);
  const [unitNotes, setUnitNotes] = useState<any>(null);
  const [unitFiles, setUnitFiles] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [outsideAirTemp, setOutsideAirTemp] = useState<any>(null);
  const [remoconRoomTemp, setRemoconRoomTemp] = useState<any>(null);
  const [openAlthermaSettings, setOpenAlthermaSettings] = useState<boolean>(false);
  const { hvacBrands } = useStoreState((state) => state.types);

  const controlUnit = unit?.controlUnit && getUnitFromStore(unit.controlUnit);
  const getBrandName = (value: any) => {
    return hvacBrands?.filter((item) => item.value === value)[0]?.name;
  };
  const { temperatureScale } = currentUser;
  const { eventTypes, unitTypes } = types;

  useEffect(() => {
    if (selections.unitId && !unitId) {
      onUnitSelect(selections.unitId);
      return;
    }

    if (isLoading) {
      return;
    }

    if (unitId && selections.unitId && unitId === selections.unitId) {
      setUnit(getUnit(unitId));

      const brand = getUnitSystemBrand(unitId);
      setBrandName(getBrandName(brand));

      getUnitNotes(unitId)
        .then((notes: any) => {
          setUnitNotes(notes);
        });

      getUnitFiles(unitId)
        .then((files: any) => {
          setUnitFiles(files);
        });

      return;
    }
    setUnit(null);
  }, [unitId, selections.unitId, isLoading]);

  useEffect(() => {

    // const contUnit = getUnit(unit.controlUnit);
    if (unit?.controlUnit) {
      // setControlUnit(contUnit);
      unitUpdateStatus({ status: unit.controlUnit });
      return;
    }
    unitUpdateStatus({ status: "" });
    // setControlUnit(null);
  }, [unit?.controlUnit]);

  useEffect(() => {
    if (unitId !== selections.unitId) {
      updateSelections({ type: "unit", data: unitId });
    }
    // setControlUnit(null);
  }, [unitId]);

  useEffect(() => {
    if (unitId && selections.unitId && unitId === selections.unitId) {
      setUnit(getUnit(unitId));
      return;
    }
    setUnit(null);
  }, [unitId, selections.unitId]);

  useEffect(() => {
    if (!reFectUnit) {
      return;
    }
    setReFetchUnit(false);
    setUnit(allUnits[unitId]);
    const brand = getUnitSystemBrand(unitId);
    setBrandName(getBrandName(brand));
  }, [reFectUnit]);

  useEffect(() => {
    if (!unit) {
      return;
    }
    isAltherma && getUnitParamsAndStats({
      unitId,
      startTime: selectedTime.selectedStartTime,
      endTime: selectedTime.selectedEndTime
    })
      .then((res: any) => {
        setOutsideAirTemp(res.ranges[14619].value);
        setRemoconRoomTemp(res.ranges[14622].value);
      });
  }, [unit]);

  useEffect(() => {

    if (isLoading || !unitId || !selectedTime.selectedStartTime || !selectedTime.selectedEndTime) {
      return;
    }
    //get graph all alerts based on time frame
    getAlertsByFilters({
      startTime: selectedTime.selectedStartTime,
      endTime: selectedTime.selectedEndTime,
      type: types.applications.service
    })
      .then((alertsByTime: any) => {

        if (_.isEmpty(alertsByTime)) {
          return;
        }
        initializeAlerts({ events: alertsByTime, openAlertsOnly: false });
      });

  }, [unitId, selectedTime.selectedStartTime, selectedTime.selectedEndTime, isLoading]);

  useEffect(() => {

    if (!unitId || _.isEmpty(allAlerts)) {
      return;
    }
    const filteredAlerts = Object.values(alerts).filter((alert: any) => {
      const isEntitiesNoDateType = eventTypes.entitiesHealthLowIstat === alert.type
        || eventTypes.entitiesHealthLowOstat === alert.type
        || eventTypes.entitiesHealthLowPstat === alert.type
        || eventTypes.entitiesHealthLowLqstat === alert.type
        || eventTypes.entitiesHealthLowSstat === alert.type;
      if (!alert?.unitIds?.length || alert.unitIds.indexOf(unitId) === -1) {
        return false;
      }
      if (isEntitiesNoDateType) {
        return false;
      }
      return true;
    });
    setUnitAlerts(filteredAlerts);
  }, [allAlerts, unitId]);

  const onUnitSelect = (unitId: string | null) => {
    if (unitId) {
      history.push(`/unit-stats/${unitId}`);
    } else {
      history.push("/unit-stats");
    }
  };

  const addFileLocally = (file: any) => {
    setUnitFiles([...unitFiles, file]);
  };

  const isBsUnit = unit?.type === unitTypes["bsBox"];
  const notOutdoor = unit?.type !== unitTypes["outdoor"];
  const isAltherma = brandName === "Daikin Altherma";

  useEffect(() => {

    if (!allSites || !allSystems || !allUnits || allObj.length) {
      return;
    }

    const sites = Object.values(allSites).map((site: any) => {
      return {
        name: site.name,
        label: site.name,
        type: "site",
        id: site.id
      };
    });

    const systems = Object.values(allSystems).map((system: any) => {
      const siteName = allSites[system.site]?.name;
      return {
        name: system.name,
        label: `${siteName} - ${system.name}`,
        type: "system",
        id: system.id
      };
    });

    const units = Object.values(allUnits).reduce((val: any, unit: any) => {
      if (unit.type === types.unitTypes["indoor"]) {
        return val;
      }
      const siteName = allSites[unit.site]?.name;
      const systemName = allSystems[unit.system]?.name || t`Unassigned`;
      val.push({
        name: unit.name,
        label: `${siteName} - ${systemName} - ${unit.name}`,
        type: "unit",
        id: unit.id
      });
      return val;
    }, []);

    setAllObj([...sites, ...systems, ...units]);

  }, [allUnits]);

  const searchComponent = (
    <Autocomplete
      id="combo-box-demo"
      options={allObj}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.label}
      groupBy={(option) => option.type}
      value={""}
      inputValue={searchTerm}
      classes={{
        clearIndicator: classes.searchIcon,
        popupIndicator: classes.searchIcon,
        popper: classes.autoCompletePoper,
        paper: classes.autoCompletePaper,
        listbox: classes.autoCompleteItem,
        noOptions: classes.autoCompleteItem,
        groupLabel: classes.autoCompleteGroup,
        groupUl: classes.autoCompleteGroupUl

      }}
      onInputChange={(e: any, val: any) => {
        setSearchTerm(val);
      }}
      renderInput={(params) => <TextField {...params} placeholder={t`Search...`} InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot }, ...params.InputProps, endAdornment: <Search /> }} />}
      onChange={(e: any, val: any) => {
        setSearchTerm("");
        if (!val) {
          return;
        }
        if (val.type === "unit") {
          onUnitSelect(val.id);
          return;
        }
        updateSelections({ type: val.type, data: val.id });
        onUnitSelect(null);
      }}
    />
  );

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.rightSide}>
        <Header
          onUnitSelect={onUnitSelect}
          customGeneralNames={{ unit: "Select Unit" }}
          hideAllOptionsFromDropdown={["unit"]}
          searchComponent={searchComponent}
        />

        {unit &&
          unitAlerts &&
          !_.isNil(unitCustomer) &&
          !_.isNil(unitSite) &&
          !_.isNil(unitDevice) ? (
          <Grid container direction={"row"} className={classes.contentContainer}>
            {/* --- start of unitInfoContainer  */}
            <Grid container direction={"row"} className={classes.unitInfoContainer}>
              <Grid item xs={7} className={classes.halfPadding} style={{ maxHeight: "121px" }}>
                <UnitInfo
                  unit={unit}
                  site={unitSite}
                  device={unitDevice}
                  system={unitSystem}
                  setNotesAnch={setNotesAnch}
                  isAltherma={isAltherma}
                  setOpenAlthermaSettings={setOpenAlthermaSettings}
                />
              </Grid>
              <Grid item xs={2} className={classes.halfPadding}>
                {unit?.type !== unitTypes["outdoor"] && !isBsUnit ? (
                  <TemperatureInfo
                    unit={unit} site={unitSite}
                    externalTemp={externalTemp}
                    temperatureScale={temperatureScale}
                    roomTemp={isAltherma && notOutdoor ? remoconRoomTemp : controlUnit ? controlUnit.ambientTemperature : unit.ambientTemperature}
                    brandName={brandName}
                    outsideAirTemp={outsideAirTemp}
                  />
                ) : (
                  <TemperatureInfo
                    unit={unit}
                    site={unitSite}
                    externalTemp={externalTemp}
                    externalOnly
                    temperatureScale={temperatureScale}
                    outdoorTemp={outdoorTemp}
                    roomTemp={isAltherma && notOutdoor ? remoconRoomTemp : controlUnit && controlUnit.ambientTemperature}
                    brandName={brandName}
                    outsideAirTemp={outsideAirTemp}
                  />
                )}
              </Grid>
              <Grid item xs={3} className={classes.halfPadding}>
                {!isBsUnit && controlUnit ? (
                  <UnitControl unit={controlUnit} temperatureScale={temperatureScale} device={unitDevice} />
                ) : null}
              </Grid>
            </Grid>
            {/* --- end of unitInfoContainer */}
            {/* --- start of paramsContainer  */}
            <Grid container direction={"row"} className={classes.halfPadding}>
              <Grid item xs={12} className={classes.paramsContainer}>
                <Card className={classes.statsRapper}>
                  {unit?.type === unitTypes["outdoor"] ? (
                    <UnitStats
                      site={unitSite}
                      unit={unit}
                      alerts={unitAlerts}
                      setOutdoorTemp={setOutdoorTemp}
                      setSelectedTime={setSelectedTime}
                      setReFetchUnit={setReFetchUnit}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      selectedTime={selectedTime}
                      setExternalTemp={setExternalTemp}
                    />
                  ) : (
                    <UnitStats
                      site={unitSite}
                      unit={unit}
                      alerts={unitAlerts}
                      setOutdoorTemp={setOutdoorTemp}
                      setSelectedTime={setSelectedTime}
                      setReFetchUnit={setReFetchUnit}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      selectedTime={selectedTime}
                      setExternalTemp={setExternalTemp}
                    />
                  )}
                </Card>
              </Grid>
              {/* --- end of paramsContainer */}
              {/* --- start of errorLogs and systemOverview */}
            </Grid>

            <Grid container direction={"row"} spacing={3} className={classes.bottomContainer}>
              <Grid item xs={5}>
                <Card
                  classes={{
                    root: classes.errorLogsCard
                  }}
                >
                  <ErrorLogs
                    unit={unit}
                    usePadding={true}
                    showTitle
                    hideColumns={["unitName", "errorCode", "customerName", "system", "siteName", "status", "alertType1"]}
                    hideAlertOfType={["2"]}
                    unitAlerts={unitAlerts}
                    hideEntity={false}
                  />
                </Card>
              </Grid>

              <Grid item xs={7}>
                {unitSystem && (
                  <Card className={classes.systemOverviewCard}>
                    <SystemOverview system={unitSystem} assoBSBox={unit.branchBoxUnit} />
                  </Card>
                )}
              </Grid>

              {/* --- end of errorLogs and systemOverview */}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={"column"} className={classes.noContentContainer}>
            <div className={classes.grayOval}>
              <ArrowIcon className={classes.arrowIcon} />
            </div>
            <Typography className={classes.noUnitText}>
              {t`Please select a unit using the above filters.`}
            </Typography>
          </Grid>
        )}
      </div>
      {notesAnch && unitId && systemId && (
        <NotesAndAttachments
          anchorEl={notesAnch}
          setAnchorEl={setNotesAnch}
          unitId={unitId}
          systemId={systemId}
          unitNotes={unitNotes}
          setUnitNotes={setUnitNotes}
          createNewNote={newNote}
          deleteNote={deleteNote}
          allUsers={{ [currentUser.id]: currentUser, ...allUsers }}
          unitFiles={unitFiles}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          uploadFile={uploadFile}
          updateFilesInfo={updateFilesInfo}
          addFileLocally={addFileLocally}
          siteId={unitSite?.id}
          timezone={unitSite?.timezone}
        />
      )}

      {selectedFile && (
        <Dialog
          open={selectedFile}
          keepMounted
          onClose={() => setSelectedFile(null)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{ paper: classes.dialogStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title">{`${selectedFile.name} preview`}</DialogTitle>
          <DialogContent>
            {selectedFile.isPhoto ? <img src={selectedFile.url} className={classes.imagePreview} /> :
              (<iframe
                src={`${selectedFile.isPdf ? "https://docs.google.com/gview?url=" : "https://view.officeapps.live.com/op/embed.aspx?src="}${selectedFile.url}${selectedFile.isPdf ? "&embedded=true" : ""}`}
                width="100%"
                height="100%"
                frameBorder="0"
                className={classes.frameStyle}
              >
              </iframe>)}
          </DialogContent>
          <DialogActions>
            <Button onMouseUp={() => downloadAs(selectedFile.url, selectedFile.name)} color="primary">
              Download
          </Button>
            <Button onMouseUp={() => setSelectedFile(null)} color="primary">
              Close
          </Button>
          </DialogActions>
        </Dialog>
      )}
      {openAlthermaSettings && <AlthermaUnitSettings unitId={selections.unitId} onClose={() => setOpenAlthermaSettings(false)} />}
    </div>
  );
};

export default DashboardUnit;
