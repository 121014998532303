import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import { ArrowDownO, Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import UserDetailsField from "../UserManagement/UserDetailsField";
import useStyles from "./ReportProblem.style";

const reportSchema =
Yup.object().shape({
  topic: Yup.string()
    .required("Required"),
  description: Yup.string()
    .max(1000, "Too Long! Maximum 1000 characters"),
  email: Yup.string().email("Please enter a valid email address")
});
export { reportSchema };

const initialTopicOptions: any = [
  {value: "0", name: "App/Data problem"},
  {value: 1, name: "Devices"},
  {value: 2, name: "Connectivity and mapping"},
  {value: 3, name : "Suggest Product Enhancement" },
  {value: 4, name : "Other" }
];

const ReportProblem: React.FC<any> = (props: any) => {
    const classes = useStyles();
    const { selections } = useStoreState((state) => state.selections);
    const getSystemInfo = useStoreState((state) => state.systems.getSystem);
    const getDeviceById = useStoreState((state) => state.devices.getDeviceById);
    const getUnitName = useStoreState((state) => state.units.getUnitName);
    const getSiteName = useStoreState((state) => state.sites.getSiteName);
    const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
    const reportProblem = useStoreActions((action) => action.users.reportProblem);
    const deviceId = selections?.systemId ? getSystemInfo(selections.systemId).device : null;

    const [topic, setTopic] = useState<string>();
    const [description, setDescription] = useState<string>("");
    const [topicOptions, setTopicOptions] = useState<any>(initialTopicOptions);
    const [siteOptions, setSiteOptions] = useState<any>([]);
    const [deviceOptions, setDeviceOptions] = useState<any>([]);
    const [systemOptions, setSystemOptions] = useState<any>([]);
    const [unitOptions, setUnitOptions] = useState<any>([]);
    const [selectedSite, setSelectedSite] = useState<string>(selections?.siteId ? selections?.siteId : "");
    const [selectedDevice, setSelectedDevice] = useState<string>(deviceId ? deviceId : "");
    const [selectedSystem, setSelectedSystem] = useState<string>(selections?.systemId ? selections?.systemId : "");
    const [selectedUnit, setSelectedUnit] = useState<string>(selections?.unitId ? selections?.unitId : "");
    const [initialValues, setInitialValues] = useState<any>({});
    const { onClose: handleClose, openReport, location = "" } = props;
    const isFromLogin = location === "login";

    const me = useStoreState((state) => state.users.me);
    const allSites = useStoreState((s) => s.selections.getSitesBySelection);
    const getDevicesBySite = useStoreState((state) => state.devices.getDevicesBySite);
    const getDeviceSystems = useStoreState((state) => state.devices.getDeviceSystems);
    const getUnitsBy = useStoreState((state) => state.units.getUnitsBy);

    const { email, customer: customerId = "", username } = me;

    const report = (values: any) => {
      const sUsrAg = navigator.userAgent;
      let OSName = "Unknown OS";
      let sBrowser;
      if (navigator.userAgent.indexOf("Win") !== -1){ OSName = "Windows"; }
      if (navigator.userAgent.indexOf("Mac") !== -1){ OSName = "Macintosh"; }
      if (navigator.userAgent.indexOf("Linux") !== -1){ OSName = "Linux"; }
      if (navigator.userAgent.indexOf("Android") !== -1){ OSName = "Android"; }
      if (navigator.userAgent.indexOf("like Mac") !== -1){ OSName = "iOS"; }

      // The order matters here, and this may report false positives for unlisted browsers.
      if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
      } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
      } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
      } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
      } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
      } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
      } else {
        sBrowser = "unknown";
      }

      const data: any = {
        topic,
        customer: isFromLogin ? values.customer || "no data" : customerId ? getCustomerName(customerId) : "no data",
        username: (isFromLogin ? values.username : username) || "no data",
        email: (isFromLogin ? values.email : email) || "no data",
        application: "Service",
        site: selectedSite ? getSiteName(selectedSite) : "no data",
        deviceSN: selectedDevice ?  getDeviceById(selectedDevice)?.name : "no data",
        system: selectedSystem ? getSystemInfo(selectedSystem)?.name : "no data",
        unit: selectedUnit ? getUnitName(selectedUnit) : "no data",
        description,
        currentURL: window.location.href,
        browser: sBrowser,
        OS: OSName,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        dateRange: selections?.dateRange  ? `${selections?.dateRange?.startDate} - ${selections?.dateRange?.endDate}` : "no data"
      };
      reportProblem(data);
      handleClose();
    };

    useEffect(() => {
      if (isFromLogin){
        setTopicOptions([...topicOptions, {value : 5, name : "Problem with Login"}]);
        setTopic("Problem with Login");
        setInitialValues({topic: 5, application: "Service"});
        return;
      }
      setSiteOptions(allSites);
      const values = {
        customer: customerId ? getCustomerName(customerId) : "",
        username: username ? username : "",
        email: email ? email : "",
        site: selections && selections?.siteId ? selections.siteId : "",
        device: deviceId ? deviceId : "",
        system: selections && selections?.systemId ? selections.systemId : "",
        unit: selections && selections?.unitId ? selections.unitId : "",
        description: "",
        application: "Service"
      };
      setInitialValues(values);
    }, []);

    useEffect(() => {
      setDeviceOptions(getDevicesBySite(selectedSite));
      if (selectedSite !== selections?.siteId){
        setSelectedDevice("");
        setSelectedSystem("");
        setSelectedUnit("");
      }
    }, [selectedSite]);

    useEffect(() => {
      setSystemOptions(getDeviceSystems(selectedDevice));
      if (selectedDevice !== deviceId){
        setSelectedSystem("");
        setSelectedUnit("");
      }
    }, [selectedDevice]);

    useEffect(() => {
      selectedSystem ? setUnitOptions(getUnitsBy("system", selectedSystem, { type: "all" })) : setUnitOptions([]);
      if (selectedSystem !== selections.systemId){
        setSelectedUnit("");
      }
    }, [selectedSystem]);

    const CustomSelect = ({ notDisabledOptions, className, error, disabled, options = [], value, placeholder, onChange, optionValue, label = "name", name }: any) => {
      const replacedValue = value === undefined ? "" : (value || "");
      return <FormControl variant="outlined" className={clsx({ [className]: className })}>
        <InputLabel id="demo-simple-select-outlined-label" className={classes.labelSelect}>{placeholder}</InputLabel>
        <Select
          name={name}
          displayEmpty
          disabled={disabled}
          value={replacedValue}
          onChange={onChange}
          error={error}
          variant="outlined"
          disableUnderline
          classes={{ icon: classes.arrowDownIcon }}
          IconComponent={ArrowDownO}
          className={clsx(classes.select, { [classes.disabledSelect]: options.length === 0 || disabled })}
          label={placeholder}
          MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
        >
          {_.isEmpty(options) ? <MenuItem value={"none"} disabled>{t`No Options`}</MenuItem> : <MenuItem value={""} disabled
            style={{ display: "none" }}
          ></MenuItem>}
          {options.map((option: any) => {
            if (!!notDisabledOptions && notDisabledOptions.includes(option[optionValue])) {
              return null;
            }
            return (<MenuItem key={option[optionValue]} value={option[optionValue]}>
              {option[label]}
            </MenuItem>);
          }
          )}
        </Select >
      </FormControl>;
    };

    return (
        <Dialog
           open={openReport}
           onClose={handleClose}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
           maxWidth="sm"
           fullWidth
           classes={{ paper: classes.dialog }}
         >
            <DialogTitle id="alert-dialog-title" className={classes.title}>
                <Typography variant="h6">{t`Report a Problem`}</Typography>
                <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
                  <Close color="#7f7692" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Formik
              initialValues={initialValues}
              onSubmit={report}
              enableReinitialize={true}
              validationSchema={reportSchema}
              >
              {({ handleSubmit, errors, touched, values, setFieldValue, ...restFormikProps }) => (
                  <Form translate="yes">
                      <Paper className={classes.leftCard} elevation={0}>
                          <Grid direction="column" container>
                              <Grid item spacing={1}>
                                <div className={classes.topicContainer}>
                                  <CustomSelect className={classes.fullWidth} error={errors.topic} placeholder="Select a topic" name="topic" optionValue="value" value={values.topic} options={topicOptions} onChange={(e: any) => {setFieldValue("topic", e.target.value); setTopic(topicOptions[e.target.value].name); }}/>
                                </div>
                              </Grid>
                              <Grid item spacing={1} className={classes.descriptionContainer}>
                                <InputLabel className={classes.label}  id="demo-simple-select-outlined-label">{"Description"}</InputLabel>
                                 <Field
                                  aria-label="minimum height"
                                  rowsMin={6}
                                  rowsMax={6}
                                  value={values.description}
                                  placeholder="Insert Description"
                                  onChange={(e: any) => {setFieldValue("description", e.target.value); setDescription(e.target.value); }}
                                  error={errors.description}
                                  name="description"
                                  as="textarea"
                                  maxlength="1000"
                                  className={classes.textArea}
                                 />
                                {<FormHelperText className={classes.requiredError}>{errors.description || ""}</FormHelperText>}
                              </Grid>
                              <Grid item spacing={1}>
                                  <Typography
                                  className={classes.headerStyle}
                                  >{t`User Information`}</Typography>
                                  <Divider orientation="horizontal" />
                                  <Grid container className={classes.userInfoContainer}>
                                    <Grid spacing={2} className={clsx(classes.personalDetailsContainer, {[classes.fullWidth]: isFromLogin})}>
                                      <Field
                                        disabled={!isFromLogin}
                                        label={t`Customer`}
                                        value={values.customer}
                                        name="customer"
                                        component={UserDetailsField}
                                      />
                                      <Field
                                        disabled={!isFromLogin}
                                        label={t`User name`}
                                        value={values.username}
                                        name="username"
                                        component={UserDetailsField}
                                      />
                                      <Field
                                        disabled={!isFromLogin}
                                        label={t`Email Address`}
                                        value={values.email}
                                        name="email"
                                        component={UserDetailsField}
                                        type="email"
                                        error={errors.email}
                                      />
                                      {<FormHelperText className={classes.requiredError}>{errors.email || " "}</FormHelperText>}
                                      <Field
                                        disabled
                                        label={t`application`}
                                        value={values.application}
                                        name="application"
                                        component={UserDetailsField}
                                      />
                                    </Grid>
                                    {!isFromLogin && <Grid spacing={2} className={classes.personalDetailsContainer}>
                                      <CustomSelect className={classes.selectStyle} error={errors.site} placeholder="Select a site" name="site" optionValue="id" value={values.site} options={siteOptions} onChange={(e: any) => {setFieldValue("site", e.target.value); setSelectedSite(e.target.value); }}/>
                                      <CustomSelect disabled={!selectedSite} className={classes.selectStyle} error={errors.device} placeholder="Select a device" name="device" optionValue="id" value={values.device} options={deviceOptions} onChange={(e: any) => {setFieldValue("device", e.target.value); setSelectedDevice(e.target.value); }}/>
                                      <CustomSelect disabled={!selectedDevice} className={classes.selectStyle} error={errors.system} placeholder="Select a system" name="system" optionValue="id" value={values.system} options={systemOptions} onChange={(e: any) => {setFieldValue("system", e.target.value); setSelectedSystem(e.target.value); }}/>
                                      <CustomSelect disabled={!selectedSystem} className={classes.selectStyle} error={errors.unit} placeholder="Select a unit" name="unit" optionValue="id" value={values.unit} options={unitOptions} onChange={(e: any) => {setFieldValue("unit", e.target.value); setSelectedUnit(e.target.value); }}/>
                                    </Grid>}
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Paper>
                      <Divider orientation="vertical" />
                    <div className={classes.actionsHolder}>
                      <Button
                        uppercase
                        width={150}
                        white
                        marginRight
                        onClick={handleClose}
                        onMouseUp={handleClose}
                      >
                      {t`Cancel`}
                      </Button>
                      <Button
                        uppercase
                        width={150}
                        type="submit"
                        onMouseUp={handleSubmit}
                        onClick={handleSubmit}
                      >
                      {t`Send`}
                      </Button>
                    </div>
                  </Form>
              )}
              </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default ReportProblem;
