import { Checkbox, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import checkboxStyle from "./checkbox.style";

interface ICheckboxProps {
  onChange?: any;
  className?: any;
  disabled?: boolean;
  value?: string | number;
  checked?: boolean;
}

const CustomCheckbox: React.FC<ICheckboxProps> = ({
  className,
  onChange,
  value,
  disabled = false,
  checked,
  ...props
}) => {
  const useStyles = makeStyles(checkboxStyle);
  const classes = useStyles();

  return (
    <Checkbox
      classes={{ disabled: classes.disabledStyle }}
      checked={checked}
      onChange={() => {
        if (disabled) {
          return;
        }
        onChange(value);
      }}
      value={value}
      disabled={disabled}
      className={clsx(classes.root, className)}
      {...props}
    />
  );
};

export default CustomCheckbox;
