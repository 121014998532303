import {
    CircularProgress,
    IconButton,
    Input,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { Close, FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { Lookup } from "../../components/Lookup";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import SVGDownload from "../../icons/Download";
import Export from "../../icons/Export";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { SearchIcon } from "../../svgComponents";
import ExportTraps from "./ExportTraps";
import ImportTraps from "./ImportTraps";
import useStyles from "./TrapsList.style";

const TrapsList: React.FC = (props: any) => {
    const classes = useStyles();
    const types = useStoreState((s) => s.types);
    const isInitialized = useStoreState((s) => s.isInitialized);
    const { customerId: selectedCustomer } = useStoreState((s) => s.selections.selections);
    const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
    const isLoggedInUserCustomerUser = useStoreState((s) => s.users.isLoggedInUserCustomerUser());
    const getTraps = useStoreActions((action) => action.traps.getTraps);
    const addTrap = useStoreActions((action) => action.traps.addTrap);
    const updateTrap = useStoreActions((action) => action.traps.updateTrap);
    const deleteTrap = useStoreActions((action) => action.traps.deleteTrap);
    const getTrapTemplates = useStoreActions((action) => action.anomalyTemplates.getTrapTemplates);
    const [openAddRule, setOpenAddRule] = useState<boolean>(false);
    const [traps, setTraps] = useState<any>({});
    const [editTrap, setEditTrap] = useState<any>(null);
    const [trapTemplatesToBrands, setTrapTemplatesTobrands] = useState<any>({});
    const [trapTemplatesMap, setTrapTemplatesMap] = useState<any>({});
    const [selectedFilters, setSelectedFilters] = useState<any>({ brands: [] });
    const [lookupAnchor, setAnchor] = useState(null);
    const [searchAnchor, setSearchAnchor] = useState(null);
    const [filteredTraps, setFilteredTraps] = useState<any>({});
    const [brandsArray, setBrandsArray] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [openExportTraps, setOpenExportTraps] = useState<boolean>(false);
    const [fileData, setFileData] = useState<any>("");
    const [errors, setErrors] = useState<any>({ file: false, error: "" });
    const { hvacBrands = [] } = types;
    const sortedTraps = _.orderBy(Object.values(filteredTraps), [(trap: any) => trap.name?.toLowerCase()], ["asc"]);
    const tableHasFilters = selectedFilters["brands"]?.length;
    const [refresh, setRefresh] = useState<number>(0);
    const [multiTrapsToImport, setMultiTrapsToImport] = useState<any>(null);
    const dateFormat = useStoreState((state) => state.users.dateFormat);

    useEffect(() => {
        Promise.all([getTraps({ type: types.applications.service }), getTrapTemplates()]).then((response: any) => {
            const templatesToBrands: any = {};
            Object.values(response[1] || {}).forEach((template: any) => {
                const brandNum = template?.userSelections?.brand || null;
                if (!brandNum) {
                    return;
                }
                if (templatesToBrands[brandNum]) {
                    templatesToBrands[brandNum].push(template);
                    return;
                }

                templatesToBrands[brandNum] = [template];
            });
            setTraps(response[0]);
            setTrapTemplatesMap(response[1]);
            setTrapTemplatesTobrands(templatesToBrands);
        });
    }, [refresh]);

    useEffect(() => {
        setFilteredTraps(getFilteredTraps(traps));
    }, [selectedFilters, searchValue]);

    useEffect(() => {
        if (_.isEmpty(traps)) {
            return;
        }
        setFilteredTraps(traps);
        updateFiltersVals(Object.values(traps));
    }, [traps]);

    useEffect(() => {
        if (!fileData) {
            return;
        }
        const currNames = Object.values(traps).map((t: any) => t.name);
        const today = moment().format(dateFormat);

        const importedTraps = fileData?.length && fileData.map((impTrap: any) => {
            const { customer, ...restTrap } = impTrap;
            if (restTrap.type === types.trapTypes.operationalTelemetry) {
                setErrors({ file: false, error: "anomaly type not supported" });
                return;
            }
            restTrap.type = types.trapTypes.customTelemetry;
            const name = currNames.indexOf(impTrap?.name) > -1 ? `${impTrap.name}-imported at ${today}` : impTrap.name;
            return addTrap({ data: { ...restTrap, name }, customerId: selectedCustomer as any });
        });
        Promise.all(importedTraps)
            .then(() => {
                setRefresh(refresh + 1);
            });
    }, [fileData]);

    const getBrandName = (value: any) => {
        return hvacBrands?.filter((item) => item.value === value)[0]?.name;
    };
    const addNewTrap = (data: any, customerId: string) => {
        return addTrap({ data, customerId })
            .then((resp: any) => {
                setTraps({ ...traps, [resp.id]: { ...resp } });
            })
            .catch((error: any) => {

            });
    };

    const trapDelete = (id: string) => {
      deleteTrap({id})
      .then(() => {
        delete traps[id];
        setTraps({ ...traps });
      });
    };

    const updateExistingTrap = (data: any, trapId: string) => {
        return updateTrap({ data, trapId })
            .then((resp: any) => {
                setTraps({ ...traps, [trapId]: { ...traps[trapId], ...data } });
            })
            .catch((error: any) => {

            });
    };

    const closeDialog = () => {
        setOpenAddRule(false);
        setEditTrap(null);
    };

    const closeExport = () => {
        setOpenExportTraps(false);
    };

    const stopPropagation = (e: any) => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
            case "Home":
            case "End":
                break;
            default:
                e.stopPropagation();
        }
    };

    const openFilterbrands = (event: any, traps: any) => {
        setAnchor(event.currentTarget);
    };

    const onApply = (appliedFilters: any) => {
        if (typeof appliedFilters === "object" && appliedFilters !== null) {
            setSelectedFilters({ ...selectedFilters, ["brands"]: appliedFilters });
        }
        else {
            setSelectedFilters([]);
        }
        setAnchor(null);
        setSearchAnchor(null);
    };

    const clearAllFilters = () => {
        setSelectedFilters({ brands: [] });
    };

    const updateFiltersVals = (rows: any) => {
        const brandsArray: any = [];
        for (let rowIndex in rows) {
            const { userSelections: { brand } } = rows[rowIndex];
            if (!!brand) { brandsArray.push(getBrandName(brand)); }
        }

        const uniqueBrands = brandsArray.filter((value: any, index: any, self: any) => {
            return self.indexOf(value) === index;
        });
        setBrandsArray(uniqueBrands);
    };
    const getFilteredTraps = (traps: any[]) => {
        function applyFilters(traps: any[]) {
            return _(traps)
                .filter((trap) => {
                    return selectedFilters.brands.length
                        ? selectedFilters.brands.indexOf(getBrandName(trap.userSelections.brand)) > -1
                        : true;
                }).filter((trap) => {
                    return searchValue?.length
                        ? (trap["name"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || trap["description"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
                        : true;
                })
                .value();
        }
        const filteredRows = applyFilters(traps);
        updateFiltersVals(filteredRows);
        return filteredRows;
    };

    const handleUploadFile = (event: any) => {
        setErrors({ file: false, error: "" });
        const noFileChoosed = event.target.files.length < 1;

        if (noFileChoosed) {
            setErrors({ file: noFileChoosed, error: "" });
            return;
        }

        const ext: any = _.last(event.target.files[0].name.split(".")) || "";
        if (ext !== "json") {
            setErrors({ file: false, error: t`file type is not allowed, use Json files`, });
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], "UTF-8");
        fileReader.onload = (event) => {
            const x: any = event.target?.result;
            const data = JSON.parse(x);

            if (!Array.isArray(data)) {
                return;
            }

            if (data.length < 2) {
                setFileData(data);
                return;
            }

            setMultiTrapsToImport(data);
        };

    };

    if (!isInitialized) { return <Loading />; }

    const searchComponent = (
        <Input
            placeholder="Search..."
            value={searchValue}
            onChange={(event: any) => setSearchValue(event.target.value)}
            disableUnderline={true}
            classes={{ root: classes.inputRoot }}
            startAdornment={
                <InputAdornment position="start" className={classes.searchAdorment}>
                    <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
            }
            endAdornment={
                searchValue && (
                    <IconButton
                        onClick={() => setSearchValue("")}
                        className={classes.closeIconStyle}
                    >
                        <Close />
                    </IconButton>
                )
            }
        />
    );
    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header hideSiteSelection hideSystemSelection hideUnitSelection
                    searchComponent={searchComponent} />
                <div className={classes.titleBar}>
                    <div className={classes.barRIghtSide}>
                        <div className={classes.ImportExportContainer}>
                            <div>
                                <LightTooltip title={"Export Anomaly"}>
                                    <IconButton disableRipple onClick={() => setOpenExportTraps(true)} className={clsx(classes.iconBtnStyle, classes.exportIcon)}>
                                        <Export />
                                    </IconButton>
                                </LightTooltip>
                                <LightTooltip title={"Import Anomaly"}>
                                    <IconButton disableRipple className={clsx(classes.iconBtnStyle, classes.importIcon)}>
                                        <label htmlFor="file"><SVGDownload /></label>
                                        <input disabled={false} type="file" hidden name="file" id="file" accept=".json" onChange={handleUploadFile} />
                                    </IconButton>

                                </LightTooltip>
                            </div>
                            <Typography style={{ color: "red", fontSize: "12px", width: "100%" }}>{errors.error}</Typography>
                        </div>
                        <Button
                            disabled={!isLoggedInUserCustomerUser}
                            onClick={() => setOpenAddRule(true)}
                        >
                            {t`Add Rule`}
                        </Button>
                    </div>
                </div>

                <Paper elevation={0} className={classes.paperTableContainer}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Rule Name`}
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Customer`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                        onClick={(e: any) => openFilterbrands(e, sortedTraps)}
                                    >
                                        <div className={classes.filterIconContainer}>
                                            {t`BRAND`}
                                            <FilterList
                                                className={clsx(classes.filterStyle, {
                                                    [classes.blueFilter]: !_.isEmpty(selectedFilters.brands)
                                                })}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Description`}
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`EDIT`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`REMOVE`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedTraps.map((trap: any) => {
                                    const { id, name, customer, userSelections: { brand = -99 } = {}, description } = trap;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={id}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {name}
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {getCustomerName(customer)}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {getBrandName(brand)}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.descriptionCell, classes.mediumWidth) }}
                                                align="left"
                                            >
                                                {description}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                <LightTooltip title="Edit anomaly">
                                                    <IconButton
                                                        disableRipple
                                                        onClick={() => setEditTrap(trap)}
                                                        className={clsx(classes.iconBtnStyle, classes.editIcon)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                <Delete
                                                    type={t`anomaly`}
                                                    object={trap}
                                                    detach={() => trapDelete(id)}
                                                    buttonClass={classes.deleteIcon}
                                                ></Delete>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {(openAddRule || editTrap) &&
                    <AddRule
                        close={closeDialog}
                        createRule={addNewTrap}
                        editTrap={editTrap}
                        updateRule={updateExistingTrap}
                        trapTemplatesToBrands={trapTemplatesToBrands}
                        trapTemplatesMap={trapTemplatesMap}
                    />
                }
            </div>
            {lookupAnchor && (
                <Lookup
                    filtersList={brandsArray}
                    appliedFilters={selectedFilters.brands}
                    onApply={onApply}
                    lookupAnchor={lookupAnchor}
                    onClose={() => setAnchor(null)}
                    tableHasFilters={tableHasFilters}
                    clearAllFilters={clearAllFilters}
                />
            )}
            {openExportTraps && (
                <ExportTraps traps={sortedTraps}
                    close={closeExport}
                    getBrandName={getBrandName}
                />
            )}
            {multiTrapsToImport && (
                <ImportTraps
                    traps={multiTrapsToImport}
                    close={() => setMultiTrapsToImport(null)}
                    getBrandName={getBrandName}
                    passTraps={(traps: any) => setFileData(traps)}
                />
            )}
        </div>
    );
};

export default TrapsList;
