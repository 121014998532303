import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1)
    },
    tableCell: {
        fontSize: "13px",
        color: "rgb(84, 89, 100)",
        // paddingBottom: '3px',
        // paddingTop: '3px',
        // padding: "6px 10px",
        paddingBottom: "0px",
        paddingTop: "0px",
        height: "35px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"

    },
    filterCleanBtn: {
        fontSize: "0.75rem",
        color: "rgba(41, 19, 46, 0.95)",
        backgroundColor: "white",
        border: "1px solid rgba(41, 19, 46, 0.95)",
        borderRadius: "3px",
        width: "20px",
        "&:hover": {
            color: "white",
            backgroundColor: "rgba(41, 19, 46, 0.95)"
        },
        "&>span": {
            fontSize: ".7rem"
        }
    },
    breakStyle: {
        wordBreak: "break-all"
    },
    addEllip: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
    pointerCursor: {
        cursor: "pointer"
    },
    fixedWidth: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "unset"
    },
    multiLine: {
        wordBreak: "break-all",
        whiteSpace: "unset"
    },
    showWhiteSpace: {
        whiteSpace: "pre",
        overflow: "unset",
        textOverflow: "unset"
    },
    typeKey: {
        width: "7px",
        height: "7px",
        borderRadius: "100%",
        backgroundColor: "#000"
    }
}));
export default useStyles;
