// prettier-ignore
import { Thunk, thunk } from "easy-peasy";

export interface IFilesModel {
  getSiteFiles: Thunk<IFilesModel, string>;
  getUnitFiles: Thunk<IFilesModel, string>;
  uploadFile: Thunk<IFilesModel, any>;
  updateFilesInfo: Thunk<IFilesModel, { siteId: string; data: any }>;
  deleteFile: Thunk<IFilesModel, string>;
  updateFile: Thunk<IFilesModel, { id: string; data: any }>;
}

export const filesModel: IFilesModel = {
  getSiteFiles: thunk((actions, payload, { injections }) => {
    const { sdkFile } = injections;
    return sdkFile.getSiteFiles(payload);
  }),
  getUnitFiles: thunk((actions, payload, { injections }) => {
    const { sdkFile } = injections;
    return sdkFile.getUnitFiles(payload);
  }),
  updateFilesInfo: thunk((actions, payload, { injections }) => {
    const { sdkFile } = injections;
    return sdkFile.updateFilesInfo(payload.siteId, payload.data);
  }),
  deleteFile: thunk((actions, payload, { injections }) => {
    const { sdkFile } = injections;
    return sdkFile.delete(payload);
  }),
  updateFile: thunk((actions, payload, { injections }) => {
    const { sdkFile } = injections;
    return sdkFile.update(payload.id, payload.data);
  }),
  uploadFile: thunk((actions, payload, { injections }) => {
    const { sdkFile } = injections;
    return sdkFile.uploadFile(payload);
  })
};
