import {
    Card,
    FormControl, FormHelperText,
    Input,
    InputLabel,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { manuallySetSystemViewStyle } from "./Device/ManuallySetSystems.style";

interface IProps {
    mode: number;
    ppds: any[];
    sensors: any[];
    closeModal: any;
    classes?: any;
    import?: boolean;
}
export default withStyles(manuallySetSystemViewStyle)(function ManuallySetOther(props: IProps) {
    const { classes } = props;
    const {
        modalActions,
        systemAttributes
    } = classes;
    const updateSensor = useStoreActions((action) => action.sensors.updateSensor);
    const updatePowerMeters = useStoreActions((action) => action.powerMeters.updatePowerMeter);
    const [ppdData, setPpdData] = React.useState<any>({});
    const [sensorData, setSensorData] = React.useState<any>({});
    const [error, setError] = useState<string | null>(null);
    const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
    useEffect(() => {
        const localPpdData = _.reduce(props.ppds, (ppdsObj: any, ppd: any) => {
            ppdsObj[ppd.id] = {
                name: ppd.name || "",
                sn: ppd.sn || "",
                model: ppd.model || "",
                id: ppd.id
            };

            return ppdsObj;
        }, {});
        setPpdData(localPpdData);

        const localSensorData = _.reduce(props.sensors, (sesnorsObj: any, sensor: any) => {
            sesnorsObj[sensor.id] = {
                name: sensor.name || "",
                line: sensor.line || "",
                model: sensor.model || "",
                sn: sensor.sn || "",
                dataAddress: sensor.dataAddress || "",
                id: sensor.id
            };

            return sesnorsObj;
        }, {});

        setSensorData(localSensorData);
    }, [props.ppds, props.sensors]);

    const handleSave = async () => {
        _.forEach(ppdData, (pm) => {
            const oldPpd = _.find(props.ppds, (ppd) => ppd.id === pm.id);
            if (pm.name !== oldPpd.name || pm.sn !== oldPpd.sn || pm.model !== oldPpd.model) {
                const data = {
                    name: pm.name,
                    system: oldPpd.system,
                    identifier: pm.identifier,
                    model: pm.model,
                    sn: pm.sn
                };
                updatePowerMeters({ id: pm.id, data });
            }

        });
        _.forEach(sensorData, (pm) => {
            const oldSensor = _.find(props.sensors, (sensor) => sensor.id === pm.id);
            if (pm.name !== oldSensor.name || pm.sn !== oldSensor.sn || pm.model !== oldSensor.model) {
                const updatedData: any = {
                    name: pm.name,
                    model: pm.model,
                    sn: pm.sn
                };
                updateSensor({ id: pm.id as string, updatedData });
            }
        });
        props.closeModal();
    };

    const getColumnHeader = () => {
        return (
                    <TableHead>
                        <TableRow>
                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                className={classes.headCellName}
                                align="left"
                                style={{ width: "37ch", minWidth: "37px" }}
                            >
                                {t`Unit name`}
                            </TableCell>

                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                className={classes.headCellType}
                                align="left"
                                style={{ width: "9ch", minWidth: "9ch" }}
                            >
                                {t`Type`}
                            </TableCell>

                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                className={classes.headCellType}
                                align="left"
                                style={{ width: "10ch", minWidth: "10ch" }}
                            >
                                {t`Model`}
                            </TableCell>

                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                className={classes.headCellType}
                                align="left"
                                style={{ width: "10ch", minWidth: "10ch" }}
                            >
                                {t`CA Line`}
                            </TableCell>

                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                className={classes.headCellType}
                                align="left"
                                style={{ width: "18ch", minWidth: "18ch" }}
                            >
                                {t`SN`}
                            </TableCell>

                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                className={classes.headCellType}
                                align="left"
                                style={{ width: "16ch", minWidth: "16ch" }}
                            >
                                {t`ID`}
                            </TableCell>
                        </TableRow>
                    </TableHead>

        );
    };

    const getInput = ({value = "", lebel= "", helperText = "", isReadyOnly= false, onChange= _.noop, type = ""}) => {

        return (
            <FormControl
                className={clsx(classes.inputFormWrapper, classes.textField)}
                variant="filled"
            >
                {lebel && <InputLabel htmlFor="my-input">{lebel}</InputLabel>}
                <OutlinedInput
                    onChange={((event) => {
                        onChange(event.target.value, type);

                    })}
                    value={value}
                    margin="dense"
                    className={classes.inputFieldStyle}
                    readOnly={isReadyOnly}
                />

            </FormControl>
        );

    };
    const generateRow = (oneRow: any, rowType: string) => {
        const isSensor = rowType === "sensor" ;

        const onChange = (data: any, type: string) => {
            if (isSensor){
                const newSensorData: any = _.clone(sensorData);
                if (!newSensorData){
                console.log(`error - didn't find ${oneRow.id} sensor`);
                }
                if (type === "name"){
                    newSensorData[oneRow.id].name = data;
                }
                if (type === "sn"){
                    newSensorData[oneRow.id].sn = data;
                }
                if (type === "model"){
                    newSensorData[oneRow.id].model = data;
                }
                setSensorData(newSensorData);
            }else{
                const newPpdData: any = _.clone(ppdData);
                if (!newPpdData){
                    console.log(`error - didn't find ${oneRow.id} power meter`);
                }
                if (type === "name"){
                newPpdData[oneRow.id].name = data;
                }
                if (type === "sn"){
                newPpdData[oneRow.id].sn = data;
                }
                if (type === "model"){
                newPpdData[oneRow.id].model = data;
                }

                setPpdData(newPpdData);
            }
        };
        const dataArray = isSensor ? sensorData : ppdData;

        const name = dataArray[oneRow.id] ? dataArray[oneRow.id].name : "";
        const sn = dataArray[oneRow.id] ? dataArray[oneRow.id].sn : "";
        const identifier = oneRow.identifier  || "";
        const ca = `L${identifier[1]}` || "unknown";
        const model = dataArray[oneRow.id] ? dataArray[oneRow.id].model : "";
        const line = isSensor && dataArray[oneRow.id] ? `L${dataArray[oneRow.id].line}` : "";
        const id = isSensor && dataArray[oneRow.id] ? dataArray[oneRow.id].dataAddress : "";

        return (
            <TableRow key={oneRow.id} >
                <TableCell align="left" className={classes.wideCell}>
                    {getInput({value: name, isReadyOnly: false, helperText: "chose name of power meter", onChange: onChange, type: "name"})}

                </TableCell>

                <TableCell align="left" className={classes.wideCell}>
                    <Typography>
                        {isSensor ? "Sensor" : "Power Meter"}
                    </Typography>

                </TableCell>

                <TableCell align="left" className={classes.wideCell}>
                    {getInput({value: model, isReadyOnly: false, helperText: "set power meter model", onChange: onChange, type: "model"})}
                </TableCell>

                <TableCell align="left" className={classes.wideCell}>
                    <Typography className={classes.caRow}>
                        {isSensor ? line : ca}
                    </Typography>

                </TableCell>

                <TableCell align="left" className={classes.wideCell}>
                    {getInput({value: sn, isReadyOnly: false, onChange: onChange, type: "sn"})}
                </TableCell>

                <TableCell align="left" className={classes.wideCell}>
                    {isSensor ? id : identifier}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <>
            {errorBox}
            <Paper className={systemAttributes}>
                <Card key="1"  className={classes.otherFormWarpper}>
                    <Typography className={classes.otherFormWarpperTitle}>
                        {t`Auxiliary devices`}
                    </Typography>
                    <div className={classes.scrollableTable}>
                   <form onSubmit={() => {
                        }} style={{
                            display: "flex",
                            flexFlow: "column",
                            flex: 1,
                            height: "100%"
                        }}>

                            <div className={classes.tableWrapper}>
                                <Table
                                    size="small"
                                    stickyHeader
                                    aria-label="sticky table"
                                >
                                    {getColumnHeader()}
                                    <TableBody>
                                        {props.ppds && _.map(props.ppds, (ppd) => generateRow(ppd, "ppd"))}
                                        {props.sensors && _.map(props.sensors, (sensor) => generateRow(sensor, "sensor"))}
                                    </TableBody>
                                </Table>
                            </div>

                        </form>

                    </div>
                </Card>
                <div className={modalActions} style={{bottom: props.mode === 0 ? -8 : 0}}>
                    {(props.mode === 2 || props.mode === 1) && (
                        <Button
                            title="close edit"
                            variant="contained"
                            onClick={props.closeModal}
                            uppercase="uppercase"
                            width={150}
                            white="white"
                            marginRight={true}
                        >
                            {t`Cancel`}
                        </Button>
                    )}

                    <Button
                        title="save system"
                        type="submit"
                        uppercase="uppercase"
                        width={150}
                        onClick={handleSave}
                    >
                        {props.mode === 0 && !props.import ? t`Next` : t`Save`}
                    </Button>
                </div>
            </Paper>
        </>
    );

});
