import { ISite } from '../models/Sites';

const CommonUtils = {
  fahrenheitToCelsius(fahrenheit: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);

    return Math.round((((fahrenheit - 32) * 5) / 9) * accuracyMultiplier) / accuracyMultiplier;
  },

  celsiusToFahrenheit(celsius: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);

    return Math.round(((celsius * 9) / 5 + 32) * accuracyMultiplier) / accuracyMultiplier;
  },

  kgcm2ToPSI(kgcm: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);
    return Math.round((kgcm * 14.2233) * accuracyMultiplier) / accuracyMultiplier;
  },

  PSITokgcm2(PSI: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);
    return Math.round((PSI / 14.2233) * accuracyMultiplier) / accuracyMultiplier;
  },

  MPaToPSI(MPa: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);
    return Math.round((MPa * 145.038) * accuracyMultiplier) / accuracyMultiplier;
  },

  PSIToMPa(PSI: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);
    return Math.round((PSI / 145.038) * accuracyMultiplier) / accuracyMultiplier;
  },

  MPaTokgcm2(MPa: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);
    return Math.round((MPa * 10.1972) * accuracyMultiplier) / accuracyMultiplier;
  },
};

export default CommonUtils;

export function makeFullAddressForSite(site: ISite) {
  if (!site) {
    return '';
  }
  let address = '';
  if (!site.country) return '';
  // US version !
  if (site.address) address += site.address;
  if (site.city) address += `, ${site.city}`;
  if (site.country==="US" && site.state) address += `, ${site.state}`;
  if (site.postalCode) address += `, ${site.postalCode}`;
  address += `, ${site.country}`;
  return address;
}

export function makeFullName({
  firstName,
  lastName
}: {
  firstName: string;
  lastName: string;
  [key: string]: any;
}) {
  if (!firstName) {
    if (!lastName) return '-';
    else return lastName;
  } else {
    return firstName + ' ' + lastName;
  }
}
