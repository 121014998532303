import coolremoteSDK from "coolremote-sdk";
import { Action, action, actionOn, ActionOn, debug , Thunk, thunk} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IGroup {
  id: string;
  units: string[];
  name?: string;
  site: string;
  customer: string;
  description?: string;
  role: any;
}

export interface IGroupMap {
  [key: string]: IGroup;
}

interface IPowerPayload {
  groupId: string;
  state: number;
}

interface ISetPointPayload {
  groupId: string;
  setPoint: number;
}

export interface IGroupsModel {
  allGroups: IGroupMap;
  initialize: Action<IGroupsModel, any>;
  onInitialized: ActionOn<IGroupsModel, IRootStoreModel>;
  getFullGroups: Thunk<IGroupsModel, void>;
  changePowerState: Thunk<IGroupsModel, IPowerPayload>;
  changeSetPoint: Thunk<IGroupsModel, ISetPointPayload>;
}

export const groupsModel: IGroupsModel = {
  allGroups: {},

  initialize: action((state, payload) => {
    const newGroups: IGroupMap = _(Object.values(payload))
      .map((group: any) => {
        const newGroup: IGroup = { ...group };

        return newGroup;
      })
      .keyBy("id")
      .value();
    return { ...state, allGroups: newGroups };
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),
  getFullGroups: thunk(async (actions, payload) => {
  return coolremoteSDK.Group.getFullGroups();
}),
  changeSetPoint: thunk((actions, payload) => {
    const { groupId, setPoint } = payload;

    return coolremoteSDK.Group.setActiveSetpoint(groupId, setPoint);
  }),
  changePowerState: thunk((actions, payload) => {
    const { groupId, state } = payload;

    return coolremoteSDK.Group.setActiveOperationStatus(groupId, {
      operationStatus: state
    });
  })

};
