import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },

  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },

  "@media (max-width: 970px)": {
    contentArea: {
      overflowX: "unset"
    }
  },

  siteViewContainer: {
    background: "white",
    marginBottom: "1rem !important",
    borderRadius: "4px",
    boxSizing: "border-box",
    display: "flex"
  },
  sitesContainer: {
    padding: "0 20px",
    display: "flex",
    overflow: "auto"
  },

  editSiteDialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "30%"
  },

  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "-webkit-fill-available"
  },
  whiteMoreVert: { height: 24, width: 3, "& path": { fill: "#fff" } },
  dragContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    backgroundColor: "#aaa2aa"

  },
  siteHolder: { width: "100%", padding: "18px 12px 8px" },
  siteviewHolder: { width: "100%" }
});
export default useStyles;
