import { Customer as sdkCustomer, Scheduler as sdkScheduler } from "coolremote-sdk";
import {
  Action,
  action,
  Thunk,
  thunk,
} from "easy-peasy";

interface ISchedulePayload {
  id: string;
  data: object;
}

interface IGetSchedulePayload {
  id: string | any;
  objectType: "unit" | "group";
}

interface ICreateSchedulePayload {
  objId: string;
  objectType: "unit" | "group";
  data: any;
}

export interface IScheduleModel {
  schedules: any;
  getSchedule: Thunk<IScheduleModel, string, any>;
  getCustomerSchedules: Thunk<IScheduleModel, string | null>;
  deleteSchedule: Thunk<IScheduleModel, string, any>;
  updateSchedule: Thunk<IScheduleModel, ISchedulePayload, any>;
  removeSchedule: Action<IScheduleModel, any>;
  getObjectSchedules: Thunk<IScheduleModel, IGetSchedulePayload, any>;
  createObjectSchedules: Thunk<IScheduleModel, ICreateSchedulePayload, any>;

}

export const scheduleModel: IScheduleModel = {
  schedules: [],
  getSchedule: thunk((actions, payload) => {

    return sdkScheduler.getScheduleById(payload);
  }),
  getCustomerSchedules: thunk((actions, payload) => {

    return sdkCustomer.getSchedules(payload);
  }),
  deleteSchedule: thunk((actions, payload,) => {

    return sdkScheduler.delete(payload);
  }),
  removeSchedule: action((state, payload) => {
    const { schedules: newScedules } = state;
    delete newScedules[payload];

    state.schedules = newScedules;
  }),

  updateSchedule: thunk((actions, payload) => {
    const { id, data } = payload;

    return sdkScheduler.update(id, data);
  }),
  getObjectSchedules: thunk((actions, payload) => {
    const { id, objectType } = payload;
    return sdkScheduler.getObjectScheduleById(objectType, id);
  }),
  createObjectSchedules: thunk((actions, payload) => {

    const { objId, data, objectType } = payload;
    return sdkScheduler.createObjectSchedule(objectType, objId, data);
  })
};
