import { fade, makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxHeight: "calc(100vh - 150px)",
      overflow: "hidden",
      width: "800px",
      maxWidth: "calc(100vw - 600px)"
    },
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: 20,
      boxSizing: "border-box",
      overflow: "auto",
      maxHeight: "calc(100vh - 220px)"

    },
    notesSection: {
      width: "100%",
      // heigth: "45%",
      display: "flex",
      flexDirection: "column"
      // overflowY: "auto",
      // maxHeight: "347px"
    },
    attachSection: {
      width: "100%",
      // heigth: "45%",
      display: "flex",
      flexDirection: "column",
      marginTop: 40
      // overflowY: "auto",
      // maxHeight: "347px"
    },
    sectionHeader: {
      display: "flex",
      justifyContent: "flex-start"
    },
    deviderStyle: {
      margin: "20px 0"
    },
    newNoteColl: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%"
    },
    saveBtns: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-end",
      width: "100%"
    },
    sysBtn: {
      color: "#fff",
      backgroundColor: "#a1e87d",
      width: "80%",
      borderColor: "#a1e87d",
      "&:hover": {
        color: "#a1e87d"
      },
      "&:disabled": {
        border: "none"
      }
    },
    btn: {
      marginLeft: 10,
      maxHeight: 30,
      fontSize: "13px !important",
      fontFamily: "Roboto !important",
      marginTop: 10
    },
    cancelNewNoteBtn: {
      alignSelf: "flex-start"
    },
    fileBtn: {
      width: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px"
    },
    fileBtnlabel: {
      flexDirection: "column"
    },
    filesSection: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap"
    },
    fileNameStyle: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%"
    },
    headerTitle: {
      fontFamily: "RobotoMedium",
      color: "#29132e",
      lineHeight: "normal",
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "normal"
    },
    dialogHeader: {
      paddingLeft: 20,
      paddingRight: 13,
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
    fileHolder: {
      maxWidth: "fit-content", // to enhance design it can be removed and replaced with new devs to hanle the position of download
      height: 50,
      boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
      border: "solid 1px #d5d2d5",
      padding: "0 16px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginRight: 16,
      marginBottom: 10,
      borderRadius: 8,
      flex: 1
    },
    fileIcon: {
      width: 24,
      height: 24
    },
    title: {
      marginBottom: 15
    },
    link: {
      fontFamily: "Roboto",
      fontSize: 13,
      fontWeight: "normal",
      lineHeight: 1.85,
      letterSpacing: "normal",
      color: "#545964",
      textDecoration: "none"
    },
    fileName: {
      fontFamily: "Roboto", marginLeft: 16, marginRight: 40, opacity: 0.89, fontSize: 16, fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#29132e",
      maxWidth: "calc(100% - 129px)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    closeIcon: { padding: "6px", marginRight: "-6px" },
    textField: {
      height: 50,
      border: 0,
      "& div": {
        padding: 0,
        height: 50,
        border: 0,
        "& fieldset": {
          border: 0
        }
      }
    },
    errorText: {marginLeft: "20px", color: "red", fontSize: "12px"},
    loaderContainer: {
      width: 32.8,
      alignSelf: "center",
      marginLeft: 20
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
  })
);

export default useStyles;
