import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'rgb(236, 235, 239)',
    maxHeight: '-webkit-fill-available'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 16px',
    minHeight: '70px'
  },

  usersContainer: {
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    paddingBottom: '1rem',
    padding: '0 16px'
  },
  tableContainer1: {
    height: 'calc(100vh - 175px)',
    padding: '0.6rem 1rem 1rem 0',
    width: 'auto'
  },
  tableHeadRow: {
    // height: '45px'
  },
  overWritePadding: {
    fontSize: '0.8rem',
    padding: '0 0.8rem',
    cursor: "pointer"
  },
  tableHeadCell: {
    borderBottom: 'none',
    padding: '0 15px',
    fontSize: '13px',
    color: '#79797d',
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    backgroundColor: '#efeff2',
    height: 45,
  },
  editIcon: {
    fontSize: '1rem',
    cursor: 'pointer',
    color: 'initial',
    '&:hover': {
      color: 'green'
    }
  },
  deleteIcon: {
    fontSize: '1rem',
    cursor: 'pointer',
    color: 'initial',
    '&:hover': {
      color: 'red'
    }
  },

  title: {
    borderBottom: '1px gray solid',
    marginBottom: '2rem',
    width: '100%',
    lineHeight: '50px'
  },
  addNewUser: {
    borderRadius: '4px',
    width: '15%',
    alignSelf: 'flex-end',
    color: '#fff',
    background: 'linear-gradient(-60deg, rgb(66, 26, 64) 50%, rgb(41, 19, 46) 50%)',
    fontFamily: 'RobotoMedium',
    fontSize: '0.875rem',
    textAlign: 'center',
    marginRight: '1rem'
  },

  mainTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconButton: {
    minWidth: 'fit-content',
    padding: '0'
  },

  headMenuTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'white',
    marginBottom: '1.5rem',
    width: '100%',
    lineHeight: '6rem',
    borderBottom: '1px rgb(87, 69, 92) solid',
    padding: '0 10px 0 10px',
    boxSizing: 'border-box'
  },
  '[class^="NavigationBar"]': {
    padding: 0
  },
  body: {
    background: '#fafafa'
  },
  emailColor: {
    color: '#0079ff'
  },
  iconsColumnWidth: { maxWidth: '7rem', width: '5rem' },
  limitWidth: { wordBreak: 'break-word', width: '15%' },
  breakLine: { wordBreak: 'break-word', padding: "0 15px",
    height: "35px" },
  tableContainer: {
    display: 'flex',
    height: '100%',
  },

  paperTableContainer: {
    boxSizing: 'border-box',
    margin: '1rem',
    marginTop: 0,
    overflow: 'auto',
    height: '100%',
    padding: '20px',
    maxHeight: 'calc(100% - 130px)',
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: '250px'
  },

  table: {
    background: theme.palette.background.paper
  }
}));

export default useStyles;
