// prettier-ignore
import { File as fileSdk, Site as siteSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { makeFullAddressForSite } from "../utils/CommonUtils";
import { IAlert } from "./Alerts";
import { IRootStoreModel } from "./RootStore";

export interface ISite {
  id: string;
  name: string;
  description?: string;
  country?: string;
  city?: string;
  state?: string;
  address?: string;
  postalCode?: string;
  timezone?: string;
  devices: string[];
  zones: string[];
  triggers: string[];
  alerts: string[];
  customer: string;
  unitSupportedOperationModes: number[];
  unitSupportedFanModes: number[];
  unitSupportedSwingModes: number[];
  unitTemperatureLimits?: any;
  shouldAutoLimit?: boolean;
  role: any;
  lat: any;
  lon: any;
  serviceStatus: number;
  subscriptions?: string[];
}

export interface ISiteMap {
  [key: string]: ISite;
}

export interface ISiteAlertsCount {
  systemError: number;
  indoorError: number;
  maintenance: number;
  notConnected: number;
  anomalies: number;
}

export interface ISitesModel {
  allSites: ISiteMap;
  warnedSites: ISite[];
  blockedSites: ISite[];
  initialize: Action<
    ISitesModel,
    {
      all: any;
      warned: any;
      blocked: any;
    }
  >;
  onInitialized: ActionOn<ISitesModel, IRootStoreModel>;
  getSite: Computed<ISitesModel, (id?: string | null) => ISite | undefined>;
  getSiteName: Computed<ISitesModel, (id?: string) => string>;
  getSiteFullAddress: Computed<ISitesModel, (id: string) => string>;
  getSiteAlerts: Computed<
    ISitesModel,
    (id: string, type: "all" | "unresolved") => IAlert[],
    IRootStoreModel
  >;
  getSiteByName: Computed<
    ISitesModel,
    (name: string | undefined) => ISite | null
  >;
  changeSitePower: Thunk<ISitesModel, { siteId: string | null; state: number }>;
  changeSiteSetpoints: Thunk<
    ISitesModel,
    { siteId: string | null; setpoint: number }
  >;
  // getSiteSystems: Computed<
  //   ISitesModel,
  //   (id: string) => ISystem[] | any,
  //   IRootStoreModel
  // >;
  // getAlertsCountBySelection: Computed<
  //   ISitesModel,
  //   (id: string) => ISiteAlertsCount,
  //   IRootStoreModel
  // >;
}

export const sitesModel: ISitesModel = {
  allSites: {},
  warnedSites: [],
  blockedSites: [],
  initialize: action((state, payload) => {
    state.allSites = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  // getSiteSystems: computed(
  //   [
  //     state => state.allSites,
  //     (state, storeState) => storeState.systems.allSystems
  //   ],
  //   (allSites, allSystems) => id => {
  //     if (!allSites[id]) return [];
  //     const site = allSites[id];
  //     // return
  //     const res = _.filter(Object.values(allSystems), (system: ISystem) => {
  //       return _.includes(site.devices, system.device);
  //     });
  //     return res;
  //   }
  // ),

  getSite: computed([(state) => state.allSites], (allSites) =>
    memo((id) => {
      if (_.isNil(id)) {
        return undefined;
      }
      return allSites[id];
    }, 100)
  ),

  getSiteName: computed([(state) => state.allSites], (allSites) => (id) => {
    if (id && allSites[id]) {
      return allSites[id].name;
    } else {
      return "-";
    }
  }),
  getSiteByName: computed((state) => (name) => {
    const foundSites = _.filter(
      _.values(state.allSites),
      (site: ISite) => site.name === name
    );
    if (foundSites.length > 0) {
      if (foundSites.length > 1) {
        return null;
      }

      return foundSites[0];
    }
    return null;
  }),
  getSiteFullAddress: computed([(state) => state.allSites], (allSites) => (id) => {
    const site = allSites[id];
    return makeFullAddressForSite(site);

    // if (!site) {
    //   return "";
    // }
    // let address = "";
    // if (!site.country) return "";
    // // US version !
    // if (site.address) address += site.address;
    // if (site.city) address += `, ${site.city}`;
    // if (site.state) address += `, ${site.state}`;
    // if (site.postalCode) address += `, ${site.postalCode}`;
    // address += `, ${site.country}`;
    // return address;
  }),
  getSiteAlerts: computed(
    [
      (state) => state.allSites,
      (state, storeState) => storeState.alerts.allOpenAlerts,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.units.allUnits
    ],
    (allSites, allOpenAlerts, allDevices, allUnits) =>
      memo((id, type) => {
        if (_.isUndefined(allSites[id])) {
          return [];
        }
        return _.filter(Object.values(allOpenAlerts), (alert: IAlert) => {
          if (alert.data.trigger.template.indexOf("UNIT_ERROR") !== -1) {
            const siteDevices = Object.values(allDevices).filter(
              (device: any) => device.site === alert.site
            );
            const siteUnits = Object.values(allUnits).filter((unit: any) =>
              _.includes(siteDevices, allDevices[unit.device])
            );
            const erroredUnit = siteUnits.filter(
              (unit: any) => unit.internalId === alert.data.eventData.unitId
            )[0];
            if (!erroredUnit) {
              return false;
            }
          }
          if (_.includes(allSites[id].alerts, alert.id)) {
            if (
              (type === "unresolved" && _.isNil(alert.clearTime)) ||
              type === "all"
            ) {
              return true;
            }
          }
          return false;
          // _.includes(allSites[id].alerts, alert.id) &&
          // (type === 'all' ||
          //   (type === 'unresolved' && !_.has(alert, 'clearTime')))
        });
      }, 100)
  ),
  changeSitePower: thunk(async (actions, payload) => {
    const { siteId, state } = payload;
    return siteSdk.changeSitePower(siteId, state);
  }),
  changeSiteSetpoints: thunk(async (actions, payload) => {
    const { siteId, setpoint } = payload;
    return siteSdk.changeSiteSetPoints(siteId, setpoint);
  })

  // getAlertsCountBySelection: computed(
  //   [
  //     state => state.allSites,
  //     (state, storeState) => storeState.selections.selections.siteId,
  //     (state, storeState) => storeState.selections.selections.systemId,
  //     (state, storeState) => storeState.selections.getSitesBySelection,
  //     (state, storeState) => storeState.alerts.allOpenAlerts,
  //     (state, storeState) => storeState.alerts.getAlertSeverity,
  //     (state, storeState) => storeState.units.allUnits,
  //     (state, storeState) => storeState.units.getUnitByInternalOrProId
  //   ],
  //   (
  //     allSites,
  //     selectedSiteId,
  //     selectedSystemId,
  //     sitesBySelection,
  //     allOpenAlerts,
  //     getAlertSeverity,
  //     allUnits,
  //     getUnitByInternalOrProId
  //   ) =>
  //     memo(id => {
  //       const alertsCount: ISiteAlertsCount = {
  //         systemError: 0,
  //         indoorError: 0,
  //         maintenance: 0,
  //         notConnected: 0,
  //         anomalies: 0
  //       };

  //       if (_.isUndefined(allSites[id])) return alertsCount;

  //       const currentSites = selectedSiteId
  //         ? Object.values(allSites).filter(site => site.id === selectedSiteId)
  //         : allSites;
  //       _.forEach(currentSites, (site: ISite) => {
  //       _.forEach(site.alerts, (alertId: string) => {
  //         const alertSeverity = getAlertSeverity(alertId);
  //         const alert = allOpenAlerts[alertId];

  //         const alertInternalUnitId = alert.data.eventData
  //           ? alert.data.eventData.unitId
  //           : undefined;
  //         let alertUnitId = undefined;

  //         // Try to find out unit id from alert
  //         if (alertInternalUnitId) {
  //           const foundUnit: IUnit | null = getUnitByInternalOrProId(
  //             alertInternalUnitId
  //           );

  //           if (foundUnit) {
  //             alertUnitId = foundUnit.id;
  //           }
  //         }

  //         //_.isUndefined(alertUnitId) && alert.data.type !== 'DEVICE_DISCONNECTED' && //console.log(`Can't find unit for alert`, alert);

  //         // Device alert
  //         if (
  //           _.has(alert, 'data.type') &&
  //           alert.data.type === 'DEVICE_DISCONNECTED'
  //         ) {
  //           if (_.isNil(alert.clearTime)) {
  //             ++countNotConnected;
  //           }
  //         }
  //         // Or system alert. Make sure the alert's unit corresponds to selection
  //         else if (
  //           alertUnitId &&
  //           (!selectedSystemId /* all systems */ ||
  //             allUnits[alertUnitId].system === selectedSystemId)
  //         ) {
  //           // Count only unresolved alerts and only alerts which relate to existing units
  //           if (
  //             _.isNil(alert.clearTime) &&
  //             getUnitByInternalOrProId(alert.data.eventData.unitId) !== null
  //           ) {
  //             if (alertSeverity.severity === 5) {
  //               ++countSystemError;
  //             } else if (alertSeverity.severity === 4) {
  //               ++countIndoorError;
  //             } else if (alertSeverity.severity === 3) {
  //               ++countMaintenance;
  //             } else if (alertSeverity.severity === 2) {
  //               ++countNotConnected;
  //             } else if (alertSeverity.severity === 1) {
  //               ++countAnomalies;
  //             }
  //           }
  //         }
  //       });
  //     })
  //     }, 100)
  // )
};
