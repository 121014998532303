import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import moment from "moment-timezone";
import alertSeverities from "../constants/alertSeverities";
import { IRootStoreModel } from "./RootStore";
const severties: any = alertSeverities;
export interface IAlert {
  id: string;
  type: string;
  eventTime: number;
  site: string;
  trigger: string | null;
  isRead: boolean;
  data: any;
  initiator: string | null;
  clearTime?: number;
  clearReason?: number;
  shortId: string;
  status: number;
}

export interface IAlertMap {
  [key: string]: IAlert;
}

export interface IAlertsModel {
  allOpenAlerts: IAlertMap;
  allAlerts: IAlertMap;
  initialize: Action<IAlertsModel, any>;
  onInitialized: ActionOn<IAlertsModel, IRootStoreModel>;
  fetchUpdatedAlert: Thunk<IAlertsModel, { id: string }>;
  sendAlertEmail: Thunk<IAlertsModel, { id: string; emails: string[] }>;
  deleteAlert: Thunk<IAlertsModel, string>;
  setAcknowledgedEvent: Thunk<IAlertsModel, { id: string; data: any }>;
  _storeDeleteAlert: Action<IAlertsModel, string>;
  getAlertsPaginated: Thunk<
    IAlertsModel,
    {
      filter: string;
      id: string;
      page: number;
      rowsPerPage: number;
      startDate: any;
      endDate: any;
      types: any;
      codes: any;
      statuses: any;
    }
  >;
  _storeFetchUpdatedAlert: Action<IAlertsModel, { alertId: string; alert: IAlert }>;
  isResolved: Computed<IAlertsModel, (id: string) => boolean>;
  timeResolved: Computed<IAlertsModel, (id: string) => number | undefined>;
  getAlertsByFilter: Computed<
    IAlertsModel,
    (
      filter: {
        customerId?: string | null | undefined;
        siteId?: string | null | undefined;
        systemId?: string | null | undefined;
        unitId?: string | null | undefined;
      },
      showOpenAlertsOnly?: boolean,
      hideUnitDisconnected?: boolean
    ) => any,
    IRootStoreModel
  >;
  getAlertFeildsNamesAndIds: Computed<IAlertsModel, any>;
  openParsedAlerts: Computed<IAlertsModel>;

  parsedAlerts: Computed<IAlertsModel>;

  updateAlert: Thunk<IAlertsModel, { id: string; data: any }>;
  getAlertsByFilters: Thunk<IAlertsModel, { startTime?: any; endTime?: any, status?: any, type?: any }>;
}

const eventTypesToSeverityMapping: any = {
  cleanFilter: "MAINTENANCE",
  customTelemetry: "ANOMALIES",
  deviceDisconnected: "NOT_CONNECTED",
  indoorUnitError: "INDOOR_ERROR",
  outdoorUnitError: "SYSTEM_ERROR",
  unitDisconnected: "NOT_CONNECTED",
  unitsDisconnected: "NOT_CONNECTED",
  unspecified: "ALL_IS_WELL",
  entityHealthLowIstat: "NOT_CONNECTED",
  entityHealthLowOstat: "NOT_CONNECTED",
  entityHealthLowPstat: "NOT_CONNECTED",
  entityHealthLowLqstat: "NOT_CONNECTED",
  entityHealthLowSstat: "NOT_CONNECTED",
  entitiesHealthLowIstat: "NOT_CONNECTED",
  entitiesHealthLowOstat: "NOT_CONNECTED",
  entitiesHealthLowPstat: "NOT_CONNECTED",
  entitiesHealthLowLqstat: "NOT_CONNECTED",
  entitiesHealthLowSstat: "NOT_CONNECTED"
};

export const alertsModel: IAlertsModel = {
  allOpenAlerts: {},
  allAlerts: {},
  openParsedAlerts: computed([
    (state) => state.allOpenAlerts,
    (state, storeState: any) => storeState.serviceErrorTypes,
    (state, storeState: any) => storeState.customers.allCustomers,
    (state, storeState: any) => storeState.sites.allSites,
    (state, storeState: any) => storeState.units.allUnits,
    (state, storeState: any) => storeState.devices.allDevices,
    (state, storeState: any) => storeState.systems.allSystems,
    (state, storeState: any) => storeState.types.eventTypes,
    (state, storeState: any) => storeState.types.eventStatusTypes,
    (state, storeState: any) => storeState.eventTypesMirror,
    (state, storeState: any) => storeState.eventStatusTypesMirror,
    (state, storeState: any) => storeState.eventClearTypesMirror,
    (state, storeState: any) => storeState.alerts.getAlertFeildsNamesAndIds,
    (state, storeState: any) => storeState.users.timeFormat,
    (state, storeState: any) => storeState.users.dateFormat
  ],
    (allOpenAlerts, serviceErrorTypes, allCustomers, allSites, allUnits, allDevices, allSystems,
     eventTypes, eventStatusTypes, eventTypesMirror, eventStatusTypesMirror, eventClearTypesMirror,
     getAlertFeildsNamesAndIds, timeFormat, dateFormat) => {

      return parseAlerts(allOpenAlerts, serviceErrorTypes, allCustomers, allSites, allUnits, allDevices, allSystems,
        eventTypes, eventStatusTypes, eventTypesMirror, eventStatusTypesMirror, eventClearTypesMirror,
        getAlertFeildsNamesAndIds, timeFormat, dateFormat);

    }),
  parsedAlerts: computed([
    (state) => state.allAlerts,
    (state, storeState: any) => storeState.serviceErrorTypes,
    (state, storeState: any) => storeState.customers.allCustomers,
    (state, storeState: any) => storeState.sites.allSites,
    (state, storeState: any) => storeState.units.allUnits,
    (state, storeState: any) => storeState.devices.allDevices,
    (state, storeState: any) => storeState.systems.allSystems,
    (state, storeState: any) => storeState.types.eventTypes,
    (state, storeState: any) => storeState.types.eventStatusTypes,
    (state, storeState: any) => storeState.eventTypesMirror,
    (state, storeState: any) => storeState.eventStatusTypesMirror,
    (state, storeState: any) => storeState.eventClearTypesMirror,
    (state, storeState: any) => storeState.alerts.getAlertFeildsNamesAndIds,
    (state, storeState: any) => storeState.users.timeFormat,
    (state, storeState: any) => storeState.users.dateFormat
  ],
    (allAlerts, serviceErrorTypes, allCustomers, allSites, allUnits, allDevices, allSystems,
     eventTypes, eventStatusTypes, eventTypesMirror, eventStatusTypesMirror, eventClearTypesMirror,
     getAlertFeildsNamesAndIds, timeFormat, dateFormat) => (isOpen: boolean) => {
        return parseAlerts(allAlerts, serviceErrorTypes, allCustomers, allSites, allUnits, allDevices, allSystems,
          eventTypes, eventStatusTypes, eventTypesMirror, eventStatusTypesMirror, eventClearTypesMirror,
          getAlertFeildsNamesAndIds, timeFormat, dateFormat);
      }),

  initialize: action((state, payload) => {
    // const newAlerts: IAlertMap = _(Object.values(payload))
    //   .map((alert: any) => {
    //     const newAlert: IAlert = { ...alert };

    //     return newAlert;
    //   })
    //   .keyBy("id")
    //   .value();

    const { events, openAlertsOnly = true } = payload;

    if (openAlertsOnly) {
      state.allOpenAlerts = events;
    } else {
      state.allAlerts = events;
    }
  }),

  onInitialized: actionOn(
    (actions) => [actions.initialize],
    () => { }
  ),
  sendAlertEmail: thunk((actions, payload) => {
    return coolremoteSDK.Services.sendEventEmail({ mailList: payload.emails }, payload.id);
  }),
  _storeDeleteAlert: action((state, payload) => {
    if (!state.allOpenAlerts[payload]) {
      return;
    }
    const { [payload]: _, ...otherAlerts } = state.allOpenAlerts;

    state.allOpenAlerts = otherAlerts;
  }),
  deleteAlert: thunk(async (actions, payload) => {
    await coolremoteSDK.Event.delete(payload);

    actions._storeDeleteAlert(payload);
  }),
  setAcknowledgedEvent: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.setAcknowledgedEvent(id, { value: data });
  }),
  updateAlert: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.update(id, data);
  }),
  getAlertsPaginated: thunk(async (actions, payload) => {
    const res = await coolremoteSDK.Event.getEventsPaginated(payload),
      alerts = res.retAlertObj,
      { length } = res,
      data = { alerts, length };
    return data;
  }),

  fetchUpdatedAlert: thunk(async (actions, payload) => {
    const alert = await coolremoteSDK.Event.fetch(payload.id);

    actions._storeFetchUpdatedAlert({ alertId: payload.id, alert });
  }),

  _storeFetchUpdatedAlert: action((state, payload) => {
    const { allOpenAlerts, allAlerts } = state;

    const { alert, alertId } = payload;
    if (alert.status === 1) {
      state.allOpenAlerts[alertId] = alert;
    } else {
      delete allOpenAlerts[alertId];
      state.allOpenAlerts = allOpenAlerts;
    }
  }),
  isResolved: computed([(state) => state.allOpenAlerts], (allOpenAlerts) => (id) => {
    if (_.isUndefined(allOpenAlerts[id]) || !_.isUndefined(allOpenAlerts[id].clearTime)) {
      return true;
    }
    else {
      return false;
    }
  }),

  timeResolved: computed([(state) => state.allOpenAlerts], (allOpenAlerts) => (id) => {
    if (_.isUndefined(allOpenAlerts[id])) {
      return undefined;
    }
    else {
      return allOpenAlerts[id].clearTime;
    }
  }),

  getAlertsByFilter: computed([
    (state) => state.parsedAlerts,
    (state) => state.openParsedAlerts,
    (state, storeState: any) => storeState.types,
    (state, storeState: any) => storeState.units.allUnits
  ],

    (parsedAlerts, openParsedAlerts, types, allUnits) => (filter, showOpenAlertsOnly = true, hideUnitDisconnected = true) => {
      const { eventTypes } = types;
      const alertsToFilter = showOpenAlertsOnly ? Object.values(openParsedAlerts) : Object.values(parsedAlerts);
      const { customerId, siteId, systemId, unitId } = filter;
      return _.filter(_.values(alertsToFilter), (alert: any) => {
        if (hideUnitDisconnected && alert.type === eventTypes.unitDisconnected) {
          return false;
        }

        if (!hideUnitDisconnected && alert.type === eventTypes.unitsDisconnected) {
          return false;
        }

        if (!!systemId && !alert.systemId) {
          return false;
        }

        if (!!unitId && alert.unitId !== unitId) {
          return false;
        }

        if (alert.unitId && !allUnits[alert.unitId]?.isVisible) {
          return false;
        }

        if (!!systemId && (alert.systemId ? alert.systemId !== systemId : (alert.systemIds.length > 1 && !alert.systemIds.includes(systemId)))) {
          return false;
        }

        if (!!siteId && alert.siteId !== siteId) {
          return false;
        }

        if (!!customerId && alert.customer !== customerId) {
          return false;
        }

        return true;
      });

    }),
  getAlertFeildsNamesAndIds: computed(
    [(state, storeState: any) => storeState.sites.allSites,
    (state, storeState: any) => storeState.units.allUnits,
    (state, storeState: any) => storeState.devices.allDevices,
    (state, storeState: any) => storeState.systems.allSystems,
    (state, storeState: any) => storeState.types.eventTypes],
    (allSites, allUnits, allDevices, allSystems, eventTypes) => (alert: any) => {
      return mapAlertFields(allSites, allDevices, allSystems, allUnits, eventTypes, alert);

    }),
  getAlertsByFilters: thunk(async (actions, payload: any = "") => {
    const alerts = coolremoteSDK.Event.getEvents(payload);
    return alerts;
  })
};

const mapAlertFields = (sites: any, devices: any, systems: any, units: any, eventTypes: any, alert: any) => {
  const { type, resources = [], status } = alert;
  const fieldsObj: any = { eventNames: { siteName: "", deviceName: "", systemName: "", unitName: "" }, eventIds: { siteId: "", systemId: "", unitId: "", unitIds: [], systemIds: [] } };
  if (resources.length === 0) { return fieldsObj; }

  const { eventNames, eventIds } = fieldsObj;
  //device disconnected
  if (eventTypes.deviceDisconnected === type || eventTypes.deviceHealthLowIstat === type || eventTypes.deviceHealthLowOstat === type || eventTypes.deviceHealthLowPstat === type || eventTypes.deviceHealthLowLqstat === type || eventTypes.deviceHealthLowSstat === type) {
    const device = devices[resources[0].id];
    eventNames.deviceName = device ? device.serial : (resources[0]?.name || "");
    eventNames.siteName = (device && device.site && sites[device.site]?.name) || "";
    eventIds.siteId = device ? device.site : "";
    if (status === 1) { eventNames.unitName = eventNames.deviceName; }
    return fieldsObj;
  }

  let siteId = "";
  let deviceName = "";

  for (let i in resources) {
    const { id = "" } = resources[i];
    const storeUnit: any = units[id];

    if (storeUnit) {
      let { site = "", system = "", device = "" } = storeUnit;
      if (storeUnit.type === 1 && storeUnit.serviceUnits.length) {
        const serviceUnit = units[storeUnit.serviceUnits[0]];
        system = serviceUnit ? serviceUnit.system : "";
      }

      if (!siteId) {
        siteId = site;
      }

      if (!deviceName) {
        deviceName = devices[device]?.serial || "";
      }

      (system && !eventIds.systemIds.includes(system)) && eventIds.systemIds.push(system);
      eventIds.unitIds.push(id);
    }
  }

  if (_.isEmpty(eventIds.unitIds)) {
    return fieldsObj;
  }

  const unitId = eventIds.unitIds.length === 1 ? eventIds.unitIds[0] : "";
  const systemId = eventIds.systemIds.length === 1 ? eventIds.systemIds[0] : "";
  eventNames.unitName = unitId ? units[unitId]?.name : "multiple";
  eventNames.systemName = systemId ? systems[systemId]?.name : _.isEmpty(eventIds.systemIds) ? "" : "multiple";
  eventIds.unitId = unitId;
  eventIds.systemId = systemId;
  eventNames.siteName = sites[siteId]?.name || "";
  eventNames.deviceName = deviceName;
  eventIds.siteId = siteId;

  return fieldsObj;

};

const parseAlerts = (allAlerts: any, errorTypes: any, allCustomers: any, allSites: any, allUnits: any, allDevices: any, allSystems: any,
                     eventTypes: any, eventStatusTypes: any, eventTypesMirror: any, eventStatusTypesMirror: any, eventClearTypesMirror: any,
                     getAlertFeildsNamesAndIds: any, timeFormat: any, dateFormat: any) => {

  const parsedAlerts: any = Object.create(allAlerts);

  Object.values(allAlerts).forEach((alert: any) => {

    const { eventNames, eventIds } = getAlertFeildsNamesAndIds(alert);

    //error is here
    if (!eventIds.siteId) {
      return;
    }

    const timezone = allSites[eventIds.siteId]?.timezone || "";
    const { data, type } = alert;
    const errorCode = eventTypes.indoorUnitError === type  ||
      eventTypes.outdoorUnitError === type ? data : "";
    let errorDescription = "";

    if ((errorCode || errorCode === 0) && eventIds.systemId){
      const { brandNum } =  allSystems[eventIds.systemId] || {};
      errorDescription = getErrorDescription(errorTypes.errorCodeTypes, brandNum, errorCode);
      errorDescription = errorDescription;
    }

    parsedAlerts[alert.id] = {
      ...alert,
      time: moment(alert.eventTime)?.tz(timezone)?.format(`${dateFormat}   ${timeFormat}`),
      clearTime: moment(alert.clearTime)?.tz(timezone)?.format(`${dateFormat}   ${timeFormat}`),
      status: eventStatusTypesMirror[alert.status] || "",
      alertType: alert.type === 1 ? "Anomaly" : (eventTypesMirror[alert.type].split(/(?=[A-Z])/)).map((word: string) => {
        word = word[0].toUpperCase() + word.substr(1);
        return word;
      }).join(" "),
      siteName: eventNames.siteName,
      unitName: eventNames.unitName || "-",
      deviceName: eventNames.deviceName,
      systemName: eventNames.systemName || "-",
      customerName: allCustomers[alert.customer]?.name || "",
      clearReason: eventClearTypesMirror[alert.clearReason],
      alertItemContainerIds: { ...eventIds, customerId: alert.customer },
      description: alert.trapDescription,
      errorCode: eventTypes.unitsDisconnected === alert.type ? "" : alert.data,
      timestamp: alert.eventTime,
      severity: severties[eventTypesToSeverityMapping[eventTypesMirror[alert.type]]],
      trapTemplateId: alert?.trapTemplateId || "",
      errorDescription,
      timezone,
      ...eventIds
    };

  });

  return parsedAlerts;

};

const getErrorDescription = (errorCodeTypes: any = {}, brand: any, errorCode: any) => {
      if (!brand && brand !== 0){
        return "";
      }

      const brandsErrors = errorCodeTypes[brand] || {};
      if (_.isEmpty(brandsErrors)){
        return "";
      }

      return brandsErrors[errorCode] || brandsErrors[+errorCode] || "";
    };
