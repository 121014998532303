import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px",
    boxSizing: "border-box"
  },

  "[class^=\"NavigationBar\"]": {
    padding: "0"
  },

  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },

  heading: {
    width: "100%"
  },

  alertTypesContainer: {
    width: "100%",
    height: "calc(100vh - 80px)",
    padding: "0 20px",
    display: "flex",
    overflow: "hidden"
  },

  alertTypesCard: {
    width: "inherit"
  },

  alertTypesItem: {
    marginLeft: "1rem",
    marginRight: "1rem",
    // marginTop: "0.5rem",
    marginBottom: "0.5rem",
    border: "1px solid lightgray"
  },

  userName: {
    margin: "1rem 1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    "& >svg": {
      marginRight: "0.5rem"
    }
  },
  alertGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    margin: "1rem"
  },

  switch: {
    display: "flex",
    justifyContent: "flex-end"
  },

  icons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  content: {
    margin: "1rem"
  },
  userHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    margin: "1rem"
  },
  chosenAlert: {
    backgroundColor: "rgb(236, 235, 239)"
  },
  userIcon: {
    backgroundColor: "lightgray"
  },
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  alertsSettingsContainer: {
    height: "calc(100% - 140px)",
    display: "flex",
    padding: "1rem",
    paddingTop: 0,
    overflow: "hidden"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem 1.5rem",
    background: "rgb(236, 235, 239)"
  },
  tableContainer: {
    height: "calc(100vh - 175px)",
    padding: "0",
    width: "auto"
  },
  table: {
    // height: '100%',
    // overflow: 'auto',
    padding: "0 1rem",
    background: theme.palette.background.paper
  },
  tableHeadRow: {
    height: "3rem"
  },
  overWritePadding: {
    fontSize: "0.8rem",
    padding: "0 0.8rem"
  },
  overrideFontSize: {
    fontSize: "2rem",
    "& svg": {
      width: "38px"
    }
  },
  tableHeadCell: {
    height: 56,
    borderBottom: "none",
    padding: "16px 15px",
    color: "#79797d",
    backgroundColor: "#efeff2",
    textTransform: "uppercase",
    "& p": {
      fontSize: "13px",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal"
    }
  },
  rowContainer: {
    display: "flex",
    alignItems: "center"
  },
  grayCheckbox: {
  "&$checked": {
    color: "#736973"
  }},
  selectedUnit: {
    backgroundColor: "#edebed"
  },
  tableContainer1: {
    height: "calc(100vh - 185px)",
    margin: "auto",
    width: "95.5%"
  },
  modeHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative"
  },
  alertGroupHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    margin: "1rem"
  },
  textField: {
    marginLeft: "10px",
    " & div": {
      height: "30px",
      width: "160px"
    }
  },
  plusIcon: {
    "& svg ": {
    "& g": {
      "& rect": {
        fill: "none"
      }
    }
  },
    "&:hover": {
      "& $addAlertIcon": {
        display: "none"
      },
      "& $addOnHoverIcon": {
        display: "inline"
      }
    }
  },
  container: {
    minHeight: "-webkit-fill-available",
    padding: "2rem",
    overflow: "auto"
  },
  user: {
    display: "flex",
    justifyContent: "space-between"
  },
  userContainer: {
    margin: "0 1rem 0 1rem",
    borderBottom: "1px solid lightgrey",
    "&:first-of-type": {
      margin: "-1rem 1rem 0 1rem"
    }
  },
  iconBtnStyle: {
     width: 30,
     height: 30,
     borderRadius: 6,
     padding: 0
   },
  addContactIcon: {
    marginRight: "2px"
  },
  contactActionsContainer : {
    display: "flex",
    alignItems: "center"
  },
  innerTableContainer: {
    marginBottom: "2rem"
  }
}));
export default useStyles;
