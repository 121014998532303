import * as React from 'react';

function SvgAnalog(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 5)">
          <path stroke="#FFF" d="M16 8A8 8 0 000 8" />
          <circle cx={13} cy={2} r={2} fill="#FFF" />
        </g>
        <g stroke="#FFF" strokeLinecap="round">
          <path d="M12 12.035l1.538 1.983L12 16M8 12.035l-1.538 1.983L8 16" />
        </g>
      </g>
    </svg>
  );
}

export default SvgAnalog;
