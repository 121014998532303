import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core/";
import { AccessTime, ReportProblemOutlined } from "@material-ui/icons/";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PulseLoader } from "react-spinners";
import { t } from "ttag";
import {UnitItem} from "../";
import { Switch } from "../../../../cool_widgets/Switch";
import {ArrowDownO} from "../../../../icons";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import {
  AutoMode,
  ColdMode,
  DryIcon,
  HotMode,
  ModeFanIcon
} from "../../../../svgComponents";
import { SensorItem } from "../SensorItem";
import useStyle from "./GroupItem.style";

interface IObject {
  [key: string]: any;
}

const ControlItem = (props: any) => {
  const {openCollapse = true, addMessage, disableDragging, handleDragging, item, siteId, selectItem, hasSchedules, selectedItem, changeSitePower , onDragEnd, isCelsius} = props;
  const {
    id: itemId,
    name,
    units,
    sensors = [],
    schedules = []
  } = item;

  const [togglePowerButton, setTogglePowerButton] = useState<boolean>(false);
  const [targetPowerVal, setTargetPowerVal] = useState<any>(null);
  const [open, setOpen] = useState<boolean>((selectedItem?.firstTimeLoading && selectedItem.groupId === itemId) || false);
  const [groupStatus, setGroupStatus] = useState<any>({
  power: false,
  setPoint: null,
  mode: null
});
  let spinnerTimer: number = 0;

  const operationStatusesMirror = useStoreState((state) => state.operationStatusesMirror);
  const groupPowerControl = useStoreActions((state) => state.groups.changePowerState);
  const allUnits = useStoreState((state) => state.units.allUnits);

  const  {on: powerOnVal, off : powerOffVal } = operationStatusesMirror;

  const classes = useStyle();

  useEffect(() => {
    if (!openCollapse) {
    setOpen(true);
    return;
    }
    // setOpen((selectedItem?.firstTimeLoading && selectedItem.groupId === itemId) || false);
  }, [openCollapse]);

  useEffect(() => {
  let power: boolean = false,
    setPoint: any = null,
    mode: any = null,
    first: boolean = true;
  const { units } = item;

  for (let x in units) {
    const unit = units[x];

    if (!unit) {
      continue;
    }
    if (first) {
      power = unit.activeOperationStatus === +powerOnVal;
      setPoint = unit.activeSetpoint;
      mode = unit.activeOperationMode;
      first = false;
      continue;
    }
    if (!unit) {
      continue;
    }
    if (!power && unit.activeOperationStatus === +powerOnVal) {
      power = true;
    }
    if (+x !== 0 && !isNaN(setPoint) && setPoint !== unit.activeSetpoint) {
      setPoint = null;
    }
    if (+x !== 0 && !isNaN(mode) && mode !== unit.activeOperationMode) {
      mode = null;
    }
  }
  setGroupStatus({ power, setPoint, mode });
}, [item, powerOnVal]);

  useEffect(() => {
    if (!togglePowerButton) {
      return;
    }
    if (
      groupStatus.power === targetPowerVal
    ) {
      setTogglePowerButton(false);
      clearTimeout(spinnerTimer);
    }
  }, [
    togglePowerButton,
    targetPowerVal,
    spinnerTimer,
    groupStatus
  ]);

  const switchClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const changeGroupPowerState = (event: any, powerOn: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    const state = powerOn ? 1 : 2;

    setTargetPowerVal(powerOn);
    setTogglePowerButton(true);
    spinnerTimer = window.setTimeout(() => {
      setTogglePowerButton(false);
    }, 5000);

    itemId === "allUnits" ? changeSitePower({state, siteId}) :  groupPowerControl({ groupId: itemId, state })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  const onUnitsCount = units.filter((unit: any) =>  (allUnits[unit[0]] || {})?.activeOperationStatus === 1).length;

  return (
    <Fragment>
    <div
    {...handleDragging}
      className={clsx(classes.groupStyle, {[classes.selected]: selectedItem.isGroup && selectedItem.groupId === itemId})}
      onClick={() => selectItem(siteId, {isGroup: true, groupId: itemId, unitId: "", type: ""})}
    >
    <IconButton onClick={(event: any) => {event.stopPropagation(); setOpen(!open); }} className={classes.iconHolder}>
    <ArrowDownO style={{transform: open ? "unset" : "rotate(270deg)"}}/>
    </IconButton>
    <div className={classes.innerContainer}>
          <div className={classes.locSec}>
            <Typography noWrap className={clsx(classes.nameStyle, {[classes.boldText]: selectedItem.isGroup && selectedItem.groupId === itemId})}>{name}</Typography>
          </div>
          <Grid container className={clsx(classes.powerAlertsSchedulesContainer, {[classes.hidden]: itemId === "sensors" || units.length === 0})}>
          <div className={classes.notesSec}>
          {schedules.length > 0 && <AccessTime className={classes.noteIcon} />}
          </div>
          <Typography className={classes.unitsCount}>{onUnitsCount}/{units.length}</Typography>

              <div className={clsx(classes.loader, {[classes.hideElement]: !togglePowerButton})}>
              <PulseLoader size={5} color="white" margin="3px" /></div>
            <Switch
              checked={onUnitsCount > 0}
              disableRipple={true}
              onClick={(e: any) => switchClick(e)}
              switchChange={(e: any) =>  changeGroupPowerState(e, !(onUnitsCount > 0))}
              value="checkedA"
            />
            </Grid>
            </div>
    </div>
    <DragDropContext onDragEnd={(result: any) => onDragEnd(result)(itemId)}>
                   <Droppable droppableId="list">
                       {(provided: any) => (
                         <div ref={provided.innerRef} {...provided.droppableProps}>
    {open && units.map((unit: any, index: number) =>

      <Draggable isDragDisabled={disableDragging ? true : false} key={`group-${itemId}-unit-${unit[0]}`} draggableId={unit[0]} index={index}>
              {(provided: any) => (<div ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                            {!unit[1] ? null :   <UnitItem addMessage={addMessage}  key={`u-group-${itemId}-unit-${unit[0]}`} selectedItem={selectedItem} unitId={unit[0]} siteId={siteId} selectItem={selectItem} groupId={itemId} hasSchedules={true}/>}</div>)}
      </Draggable>
    )}
    {open && !_.isEmpty(sensors) && sensors.map((sensor: any) =>
      <SensorItem  key={`group-${itemId}-sensor-${sensor.id}`} selectedItem={selectedItem} sensorId={sensor.id} siteId={siteId} selectItem={selectItem} groupId={itemId} isCelsius={isCelsius}/>
    )}

    {provided.placeholder}
                             </div>
                         )}
                     </Droppable>
                 </DragDropContext>
    </Fragment>
  );

};

export default ControlItem;
