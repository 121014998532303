import {
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { Field, getIn } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { CheckboxChecked, Delete, MoreVert } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import styles from "./AddRule.style";
import AnomaliesUtils from "./AnomaliesUtils";
import { CustomedTextField, CustomSelect, GButton } from "./CustomedComponents";

const theresholdOptionOnly = [
  { value: "threshold", label: "Difference" },
  { value: "> parameter", label: ">" },
  { value: "< parameter", label: "<" }
];
const equalOptionOnly = [{ value: "=", label: "=" }, { value: "!=", label: "≠" }];
const iduNumOption = { value: "iduNum", name: "# of Indoor Units" };
const oduNumOption = { value: "oduNum", name: "# of Outdoor Units" };
const theresholdOptions = [
  { value: "threshold", label: " >" },
  { value: "thresholdWithin", label: " <" }
];
const unitsNumOptions = [{ value: 1, label: "Number of units" }, { value: 2, label: "% of total system units" }];

export default (props: any) => {
  const {
    errors,
    touched,
    conditionsUnitsType,
    isUnitsSelected,
    showWranning,
    name,
    indoorParams,
    outdoorParams,
    condition,
    deleteCondition,
    index,
    onSelect,
    lastCondition,
    setFieldValue,
    disabled,
    groupKey,
    allParamsMap,
    unitTypes,
    enumParams,
    serviceParamTypes,
    conditions,
    nextCondition,
    hideIduNum,
    hideOduNum,
    editConditionRelation
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { inGroup } = props;
  const unitTypesOptions = useStoreState((state) => state.unitTypesOptions);
  const trapOperatorsOptions = useStoreState((state) => state.trapOperatorsOptions);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);
  const pressureUnit = useStoreState((s) => s.users.me.measurementUnits);
  const handleCheck = (event: any) => {
    const { target: { checked } } = event;
    onSelect(condition.id, checked);
  };
  const removeCheck = () => {
    onSelect(condition.id, false);
  };

  const matchParamLabelWithUnitType: any = {
    param1: {
      [unitTypes.service]: "Indoor Parameter",
      [unitTypes.outdoor]: "Outdoor Parameter",
      mixed: "Indoor Parameter"
    },
    param2: {
      [unitTypes.service]: "Indoor Parameter",
      [unitTypes.outdoor]: "Outdoor Parameter",
      mixed: "Outdoor Parameter"
    }
  };

  const handleUnitsChange = (event: any, name: any) => {
    const { target: { value } } = event;

    if (value && isUnitsSelected) {
      setFieldValue(name, "");
      showWranning(value, index, "unitsNumber", !!inGroup, groupKey || "");
      return;
    }
    setFieldValue(name, value);

  };

  const handleUnitsTypeChange = (event: any, name: any) => {
    const { target: { value } } = event;

    if (value === "iduNum" || value === "oduNum") {
      removeCheck();
      setFieldValue(name, value);
      return;
    }

    if (isUnitsSelected) {
      if (value === "mixed" || (value === unitTypes.service && conditionsUnitsType !== "indoorUnits") || (value === unitTypes.outdoor && conditionsUnitsType !== "outdoorUnits")) {
        showWranning(value, index, "unitType", !!inGroup, groupKey || "");
        return;
      }
    }

    const oldVal = !condition["unitType"] ? null : (condition["unitType"] === "mixed" ? "mixed" : (+unitTypes["service"] === +condition["unitType"] ? "indoor" : "outdoor"));
    const newVal = value === "mixed" ? "mixed" : (+unitTypes["service"] === +value ? "indoor" : "outdoor");

    condition.parameter = "";
    condition.parameter2 = "";
    setFieldValue(name, value);
  };
  const isMixed = condition.unitType === "mixed";
  const isThreshHold = condition.operator?.includes("threshold");
  const isParamOperator = condition.operator?.includes("parameter");
  const paramsOptions = (condition.unitType === unitTypes.service ? indoorParams : condition.unitType === unitTypes.outdoor ? outdoorParams : [...indoorParams, ...outdoorParams]);
  const isMixedThreshHold = isMixed && isThreshHold;
  const paramList1Options = isMixed ? indoorParams : paramsOptions;
  const paramList2Options = isMixed ? outdoorParams : paramsOptions;
  const selectedParamEnum = condition.parameter ? allParamsMap[condition.parameter]?.enum : undefined;
  const paramsEnumTypes = selectedParamEnum ? (serviceParamTypes[selectedParamEnum]) : {};
  const valueDropdownOptions = Object.keys(paramsEnumTypes || {}).map((key: any) => ({ status: paramsEnumTypes[key], value: key }));

  const param1Unit = !!condition.parameter && allParamsMap[condition.parameter]?.data_unit_of_measurement;
  const paramList2Filtered = !condition.parameter ? paramList2Options : paramList2Options.filter((param: any) => {
    return param.data_unit_of_measurement === param1Unit && param.code !== condition.parameter;
  });

  const paramMeasurementMatch = (paramScale: any) => {

    if (temperatureScale === 2 && paramScale === "°C") {
      return "°F";
    }
    if (pressureUnit === 2 && paramScale === "kg/cm2") {
      return "PSI";
    }
    if (pressureUnit === 2 && paramScale === "MPa") {
      return "PSI";
    }

    return paramScale;
  };
  // console.log("ASada", matchParamLabelWithUnitType
  //   , condition.unitType);
  const valueUnit: any = !isThreshHold ?
    paramMeasurementMatch(allParamsMap[condition.parameter]?.data_unit_of_measurement) :
    (allParamsMap[condition.parameter]?.data_unit_of_measurement === allParamsMap[condition.parameter2]?.data_unit_of_measurement ?
      paramMeasurementMatch(allParamsMap[condition.parameter]?.data_unit_of_measurement) :
      "");

  const isFakeCondition = condition.unitType === "iduNum" || condition.unitType === "oduNum";
  const isNextConditionFake = inGroup ? false : lastCondition && (nextCondition?.unitType === "iduNum" || nextCondition?.unitType === "oduNum");
  let allunitsTypesOptions = unitTypesOptions;
  if (!hideIduNum) { allunitsTypesOptions = [...allunitsTypesOptions, iduNumOption]; }
  if (!hideOduNum) { allunitsTypesOptions = [...allunitsTypesOptions, oduNumOption]; }
  const isFakePercent = (condition.unitType === "iduNum" && condition.indoorPerc != 1)
    || (condition.unitType === "oduNum" && condition.outdoorPerc != 1);
  const fakeError = getIn(errors, `${name}[${index}].value`);

  return (
    <>
      <div className={clsx(classes.conditionContainer, { [classes.conditionInGroupContainer]: inGroup })}>
        {inGroup ? <MoreVert className={classes.purbleMoreEvert} /> :
          <div className={classes.moreVertContainer}><MoreVert className={classes.whiteMoreVert} /></div>
        }

        <Checkbox
          color="default"
          edge="end"
          variant="outlined"
          onChange={handleCheck}
          checkedIcon={<CheckboxChecked />}
          className={clsx(classes.checkboxStyle, { [classes.hideCheckbox]: inGroup, [classes.hideElement]: isFakeCondition })}
          disabled={disabled}
        />
        <CustomSelect className={classes.width110} error={getIn(errors, `${name}[${index}].unitType`) && getIn(touched, `${name}[${index}].unitType`)}
          placeholder="Type"
          name={`${name}[${index}].unitType`}
          onChange={(event: any) => handleUnitsTypeChange(event, `${name}[${index}].unitType`)}
          value={condition.unitType}
          options={inGroup ? unitTypesOptions : !isFakeCondition ? allunitsTypesOptions : (condition.unitType === "iduNum" ? [...allunitsTypesOptions, iduNumOption] : [...allunitsTypesOptions, oduNumOption])}
          disabled={disabled}
        />
        {isFakeCondition &&
          <>
            <CustomSelect className={classes.width210} error={getIn(errors, `${name}[${index}].unitType`) && getIn(touched, `${name}[${index}].unitType`)}
              placeholder=" # Or %"
              name={`${name}[${index}].${condition.unitType === "iduNum" ? "indoorPerc" : "outdoorPerc"}`}
              onChange={(event: any) => { setFieldValue(`${name}.${index}.value`, ""); setFieldValue(`${name}[${index}].${condition.unitType === "iduNum" ? "indoorPerc" : "outdoorPerc"}`, event.target.value); }}
              value={condition.unitType === "iduNum" ? condition.indoorPerc : condition.outdoorPerc}
              options={unitsNumOptions}
              label="label"
              optionValue="value"
              disabled={disabled}
            />
            <div className={classes.fieldContainer}>
              <CustomedTextField
                disabled={disabled}
                className={classes.width140}
                error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                onChange={({ target: { value } }: any) => setFieldValue(`${name}[${index}].value`, value)}
                value={condition.value}
                name={`${name}.${index}.value`}
                type="number"
                label={t`Value` + `${isFakePercent ? ` (%)` : " (#)"}`}
              />
              {(getIn(touched, `${name}[${index}].value`) && fakeError && fakeError !== "Required") &&
                <Typography className={classes.errorText}>{fakeError}</Typography>}
            </div>
          </>}
        {!isFakeCondition && <CustomSelect disabled={disabled || !condition.unitType}
          error={getIn(errors, `${name}[${index}].parameter`) && getIn(touched, `${name}[${index}].parameter`)}
          placeholder={condition.parameter ? (isThreshHold || isParamOperator ? matchParamLabelWithUnitType.param1[condition.unitType] + (!isMixed && " 1" || "") : matchParamLabelWithUnitType.param1[condition.unitType]) : (isThreshHold || isParamOperator ? "Parameter 1" : "Select Parameter")}
          onChange={(event: any) => {
            const { target: { value } } = event;
            if (allParamsMap[value]?.enum !== selectedParamEnum) { setFieldValue(`${name}[${index}].value`, ""); }
            if (allParamsMap[value]?.enum && !["=", "!="].includes(condition.operator)) { setFieldValue(`${name}[${index}].operator`, ""); setFieldValue(`${name}[${index}].parameter2`, ""); }
            setFieldValue(`${name}[${index}].parameter`, value);
          }}
          value={condition.parameter}
          label="title"
          optionValue="code"
          options={isMixed ? indoorParams : paramList1Options}
          notDisabledOptions={isMixed ? enumParams : undefined}
          name={`${name}[${index}].parameter`}
        />}

        {!isFakeCondition && <CustomSelect
          placeholder="Operator"
          className={classes.width110}
          error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
          onChange={(event: any) => setFieldValue(`${name}[${index}].operator`, event.target.value)}
          value={condition.operator}
          label="label"
          optionValue="value"
          options={isMixed ? theresholdOptionOnly : (selectedParamEnum ? equalOptionOnly : trapOperatorsOptions)}
          name={`${name}[${index}].operator`}
          disabled={disabled}
        />}

        {(!isFakeCondition && (isThreshHold || isParamOperator)) &&
          <CustomSelect disabled={disabled || !condition.unitType || !condition.parameter}
            error={getIn(errors, `${name}[${index}].parameter2`) && getIn(touched, `${name}[${index}].parameter2`)}
            placeholder={condition.parameter2 ? (isThreshHold || isParamOperator ? matchParamLabelWithUnitType.param2[condition.unitType] + (!isMixed && " 2" || "") : matchParamLabelWithUnitType.param2[condition.unitType]) : (isThreshHold || isParamOperator ? "Parameter 2" : "Select Parameter")}
            onChange={(event: any) => setFieldValue(`${name}[${index}].parameter2`, event.target.value)}
            value={condition.parameter2}
            label="title"
            optionValue="code"
            options={paramList2Filtered}
            notDisabledOptions={isMixed || isThreshHold || isParamOperator ? enumParams : undefined}
            name={`${name}[${index}].parameter2`}
          />
        }

        {isThreshHold &&
          <CustomSelect
            placeholder="Difference"
            className={classes.width110}
            error={getIn(errors, `${name}[${index}].thresholdOperator`) && getIn(touched, `${name}[${index}].thresholdOperator`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].thresholdOperator`, event.target.value)}
            value={condition.thresholdOperator}
            label="label"
            optionValue="value"
            options={theresholdOptions}
            name={`${name}[${index}].thresholdOperator`}
            disabled={disabled}
          />}

        {isParamOperator || isFakeCondition ? null : selectedParamEnum ?
          (
            <CustomSelect disabled={disabled}
              className={classes.width140}
              placeholder={"Value"}
              onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
              value={condition.value + ""}
              label="status"
              optionValue="value"
              options={valueDropdownOptions}
              notDisabledOptions={["default"]}
              name={`${name}[${index}].value`}
            />
          )
          : (<CustomedTextField
            disabled={disabled}
            className={classes.width140}
            error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
            value={condition.value}
            name={`${name}.${index}.value`}
            type="number"
            label={t`Value` + `${!!valueUnit ? `(${valueUnit})` : ""}`}
          // label={t`Value ${!!valueUnit ? `(${valueUnit})` : ""}`}
          />)}
        {!isFakeCondition && <CustomSelect className={classes.width120} error={getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`)}
          placeholder="Duration"
          name={`${name}[${index}].duration`}
          onChange={(event: any) => setFieldValue(`${name}[${index}].duration`, event.target.value)}
          value={condition.duration}
          label="title"
          optionValue="value"
          options={AnomaliesUtils.selectableDurations}
          disabled={disabled}
        />}

        <IconButton
          disabled={disabled}
          className={classes.iconBtnStyle}
          style={{ marginRight: inGroup ? 15 : 65, marginLeft: condition.operator?.includes("threshold") ? 40 : condition.operator?.includes("parameter") ? 318 : isFakeCondition ? 590 : 359 }}
        >
          <Delete style={{ width: 20, height: 20 }}  onClick={deleteCondition} />
        </IconButton>
      </div>
      {!lastCondition ? null : isFakeCondition || isNextConditionFake ?
        (<Button disabled className={classes.addButton} >And</Button>) :
        (<GButton
          inGroup={inGroup}
          disabled={disabled}
          selected={condition.relation}
          onClick={(text: any) => setFieldValue(`${name}[${index}].relation`, text)}
        />)
      }
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`${name}[${index}].relation`}
        value="and"
        checked={condition.relation === "and"}
      />
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`${name}[${index}].relation`}
        value="or"
        checked={condition.relation === "or"}
      />
    </>
  );
};
