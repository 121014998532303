import * as React from 'react';

function SvgCommissioning(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M11.393 6.6c.15 0 .27.12.27.27v.94l.47.179c.065.025.13.052.194.08l.458.208.356-.355.312-.312a.267.267 0 01.19-.078c.042 0 .122.01.19.078l.557.558a.26.26 0 01.078.19.267.267 0 01-.078.19l-.311.31-.356.357.208.458c.029.064.056.129.08.194l.178.47h.942c.149 0 .269.12.269.27v.786c0 .15-.12.27-.27.27h-.94l-.178.47c-.025.066-.052.13-.082.195l-.206.458.355.355.312.312c.05.051.077.116.077.19a.264.264 0 01-.078.19l-.556.556a.27.27 0 01-.381.001l-.668-.667-.458.208a2.612 2.612 0 01-.19.078l-.474.176v.946c0 .149-.12.269-.27.269h-.786a.27.27 0 01-.27-.27v-.942l-.47-.177a4.654 4.654 0 01-.194-.08l-.458-.208-.356.355-.31.311a.273.273 0 01-.192.074.267.267 0 01-.19-.074l-.558-.558a.262.262 0 01-.077-.19c0-.072.027-.137.077-.188l.312-.311.356-.356-.207-.458a2.96 2.96 0 01-.08-.195l-.178-.47H6.87a.27.27 0 01-.27-.27v-.786c0-.15.121-.27.27-.27h.943l.177-.472a2.68 2.68 0 01.08-.192l.208-.458-.356-.356-.312-.312c-.051-.051-.078-.117-.078-.19s.027-.139.077-.19l.558-.557a.27.27 0 01.381 0l.668.667.458-.208c.064-.028.128-.055.193-.08l.47-.178V6.87a.27.27 0 01.27-.269h.786zM11 9.533a1.467 1.467 0 100 2.935 1.467 1.467 0 000-2.935z"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth={1.4}
          d="M11 2.2c-4.86 0-8.8 3.828-8.8 8.55 0 1.454.374 2.823 1.032 4.021m7.744 4.998c4.86 0 8.8-3.828 8.8-8.55a8.32 8.32 0 00-1.033-4.022"
        />
        <path fill="#FFF" d="M14.345 2.16l-3.3 2.75v-5.5zM7.745 19.76l3.3 2.75v-5.5z" />
      </g>
    </svg>
  );
}

export default SvgCommissioning;
