import React from 'react';

function SvgAnomalies(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <defs>
        <path id="anomalies_svg__a" d="M.735.973h4.528V5.5H.735z" />
        <path id="anomalies_svg__c" d="M.904.396h4.528v4.527H.904z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M8.792 0a4.88 4.88 0 00-4.88 4.881 4.88 4.88 0 109.76 0 4.88 4.88 0 00-4.88-4.88m0 1.489c1.87 0 3.391 1.52 3.391 3.391a3.396 3.396 0 01-3.391 3.393A3.397 3.397 0 015.4 4.88a3.395 3.395 0 013.392-3.39"
        />
        <g transform="translate(11 16.5)">
          <mask id="anomalies_svg__b" fill="currentColor">
            <use xlinkHref="#anomalies_svg__a" />
          </mask>
          <path
            fill="currentColor"
            d="M2.999.973a2.263 2.263 0 10-.001 4.526 2.263 2.263 0 000-4.526m0 1.402a.861.861 0 110 1.722.861.861 0 010-1.722"
            mask="url(#anomalies_svg__b)"
          />
        </g>
        <g transform="translate(16.5 8.8)">
          <mask id="anomalies_svg__d" fill="currentColor">
            <use xlinkHref="#anomalies_svg__c" />
          </mask>
          <path
            fill="currentColor"
            d="M3.168.396a2.265 2.265 0 100 4.526 2.265 2.265 0 000-4.526m0 1.403a.862.862 0 010 1.722.861.861 0 010-1.722"
            mask="url(#anomalies_svg__d)"
          />
        </g>
        <path
          fill="currentColor"
          d="M2.264 10.512a2.263 2.263 0 10-.001 4.526 2.263 2.263 0 000-4.526m0 1.402a.862.862 0 010 1.722.86.86 0 010-1.722"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth={1.276}
          d="M3.304 11.539l2.897-3.447m7.198 9.801l-3.186-9.785m8.395 4.893l-3.694 5.319"
        />
        <path
          fill="currentColor"
          d="M8.792 3.255v1.999m.623-1.999l-.132 2a.492.492 0 01-.982 0l-.133-2a.625.625 0 111.247 0m-.121 3.361a.502.502 0 11-1.004.002.502.502 0 011.004-.002"
        />
      </g>
    </svg>
  );
}

export default SvgAnomalies;
