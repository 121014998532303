import {
  Action,
  action,
  Computed,
  computed,
  createTypedHooks,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { t } from "ttag";
import { stateList } from "../screens/SiteManagement/stateList";
import walkMe from "../services/walkMe";
import { alertActions, IAlertActions } from "./actions/AlertActions";
import {
  alertGroupActions,
  IAlertGroupActions,
} from "./actions/AlertGroupActions";
import { deviceActions, IDeviceActions } from "./actions/DeviceActions";
import { ISiteActions, siteActions } from "./actions/SiteActions";
import { ISystemActions, systemActions } from "./actions/SystemActions";
import { ITriggerActions, triggerActions } from "./actions/TriggerActions";
import { IUnitActions, unitActions } from "./actions/UnitActions";
import { IUserActions, userActions } from "./actions/UserActions";
import { alertGroupsModel, IAlertGroupsModel } from "./AlertGroups";
import { alertsModel, IAlertsModel } from "./Alerts";
import {
  anomalyTemplatesModel,
  IAnomalyTemplatesModel,
} from "./AnomalyTemplates";
import { auditsModel, IAuditsModel } from "./Audits";
import { compressorsModel, ICompressorsModel } from "./Compressors";
import { contactsModel, IContactsModel } from "./Contacts";
import { customersModel, ICustomersModel } from "./Customers";
import { devicesModel, IDevice, IDevicesModel } from "./Devices";
import { IMessagesModel, messageModel } from "./ErrorMessage";
import { featuresModel, IFeaturesModel } from "./Features";
import { filesModel, IFilesModel } from "./Files";
import { groupsModel, IGroupsModel } from "./Groups";
import { IInvitesModel, invitesModel } from "./Invites";
import { ILoaderModel, loaderModel } from "./loader";
import { IMessageHandlers, messageHandlers } from "./MessageHandlers";
import { INoteModel, notesModel } from "./Notes";
import { IPolicyStore, policyStore } from "./policyStore";
import { IPowerMetersModel, powerMetersModel } from "./powerMeters";
import { IScheduleModel, scheduleModel } from "./Schedules";
import { IScriptModel, scriptModel } from "./Scripts";
import { ISelectionsModel, selectionsModel } from "./Selections";
import { ISensorsModel, sensorsModel } from "./Sensors";
import { ISite, ISitesModel, sitesModel } from "./Sites";
import { ISystemsModel, systemsModel } from "./Systems";
import { ITrapsModel, trapsModel } from "./traps";
import { ITriggersModel, triggersModel } from "./Triggers";
import { IUnitsModel, unitsModel } from "./Units";
import { IUser, IUsersModel, usersModel } from "./Users";
import { IZonesModel, zonesModel } from "./Zones";

// TODO: move IServiceParams and IServiceTypes into separate models?
// Or make one IServices model for all of them?

export interface IServiceParams {
  [key: string]: any;
}

export interface IServiceTypes {
  temperatureScale?: any;
  operationStatuses?: any;
  operationModes?: any;
  operationModesWithTemperatures?: any;
  fanModes?: any;
  swingModes?: any;
  weekDays?: any;
  roles?: any;
  permissions?: any;
  resources?: any;
  hvacBrands?: any[];
  outdoorUnitTasks?: any;
  systemTypes?: string[];
  alertClasses?: any;
  alertClearTypes?: any;
  proParsingCodes?: any;
  capacityMeasurementUnitTypes?: any;
  unitTypes?: any;
  unitSubTypes?: any;
  systemUnitTypes?: any;
  eventClearTypes?: any;
  eventTypes?: any;
  eventStatusTypes?: any;
  measurementUnitTypes?: any;
  pressureScale?: any;
  languages?: any;
  procedureStepTypes?: any;
  procedureDeviceCommands?: any;
  procedureConditions?: any;
  procedureRunningStates?: any;
  procedureStateCommands?: any;
  actionSources?: any;
  procedureRunResultTypes?: any;
  procedureStepResultTypes?: any;
  [index: string]: any | undefined;
  timeFormat?: any;
  dateFormat?: any;
}

// TODO: move IConfig to separate model?
// where do we load it from? How do we save it? Is it per user/customer, etc?
export interface IConfig {
  isSplashScreen: boolean;
  splashScreenTimeout: number;
}

export interface IRootStoreModel
  extends IUserActions,
  ISiteActions,
  IDeviceActions,
  IUnitActions,
  ISystemActions,
  IAlertGroupActions,
  ITriggerActions,
  IAlertActions,
  IMessageHandlers {
  customers: ICustomersModel;
  sites: ISitesModel;
  devices: IDevicesModel;
  alerts: IAlertsModel;
  triggers: ITriggersModel;
  zones: IZonesModel;
  units: IUnitsModel;
  sensors: ISensorsModel;
  contacts: IContactsModel;
  compressors: ICompressorsModel;
  systems: ISystemsModel;
  scripts: IScriptModel;
  users: IUsersModel;
  features: IFeaturesModel;
  alertGroups: IAlertGroupsModel;
  audits: IAuditsModel;
  selections: ISelectionsModel;
  serviceParams: IServiceParams;
  types: IServiceTypes;
  schedules: IScheduleModel;
  serviceParamTypes: any;
  serviceErrorTypes: any;
  traps: ITrapsModel;
  anomalyTemplates: IAnomalyTemplatesModel;
  config: IConfig;
  isLoggedIn: boolean;
  isInitialized: boolean;
  unitUpdateStatus: string;
  errorMessage: IMessagesModel;
  loader: ILoaderModel;
  trapOperatorsOptions: any;
  checkOperatorsOptions: any;
  invites: IInvitesModel;
  files: IFilesModel;
  groups: IGroupsModel;
  powerMeters: IPowerMetersModel;
  notes: INoteModel;
  importExcel: Thunk<IRootStoreModel, { data: any }>;
  doLogin: Thunk<IRootStoreModel, { username: string; password: string }>;
  doImpersonate: Thunk<IRootStoreModel, { username: string; adminname: string; password: string }>;
  doLogout: Thunk<IRootStoreModel>;
  getUserTree: Thunk<IRootStoreModel, void, any, IRootStoreModel>;
  getTreeImport: Thunk<IRootStoreModel, any, any, IRootStoreModel>;
  setLoggedIn: Action<IRootStoreModel, boolean>;
  setLogOut: Action<IRootStoreModel>;
  setUnitUpdateStatus: Action<IRootStoreModel, { status: string }>;
  fetchedUserTree: Action<IRootStoreModel>;
  resetCallBacks: Action<IRootStoreModel>;
  setCallBacks: Action<IRootStoreModel, { messageName: any; func: any }>;
  setServiceParams: Action<IRootStoreModel, IServiceParams>;
  setTypes: Action<IRootStoreModel, IServiceTypes>;
  doStatsUpdate: boolean;
  setStatsUpdate: Action<IRootStoreModel, boolean>;
  unitTypesMirrror: Computed<IRootStoreModel>;
  eventClearTypesMirror: Computed<IRootStoreModel>;
  eventTypesMirror: Computed<IRootStoreModel>;
  eventStatusTypesMirror: Computed<IRootStoreModel>;
  unitTypesOptions: Computed<IRootStoreModel>;
  adminLogin: Thunk<IRootStoreModel, string>;
  getObject: Computed<
    ISelectionsModel,
    (objType: string, objId: string) => any,
    IRootStoreModel
  >;
  parentsMap: {
    sites: any;
    systems: any;
    devices: any;
    units: any;
  };
  setParentsMap: Action<IRootStoreModel, any>;
  setServiceParamTypes: Action<IRootStoreModel, any>;
  setServiceErrorTypes: Action<IRootStoreModel, any>;
  selectedAlert: any;
  isWalkMeLoaded: boolean;
  setSelectedAlert: Action<IRootStoreModel, any>;
  walkMeInit: Action<IRootStoreModel>;
  appVersion: string;
  setAppVersion: Action<IRootStoreModel, string>;
  compareAppVersion: Action<IRootStoreModel, string>;
  operationModesMirror: Computed<IRootStoreModel>;
  fanModesMirror: Computed<IRootStoreModel>;
  swingModesMirror: Computed<IRootStoreModel>;
  operationStatusesMirror: Computed<IRootStoreModel>;
  temperatureScaleMirror: Computed<IRootStoreModel>;
  serviceParamTypesOptions: Computed<IRootStoreModel>;

  getFullTree: Thunk<IRootStoreModel>;
  procedureRunningStatesMirror: Computed<IRootStoreModel>;
  stepsOptions: Computed<IRootStoreModel>;
  commandsOptions: Computed<IRootStoreModel>;
  conditionsOptions: Computed<IRootStoreModel>;
  callBacks: any;
  procedureStatuses: Computed<IRootStoreModel>;
  setDropDownNavigation: Action<IRootStoreModel, string>;
  isDropDownOpen: any;
  reportResults: Computed<IRootStoreModel>;
  stepResultStatus: Computed<IRootStoreModel>;
  stepsTypesMirror: Computed<IRootStoreModel>;
  theme: any;
  prepareTheme: Thunk<IRootStoreModel>;
  setThemeFile: Action<IRootStoreModel, any>;
}

const RootStoreModel: IRootStoreModel = {
  customers: customersModel,
  sites: sitesModel,
  devices: devicesModel,
  alerts: alertsModel,
  triggers: triggersModel,
  zones: zonesModel,
  units: unitsModel,
  sensors: sensorsModel,
  contacts: contactsModel,
  compressors: compressorsModel,
  systems: systemsModel,
  users: usersModel,
  features: featuresModel,
  invites: invitesModel,
  notes: notesModel,
  alertGroups: alertGroupsModel,
  groups: groupsModel,
  powerMeters: powerMetersModel,
  audits: auditsModel,
  traps: trapsModel,
  schedules: scheduleModel,
  anomalyTemplates: anomalyTemplatesModel,
  selections: selectionsModel,
  loader: loaderModel,
  files: filesModel,
  serviceParams: [],
  scripts: scriptModel,
  types: {},
  errorMessage: messageModel,
  serviceParamTypes: {},
  serviceErrorTypes: {},
  callBacks: {},
  theme: null,
  config: {
    // TODO: bring those defaults from somewhere?
    isSplashScreen: false,
    splashScreenTimeout: 3000
  },
  isWalkMeLoaded: false,
  walkMeInit: action((state) => {
    if (state.isWalkMeLoaded) {
      return;
    }
    walkMe();
    state.isWalkMeLoaded = true;
  }),
  isLoggedIn: true, // Optimistic default
  isInitialized: false,
  unitUpdateStatus: "",
  doStatsUpdate: false,
  parentsMap: { sites: {}, devices: {}, systems: {}, units: {} },
  appVersion: "",
  setAppVersion: action((state, payload) => {
    if (!payload) {
      return;
    }
    state.appVersion = payload;
    return;

  }),
  compareAppVersion: action((state, payload) => {
    if (!payload) {
      return;
    }
    if (!state.appVersion) {
      state.appVersion = payload;
      return;
    }
    if (state.appVersion && state.appVersion !== payload) {
      window.location.reload();
    }
  }),
  setParentsMap: action((state, payload) => {
    state.parentsMap = payload;
  }),
  setStatsUpdate: action((state, payload) => {
    state.doStatsUpdate = payload;
  }),
  selectedAlert: null,
  setSelectedAlert: action((state, payload) => {
    state.selectedAlert = payload;
  }),

  // Imported actions
  ...userActions,
  ...siteActions,
  ...deviceActions,
  ...unitActions,
  ...systemActions,
  ...alertGroupActions,
  ...triggerActions,
  ...alertActions,
  ...messageHandlers,
  unitTypesMirrror: computed((state) => {
    const { types } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const unitType = keys.reduce((allTypes, key) => {
      const name = unitTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return unitType;
  }),
  eventClearTypesMirror: computed((state) => {
    const { types } = state;
    const { eventClearTypes } = types;

    if (!eventClearTypes) {
      return null;
    }

    const keys = Object.keys(eventClearTypes);
    const object: { [index: string]: any } = {};

    const eventClearType = keys.reduce((allTypes, key) => {
      const name = eventClearTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventClearType;
  }),
  eventTypesMirror: computed((state) => {
    const { types } = state;
    const { eventTypes } = types;

    if (!eventTypes) {
      return {};
    }

    const keys = Object.keys(eventTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  eventStatusTypesMirror: computed((state) => {
    const { types } = state;
    const { eventStatusTypes } = types;

    if (!eventStatusTypes) {
      return {};
    }

    const keys = Object.keys(eventStatusTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventStatusTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  serviceParamTypesOptions: computed((state) => {
    const { serviceParamTypes } = state;

    if (_.isEmpty(serviceParamTypes)) {
      return {};
    }

    const allOptions: any = {};

    Object.keys(serviceParamTypes).forEach((paramEnum: any) => {
      const options = serviceParamTypes[paramEnum];
      allOptions[paramEnum] = [];
      Object.keys(options).map((key: any) => allOptions[paramEnum].push({ value: +key, label: options[key] }));
    });

    return allOptions;
  }),
  unitTypesOptions: computed((state) => {
    const { types } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    return [
      { value: unitTypes.service, name: "Indoor" },
      { value: unitTypes.outdoor, name: "Outdoor" },
      { value: "mixed", name: "Mixed" }
    ];
  }),
  trapOperatorsOptions: [
    {
      value: ">",
      label: ">"
    },
    {
      value: "<",
      label: "<"
    },
    { value: "=", label: "=" },
    {
      value: "!=",
      label: "≠"
    },
    {
      value: "threshold",
      label: "Difference"
    }
  ],
  // try to login the user
  doLogin: thunk(async (actions, payload, { injections }) => {
    const { sdkUser, sdkXhr } = injections;
    // localStorage.removeItem('token');
    sdkUser.logout();
    const { data, message } = await sdkUser.connect(
      payload.username,
      payload.password,
      "CoolService"
    );

    if (message) {
      return { message };
    }
    const token = data.token;

    if (!token) {
      return { message: "your session has been expired please login again" };
    }
    localStorage.setItem("token", token);
    await sdkXhr.setToken(token);
    actions.setLoggedIn(true);
  }),

  doImpersonate: thunk(async (actions, payload, { injections }) => {
    const { sdkUser, sdkXhr, sdkAdmin } = injections;
    sdkUser.logout();
    const { data, message } = await sdkUser.connect(
      payload.adminname,
      payload.password
    );

    if (message) {
      return { message };
    }

    const token = data.token;

    await sdkXhr.setToken(token);
    try {
      const userData = await sdkAdmin.getUserToken(payload.username);
      if (!userData || !userData?.token || typeof userData.token !== "string" || userData.token.length === 0) {
        return { message: "Something went wrong" };
      }
      localStorage.clear();
      localStorage.setItem("token", userData.token);
    }
    catch (error) {
      return { message: "Invalid username" };
    }
  }),

  // logout the user
  doLogout: thunk(async (actions, payload, { injections }) => {
    const { sdkUser } = injections;
    actions.setLogOut();
    localStorage.removeItem("token");
    actions.setLoggedIn(false);
    await sdkUser.closeWebSocket();
    return sdkUser.logout();
  }),
  importExcel: thunk(async (actions, payload) => {
    // actions
    // actions.setLogOut();
    // localStorage.removeItem('token');
    // actions.setLoggedIn(false);
    // await coolremoteSDK.User.closeWebSocket();
    // return coolremoteSDK.User.logout();
  }),
  // get all initial data for the user
  getUserTree: thunk(
    async (actions, payload, { getStoreState, getStoreActions, injections }) => {
      const {
        sdkUser,
        sdkCustomer,
        sdkSite,
        sdkSensor,
        sdkPowerMeter,
        sdkDevice,
        sdkSystem,
        sdkUnit,
        sdkService,
        sdkAlertGroup,
        sdkCompressor
      } = injections;
      const token = localStorage.getItem("token");

      if (!token) {
        return;
      }
      actions.users.initialize();

      const initAPIs = [
        sdkCustomer.getCustomers(),
        sdkSite.getSites(),
        sdkSensor.getMySensors(),
        sdkDevice.getDevices(),
        sdkSystem.getSystems(),
        sdkUnit.getUnits(),
        sdkService.getTypes(),
        sdkService.getServiceParamTypes(),
        sdkService.getServiceErrorTypes(),
        sdkService.getServiceParams(),
        sdkAlertGroup.getAlertGroups(),
        sdkCompressor.getCompressors(),
        sdkPowerMeter.find(),
        actions.users.getUsers()
      ];

      Promise.all(initAPIs)
        .then((resp: any) => {
          const [
            customers,
            sites,
            sensors,
            devices,
            systems,
            units,
            types,
            paramsTypes,
            errorTypes,
            params,
            alertGroups,
            compressors,
            powerMeters,
            users
          ] = resp;

          actions.customers.initialize(customers);
          actions.sites.initialize(sites);
          actions.sensors.initialize(sensors);
          actions.devices.initialize(devices);
          actions.units.initialize(units);
          actions.compressors.initialize(compressors);
          actions.systems.initialize(systems);
          actions.setTypes(types);
          actions.setServiceParamTypes(paramsTypes);
          actions.setServiceErrorTypes(errorTypes);
          actions.setServiceParams(params);
          actions.alertGroups.initialize(alertGroups);
          actions.features.initialize();
          actions.users.setUsers(users);
          actions.powerMeters.initialize(powerMeters);

          // const openAlertVal = types.eventStatusTypes["open"];
          return types;
        })
        .then((types) => {
          return actions.alerts.getAlertsByFilters({ status: types.eventStatusTypes["open"], type: types.applications.service });
        })
        .then((events: any) => {
          actions.alerts.initialize({ events, openAlertsOnly: true });
        })
        .then(() => {
          actions.fetchedUserTree();
        })
        .catch((e: any) => {
          actions.doLogout();
        });

      const rootActions = getStoreActions();

      sdkUser.openWebSocket((message: any) => {
        if (message.name === "PROCEDURE_INSTANCE_UPDATED") {
          const cb = getStoreState().callBacks;
          if (cb[message.name]) {
            cb[message.name](message.data);
          }
          return;
        }
        if (message.type === "ping") {
          if (message.version) {
            let appVersion = getStoreState().appVersion;
            if (!appVersion) {
              actions.setAppVersion(message.version);
              appVersion = message.version;
            }

            if (appVersion !== message.version) {
              window.location.reload();
            }
          }
          return;
        }

        // Special treatment for unit updates.
        const unitUpdateStatus = getStoreState().unitUpdateStatus;
        if (message.name === "UPDATE_UNIT") {

          //if we are on site mgmt, only some updates
          if (
            unitUpdateStatus === "sitemgmt" ||
            unitUpdateStatus === "control" ||
            message.data.unitId === unitUpdateStatus ||
            message.data.serviceUnits && message.data.serviceUnits.indexOf(unitUpdateStatus) > -1
          ) {

            const updatedUnit = getStoreState().units.allUnits[
              message.data.unitId
            ];

            // If the unit is not found, nothing to update anyway.
            if (!updatedUnit) {
              return;
            }

            const {
              ambientTemperature,
              fan: activeFanMode,
              operationMode: activeOperationMode,
              operationStatus: activeOperationStatus,
              setpoint: activeSetpoint,
              swing: activeSwingMode,
              unitId
            } = message.data;

            rootActions.units.updateUnitLocally({
              id: unitId, unit: {
                ambientTemperature,
                activeFanMode,
                activeOperationMode,
                activeOperationStatus,
                activeSetpoint,
                activeSwingMode
              }
            });
            return;
          }
        }

        if (message.name === "UPDATE_SENSOR") {

          if (unitUpdateStatus === "control") {
            const { sensorId, ...sensorUpdate } = message.data;
            rootActions.sensors._storeUpdateSensor({ id: sensorId, data: sensorUpdate });
          }
          return;
        }

        if (message.name === "DEVICE_CONNECTED") {
          rootActions.devices._storeUpdateDeviceConnection({ deviceId: message.data.deviceId, data: { isConnected: true } });
        }

        if (message.name === "DEVICE_DISCONNECTED") {
          rootActions.devices._storeUpdateDeviceConnection({ deviceId: message.data.deviceId, data: { isConnected: false } });
        }

      });
    }),
  getTreeImport: thunk(
    async (actions, payload, { getStoreState, injections }) => {
      const {
        sdkUser,
        sdkCustomer,
        sdkSite,
        sdkDevice,
        sdkSystem,
        sdkUnit,
        sdkService,
        sdkAlertGroup,
        sdkCompressor
      } = injections;
      const token = localStorage.getItem("token");

      if (!token) {
        return;
      }

      const initAPIs = [
        sdkCustomer.getCustomers(),
        sdkSite.getSites(),
        sdkDevice.getDevices(),
        sdkSystem.getSystems(),
        sdkUnit.getUnits(),
        sdkService.getTypes(),
        sdkService.getServiceParamTypes(),
        sdkService.getServiceErrorTypes(),
        sdkService.getServiceParams(),
        sdkAlertGroup.getAlertGroups(),
        sdkCompressor.getCompressors()
        // sdkSite.getSites(true)

      ];

      Promise.all(initAPIs)
        .then((resp: any) => {
          const [
            customers,
            sites,
            devices,
            systems,
            units,
            types,
            paramsTypes,
            errorTypes,
            params,
            alertGroups,
            compressors
            // blockedSites
          ] = resp;
          const { siteStatuses } = types;
          const siteWarnedStatus = siteStatuses["warned"];
          const warnedSites = Object.values(sites).filter((site: any) => site.serviceStatus === siteWarnedStatus);
          actions.customers.initialize(customers);
          actions.sites.initialize(sites);
          // actions.sites.initialize({ all: sites, warned: warnedSites, blocked: Object.values(blockedSites) });
          actions.devices.initialize(devices);
          actions.units.initialize(units);
          actions.compressors.initialize(compressors);
          actions.systems.initialize(systems);
          actions.setTypes(types);
          actions.setServiceParamTypes(paramsTypes);
          actions.setServiceErrorTypes(errorTypes);
          actions.setServiceParams(params);
          actions.alertGroups.initialize(alertGroups);
          actions.features.initialize();

          // const openAlertVal = types.eventStatusTypes["open"];
          return types;
        })
        .then((types) => {
          return actions.alerts.getAlertsByFilters({ status: types.eventStatusTypes["open"], type: types.applications.service });
        })
        .then((events: any) => {
          actions.alerts.initialize({ events, openAlertsOnly: true });
        })
        .then(() => {
          actions.fetchedUserTree();
        })
        .catch(() => {
          actions.doLogout();
        });
    }
  ),
  setLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  setLogOut: action((state, payload) => {
    state.selections.selections.customerId = null;
    state.selections.selections.siteId = null;
    state.selections.selections.systemId = null;
    state.selections.selections.unitId = null;
    state.selections.selections.lastSelectedUnitId = null;
    state.isInitialized = false;
  }),
  setUnitUpdateStatus: action((state, payload) => {
    state.unitUpdateStatus = payload.status;
  }),

  fetchedUserTree: action((state) => {
    state.isInitialized = true;
  }),

  setServiceParams: action((state, payload) => {
    state.serviceParams = payload;
  }),

  setTypes: action((state, payload) => {
    state.types = payload;
  }),

  setServiceParamTypes: action((state, payload) => {
    state.serviceParamTypes = payload;
  }),
  setServiceErrorTypes: action((state, payload) => {
    state.serviceErrorTypes = payload;
  }),
  resetCallBacks: action((state) => {
    state.callBacks = {};
  }),
  setCallBacks: action((state, payload) => {
    state.callBacks[payload.messageName] = payload.func;
  }),
  adminLogin: thunk(async (actions, payload, { injections }) => {
    const { sdkAdmin } = injections;
    const data = await sdkAdmin.getUserToken(payload),
      { token } = data;

    localStorage.setItem("token", token);
    window.location.href = "/dashboard";
  }),
  getObject: computed(
    [
      (state, storeState) => storeState.customers.allCustomers,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.units.allUnits
    ],
    (customers, sites, devices, systems, units) => (objType, objId) => {
      let obj: any = {};

      switch (objType) {
        case "customer":
          obj = customers[objId];
          break;
        case "site":
          obj = sites[objId];
          break;
        case "device":
          obj = devices[objId];
          break;
        case "system":
          obj = systems[objId];
          break;
        case "unit":
          obj = units[objId];
          break;
        default:
          obj = null;
          break;
      }
      return obj;
    }
  ),
  operationModesMirror: computed((state) => {
    const { types } = state;
    const { operationModes } = types;

    if (!operationModes) {
      return null;
    }

    const keys = Object.keys(operationModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = operationModes[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  operationStatusesMirror: computed((state) => {
    const { types } = state;
    const { operationStatuses } = types;

    if (!operationStatuses) {
      return null;
    }

    const keys = Object.keys(operationStatuses);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = operationStatuses[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  fanModesMirror: computed((state) => {
    const { types } = state;
    const { fanModes } = types;

    if (!fanModes) {
      return null;
    }

    const keys = Object.keys(fanModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = fanModes[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  swingModesMirror: computed((state) => {
    const { types } = state;
    const { swingModes } = types;

    if (!swingModes) {
      return null;
    }

    const keys = Object.keys(swingModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = swingModes[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  temperatureScaleMirror: computed((state) => {
    const { types } = state;
    const { temperatureScale } = types;

    if (!temperatureScale) {
      return null;
    }

    const keys = Object.keys(temperatureScale);
    const object: { [index: string]: any } = {};

    const temperatureScaleType = keys.reduce((types, key) => {
      const name = temperatureScale[key];
      types[name as string] = key;

      return types;
    }, object);

    return temperatureScaleType;
  }),
  procedureRunningStatesMirror: computed((state) => {
    const { types } = state;
    const { procedureRunningStates } = types;

    if (!procedureRunningStates) {
      return {};
    }

    const keys = Object.keys(procedureRunningStates);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((types, key) => {
      const name = procedureRunningStates[key];
      types[name as string] = key;

      return types;
    }, object);

    return eventType;
  }),
  stepsOptions: computed((state) => {
    const { types } = state;
    const { procedureStepTypes } = types;

    if (!procedureStepTypes) {
      return [];
    }

    return [
      { value: procedureStepTypes.command, name: "Action" },
      { value: procedureStepTypes.wait, name: "Wait" },
      { value: procedureStepTypes.condition, name: "Check" }
    ];
  }),
  commandsOptions: computed((state) => {
    const { types } = state;
    const { procedureDeviceCommands } = types;

    if (!procedureDeviceCommands) {
      return [];
    }

    return {
      [procedureDeviceCommands.setUnitOperationMode]: {
        value: procedureDeviceCommands.setUnitOperationMode,
        name: "Mode",
        enum: "operationModes"
      },
      [procedureDeviceCommands.setUnitPowerState]: {
        value: procedureDeviceCommands.setUnitPowerState,
        name: "Power",
        enum: "operationStatuses"
      },
      [procedureDeviceCommands.setUnitFanMode]: {
        value: procedureDeviceCommands.setUnitFanMode,
        name: "Fan",
        enum: "fanModes"
      },

      [procedureDeviceCommands.setUnitSetpoint]: {
        value: procedureDeviceCommands.setUnitSetpoint,
        name: "Setpoint"
      }
    };
  }),
  checkOperatorsOptions: [
    {
      value: ">",
      label: ">"
    },
    {
      value: "<",
      label: "<"
    },
    { value: "=", label: "=" }
  ],

  conditionsOptions: computed((state) => {
    const { types } = state;
    const { procedureConditions } = types;

    if (!procedureConditions) {
      return [];
    }

    return {
      [procedureConditions.unitOperationMode]: {
        value: procedureConditions.unitOperationMode,
        name: "Mode",
        enum: "operationModes"
      },
      [procedureConditions.unitPowerState]: {
        value: procedureConditions.unitPowerState,
        name: "Power",
        enum: "operationStatuses"
      },
      [procedureConditions.unitFanMode]: {
        value: procedureConditions.unitFanMode,
        name: "Fan",
        enum: "fanModes"
      },
      [procedureConditions.ambientTemperature]: {
        value: procedureConditions.ambientTemperature,
        name: "RoomTemp"
      },
      [procedureConditions.unitSetpoint]: {
        value: procedureConditions.unitSetpoint,
        name: "Setpoint"
      }
    };
  }),
  getFullTree: thunk((actions, payload, { injections }) => {
    const { sdkUser } = injections;
    return sdkUser.getMyTree();
  }),
  procedureStatuses: computed((state) => {
    const { types } = state;
    const { procedureRunningStates } = types;

    if (!procedureRunningStates) {
      return {};
    }

    return {
      [procedureRunningStates.completedFailure]: t`Failed`,
      [procedureRunningStates.completedPartialSuccess]: t`Partially Successful`,
      [procedureRunningStates.completedSuccess]: t`Completed Successfully`,
      [procedureRunningStates.initialized]: t`Initialized`,
      [procedureRunningStates.paused]: t`Paused`,
      [procedureRunningStates.running]: t`Running`,
      [procedureRunningStates.stopped]: t`Stopped`
    };
  }),
  isDropDownOpen: { settings: false, commissioning: false },
  setDropDownNavigation: action((state, payload) => {
    state.isDropDownOpen[payload] = !state.isDropDownOpen[payload];
  }),
  reportResults: computed((state) => {
    const { types } = state;
    const { procedureRunResultTypes } = types;

    if (!procedureRunResultTypes) {
      return {};
    }

    return {
      [procedureRunResultTypes.failure]: t`Failure`,
      [procedureRunResultTypes.partialSuccess]: t`Partially Successful`,
      [procedureRunResultTypes.success]: t`Success`,
      [procedureRunResultTypes.stopped]: t`Stopped`
    };
  }),
  stepResultStatus: computed((state) => {
    const { types } = state;
    const { procedureStepResultTypes } = types;

    if (!procedureStepResultTypes) {
      return {};
    }

    return {
      [procedureStepResultTypes.failure]: t`Failure`,
      [procedureStepResultTypes.notExecuted]: t`Not Executed`,
      [procedureStepResultTypes.success]: t`Success`
    };

  }),
  stepsTypesMirror: computed((state) => {
    const { types } = state;
    const { procedureStepTypes } = types;

    if (!procedureStepTypes) {
      return {};
    }

    const keys = Object.keys(procedureStepTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((types, key) => {
      const name = procedureStepTypes[key];
      types[name as string] = key;

      return types;
    }, object);

    return eventType;
  }),
  setThemeFile: action((state, payload) => {
    if (!!state.theme) {
      return;
    }
    state.theme = payload;
  }),
  prepareTheme: thunk((actions) => {
    const themeUrl = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/style.config.json`;
    fetch(themeUrl)
      .then((res) => res.json())
      .then((res) => {
        actions.setThemeFile(res);
      });

    const favIconUrl = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/favicon.ico`;
    const favIconEl: any = document.getElementById("favIconId");
    favIconEl.href = favIconUrl;
    if (process.env.PUBLIC_URL.indexOf("airwell") > -1) {
      document.title = "Airwell";
    }
  })
};

const typedHooks = createTypedHooks<IRootStoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default RootStoreModel;
