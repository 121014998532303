import React from 'react';

function SvgAudit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <defs>
        <path id="audit_svg__a" d="M-.001 1.1a1.1 1.1 0 102.2 0 1.1 1.1 0 00-2.2 0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={1.4}
          d="M11 20.9H3.231c-.57 0-1.031-.492-1.031-1.1V2.2c0-.608.462-1.1 1.031-1.1H17.67c.57 0 1.031.492 1.031 1.1v8.943"
        />
        <path
          stroke="currentColor"
          strokeWidth={1.4}
          d="M15.4 19.25c1.822 0 3.472-.916 4.95-2.75-1.479-1.834-3.129-2.75-4.95-2.75s-3.473.916-4.95 2.75c1.477 1.834 3.127 2.75 4.95 2.75z"
        />
        <g transform="translate(14.3 15.4)">
          <mask id="audit_svg__b" fill="currentColor">
            <use xlinkHref="#audit_svg__a" />
          </mask>
          <path fill="currentColor" d="M-5.5 7.7H7.7V-5.5H-5.5z" mask="url(#audit_svg__b)" />
        </g>
        <path
          stroke="currentColor"
          strokeLinecap="square"
          strokeWidth={1.4}
          d="M6.05 6.05h8.8M6.05 9.9h8.8m-8.8 3.85h2.2"
        />
      </g>
    </svg>
  );
}

export default SvgAudit;
