import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { IDevice } from "../../models/Devices";
import { useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import { IUnit } from "../../models/Units";
import OutdoorUnitInfoPanel from "./OutdoorUnitInfoPanel";
import SystemInfoPanel from "./SystemInfoPanel";
import { systemOverviewStyle } from "./SystemOverview.style";

interface IProps {
  system: ISystem;
  hideTitle?: boolean;
  assoBSBox?: any;
}

const SystemOverview: React.FC<IProps> = (props: IProps) => {
  const { system, hideTitle, assoBSBox } = props;
  const classes = systemOverviewStyle();

  const allDevices = useStoreState((state) => state.devices.allDevices);
  const allUnits = useStoreState((state) => state.units.allUnits);

  const getDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits);
  const getVisibleDeviceUnits = useStoreState((state) => state.devices.getVisibleDeviceUnits);
  const selections = useStoreState((state) => state.selections.selections);
  const { unitId: selectedUnit } = selections;
  const isVirtual = _.includes(system.id, "_") ? true : false;

  const systemDevice: IDevice | undefined = allDevices[system.device];

  const systemOutdoorUnits = isVirtual ?
    _.filter(getDeviceUnits(systemDevice.id, "unassigned", "outdoor"), (unit) => unit.line.toString() === system.id.split("_")[0] && unit.device === system.id.split("_")[1]) : systemDevice
      ? getDeviceUnits(systemDevice.id, system.id, "outdoor")
      : [];
  const systemServiceUnits = isVirtual ?
    _.filter(getVisibleDeviceUnits(systemDevice.id, "unassigned", "service"), (unit) => unit.line.toString() === system.id.split("_")[0] && unit.device === system.id.split("_")[1]) : systemDevice
      ? [...getVisibleDeviceUnits(systemDevice.id, system.id, "service"), ...getVisibleDeviceUnits(systemDevice.id, system.id, "other")]
      : [];
  const systemBsBoxUnits = isVirtual ?
    _.filter(getDeviceUnits(systemDevice.id, "unassigned", "bsBox"), (unit) => unit.line.toString() === system.id.split("_")[0] &&
      unit.device === system.id.split("_")[1]) : systemDevice
      ? getDeviceUnits(systemDevice.id, system.id, "bsBox")
      : [];

  const getNumOfUnits = (unitId: any) => {
    return _.filter(Object.values(allUnits), (unit) => unit.branchBoxUnit === unitId).length;
  };
  return (
    <Paper classes={{ elevation1: classes.elevation }} className={classes.root}>
      <div>
        <div className={classes.header}>
          {!hideTitle && <Typography className={classes.title}>{t`System Overview`}</Typography>}
        </div>
        <SystemInfoPanel system={system} systemServiceUnitsLength={systemServiceUnits.length} systemOutdoorUnitsLength={systemOutdoorUnits.length} systemBsBoxUnitsLength={systemBsBoxUnits.length} />
      </div>
      <Grid container direction={"row"} className={classes.compressorsContainer}>
        {_.map(systemOutdoorUnits, (unit: IUnit, index: number) => (
          <Grid key={index} item>
            <OutdoorUnitInfoPanel key={unit.id} unit={unit} />
          </Grid>
        ))}
        {systemBsBoxUnits?.length ?

          (<>
            <Grid container direction={"column"} style={{ marginLeft: "0.5rem" }}>

              <Grid container direction={"row"} className={classes.bsContainer}>
                {
                  systemBsBoxUnits.map((unit: any) => (
                    <div key={`bs-${unit.id}`} className={clsx(classes.bsBox, (selectedUnit === unit.id || assoBSBox === unit.id) && classes.bsSelected)}>

                      <Typography className={classes.unitName}>{unit.name}</Typography>
                      <Typography>{t`BS Box`}</Typography>
                      <Divider className={classes.unitPanelDivider} />
                      <Grid container spacing={3} justify="space-between" direction="row" style={{ padding: "0.7rem", paddingLeft: "0.9rem" }}>
                        <Grid >
                          <Typography className={classes.infoLabel}>{t`IDUs:`}</Typography>
                        </Grid>
                        <Grid style={{ paddingLeft: 0 }}>
                          <Typography className={classes.infoLabel} style={{ maxWidth: "10ch", whiteSpace: "nowrap", overflow: "hidden" }}>
                            {getNumOfUnits(unit.id)}</Typography>
                        </Grid>
                      </Grid>

                    </div>
                  ))
                }
              </Grid>
            </Grid></>)
          : null}
      </Grid>
    </Paper>
  );
};

export default SystemOverview;
