import color from "@material-ui/core/colors/amber";
import { createStyles } from "@material-ui/styles";

export const systemViewStyle = (theme: any) =>
  createStyles({
    dialogStyle: {
      width: "100%",
      maxWidth: "90%",
      display: "flex",
      minHeight: "fit-content",
      maxHeight: "calc(100% - 140px)"
    },
    smallDialogStyle: {
      width: "60%",
      maxWidth: "90%",
      display: "flex",
      minHeight: "fit-content",
      maxHeight: "calc(100% - 140px)"
    },
    overWriteDisplayVirtualSystems: {
      display: "inline-flex",
      minWidth: "100%",
      justifyContent: "space-between",
      marginBottom: "42px",
      maxHeight: "32px"
    },
    overWriteDisplay: {
      display: "inline-flex",
      minWidth: "100%",
      justifyContent: "space-between",
      marginBottom: "-1.925rem",
      maxHeight: "32px"
    },
    iconButton: {
      minWidth: "fit-content",
      padding: 0
    },
    powerButton: {
      // --- REMOVE ---
      // opacity: "0.5",
      // pointerEvents: "none",
      // --- REMOVE ---
      minWidth: "fit-content",
      borderRadius: "50%",
      marginLeft: "0.5rem",
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      marginBottom: "5px"
    },
    powerButtonDisabled: {
      opacity: "0.5",
      pointerEvents: "none"
    },
    PowerButtonOperationStatusContainer: {
      minWidth: "100%",
      height: "2.5rem",
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "0.4rem"
    },
    powerOn: {
      width: "35px",
      height: "35px",
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05)",
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "50%",
      color: "white"
    },
    powerOff: {
      background: "white",
      color: "gray",
      width: "35px",
      height: "35px",
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #d5d2d5",
      backgroundColor: "#ffffff",
      borderRadius: "50%"
    },

    indoor: {
      borderRadius: "0.25rem",
      display: "inline",
      fontSize: "0.875rem",
      fontWeight: 450
    },

    outdoor: {
      borderRadius: "0.25rem",
      display: "inline",
      marginRight: "1rem",
      fontSize: "0.875rem",
      fontWeight: 450
    },

    systemName: {
      display: "flex",
      alignItems: "flex-end",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginRight: "10px",
      fontFamily: "RobotoMedium",
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: "normal",
      color: "#29132e"
    },

    groupIcons: {
      display: "inline-flex"
    },
    systemContainer: {
      width: "16.9rem",
      padding: "0.5rem",
      border: "1px solid rgb(0, 0, 0, 0.2)",
      borderRadius: "0.25rem",
      color: "rgb(0, 0, 0, 0.7)",
      marginRight: "10px",
      marginBottom: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "10rem"
    },
    virtualSystemContainer: {
      width: "16.9rem",
      padding: "0.5rem",
      border: "1px solid rgb(0, 0, 0, 0.2)",
      borderRadius: "0.25rem",
      color: "rgb(0, 0, 0, 0.7)",
      marginRight: "10px",
      marginBottom: "10px",
      display: "flex",
      flexDirection: "column",
      minHeight: "10rem"
    },
    nameModelContainer: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2.5rem 0.4rem 0.6rem"
    },
    logo: {
      width: "auto",
      height: "1.3rem",
      marginRight: "1.25rem"
    },
    indoorOutdoorContainer: {
      width: "100%",
      marginBottom: "0.5rem",
      display: "inline-flex",
      alignItems: "center",
      height: "1.25rem",
      padding: "0 0.4rem",
      flexWrap: "wrap"
    },
    modeButtons: {
      boxShadow: "none",
      height: "35px",
      width: "35px",
      minWidth: "unset",
      "&:hover": {
        cursor: "pointer"
      }
    },

    modeButton: {
      // --- REMOVE ---
      // opacity: "0.5",
      // pointerEvents: "none",
      // --- REMOVE ---
      borderRight: "none",
      height: "35px",
      width: "35px",
      // --- BRING BACK ---
      // "&:active": {
      //   opacity: "0.5"
      // },
      // --- BRING BACK ---
      cursor: "pointer",
      color: "#fff"
    },
    modeButtonDisabled: {
      opacity: "0.5",
      pointerEvents: "none"
      // "&:active": {
      //   opacity: "0.5",
      // },
    },

    controlContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%"
    },

    powerButtonSVG: {
      position: "relative",
      top: "15%",
      fontSize: "1.3rem"
    },
    overWriteIcon: {
      "&:hover": {
        borderRadius: "0"
      }
    },
    dialogTitle: {
      paddingLeft: 20,
      paddingRight: 13,
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
    dialogText: {
      fontFamily: "RobotoMedium",
      color: "#29132e",
      lineHeight: "normal",
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "normal"
    },
    clearButton: {
      width: "40px",
      height: "40px"
    },
    dialogContent: {
      flex: "1",
      display: "flex",
      height: "100%",
      // flex: 1,
      maxHeight: "fit-content"
    },
    minLogoHeight: {
      height: "23.46px"
    },
    mappingDialogStyle: {
      width: "fit-content",
      maxWidth: "95%",
      display: "flex",
      minHeight: "200px"
      // maxHeight: "calc(100% - 140px)",
    },
    mappingDialogContent: {
      minHeight: "150px",
      padding: "1rem",
      overflowY: "hidden",
      overflowX: "hidden",
      display: "flex",
      height: "100%",
      flex: 1,
      maxHeight: "fit-content"
    },
    smallMargin: {
      marginRight: "2px",
      userSelect: "none"
    },
    otherUnitDialog: {
      zIndex: 5000,
      padding: "30px 26px 27px 26px",
      minWidth: "500px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 200
    },
    actionsHolder: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%"
    },
    unitTxtFeild: {
      width: "100%"
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
  });
