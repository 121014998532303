import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import {Info as InfoIcon, Label } from "@material-ui/icons/";
import {TreeView} from "@material-ui/lab/";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import clsx from "clsx";
import React from "react";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  node?: any;
  className?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#545964",
      fontSize: "14px",
      width: "100%",
      minHeight: "40px",
      "&:hover > $content": {
        backgroundColor: "transparent"
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, #fff)`,
        color: "var(--tree-view-color)"
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent"
      }
    },
    content: {
      color: "#545964",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      paddingRight: "10px",
      fontWeight: 400,
      "$expanded > &": {
        fontWeight: 400
      }
    },
    group: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "31px"
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
      padding: 0,
      display: "flex"
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: "9px",
      paddingLeft: 0
    },
    labelIcon: {
      marginRight: "10px"
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
      fontSize: 15
    },
    iconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "5px"
    }
  })
);

export default (props: StyledTreeItemProps) => {
  const classes = useTreeItemStyles();
  const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={clsx(classes.labelRoot, { [className]: className })}>
          {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
          {node && node}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      }}
      {...other}
    />
  );
};
