import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import {
  Close,
  CoolModeOff as SvgCoolModeOff,
  CoolModeOn as SvgCoolModeOn,
  Disconnect as SvgDisconnect,
  HeatModeOff as SvgHeatModeOff,
  HeatModeOn as SvgHeatModeOn,
  OnGrey as SvgPowerOff,
  OnWhite as SvgPowerOn
} from "../../icons/";
import { EditIcon } from "../../logos";
import { unitActions } from "../../models/actions/UnitActions";
import { IDevice } from "../../models/Devices";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import assetsService from "../../services/assetsService";
import { CreateOtherUnitPopUp } from "./CustomedModals";
import ManuallySetSystems from "./Device/ManuallySetSystems";
import ManuallySetOther from "./ManuallySetOther";
import ManuallySetSensors from "./ManuallySetSensors";
import { systemViewStyle } from "./SystemView.style";
import UnitsTable from "./UnitsTable";

interface IProps {
  deviceId: string;
  classes?: any;
  allPowerMeters: any;
}

const SystemsView = (props: IProps) => {
  const useStyles = makeStyles(systemViewStyle);
  const classes = useStyles();
  const {
    overWriteDisplay,
    iconButton,
    powerButton,
    powerButtonDisabled,
    PowerButtonOperationStatusContainer,
    systemContainer,
    overWriteDisplayVirtualSystems,
    virtualSystemContainer,
    systemName,
    logo,
    groupIcons,
    nameModelContainer,
    powerButtonSVG,
    modeButton,
    powerOff,
    powerOn,
    modeButtons,
    modeButtonDisabled,
    indoorOutdoorContainer,
    outdoor,
    indoor,
    controlContainer,
    overWriteIcon,
    minLogoHeight,
    smallMargin
  } = classes;

  const [isEdit, setEdit] = React.useState<string>("");
  const [isEditVirtualSystem, setEditVirtualSystem] = React.useState<string>("");
  const [deviceSensors, setDeviceSensors] = React.useState<any>([]);
  const [ppds, setPpds] = React.useState<any>({});
  const [editOther, setEditOther] = React.useState(false);
  const [editSensors, setEditSensors] = React.useState(false);
  const [isMapping, setIsMapping] = React.useState<boolean>(false);
  const [virtualSystems, setVirtualSystems] = React.useState<any>([]);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const deleteSystem = useStoreActions((action) => action.deleteSystem);
  const systemTogglePower = useStoreActions((action) => action.systems.systemTogglePower);
  const [openOtherUnitPopup, setOpenOtherUnitPopup] = useState<boolean>(false);
  const changeSystemOperationMode = useStoreActions(
    (action) => action.systems.changeSystemOperationMode
  );
  const getUnits = useStoreActions((actions) => actions.units.getUnits);

  const getSystemById = useStoreActions(
    (action) => action.systems.getSystemById
  );
  const assignUnitToSystem = useStoreActions((action) => action.assignUnitToSystem);
  const hasUnitsOn = useStoreState((action) => action.systems.hasUnitsOn);
  const hasControlUnits = useStoreState((action) => action.systems.hasControlUnits);
  const getSystemMode = useStoreState((action) => action.systems.getSystemMode);
  const hasDisconnectedUnits = useStoreState((action) => action.systems.hasDisconnectedUnits);
  const types = useStoreState((state) => state.types);
  const createOtherUnit = useStoreActions((action) => action.units.createOtherUnit);
  const getSensorsByDevice = useStoreActions((action) => action.sensors.getSensorsByDevice);
  const device = allDevices[props.deviceId];
  const { hvacBrands, unitTypes } = types;
  const altermaBrandNum = hvacBrands?.filter((brand: any) => brand?.type?.toUpperCase() === "ALTHERMA")[0]?.value || 10;

  const addOtherUnit = (deviceId: string, line: number, name: string) => {
    createOtherUnit({ deviceId, line, name }).then(() => setOpenOtherUnitPopup(false));
  };

  const handleCreatingNewUnit = useCallback(() => {
    setOpenOtherUnitPopup(true);
  }, []);

  useEffect(() => {

    const ppd = Object.values(props.allPowerMeters).filter(
      (pm: any) => pm.device === device.id
    );
    setPpds(ppd);

    getSensorsByDevice(device.id)
      .then((res: any) => {
        setDeviceSensors(res);
      });
  }, []);

  // In case the rendered device is deleted, this component is rendered anyway so we need to check if the device still exists.

  if (_.isUndefined(device)) {
    return null;
  }
  const assignUnitsToSystems = (unitsToSystems: any) => {
    for (let unitId of Object.keys(unitsToSystems)) {
      assignUnitToSystem({ systemId: unitsToSystems[unitId], unitId }).then(() => {
        getUnits();
      }).catch(() => {
        // errors.unitsErrors.push(`error assigning unit ${unit.address} to system`);
      });
    }
    setEditVirtualSystem("");
  };
  const togglePower = async (system: ISystem) => {
    const systemStatus = await getSystemById({ systemId: system.id });
    if (systemStatus.mapping) {
      setIsMapping(true);
    }
    else {
      const isSystemOn = hasUnitsOn(system.id);
      if (isSystemOn) {
        await systemTogglePower({ id: system.id, data: 2 });
      } else {
        await systemTogglePower({ id: system.id, data: 1 });
      }

    }

  };
  const setColdMode = async (system: ISystem) => {
    const systemStatus = await getSystemById({ systemId: system.id });
    if (systemStatus.mapping) {
      setIsMapping(true);
    }
    else {
      await changeSystemOperationMode({ id: system.id, data: 0 });

    }
  };
  const setHotMode = async (system: ISystem) => {
    const systemStatus = await getSystemById({ systemId: system.id });
    if (systemStatus.mapping) {
      setIsMapping(true);
    }
    else {
      await changeSystemOperationMode({ id: system.id, data: 1 });

    }
  };
  const editSystem = async (system: ISystem) => {
    const systemStatus = await getSystemById({ systemId: system.id });
    if (systemStatus.mapping) {
      setIsMapping(true);
    }
    else {
      setEdit(system.id);
    }
  };
  const onEditOther = () => {
    setEditOther(true);
  };

  const isSystemMapping = async (system: ISystem) => {
    const systemStatus = await getSystemById({ systemId: system.id });
    return systemStatus.mapping;
  };
  const getMappingModal: any = () => {
    return (
      <Dialog classes={{ paperWidthLg: classes.mappingDialogStyle }} onClose={_.noop} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg" fullWidth>
        <div className={classes.dialogTitle}>
          <Typography className={classes.dialogText}>{t`Automatic mapping`}</Typography>
          <IconButton disableRipple onClick={() => setIsMapping(false)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.mappingDialogContent}>
          <Typography>
            {t`Automatic address mapping is in progress, you cannot interact with this system until it is done`}
          </Typography>
        </div>
      </Dialog>
    );
  };

  const getEditModal: any = (device: IDevice, system: ISystem, openOtherUnitPopup: any, setOpenOtherUnitPopup: any) => {
    return (
      <>
        <Dialog classes={{ paperWidthLg: classes.dialogStyle }} onClose={_.noop} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg">
          <div className={classes.dialogTitle}>
            <Typography className={classes.dialogText}>{t`Edit System`}</Typography>
            <IconButton disableRipple onClick={() => setEdit("")} className={classes.iconBtnStyle}>
              <Close color="#7f7692" />
            </IconButton>
          </div>
          <div className={classes.dialogContent}>
            <ManuallySetSystems
              mode={2}
              system={system}
              closeModal={() => setEdit("")}
              site={allSites[device.site]}
              deviceId={device.id}
              openOtherUnitPopup={handleCreatingNewUnit}
            />
          </div>
        </Dialog>
        {openOtherUnitPopup && <CreateOtherUnitPopUp device={device} system={system} close={() => setOpenOtherUnitPopup(false)} onAdd={addOtherUnit} />}
      </>
    );
  };

  const systems = Object.values(allSystems).filter(
    (system: ISystem) => system.device === device.id
  );
  const getEditVirtualSystemModal: any = (unitsArray: any, systemLine: any) => {
    return (
      <Dialog classes={{ paperWidthLg: classes.smallDialogStyle }} onClose={() => setEditVirtualSystem("")} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg">
        <div className={classes.dialogTitle}>
          <Typography className={classes.dialogText}>
            {t`L${systemLine} (${device?.serial?.length ? device.serial.slice(device.serial.length - 4) : ""}) Unassigned Units `}
          </Typography>
          <IconButton disableRipple onClick={() => setEditVirtualSystem("")} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <UnitsTable unitsArray={unitsArray} onSave={assignUnitsToSystems} systems={systems}
            onClose={() => setEditVirtualSystem("")}
          ></UnitsTable>
        </div>
      </Dialog>
    );
  };
  const getEditModalOther: any = () => {
    return (
      <Dialog classes={{ paperWidthLg: classes.smallDialogStyle }} onClose={_.noop} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg" fullWidth>
        <div className={classes.dialogTitle}>
          <Typography className={classes.dialogText}>{t`Edit Power Meters`}</Typography>
          <IconButton disableRipple onClick={() => setEditOther(false)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <ManuallySetOther
            mode={2}
            ppds={ppds}
            sensors={[]}
            closeModal={() => setEditOther(false)}
          />
        </div>
      </Dialog>
    );
  };

  const generatePPDSystem = () => {
    const ppdsArr = Object.keys(ppds).length;

    return (
      <Grid key={`ppd-details`} className={virtualSystemContainer}>
        <Grid item xs={8} classes={{ root: overWriteDisplayVirtualSystems }}>
          <Typography className={systemName || ""}>
            {t` Power Meters`}
          </Typography>

          <div className={groupIcons}>
            <LightTooltip title="Edit System">
              <IconButton disableRipple onClick={() => onEditOther()} className={classes.iconBtnStyle}>
                <EditIcon />
              </IconButton>
            </LightTooltip>
          </div>
        </Grid>
        <div className={indoorOutdoorContainer}>
          <Grid item xs={6}>
            <Typography className={clsx("", outdoor)} variant="body1">
              <span>
                {ppdsArr}
                {t` Power Meters`}
              </span>
            </Typography>
          </Grid>
        </div>
        {editOther && getEditModalOther()}
      </Grid>
    );
  };

  const generateSensorsAux = () => {
    const sensors = Object.keys(deviceSensors).length;

    return (
      <Grid key={`ppd-details`} className={virtualSystemContainer}>
        <Grid item xs={8} classes={{ root: overWriteDisplayVirtualSystems }}>
          <Typography className={systemName || ""}>
            {t`Aux Devices`}
          </Typography>

          <div className={groupIcons}>
            <LightTooltip title="Edit System">
              <IconButton disableRipple onClick={() => setEditSensors(true)} className={classes.iconBtnStyle}>
                <EditIcon />
              </IconButton>
            </LightTooltip>
          </div>
        </Grid>
        <div className={indoorOutdoorContainer}>
          <Grid item xs={6}>
            <Typography className={clsx("", outdoor)} variant="body1">
              <span>
                {sensors}
                {t` Devices`}
              </span>
            </Typography>
          </Grid>
        </div>
        {editSensors &&
          (
            <Dialog classes={{ paperWidthLg: classes.smallDialogStyle }} onClose={_.noop} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg">
              <div className={classes.dialogTitle}>
                <Typography className={classes.dialogText}>{t`Edit Aux Devices`}</Typography>
                <IconButton disableRipple onClick={() => setEditSensors(false)} className={classes.iconBtnStyle}>
                  <Close color="#7f7692" />
                </IconButton>
              </div>
              <div className={classes.dialogContent}>
                <ManuallySetSensors
                  title={"Auxiliary devices"}
                  mode={2}
                  sensors={deviceSensors}
                  closeModal={() => setEditSensors(false)}
                  setDeviceSensors={setDeviceSensors}
                  deviceId={device.id}
                />
              </div>
            </Dialog>
          )}
      </Grid>
    );
  };

  return (
    <>
      {systems.map((system: any, i) => {
        const systemId = system.id;
        const isPowerOn = hasUnitsOn(systemId);
        const isSystemInHeatMode = getSystemMode(systemId) === 1;
        const isSystemInCoolMode = getSystemMode(systemId) === 0;
        const systemHasControlUnits = hasControlUnits(systemId);
        const isAlterma = +system?.brandNum === +altermaBrandNum;
        const counts: { indoor: number, outdoor: number, bsBox: number, control: number } = { indoor: 0, outdoor: 0, bsBox: 0, control: 0 };

        for (const unit of Object.values(allUnits)) {
          if (unit.system === system.id && unit.line === system.line) {
            switch (+unit.type) {
              case +unitTypes["outdoor"]:
                counts["outdoor"]++;
                break;
              case +unitTypes["service"]:
                counts["indoor"]++;
                break;
              case +unitTypes["other"]:
                counts["indoor"]++;
                break;
              case +unitTypes["bsBox"]:
                counts["bsBox"]++;
                break;
              default:
                break;
            }

          }
        }

        return (
          <Grid key={`system-details-${i}`} className={systemContainer}>
            <Grid item xs={8} classes={{ root: overWriteDisplay }}>
              <Typography className={systemName}>
                {system.name}
              </Typography>
              <div className={groupIcons}>
                <LightTooltip title={t`Edit System`}>
                  <IconButton disableRipple onClick={() => editSystem(system)} className={classes.iconBtnStyle}>
                    <EditIcon />
                  </IconButton>
                </LightTooltip>
                <Delete
                  classes={{ root: iconButton }}
                  type={t`System`}
                  object={system}
                  detach={deleteSystem}
                  isMapping={isSystemMapping}
                ></Delete>
              </div>
            </Grid>

            <div className={nameModelContainer}>
              <Grid item xs={5} className={classes.minLogoHeight}>
                {system.brandNum ? (
                  <img
                    height={25}
                    src={assetsService.getBrandLogo(`${system.brandNum}-small.svg`)}
                    className={logo}
                    onError={(event: any) =>
                      (event.target.src = assetsService.getBrandLogo("empty.png"))
                    }
                    alt={
                      _.find(hvacBrands, { value: system.brandNum })
                        ? _.find(hvacBrands, { value: system.brandNum }).name
                        : ""
                    }
                  />
                ) : (
                    ""
                  )}
              </Grid>
              <Grid item xs={7} style={{ textAlign: "right" }}>
                {hasDisconnectedUnits(system.id) && <SvgDisconnect />}
              </Grid>
            </div>

            <div className={indoorOutdoorContainer}>
              {!isAlterma ? <Grid item xs={4}>
                <Typography className={clsx("", outdoor)} variant="body1">
                  <span>

                    {
                      counts["outdoor"]
                    }
                    {t` Outdoor`}
                  </span>
                </Typography>
              </Grid>
                : <span />}
              <Grid item xs={4}>
                <Typography className={clsx("", indoor)} variant="body1">
                  <span>
                    {
                      counts["indoor"]
                    }
                    {t` Indoor `}
                  </span>
                </Typography>
              </Grid>
              {
                counts["bsBox"] && counts["bsBox"] > 0 && !isAlterma ?
                  <Grid item xs={4}>
                    <Typography className={clsx("", indoor)} variant="body1">

                      <span>
                        {
                          counts["bsBox"]
                        }
                        {t` BS Box `}
                      </span>
                    </Typography>
                  </Grid> : <></>}
            </div>

            <Grid item xs={6} classes={{ root: PowerButtonOperationStatusContainer }}>
              <Grid item>
                {isSystemInCoolMode ? (
                  <SvgCoolModeOn onClick={() => setColdMode(system)} className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)} />
                ) : (
                    <SvgCoolModeOff
                      onClick={() => setColdMode(system)}
                      className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)}
                    />
                  )}
                {isSystemInHeatMode ? (
                  <SvgHeatModeOn onClick={() => setHotMode(system)} className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)} />
                ) : (
                    <SvgHeatModeOff onClick={() => setHotMode(system)} className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)} />
                  )}
              </Grid>
              <div className={controlContainer}>
                <IconButton onClick={() => togglePower(system)} className={clsx(powerButton, systemHasControlUnits ? "" : powerButtonDisabled)}>
                  {isPowerOn ? (
                    <div className={powerOn}>
                      <SvgPowerOn className={powerButtonSVG} />
                    </div>
                  ) : (
                      <div className={powerOff}>
                        <SvgPowerOff className={powerButtonSVG} />
                      </div>
                    )}
                </IconButton>
              </div>
            </Grid>
            {isMapping && getMappingModal()}
            {(isEdit === system.id && !isMapping) && getEditModal(device, system, openOtherUnitPopup, setOpenOtherUnitPopup)}
          </Grid>
        );
      })}

      {!_.isEmpty(ppds) && generatePPDSystem()}
      {!_.isEmpty(deviceSensors) && generateSensorsAux()}
    </>
  );
};

export default SystemsView;
