import _ from "lodash";
import React, { PropsWithChildren } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import GroupSearchMenuItem from "./GroupSearchMenuItem";
import MenuItemIcon from "./MenuItemIcon";
import { useStyles } from "./UnitsMenu.style";

interface IUnitsMenuProps {
  onCustomerSelect?: (customerId: string | null) => void;
  onSiteSelect?: (siteId: string | null) => void;
  onSystemSelect?: (systemId: string | null) => void;
  onUnitSelect?: (unitId: string | null) => void;
  isSystemHidden?: boolean;
  generalNaming: string;
  showGeneralOption: boolean;
}

const UnitsMenu: React.FC<PropsWithChildren<IUnitsMenuProps>> = (
  props: PropsWithChildren<IUnitsMenuProps>
) => {
  const c = useStyles();

  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const selections = useStoreState((s) => s.selections.selections);
  const getUnitType = useStoreState((s) => s.units.getUnitType);

  const indoorUnits = useStoreState((s) => s.selections.getUnitsBySelection("indoor"));
  const outdoorUnits = useStoreState((s) => s.selections.getUnitsBySelection("outdoor"));
  const bsBoxUnits = useStoreState((s) => s.selections.getUnitsBySelection("bsBox"));
  const allOtherUnits = useStoreState((s) => s.selections.getUnitsBySelection("other"));
  const otherUnits = allOtherUnits?.filter((unit: any) => unit.controlUnit);

  const setUnit = (id: string, changePage = true) => {
    updateSelections({ type: "unit", data: id });
    props.onUnitSelect && props.onUnitSelect(id);
  };

  const getUnitMenuItem = (unit: IUnit) => {
    let unitName: string = unit.name;
    if (unit && unit.name) {

      return (
        <div key={unit.id}>
          <MenuItemIcon
            title={unitName}
            onClick={setUnit}
            unit={unit.id}
            isSelected={selections.unitId === unit.id}
          />
        </div>
      );
    }
  };

  const selectedUnitType = getUnitType(selections.unitId);
  return (
    <div className={c.view}>
      <div className={c.main}>
        <div className={props.isSystemHidden ? c.hidden : c.menuWrapper}>
          <div>
            {!props.showGeneralOption && (<MenuItemIcon
              title={props.generalNaming}
              onClick={setUnit}
              unit={"null"}
              isSelected={selections.unitId === null}
            />)}
          </div>
          {outdoorUnits?.length ? (<GroupSearchMenuItem
            title={t`Outdoor`}
            onClick={_.noop}
            numItems={outdoorUnits.length}
            className={c.outdoor}
            isOpen={true}
          >
            {_.sortBy(outdoorUnits, (unit) => [unit.line, unit.device, unit.privateId]).map((unit) =>
              getUnitMenuItem(unit)
            )}
          </GroupSearchMenuItem>)
            : <></>}

          {bsBoxUnits?.length ?
            (
              <GroupSearchMenuItem
                title={t`BS Box`}
                onClick={_.noop}
                numItems={bsBoxUnits.length}
                className={c.indoor}
                isOpen={true}
              >
                {_.sortBy(bsBoxUnits, "name").map((unit) => getUnitMenuItem(unit))}
              </GroupSearchMenuItem>
            )
            : <></>
          }

          {indoorUnits?.length ? (<GroupSearchMenuItem
            title={t`Indoor`}
            onClick={_.noop}
            numItems={indoorUnits.length}
            className={c.indoor}
            isOpen={true}
          >
            {_.sortBy(indoorUnits, "name").map((unit) => getUnitMenuItem(unit))}
          </GroupSearchMenuItem>)
            : <></>}

          {otherUnits?.length ? (<GroupSearchMenuItem
            title={t`Other`}
            onClick={_.noop}
            numItems={otherUnits.length}
            className={c.indoor}
            isOpen={true}
          >
            {_.sortBy(otherUnits, "name").map((unit) => getUnitMenuItem(unit))}
          </GroupSearchMenuItem>)
            : <></>}
        </div>
      </div>
    </div>
  );
};

export default UnitsMenu;
