import {
  Button as MuiButton,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import Switch from "../../cool_widgets/Switch/Switch";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../services/timeService";
import { Checkbox } from "../Checkbox";

import { ArrowBack, ArrowDownControl, ArrowUp, CloseIcon } from "../../svgComponents";
import TimePicker from "../TimePicker/TimePicker";
import ErrorBox from "../WarningBox/ErrorBox";
import addEditScheduleStyles from "./addEditSchedule.style";

const AddEditSchedule: React.FC<any> = (props: any) => {

  const user = useStoreState((state) => state.users.me);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay);
  const timeFormat = useStoreState((state) => state.users.timeFormat);

  const types = useStoreState((state) => state.types);
  const { weekDays = [], timeFormat: timeFormatTypes } = types;
  const { temperatureScale, timeFormat: myTimeFormat } = user;

  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createScheduleAPI = useStoreActions((actions) => actions.schedules.createObjectSchedules);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [scheduleDisabled, setScheduleStatus] = useState<boolean>();
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [setpoint, setSetpoint] = useState<number>(temperatureScale === 2 ? 75 : 24);
  const [days, setDays] = useState<[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [setpointEnabled, enableSetpoint] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<any>();
  const [openPicker, setOpenPicker] = useState<string>("");
  const [openConfirmDialog, handleConfirmDialog] = useState<string>("");

  const { addItemSchedule, itemId, isGroup, scheduleData = {}, editMode, hideSchedulesPanel, closeAddEditSchedule, saveScheduleData } = props;

  const title = editMode ? t`Edit Schedule` : t`Add Schedule`;
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;

  const useStyles = makeStyles(addEditScheduleStyles);
  const classes = useStyles();

  const hasValue = (value: any) => {
    return !!value || value === 0;
  };
  const passScheduleInfo = (schedule: any) => {
    const { powerOnTime, powerOffTime, setpoint, days = [], isDisabled } = schedule || {};

    setScheduleStatus(isDisabled);
    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
    setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");

    setpoint && setSetpoint(setpoint);
    setpoint && enableSetpoint(true);
    setDays(days as []);
  };

  useEffect(() => {
    if (!editMode) {
      return;
    }

    setSchedule(scheduleData);
    passScheduleInfo(scheduleData);
  }, [is12Hours]);

  const checkRequiredFields = () => {
    setErrorMsg("");

    if (!powerOnTime && !powerOffTime) {
      setErrorMsg(t`Add at least start or end hour`);
      return false;
    }

    if (days.length === 0) {
      setErrorMsg(t`Pick one day at least`);
      return false;
    }

    if (
      !(
        (powerOnTime) &&
        (powerOffTime)
      )
    ) {
      return true;
    }

    if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
      setErrorMsg(t`End hour must be later than start hour`);
      return false;
    }

    return true;
  };

  const editSchedule = () => {
    const {
      powerOnTime: defaultPowerOff,
      powerOffTime: defaultPowerOn,
      setpoint: defaultSetpoint
    } = schedule;

    const startHour =
      powerOnTime === ""
        ? defaultPowerOn === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOnTime, is12Hours);

    const endHour =
      powerOffTime === ""
        ? defaultPowerOff === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOffTime, is12Hours);

    let setpointValue: any;
    if (setpointEnabled) {
      setpointValue = setpoint;
    } else {
      if (defaultSetpoint) {
        setpointValue = null;
      } else {
        setpointValue = undefined;
      }
    }

    updateSchedule({
      id: scheduleData.id as string,
      data: {
        isDisabled: scheduleDisabled,
        name: "New Schedule",
        powerOnTime: startHour,
        powerOffTime: endHour,
        setpoint: setpointValue,
        days
      }
    })
      .then((data: any) => {
        saveScheduleData(scheduleData.id, data);
        closeAddEditSchedule();
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const createSchedule = () => {
    const startHour = powerOnTime ? stringTimeToUTCMins(powerOnTime, is12Hours) : undefined;
    const endHour = powerOffTime ? stringTimeToUTCMins(powerOffTime, is12Hours) : undefined;
    const setpointValue = setpointEnabled ? setpoint : undefined;

    createScheduleAPI({
      data: {
        isDisabled: scheduleDisabled,
        days,
        powerOnTime: startHour,
        powerOffTime: endHour,
        setpoint: setpointValue,
        name: "new Schedule"
      },
      objId: itemId as string,
      objectType: isGroup ? "group" : "unit"
    })
      .then((schedule: any) => {
        addItemSchedule(isGroup, itemId, schedule.id);
        saveScheduleData(schedule.id, schedule);
        closeAddEditSchedule();
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const save = () => {
    const allRequiredNotEmpty = checkRequiredFields();

    if (!allRequiredNotEmpty) {
      return;
    }

    if (!scheduleData.id) {
      createSchedule();
      return;
    }

    editSchedule();
  };

  const decreaseSetpoint = () => {
    setSetpoint(setpoint - 1);
  };

  const increaseSetpoint = () => {
    setSetpoint(setpoint + 1);
  };

  const cancel = () => {
    setOpenDialog(false);
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...[], ...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
  };

  const handleSetpoint = () => {
    enableSetpoint(!setpointEnabled);
  };

  const changeScheduleStatus = () => {
    setScheduleStatus(!scheduleDisabled);
  };
  const currentDays: string[] = days;

  const weekDaysArray = Object.keys(weekDays);

  const onClear = () => {
    setOpenPicker("");
    openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
  };

  const onSetTime = (time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
    setOpenPicker("");
  };

  const hasTime = (time: any) => {
    return time !== "" && time !== null && time >= 0;
  };

  const Header = () => <div style={{
    width: "100%", background: "linear-gradient(to left, #421a40, #29132e 100%)",
    boxShadow: "0px 5px 9px 0 rgba(0, 0, 0, 0.2)", display: "flex", height: 50, justifyContent: "space-between", alignItems: "center"
  }}>
    <IconButton onClick={() => handleConfirmDialog("hide")} className={classes.bigIconBtnStyle}>
      <ArrowBack />
    </IconButton>
    <Typography style={{ color: "#fff", fontSize: 26 }}>{title}</Typography>
    <IconButton onClick={() => handleConfirmDialog("close")} className={classes.bigIconBtnStyle}>
      <CloseIcon style={{ transform: "scale(1.3)" }} />
    </IconButton>
  </div>;

  return (
    <div className={classes.scheduleInfoContainer}>
      <Header />
      <div className={classes.backgroundContainer}>
        <div className={classes.pageContent}>
          <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.container)}
          >
            <div className={classes.bodyRow}>
              <Typography className={classes.statusStyle}>
                {scheduleDisabled ? t`Status: Inactive` : t`Status: Active`}
              </Typography>
              <div className={classes.controlSec}>
                <Switch
                  checked={!scheduleDisabled}
                  disableRipple={true}
                  onChange={changeScheduleStatus}
                  value={true}
                />
              </div>
            </div>
            <Grid className={classes.startEndTimeContainer}>
              <MuiButton
                disableElevation
                disableRipple
                variant="contained"
                className={clsx(classes.timeContainer, {
                  [classes.timeSelected]: !!powerOnTime
                })}
                onClick={() => setOpenPicker("start")}
              >
                {powerOnTime
                  ? powerOnTime
                  : "START HOUR"}
              </MuiButton>
              <MuiButton
                disableElevation
                disableRipple
                variant="contained"
                className={clsx(classes.timeContainer, {
                  [classes.timeSelected]: !!powerOffTime
                })}
                onClick={() => setOpenPicker("end")}
              >
                {powerOffTime
                  ? powerOffTime
                  : "END HOUR"}
              </MuiButton>
            </Grid>
          </Grid>
          <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.container)}
          >
            <Typography
              className={classes.selectModeStyle}
            >{t`Choose Days`}</Typography>
            <Grid container className={classes.daysContainer} id="days">
              <DaysList
                days={weekDaysArray}
                activeDays={currentDays}
                action={addRemoveDay}
              />
            </Grid>
          </Grid>

          <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.container)}
          >
            <Typography
              className={classes.selectModeStyle}
            >{t`Select Mode`}</Typography>

            <div className={classes.selectModeContainer}>
              <InputLabel htmlFor="setpoint-input" className={classes.valueTitle}>
                {t`Setpoint`}
                <Checkbox
                  onChange={handleSetpoint}
                  checked={setpointEnabled}
                  className={classes.checkboxStyle}
                />
              </InputLabel>
              <Grid className={classes.setpointContainer}>
                <MuiButton
                  disableElevation
                  disableRipple
                  disabled={!setpointEnabled}
                  onClick={increaseSetpoint}
                  className={classes.controlArrowButton}
                >
                  <ArrowUp />
                </MuiButton>
                <Typography
                  className={clsx(classes.setpointStyle, {
                    [classes.setpointNotSet]: !setpointEnabled
                  })}
                >
                  {setpointEnabled ? setpoint : temperatureScale === 1 ? "24" : "75"}
                  <span className={classes.tempSymbolStyle}>
                    {temperatureSymbol()}
                  </span>
                </Typography>
                <MuiButton
                  disableElevation
                  disableRipple
                  disabled={!setpointEnabled}
                  onClick={decreaseSetpoint}
                  className={classes.controlArrowButton}
                >
                  <ArrowDownControl />
                </MuiButton>
              </Grid>
            </div>
          </Grid>
          {errorMsg && (
            <InputLabel
              className={classes.errorLabelStyle}
            >{errorMsg}</InputLabel>
          )}

          <MuiButton disableElevation disableRipple type="submit" variant="contained" onClick={save} className={classes.redBtn}>
            {t`Save`}
          </MuiButton>
          {openConfirmDialog && <ErrorBox
            error={"Are you sure you want to discard changes made on this page?"}
            onAccept={() => openConfirmDialog === "close" ? hideSchedulesPanel() : closeAddEditSchedule()}
            onClose={() => handleConfirmDialog("")} />}
          <TimePicker
            show={!!openPicker}
            onSet={onSetTime}
            time={openPicker === "start" ? powerOnTime : powerOffTime}
            onDismiss={() => setOpenPicker("")}
            onClear={onClear}
            is12Hours={is12Hours}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditSchedule;
