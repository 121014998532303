import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { ISite } from "../../models/Sites";
import useStyles from "./ModalWithSteps.style";

interface IProps {
  site: ISite;
  showModal: any;
  closeModal: () => void;
  children: any[];
  onStep: number;
  next: () => void;
  isNextDisabled: boolean;
}

export default function ModalWithSteps(props: IProps) {
  const classes = useStyles();

  if (!props.showModal) {
    return <div />;
  }

  return (
    <Dialog
      onClose={_.noop}
      aria-labelledby="simple-dialog-title"
      open={true}
      maxWidth="lg"
      fullWidth
      classes={{ paperWidthLg: clsx(classes.dialogStyle, {[classes.systemsDialogStyle]: props.onStep === 2}) }}
    >
      <div className={classes.dialogHeader}>
      <Typography className={classes.headerTitle}>{props.onStep === 2 ? t`HVAC Systems and Units Allocation` : t`Add Device`}</Typography>
      <IconButton disableRipple className={classes.iconBtnStyle} onClick={props.closeModal}><Close color="#7f7692" /></IconButton>
      </div>

      <div className={classes.dialogContent}>
        {// Will sync the steps to the order in witch the children were passed
          props.children.filter((component, i) => {
            if (props.onStep === i) {
              return component;
            }
          })}
      </div>

      <div className={classes.modalActions}>
        {(props.onStep === 0 || props.onStep === 1) && (
          <Button
            onClick={props.closeModal}
            className={classes.modalButton}
            white
            uppercase
            width={120}
          >
            {t`Cancel`}
          </Button>
        )}
        {(props.onStep === 0 || props.onStep === 1) && (
          <Button
            disabled={props.isNextDisabled}
            onClick={props.next}
            uppercase
            width={120}
          >
            {t`Next`}
          </Button>
        )}
      </div>
    </Dialog>
  );
}
