import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  shrinkStyle: {
    transform: 'translate(3px, -15px) scale(0.75) !important'
  },
  helperTextStyle: {
    marginLeft: '2px'
  },
  inputStyle: {
    padding: '12px'
  },
  rootStyle: {
    backgroundColor: '#efeeefbf'
  }
}));
export default useStyles;
