import { Device as DeviceSdk, Services as ServicesSdk, Unit as UnitSdk } from "coolremote-sdk";
import { differenceInDays } from "date-fns/esm";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  memo,
  thunk,
  Thunk
} from "easy-peasy";
import _ from "lodash";
import { SetStateAction } from "react";
import { t } from "ttag";
import { IAlert } from "./Alerts";
import { IRootStoreModel } from "./RootStore";

export interface IUnit {
  id: string;
  type: number;
  name: string;
  isVisible: boolean;
  enableCoolMode: boolean;
  enableHeatMode: boolean;
  enableAutoMode: boolean;
  ambientTemperature?: number | 0;
  activeOperationMode: number | -1;
  activeOperationStatus: number | -1;
  readingValue?: string;
  readingValueTimestamp?: number;
  activeSetpoint?: number | 0;
  activeFanMode?: number | -1;
  activeLouverModes?: number | -1;
  device: string;
  zone: string | null;
  system: string | null;
  internalId: string;
  proId: string | null;
  line: number /* from the first 2 chars of unit name: L1, L2, etc */;
  privateId: string /* second part of unit name, after '.': '000', '003' etc */;
  role: any;
  isConnected?: boolean;
  enableSetpoint: boolean;
  enableMode: boolean;
  enableOnState: boolean;
  enableOnoff: boolean;
  supportedFanModes: number[];
  supportedSwingModes: number[];
  supportedOperationModes: number[];
  supportedOperationStatuses: number[];
  temperatureLimits: any;
  compressors?: string[];
  task: number;
  capacity?: number;
  capacityMeasurementUnits?: number;
  airnet?: number;
  model?: string;
  serialNumber?: string;
  site?: string;
  controlUnit?: string;
  serviceUnits?: string[];
  address?: string;
  schedules?: any;
  activeSwingMode?: any;
  branchBoxUnit?: any;
  bsBox?: any;
  otherUnits?: string[];
}

interface IUpdatePayload {
  unitId: string;
  data: any;
}

interface IPowerPayload {
  unitId: string;
  state: number;
}

export interface IUnitMap {
  [key: string]: IUnit;
}

export interface IUnitAffiliation {
  customerId?: string;
  siteId?: string;
  systemId?: string;
  deviceId?: string;
}

export interface IGetUnitStatsParams {
  unitIds?: any[];
  unitId?: string;
  startTime: number;
  endTime: number;
  withQuality?: boolean;
  params?: string[];
}

export interface IGetUnitStatsResult {
  unitSupportedParams: any[];
  unitProStats: { ranges: any; results: any[] };
  unitBasicStats: any[];
  updateTime: any;
}

export type IEntityType = "customer" | "site" | "system";
export type IUnitType = "all" | "indoor" | "outdoor" | "service" | "bsBox" | "other";

export interface IUnitsModel {
  allUnits: IUnitMap;
  getUnit: Computed<
    IUnitsModel,
    (unitId: string | null | undefined) => IUnit | undefined,
    IRootStoreModel
  >;
  getUnitName: Computed<
    IUnitsModel,
    (unitId: string | null | undefined) => string,
    IRootStoreModel
  >;
  getUnitType: Computed<
    IUnitsModel,
    (unitId: string | null | undefined) => "Indoor" | "Outdoor" | "Control" | "-" | "Other",
    IRootStoreModel
  >;
  getUnitTypeByTypes: Computed<
    any,
    (unitId: string | null | undefined) => string,
    IRootStoreModel
  >;
  getUnitAffiliation: Computed<IUnitsModel, (unitId: string) => IUnitAffiliation, IRootStoreModel>;
  getUnitsBy: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string | null,
      options?: { type?: IUnitType }
    ) => IUnit[],
    IRootStoreModel
  >;
  getUnitByPrivateId: Computed<
    IUnitsModel,
    (privateId: string,
      deviceSerial: string,
      line: number,
      siteId: string
    ) => IUnit,
    IRootStoreModel
  >;
  countUnitsFor: Computed<
    IUnitsModel,
    (entityType: IEntityType, entityId: string) => number,
    IRootStoreModel
  >;
  getUnitAlerts: Computed<IUnitsModel, (unitId: string) => IAlert[], IRootStoreModel>;
  getUnitSystemBrandName: Computed<IUnitsModel, (unitId: string) => string | null, IRootStoreModel>;
  getUnitSystemBrand: Computed<IUnitsModel, (unitId: string) => number | null, IRootStoreModel>;
  getUnitByInternalOrProId: Computed<IUnitsModel, (internalOrProId: string) => IUnit | null>;
  getUnitById: Computed<IUnitsModel, (unitDbId: string) => IUnit | null>;
  isItMe: Computed<IUnitsModel, (unit: IUnit, internalOrProId: string) => boolean>;
  // getUnitProSupportedServiceParams: Thunk<
  //   IUnitsModel, /* types for actions */
  //   string, /* payload : unitId */
  //   any, /* injections */
  //   IRootStoreModel, /* types for getStoreState/getStoreActions */
  //   any /* result */
  // >;
  getTableData: Thunk<
    IUnitsModel,
    IGetUnitStatsParams /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;
  getUnitStats: Thunk<
    IUnitsModel,
    IGetUnitStatsParams /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;

  getUnitSupportedParams: Thunk<
    IUnitsModel,
    { unitId: string } /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;

  initialize: Action<IUnitsModel, any>;
  updateUnitLocally: Action<IUnitsModel, { id: string, unit: any }>;
  onInitialized: ActionOn<IUnitsModel, IRootStoreModel>;

  updateUnit: Thunk<IUnitsModel, { id: string; updatedData: any }>;
  getUnits: Thunk<
    IUnitsModel,
    any /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any
  >;
  getAllUnits: Thunk<
    IUnitsModel,
    any /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any
  >;
  setParamValue: Thunk<IUnitsModel, { id: string; serviceParamCode: any; value: any; }>;
  cleanFilterUnit: Thunk<IUnitsModel, { id: string }>;
  refreshUnit: Thunk<IUnitsModel, { id: string }>;
  _storeCreateUnit: Action<IUnitsModel, { id: string; data: IUnit }>;
  _storeUpdateUnit: Action<IUnitsModel, { id: string; data: Partial<IUnit> }>;
  updateUnitsState: Action<IUnitsModel, any>;
  _storeDeleteUnit: Action<IUnitsModel, { id: string }>;

  setUnitSystem: Action<IUnitsModel, { id: string; systemId: any }>;
  setUnitName: Action<IUnitsModel, { id: string; unitName: any }>;

  requestStatsRefresh: Thunk<IUnitsModel, { id: string }>;
  setActiveSetpoint: Thunk<IUnitsModel, { id: string; setpoint: any }>;
  updateLocalSetpoint: Action<IUnitsModel, { id: string; setpoint: any }>;
  setActiveOperationMode: Thunk<IUnitsModel, { id: string; mode: any }>;
  togglePower: Thunk<IUnitsModel, { id: string; activeOperationStatus: any }>;
  setUnitActiveSetpoint: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveOperationMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveFanMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveSwingMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveOperationStatus: Thunk<IUnitsModel, IUpdatePayload>;
  changePowerState: Thunk<IUnitsModel, IPowerPayload>;
  getAndReplaceUnits: Thunk<IUnitsModel, any>;
  createOtherUnit: Thunk<IUnitsModel, { deviceId: string, line: number, name: string }>;
  getUnitParamsAndStats: Thunk<IUnitsModel, { unitId: string, startTime: number, endTime: number }>;
  getUnitCategories: Thunk<IUnitsModel, string>;
  getUnitCategoryParams: Thunk<IUnitsModel, { unitId: string; category: string }>;
  getUnitCategoryParamsByParams: Thunk<IUnitsModel, { unitId: string; params: any }>;
  getUnitParamsValues: Thunk<IUnitsModel, { id: string; params: any; }>;
  updateUnitParamsValues: Thunk<IUnitsModel, { id: string; data: any; }>;
}

export const unitsModel: IUnitsModel = {
  allUnits: {},
  initialize: action((state, payload) => {
    state.allUnits = payload;
  }),
  updateUnitLocally: action((state, payload) => {
    const { id, unit } = payload;
    state.allUnits = { ...state.allUnits, [id]: { ...state.allUnits[id], ...unit } };
  }),

  getUnits: thunk(async (actions, state) => {
    const allUnits = await UnitSdk.getUnits();
    actions.updateUnitsState(allUnits);
  }),
  getAllUnits: thunk((actions, state) => {
    return UnitSdk.getUnits();
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),

  getUnit: computed([(state) => state.allUnits], (allUnits) =>
    memo((unitId) => {
      if (_.isNil(unitId)) return undefined;
      return allUnits[unitId];
    }, 100)
  ),

  getUnitName: computed([(state) => state.allUnits], (allUnits) =>
    memo((unitId) => {
      if (_.isNil(unitId)) return "-";
      return allUnits[unitId]?.name;
    }, 100)
  ),

  getUnitType: computed([(state) => state.allUnits], (allUnits) =>
    memo((unitId) => {
      if (_.isNil(unitId)) return "-";
      return allUnits[unitId]?.type === 2 ? "Outdoor" : allUnits[unitId]?.type === 3 ? "Indoor" : allUnits[unitId]?.type === 5 ? "Other" : "Control";
    }, 100)
  ),
  getUnitTypeByTypes: computed([(state) => state.allUnits, (state, storeState: any) => storeState.unitTypesMirrror], (allUnits, unitTypesMirrror) =>
    memo((unitId) => {
      if (_.isNil(unitId)) return "-";
      return unitTypesMirrror[allUnits[unitId]?.type || ""] || "-";
    }, 100)
  ),
  getUnitAffiliation: computed(
    [
      (state) => state.allUnits,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices
    ],
    (allUnits, allSites, allDevices) =>
      memo((unitId) => {
        const aff = {} as IUnitAffiliation;
        if (unitId && allUnits[unitId]) {
          const unit = allUnits[unitId];
          aff.systemId = unit.system || unit.line + "_" + unit.device;

          const unitDevice = allDevices[unit.device];
          if (_.isUndefined(unitDevice)) return aff;
          aff.deviceId = unitDevice.id || undefined;

          const unitSite = allSites[unitDevice.site];
          if (!_.isUndefined(unitSite)) {
            aff.siteId = unitSite.id || undefined;
            aff.customerId = unitSite.customer || undefined;
          }
        }
        return aff;
      }, 100)
  ),
  getUnitByPrivateId: computed(
    [(state) => state.allUnits,
    (state, storeState) => storeState.devices.getDeviceBySerial
    ],
    (allUnits, getDeviceBySerial) =>
      memo((privateId, deviceSerial, line, siteId) => {
        const device = getDeviceBySerial(deviceSerial);
        return Object.values(allUnits).filter((unit) => {
          if (privateId === unit.privateId &&
            unit.device === device?.id &&
            line === unit.line &&
            unit.site === siteId) {
            return true;
          }
        })[0];
      }, 100)
  ),
  getUnitsBy: computed(
    [(state) => state.allUnits, (state) => state.getUnitAffiliation

    ],

    (allUnits, getUnitAffiliation) =>
      memo((entityType, entityId, options = { type: "all" }) => {
        // entityId of undefined, null or '' gets all units
        if (_.isNil(entityId) || entityId.length === 0) {
          return Object.values(allUnits);
        } else {
          return Object.values(allUnits).filter((unit) => {
            // Apply filter from options
            // Unit types: 1 - indoor, 2 - outdoor
            if (!unit.isVisible) {
              return false;
            }
            if (
              options?.type &&
              ((options.type === "indoor" && unit.type !== 3) ||
                (options.type === "outdoor" && unit.type !== 2) ||
                (options.type === "bsBox" && unit.type !== 4) ||
                (options.type === "other" && unit.type !== 5)
              )
            ) {
              return false;
            }

            // Apply filter by entity type
            const unitAff = getUnitAffiliation(unit.id);
            switch (entityType) {
              case "customer":
                return entityId === unitAff.customerId;
              case "site":
                return entityId === unitAff.siteId;
              case "system":
                return entityId === unitAff.systemId;
            }
          });
        }
      }, 100)
  ),

  countUnitsFor: computed(
    [(state) => state.allUnits, (state) => state.getUnitAffiliation,
    (state, storeState) => storeState.systems.allSystems],
    (allUnits, getUnitAffiliation, allSystems) =>
      memo((entityType, entityId) => {
        if (entityType === "system" && !allSystems[entityId]) {
          const unassignedUnits: any = Object.values(allUnits).filter(
            (unit: any) => _.isNil(unit.system)
          );
          let systems: any = {};
          for (let unit of unassignedUnits) {
            if (!systems[unit.line + "_" + unit.device]) {
              systems[unit.line + "_" + unit.device] = [unit];
            }
            else {
              systems[unit.line + "_" + unit.device].push(unit);
            }
          }
          return systems[entityId].length;
        }
        return Object.values(allUnits).filter((unit) => {
          if (unit.isVisible) {
            const unitAff = getUnitAffiliation(unit.id);
            switch (entityType) {
              case "customer":
                return (unit.type === 3 || unit.type === 5) && entityId === unitAff.customerId;
              case "site":
                return (unit.type === 3 || unit.type === 5) && entityId === unitAff.siteId;
              case "system":
                return (unit.type === 3 || unit.type === 5) && entityId === unitAff.systemId;
            }
          }
        }).length;
      }, 100)
  ),

  getUnitAlerts: computed(
    [
      (state) => state.allUnits,
      (state) => state.isItMe,
      (state, storeState) => storeState.alerts.allOpenAlerts
    ],
    (allUnits, isItMe, allOpenAlerts) =>
      memo((unitId) => {
        const unit = allUnits[unitId];
        return Object.values(allOpenAlerts).filter(
          (alert) =>
            _.has(alert, "data.eventData.unitId") &&
            alert.data.eventData.unitId &&
            isItMe(unit, alert.data.eventData.unitId)
        );
      }, 100)
  ),

  getUnitSystemBrandName: computed(
    [(state) => state.allUnits, (state, storeState) => storeState.systems.allSystems],
    (allUnits, allSystems) =>
      memo((unitId) => {
        const unit = allUnits[unitId];

        if (_.isUndefined(unit)) {
          return null;
        }
        if (_.isNil(unit.system)) {
          return null;
        }

        const system = allSystems[unit.system];

        if (_.isNil(system)) {
          return null;
        }
        if (_.isNil(system.name)) {
          return null;
        }

        return system.name;
      }, 100)
  ),
  getUnitSystemBrand: computed(
    [(state) => state.allUnits, (state, storeState) => storeState.systems.allSystems],
    (allUnits, allSystems) =>
      memo((unitId) => {
        const unit = allUnits[unitId];
        if (_.isUndefined(unit)) {
          return null;
        }
        if (_.isNil(unit.system)) {
          return null;
        }

        const system = allSystems[unit.system];

        if (_.isNil(system)) {
          return null;
        }
        if (_.isNil(system.brandNum)) {
          return null;
        }
        return system.brandNum;
      }, 100)
  ),

  getUnitByInternalOrProId: computed(
    [(state) => state.allUnits],
    (allUnits) =>
      memo((internalOrProId) => {
        let foundUnit: IUnit | null = null;

        // Internal ID first!
        for (let unitId in allUnits) {
          const unit: IUnit = allUnits[unitId];

          if (unit.internalId === internalOrProId) {
            foundUnit = unit;
            break;
          }
        }

        // If not found by internal ID, search by pro ID.
        if (_.isNull(foundUnit)) {
          for (let unitId in allUnits) {
            const unit: IUnit = allUnits[unitId];

            if (
              !_.isNil(unit.proId) &&
              !_.isEmpty(unit.proId) &&
              internalOrProId &&
              internalOrProId.indexOf(":") !== -1 &&
              internalOrProId.split(":")[1].substr(0, 6) === unit.proId
            ) {
              foundUnit = unit;
              break;
            }
          }
        }

        return foundUnit;
      }, 100) // 1000
  ),

  getUnitById: computed(
    [(state) => state.allUnits],
    (allUnits) =>
      memo((unitDbId) => {
        const unit: IUnit = allUnits[unitDbId];
        if (unit) {
          return unit;
        }
        return null;

      }, 100) // 1000
  ),

  isItMe: computed([(state) => state.allUnits], (allUnits) =>
    memo((unit, internalOrProId) => {
      if (unit.internalId === internalOrProId) {
        return true;
      }

      if (
        !_.isNil(unit.proId) &&
        !_.isEmpty(unit.proId) &&
        internalOrProId.indexOf(":") !== -1 &&
        internalOrProId.split(":")[1].substr(0, 6) === unit.proId
      ) {
        return true;
      }

      return false;
    }, 100)
  ),

  getUnitStats: thunk(async (actions, { unitId, startTime, endTime, withQuality = false }, state) => {
    let unitSupportedParams: any = [];
    let unitProStats = { ranges: {}, results: [] };
    let updateTime: any;
    const serviceParams = state.getStoreState().serviceParams;

    let promises: any = [];

    // unitSupportedParams = res.supportedColumns;
    // updateTime = res.updateTimestamp;
    return UnitSdk.getProSupportedServiceParams(unitId)
      .then((res: any) => {
        unitSupportedParams = res.supportedColumns;
        updateTime = res.updateTimestamp;

        if (unitSupportedParams && unitSupportedParams.length) {
          // const unitSupportedParamsCodes = _.map(unitSupportedParams, (param) => param.code);

          const unitSupportedParamsCodes = unitSupportedParams.reduce((filtered: any, param: any) => {
            if (param?.code && serviceParams[param.code]?.plotable) {
              filtered.push(param.code);
            }
            //plotable
            return filtered;
          }, []);

          // Period to get stats for
          let granularity = "minute";
          if (unitSupportedParamsCodes.length) {
            promises.push(
              UnitSdk.getProStats(unitId, startTime, endTime, granularity, withQuality, unitSupportedParamsCodes)
            );
          }
        }

        return Promise.all(promises).then((resp: any) => {
          if (resp[0]) {
            unitProStats = resp[0];
          }
        });
      })
      .then(() => {
        return {
          unitSupportedParams,
          unitProStats,
          updateTime
        };
      });
  }),
  getUnitSupportedParams: thunk(async (actions, { unitId }, state) => {
    let unitSupportedParams: any = [];
    let updateTime: any;
    return UnitSdk.getProSupportedServiceParams(unitId)
      .then((res: any) => {
        unitSupportedParams = res.supportedColumns;
        updateTime = res.updateTimestamp;
      })
      .then(() => {
        return {
          unitSupportedParams,
          updateTime
        };
      });
  }),
  getTableData: thunk(async (actions, { unitIds, startTime, endTime, params }, state) => {
    return UnitSdk.getProExtendedServiceParams({ units: unitIds, startTime, endTime, params, noreduce: true });
  }),

  setUnitName: action((state, payload) => {
    state.allUnits[payload.id].name = payload.unitName;
  }),

  setUnitSystem: action((state, payload) => {
    state.allUnits[payload.id].system = payload.systemId;
  }),

  requestStatsRefresh: thunk(async (actions, payload) => {
    await UnitSdk.requestStatsRefresh(payload.id);
  }),

  updateLocalSetpoint: action((state, payload) => {
    state.allUnits = { ...state.allUnits, [payload.id]: { ...state.allUnits[payload.id], activeSetpoint: payload.setpoint } };
  }),

  setActiveSetpoint: thunk(async (actions, payload) => {
    UnitSdk.setActiveSetpoint(payload.id, payload.setpoint)
      .then(() => {
        actions.updateLocalSetpoint(payload);
      });
  }),

  setActiveOperationMode: thunk(async (actions, payload) => {
    await UnitSdk.setActiveOperationMode(payload.id, payload.mode);
    actions._storeUpdateUnit({
      id: payload.id,
      data: { activeOperationMode: payload.mode }
    });
  }),

  togglePower: thunk(async (actions, payload) => {
    const toggleStates = [1, 2, 1];
    const status = toggleStates[payload.activeOperationStatus];
    await UnitSdk.setActiveOperationStatus(payload.id, status);
    actions._storeUpdateUnit({ id: payload.id, data: { activeOperationStatus: status } });
  }),

  updateUnit: thunk(async (actions, payload) => {
    const data = await UnitSdk.update(payload.id, payload.updatedData);

    // Why call _storeCreateUnit()? Because it assigns the answer as-is.
    actions._storeCreateUnit({ id: data.id, data });
    return data;
  }),
  setParamValue: thunk(async (actions, payload) => {
    const data = await UnitSdk.setParamValue(payload.id, payload.value, payload.serviceParamCode);
    return data;
  }),
  refreshUnit: thunk(async (actions, payload) => {
    return UnitSdk.fetch(payload.id)
      .then((data: any) => {
        actions._storeCreateUnit({ id: data.id, data });
        return data;
      })
      .then((data: any) => {
        return data;
      });
  }),

  cleanFilterUnit: thunk(async (actions, payload) => {
    await UnitSdk.clearFilter(payload.id);
  }),

  _storeCreateUnit: action((state, payload) => {
    if (state.allUnits[payload.id]) {
      state.allUnits[payload.id] = { ...state.allUnits[payload.id], ...payload.data };
    }
    else {
      state.allUnits = { ...state.allUnits, [payload.id]: payload.data };
    }
  }),

  _storeUpdateUnit: action((state, payload) => {
    if (state.allUnits[payload.id]) {
      state.allUnits = { ...state.allUnits, [payload.id]: { ...state.allUnits[payload.id], ...payload.data } };
    }
  }),
  updateUnitsState: action((state, payload) => {
    _.assign(state.allUnits, payload);
  }),

  _storeDeleteUnit: action((state, payload) => {
    delete state.allUnits[payload.id];
  }),
  setUnitActiveOperationMode: thunk((actions, payload) => {
    const { unitId, data: mode } = payload;
    return UnitSdk.setActiveOperationMode(unitId, +mode as number);
  }),
  setUnitActiveOperationStatus: thunk(async (actions, payload) => {
    const { unitId, data: operationStatus } = payload;
    return UnitSdk.setActiveOperationStatus(
      unitId,
      +operationStatus as number
    );
  }),
  setUnitActiveSwingMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    return UnitSdk.setActiveSwingMode(unitId, +mode as number);
  }),
  setUnitActiveFanMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    return UnitSdk.setActiveFanMode(unitId, +mode as number);
  }),
  setUnitActiveSetpoint: thunk((actions, payload) => {
    const { unitId, data: setpoint } = payload;
    return UnitSdk.setActiveSetpoint(unitId, +setpoint as number);
  }),
  changePowerState: thunk((actions, payload, { injections }) => {
    const { unitId, state } = payload;
    return UnitSdk.setActiveOperationStatus(unitId, state);
  }),
  getAndReplaceUnits: thunk(async (actions, state) => {
    const allUnits = await UnitSdk.getUnits();
    actions.initialize(allUnits);
  }),
  createOtherUnit: thunk(async (actions, payload) => {
    const unit = await DeviceSdk.createOtherUnit(payload.deviceId, { ...payload, deviceId: undefined });
    unit && actions._storeCreateUnit({ id: unit.id, data: unit });
  }),
  getUnitParamsAndStats: thunk((actions, payload) => {
    const { unitId, startTime, endTime } = payload;
    return UnitSdk.getUnitParamsAndStats(unitId, startTime, endTime);
  }),
  getUnitCategories: thunk(async (actions, payload) => {
    const response = await UnitSdk.getUnitCategories(payload);
    return response;
  }),
  getUnitCategoryParams: thunk(async (actions, payload) => {
    const { unitId, category } = payload;
    const response = await UnitSdk.getUnitCategoryParams(unitId, category);
    return response;
  }),
  getUnitParamsValues: thunk(async (actions, payload) => {
    const { id, params } = payload;
    const response = await UnitSdk.getUnitParamsValues(id, params);
    return response;
  }),
  updateUnitParamsValues: thunk(async (actions, payload) => {
    const { id, data } = payload;
    const response = await UnitSdk.updateUnitParamsValues(id, data);
    return response;
  }),
  getUnitCategoryParamsByParams: thunk(async (actions, payload) => {
    const { unitId, params } = payload;
    const response = await UnitSdk.getUnitCategoryParamsByParams(unitId, params);
    return response;
  })
};
