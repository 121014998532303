import { t } from "ttag";

const AnomaliesUtils = {

    selectableDurations: [
      {
        title: t`Immediately`,
        value: "0"
      },
      {
        title: t`5 min`,
        value: "5"
      },
      {
        title: t`10 min`,
        value: "10"
      },
      {
        title: t`15 min`,
        value: "15"
      },
      {
        title: t`30 min`,
        value: "30"
      },
      {
        title: t`60 min`,
        value: "60"
      },
      {
        title: t`90 min`,
        value: "90"
      },
      {
        title: t`120 min`,
        value: "120"
      }
    ]
};

export default AnomaliesUtils;
