import { Device as DeviceSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import { object } from "yup";
import { IRootStoreModel } from "./RootStore";
import { ISystem } from "./Systems";
import { IUnit, IUnitType } from "./Units";

export interface IDevice {
  id: string;
  serial: string;
  pin?: string;
  name: string;
  isConnected: boolean;
  site: string;
  units: string[];
  systems: string[];
  role: any;
  hvacLines?: string[];
  protocolVersion?: any;

}

export interface IDeviceMap {
  [key: string]: IDevice;
}

export interface IDevicesModel {
  allDevices: IDeviceMap;
  initialize: Action<IDevicesModel, any>;
  onInitialized: ActionOn<IDevicesModel, IRootStoreModel>;
  getDevice: Computed<IDevicesModel, (id?: string) => IDevice | undefined>;
  getDeviceName: Computed<IDevicesModel, (id: string | undefined) => string | undefined>;
  getDeviceNameBySerial: Computed<
    IDevicesModel,
    (serial: string | undefined) => string | undefined
  >;

  mapLineIndoors: Thunk<
    IDevicesModel,
    { deviceId: string, lineId: string }
  >;
  mapLines: Thunk<
    IDevicesModel,
    { deviceId: string, serviceLine: string, controlLine: string }
  >;
  getLineQuality: Thunk<
    IDevicesModel,
    { deviceId: string, lineId: string, startTime: number, endTime: number }
  >;
  getDeviceLines: Thunk<IDevicesModel, string>;
  getDeviceSystems: Computed<IDevicesModel, (deviceId: string) => ISystem[], IRootStoreModel>;
  getDeviceUnits: Computed<
    IDevicesModel,
    (
      deviceId: string,
      systemId: "all" | "unassigned" | "disconnected" | "assigned" | string,
      type: IUnitType
    ) => IUnit[],
    IRootStoreModel
  >;
  getVisibleDeviceUnits: Computed<
    IDevicesModel,
    (
      deviceId: string,
      systemId: "all" | "unassigned" | "disconnected" | "assigned" | string,
      type: IUnitType
    ) => IUnit[],
    IRootStoreModel
  >;
  getDeviceBySerial: Computed<IDevicesModel, (serial: string) => IDevice | null>;
  getDevicesBySite: Computed<IDevicesModel, (siteId: string) => IDevice[]>;
  getDeviceById: Computed<IDevicesModel, (id: string) => IDevice | null>;
  refreshSystems: Thunk<IDevicesModel, { deviceId: string }>;
  _storeUpdateDeviceConnection: Action<IDevicesModel, { deviceId: string; data: any }>;
}

export const devicesModel: IDevicesModel = {
  allDevices: {},

  initialize: action((state, payload) => {
    state.allDevices = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),

  getDevice: computed([(state) => state.allDevices], (allDevices) =>
    memo((id) => {
      if (_.isNil(id)) return undefined;
      return allDevices[id];
    }, 100)
  ),

  _storeUpdateDeviceConnection: action((state, payload) => {
    if (state.allDevices[payload.deviceId]) {
      state.allDevices[payload.deviceId] = payload.data;
    }
  }),

  getLineQuality: thunk(async (actions, payload) => {
    const params = { startTime: payload.startTime, endTime: payload.endTime };
    const data = await DeviceSdk.getLineQuality(payload.deviceId, payload.lineId, params);
    return data;
  }),
  getDeviceLines: thunk(async (actions, payload) => {
    const data = await DeviceSdk.getDeviceLines(payload);
    return data;
  }),
  mapLineIndoors: thunk(async (actions, payload) => {
    await DeviceSdk.mapLineIndoors(payload.deviceId, payload.lineId);
  }),
  mapLines: thunk(async (actions, payload) => {
    const data = { serviceLine: payload.serviceLine, controlLine: payload.controlLine };
    await DeviceSdk.mapLines(payload.deviceId, data);
  }),
  getDeviceNameBySerial: computed((state) => (serial) => {
    const noName = "-";

    if (!serial) {
      return noName;
    }
    const device = Object.values(state.allDevices).filter((device) => device.serial === serial)[0];
    if (!device) {
      return noName;
    }

    return device.name ?? noName;
  }),
  getDeviceName: computed((state) => (id) => {
    const noName = "-";

    if (!id) {
      return noName;
    }

    if (!state.allDevices[id]) {
      return noName;
    }

    return state.allDevices[id].name ? state.allDevices[id].name : noName;
  }),

  getDeviceSystems: computed([(state, storeState) => storeState.systems.allSystems], (allSystems) =>
    memo((deviceId) => {
      return Object.values(allSystems).filter((system) => system.device === deviceId);
    }, 100)
  ),

  // Returns an array of device's units
  // deviceId is required, optional filters can be applied:
  // 'systemId' and/or 'indoor/outdoor/service'
  getDeviceUnits: computed([(state, storeState) => storeState.units.allUnits], (allUnits) =>
    memo((deviceId, systemId = "all", type = "all") => {
      return Object.values(allUnits).filter(
        (unit) =>
          unit.device === deviceId &&
          (systemId === "all" ||
            (systemId === "assigned" && !_.isNil(unit.system)) ||
            (systemId === "unassigned" && _.isNil(unit.system)) ||
            (systemId === "disconnected" && (!_.has(unit, "isConnected") || !unit.isConnected)) ||
            unit.system === systemId) &&
          ((type === "all" && ((unit.type === 1) || (unit.type === 2) || (unit.type === 3) || (unit.type === 4) || (unit.type === 5))) ||
            (type === "indoor" && unit.type === 1) ||
            (type === "outdoor" && unit.type === 2) ||
            (type === "service" && unit.type === 3) ||
            (type === "bsBox" && unit.type === 4) ||
            (type === "other" && unit.type === 5))
      );
    }, 100)
  ),
  getVisibleDeviceUnits: computed([(state, storeState) => storeState.units.allUnits], (allUnits) =>
    memo((deviceId, systemId = "all", type = "all") => {
      return Object.values(allUnits).filter(
        (unit) => unit.isVisible &&
          unit.device === deviceId &&
          (systemId === "all" ||
            (systemId === "assigned" && !_.isNil(unit.system)) ||
            (systemId === "unassigned" && _.isNil(unit.system)) ||
            (systemId === "disconnected" && (!_.has(unit, "isConnected") || !unit.isConnected)) ||
            unit.system === systemId) &&
          ((type === "all" && ((unit.type === 1) || (unit.type === 2) || (unit.type === 3) || (unit.type === 4) || (unit.type === 5))) ||
            (type === "indoor" && unit.type === 1) ||
            (type === "outdoor" && unit.type === 2) ||
            (type === "service" && unit.type === 3) ||
            (type === "bsBox" && unit.type === 4) ||
            (type === "other" && unit.type === 5))
      );
    }, 100)
  ),
  getDeviceBySerial: computed((state) => (serial) => {
    const foundDevices = _.filter(
      _.values(state.allDevices),
      (device: IDevice) => device.serial === serial
    );

    if (foundDevices.length > 0) {
      if (foundDevices.length > 1) {
        return null;
      }

      return foundDevices[0];
    }

    return null;
  }),
  getDevicesBySite: computed((state) => (siteId) => {
    const foundDevices = _.filter(
      _.values(state.allDevices),
      (device: IDevice) => device.site === siteId
    );

    return foundDevices;
  }),
  getDeviceById: computed((state) => (id) => {
    const device = state.allDevices[id];

    return device ? device : null;
  }),

  refreshSystems: thunk(async (actions, payload) => {
    await DeviceSdk.refreshSystems(payload.deviceId);
  })
};
