import {
    Checkbox,
    Dialog,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import useStyles from "./TrapsList.style";

const ExportTraps = (props: any) => {
    const classes = useStyles();
    const { traps, close, getBrandName } = props;
    const [selectedTrap, setSelectedTrap] = useState<any>();

    // comments are for multiple traps export and selecting them using checkbox

    const rowCount = traps?.length;
    const [selected, setSelected] = useState<any>([]);
    const numSelected = selected.length;
    const [selectAll, setSelectAll] = useState<boolean>(numSelected === 0);

    const onSelectAllClick = () => {
        if (selected?.length === rowCount) {
            setSelected([]);
        }
        else {
            setSelected(traps.map((t: any) => t.id));
        }

    };

    const onSelectTrapClick = (id: any) => {
        const index = selected.indexOf(id);
        const selectedTemp = [...selected];
        if (index > -1) {
            selectedTemp.splice(index, 1);
            setSelected(selectedTemp);
        } else {
            setSelected([...selectedTemp, id]);
        }
        // if (rowCount === numSelected) {
        //     setSelectAll(true);
        //     setSelected([]);
        //   } else {
        //     setSelectAll(false);
        // }
    };
    // const selectTrap = (selectedTrap: any) => {
    //     const specificTrap = traps.filter((trap: any) => trap === selectedTrap)[0];
    //     setSelectedTrap(specificTrap);
    // };
    const removeApplyTo = (key: any, value: any) => {
        if (key === "systemIds" || key === "unitIds") {
            value = [];
        }
        return value;
    };
    const exportSelected = () => {
        const exportedTraps = traps.reduce((filtered: any, trap: any) => {
            if (selected.indexOf(trap.id) > -1) {
                const { id, customer, createdAt, updatedAt, ...rest } = trap;
                filtered.push(rest);
            }
            return filtered;
        }, []);

        // traps.filter((item: any) => selected.indexOf(item.id) > -1);

        const dataStr = JSON.stringify(exportedTraps, removeApplyTo);
        // const dataStr = JSON.stringify(selectedTrap, removeApplyTo);
        const dataUri = "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

        const exportFileDefaultName = `export-anomaly-${new Date()}.json`;

        const linkElement = document.createElement("a");
        linkElement.setAttribute("href", dataUri);
        linkElement.setAttribute("download", exportFileDefaultName);
        linkElement.click();
        close();
    };

    const allSelected = selected?.length === rowCount;

    return (
        <div>
            <Dialog
                disableEnforceFocus
                fullScreen={true}
                classes={{ paper: classes.dialogPaper }}
                aria-labelledby="simple-dialog-title"
                open={true}
            >
                <div className={classes.dialogHeader}>
                    <Typography className={classes.headerTitle}>{t`Export Anomaly Rules`}</Typography>
                    <IconButton disableRipple onClick={close} className={classes.iconBtnStyle}><Close color="#7f7692" /></IconButton>
                </div>
                <Paper elevation={0} className={classes.paperTableContainer}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" hidden>
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >
                                        {t`Rule Name`}
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`BRAND`}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { traps.length && <TableRow
                                hover
                                tabIndex={-1}
                                role="checkbox"
                            >
                                <TableCell padding="checkbox" hidden>
                                <Checkbox
                                    color="default"
                                    checked={allSelected}
                                    onChange={onSelectAllClick}
                                    inputProps={{ "aria-labelledby": `toggle all` }}
                                />
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                    align="left"
                                >
                                    {t`All`}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                    align="left"
                                >
                                    {""}
                                </TableCell>
                            </TableRow>}
                                {traps.map((trap: any) => {
                                    const { id, name, userSelections: { brand = -99 } = {} } = trap;
                                    const isSelected = selected.indexOf(id) > -1;
                                    const brandName = getBrandName(brand);
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={id}
                                            role="checkbox"
                                        // onClick={() => onSelectTrapClick(trap)}
                                        // className={clsx({[classes.selectedTrap]: isSelected})}
                                        >
                                            <TableCell padding="checkbox" hidden>
                                                <Checkbox
                                                    color="default"
                                                    onChange={() => onSelectTrapClick(id)}
                                                    checked={isSelected}
                                                    inputProps={{ "aria-labelledby": name }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                align="left"
                                            >
                                                {name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                align="left"
                                            >
                                                {brandName}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <div className={classes.actionsContainer}>
                    <Button onClick={close} onMouseDown={(event: any) => event.preventDefault()} marginRight white uppercase width={150}> {t`cancel`}</Button>
                    <Button onClick={() => exportSelected()} uppercase width={150} disabled={!selected?.length}> {t`export`}</Button>
                </div>
            </Dialog>
        </div>
    );
};

export default ExportTraps;
