import { css } from "@emotion/core";
import { Button as MUIButton, Collapse, IconButton, Popover, TextField, Typography } from "@material-ui/core";
import { AttachFile, Visibility } from "@material-ui/icons";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close, Excel, Pdf, Word } from "../../icons";
import { downloadAs } from "../../services/download";
import NoteBox from "./Note";
import styles from "./NotesAndAttStyles";

const override = css`animation-duration: 2s;`;
const allowedExt: any = { doc: true, docx: true, pdf: true, xls: true, xlsx: true, PDF: true, png: true, jpg: true, jpeg: true };
const maxFileSizeAllowed = 5000000; //5MB

const NotesAndAttachments = (props: any) => {

    const [newNote, setNewNote] = useState<boolean>(false);
    const [newNoteMsg, setMsg] = useState<String>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorExt, setErrorExt] = useState<string>("");

    useEffect(() => {
        if (!newNote) {
            setMsg("");
        }
    }, [newNote]);

    const {
        anchorEl,
        setAnchorEl,
        unitId,
        systemId,
        unitNotes,
        setUnitNotes,
        createNewNote,
        deleteNote,
        allUsers,
        unitFiles,
        selectedFile,
        setSelectedFile,
        uploadFile,
        updateFilesInfo,
        addFileLocally,
        siteId
    } = props;

    const clearNote = () => {
        setMsg("");
        setNewNote(false);
    };

    const addNote = (type: "unit" | "system") => {
        setNewNote(false);
        createNewNote({ type, id: type === "unit" ? unitId : systemId, message: newNoteMsg })
            .then((newNote: any) => {
                setUnitNotes([...unitNotes, newNote]);
                clearNote();
            });
    };

    const handleDeleteNote = (id: string) => {
        return deleteNote(id)
            .then(() => {
                const updatedNotes = unitNotes.filter((unit: any) => unit._id !== id);
                setUnitNotes(updatedNotes);
            });
    };

    const uploadNewFile = (event: any) => {
        const files = event?.target?.files;

        if (!files?.length) {
            return;
        }

        const file = files[0];
        const ext: any = _.last(file?.name.split(".")) || "";
        if (!allowedExt[ext]) {
            setErrorExt(ext + " file type is not allowed");
            setTimeout(() => setErrorExt(""), 3000);
            return;
        }

        if (file?.size > maxFileSizeAllowed) {
            setErrorExt("files that larger than 5MB are not allowed");
            setTimeout(() => setErrorExt(""), 3000);
            return;
        }

        setIsLoading(true);

        let formData = new FormData();
        formData.append("file", file);
        uploadFile(formData)
            .then((fileId: string) => {
                return updateFilesInfo({ siteId, data: { fileId, units: [unitId], name: file.name } });
            })
            .then((file: any) => {
                addFileLocally(file);
            })
            .finally(() => setIsLoading(false));
    };

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? "simple-popover" : undefined;

    const closeDialog = () => {
        setAnchorEl(null);
    };

    const classes = styles();
    return (
        <Popover
            id={id}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={closeDialog}
            classes={{ paper: classes.paper }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
        >
            <div className={classes.dialogHeader}>
                <Typography className={classes.headerTitle}>{t`Notes`}</Typography>
                <IconButton disableRipple className={classes.iconBtnStyle} onClick={closeDialog}>
                    <Close color="#7f7692" />
                </IconButton>
            </div>
            <div className={classes.container}>
                <div className={classes.notesSection}>
                    <div style={{
                        boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
                        border: "solid 1px #d5d2d5",
                        backgroundColor: "#f6f6f7",
                        // maxHeight: "90px",
                        borderRadius: 8,
                        marginBottom: 20,
                        display: "flex",
                        flexFlow: "column nowrap",
                        padding: newNote ? "10px 16px" : "0 16px"
                    }}>
                        <TextField
                            id="new-note-unit"
                            placeholder={newNote ? "" : t`Add a new note`}
                            onClick={() => newNote ? {} : setNewNote(true)}
                            variant="outlined"
                            multiline
                            rows={newNote ? 3 : 1}
                            onChange={(e) => setMsg(e.target.value)}
                            classes={{ root: classes.textField }}
                            value={newNoteMsg}
                        />
                        <Collapse in={newNote} timeout="auto" unmountOnExit
                            classes={{ wrapper: classes.newNoteColl, wrapperInner: classes.newNoteColl }}>
                            <div className={classes.saveBtns}>
                                <Button style={{ fontWeight: 400 }} white width={63} className={classes.btn} onClick={clearNote}>{t`Clear`}</Button>
                                <Button
                                    width={95}
                                    style={{ fontWeight: 300 }}
                                    className={classes.btn}
                                    disabled={!newNoteMsg}
                                    onClick={() => addNote("unit")}
                                >
                                    {t`Save on unit`}
                                </Button>

                                <Button
                                    width={115}
                                    style={{ fontWeight: 300 }}
                                    className={classes.btn}
                                    disabled={!newNoteMsg}
                                    onClick={() => addNote("system")}
                                >
                                    {t`Save on System`}
                                </Button>

                            </div>
                        </Collapse>
                    </div>

                    {unitNotes && unitNotes.slice(0).reverse().map((note: any, index: any) => {
                        return (
                            <NoteBox
                                key={`note-${index}`}
                                allUsers={allUsers}
                                note={note}
                                deleteNote={handleDeleteNote}
                            />
                        );
                    })}

                </div>

                <div className={classes.attachSection}>
                    <div className={classes.sectionHeader}>
                        <Typography className={clsx(classes.headerTitle, classes.title)}>{t`Attachments`}</Typography>
                        <>
                            <label htmlFor="file" style={{ cursor: "pointer", marginLeft: "18px" }} ><AttachFile /></label>
                            <input disabled={isLoading} type="file" hidden name="file" id="file" accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpg, .jpeg" onChange={uploadNewFile} />
                        </>
                        {isLoading && <div className={classes.loaderContainer}><MoonLoader css={override} size={20} color={"#7f7e7e"} loading={true} /></div>}
                        {errorExt && !isLoading && <Typography className={classes.errorText}>{errorExt}</Typography>}
                    </div>

                    <div className={classes.filesSection}>
                        {unitFiles && unitFiles.map((file: any, index: any) => {
                            const { url, name } = file;
                            const extStr: any = _.last(url?.split("."));
                            const ext: any = extStr && extStr.toLowerCase();
                            const isDoc = ext && ext.indexOf("doc") > -1;
                            const isExl = ext && ext.indexOf("xls") > -1;
                            const isPdf = ext && ext.indexOf("pdf") > -1;
                            const isPhoto = ext && (ext.indexOf("jpg") > -1 || ext.indexOf("jpeg") > -1 || ext.indexOf("png") > -1);

                            const FileIcon = () => isDoc ? <Word className={classes.fileIcon} />
                                : (isExl ? <Excel className={classes.fileIcon} /> :
                                    (isPdf ? <Pdf className={classes.fileIcon} /> :
                                        (isPhoto ? <ImageOutlinedIcon /> : <div className={classes.fileIcon} > </div>)));

                            return (
                                <div key={`file-${name}-${index}`} className={classes.fileHolder}>
                                    <FileIcon />
                                    <Typography className={classes.fileName}>{name}</Typography>
                                            <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => setSelectedFile({...file, isPdf, isPhoto})}>
                                                <Visibility />
                                            </IconButton>
                                    <MUIButton variant={"text"} className={classes.link} onMouseUp={() => downloadAs(url, name)}>{t`Download`}</MUIButton>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div >
        </Popover >
    );
};

export default NotesAndAttachments;
