import { CircularProgress, Grid } from "@material-ui/core/";
import clsx from "clsx";
import {
  SubscriptionPlan as SdkSubscribe
} from "coolremote-sdk";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RouteComponentProps } from "react-router-dom";
import { t } from "ttag";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import ModalWithSteps from "../../components/ModalWithSteps/ModalWithSteps";
import { MoreVert } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { IUnit } from "../../models/Units";
import { validatorForm } from "../../services/formService";
import ManuallySetSystems from "./Device/ManuallySetSystems";
import QueryDeviceStatus from "./Device/QueryDeviceStatus";
import RegisterDevice from "./Device/RegisterDevice";
import FoldersTableDialog from "./FilesTableDialog";
import Site from "./Site";
import useStyles from "./siteManagement.style";
import SiteView from "./SiteView";

const formValidatorDevice = validatorForm(
  {
    site: ["required"],
    serial: ["required", "string"],
    pin: ["required", "string"]
  },
  undefined,
  {
    site: t`Site`,
    serial: t`Device SN MAC`,
    pin: t`Device PIN`
  }
);

const SiteManagement: React.FC<RouteComponentProps<any> & any> = (props: any) => {
  const classes = useStyles();
  // const allDevices = useStoreState(state => state.devices.allDevices);
  // const allUnits = useStoreState(state => state.units.allUnits);
  // const allSystems = useStoreState(state => state.systems.allSystems);
  // const unassignUnitFromSystem = useStoreActions(action => action.unassignUnitFromSystem);
  const getSystems = useStoreActions((action) => action.systems.getSystems);
  const getUnits = useStoreActions((action) => action.units.getUnits);
  const getSite = useStoreState((state) => state.sites.getSite);
  const currentSites = useStoreState((state) => state.selections.getSitesIdsBySelection);
  const selections = useStoreState((state) => state.selections.selections);
  const addNewDeviceToSite = useStoreActions((action) => action.createDevice);
  const createSite = useStoreActions((action) => action.createSite);
  const allSites = useStoreState((state) => state.sites.allSites);
  const types = useStoreState((state) => state.types);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onStep, setOnStep] = useState(0);
  const [newDeviceId, setNewDeviceId] = useState("");
  const [newDeviceDbId, setNewDeviceDbId] = useState("");
  const [newDevicePin, setNewDevicePin] = useState("");
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isExecutingAddDevice, setIsExecutingAddDevice] = useState(false);
  const [sitesToRender, setSitesToRender] = useState<any>([]);
  const [isSynchronized, setIsSynchronized] = useState(false);
  const [openSiteFiles, setOpenSiteFiles] = useState<string>("");
  const { customerId, siteId } = selections;

  useEffect(() => {
    (async function() {
      props.unitUpdateStatusUpdate("sitemgmt");
      await getUnits();
      setIsSynchronized(true);
    })();
  }, []);

  useEffect(() => {
    if (siteId) {
      const selectedSite = getSite(siteId);
      setSitesToRender(selectedSite ? [siteId] : []);
      return;
    }

    if (_.isEmpty(currentSites)) {
      setSitesToRender([]);
      return;
    }

    const currentStored = JSON.parse(localStorage.getItem("sitesOrderPerCustomer") as string) || {};
    const customer: string = customerId || "";

    if (!currentStored[customer]) {
      setSitesToRender(currentSites);
      return;
    }
    const newOrder = new Set([...currentStored[customer], ...currentSites]);
    newOrder.forEach((siteId: string) => {
      if (!allSites[siteId]) {
        newOrder.delete(siteId);
      }
    });

    setSitesToRender(Array.from(newOrder));
  }, [currentSites, allSites]);

  const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
  const handleSave = async (site: any) => {
    const data = {
      name: site.name,
      country: site.country,
      city: site.city,
      state: site.state,
      address: site.address,
      postalCode: site.postalCode,
      timezone: site.timezone
    };
    await createSite({
      customerId: site.customer,
      data
    });
  };

  // const onSave = async (deviceId: string) => {
  //   // If User didn't chose a site - will use first site as default to save too
  //   // const selectedSite = rootStore.selections.site ? rootStore.selections.site: this.getAllSites()[0]
  //   // const device = allDevices[deviceId];
  //   const deviceUnits = Object.values(allUnits).filter(unit => unit.device === deviceId);
  //   await Promise.all(
  //     _.map(deviceUnits, async (unit: IUnit) => {
  //       if (!unit.system) {
  //         return Promise.resolve();
  //       }

  //       const system = allSystems[unit.system];
  //       const device = allDevices[system.device];
  //       const allDeviceSystems = device.systems;
  //       // Meaning this unit was added to this system
  //       if (!_.includes(system.units, unit.id)) {
  //         await Promise.all(
  //           _.map(allDeviceSystems, async oldSystemId => {
  //             const oldSystem = allSystems[oldSystemId];
  //             if (_.includes(oldSystem.units, unit.id) && unit.system !== oldSystem.id) {
  //               await unassignUnitFromSystem({
  //                 systemId: oldSystem.id,
  //                 unitId: unit.id
  //               });
  //               return Promise.resolve();
  //             }
  //             return Promise.resolve();
  //           })
  //         );
  //       }
  //       // don't update units that were part of the system
  //       if (allUnits[unit.id] && unit.system === system.id) {
  //         return Promise.resolve();
  //       }
  //       // Update systems with new unit details
  //       // system.onlySetUnit(unit.id, unit);
  //       // await system.onlyUpdateUnit(unit.id);
  //       return Promise.resolve();
  //     })
  //   );
  //   onCancel();
  // };

  //The right next action in the ModalWithSteps
  const next = () => {
    if (onStep === 0) {
      // Validate!
      const validate = formValidatorDevice({
        site: selectedSite,
        serial: newDeviceId,
        pin: newDevicePin
      });
      if (!validate.check()) {
        const errors = validate.errors.all();
        const allErrors = _(errors)
          .values()
          .flatten()
          .value();
        allErrors[0] && setError(allErrors);
        return;
      }

      setIsExecutingAddDevice(true);
      const selectedSiteId = selectedSite && selectedSite.id ? selectedSite.id : null;
      if (selectedSiteId) {
        addNewDeviceToSite({
          siteId: selectedSiteId,
          newDevicePin,
          newDeviceId
        })
          .then((data: any) => {
            setNewDeviceDbId(data.id);
            setOnStep(1);
            setIsNextDisabled(true);
            setIsExecutingAddDevice(false);
          })
          .catch((e: any) => {
            setError(e.message);
            setIsExecutingAddDevice(false);
          });
      }
    }
    if (onStep === 1) {
      setOnStep(2);
    }
    if (onStep === 2) {
      onCancel();
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setNewDeviceId("");
    setNewDeviceDbId("");
    setNewDevicePin("");
    setSelectedSite(undefined);
    setOnStep(0);
  };

  const _setSelectedSite = (site: ISite | null) => {
    setSelectedSite(site);
  };

  const openAddDevice = (id: string) => {
    const site = getSite(id);
    setIsModalOpen(true);
    setSelectedSite(site);
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder: any = reorder(
      sitesToRender,
      result.source.index,
      result.destination.index
    );

    setSitesToRender(newOrder);
    const currentStored = JSON.parse(localStorage.getItem("sitesOrderPerCustomer") as string) || {};
    const customer: string = customerId || "";

    if (!currentStored[customer]) {
      currentStored[customer] = {};
    }

    currentStored[customer] = newOrder;
    localStorage.setItem("sitesOrderPerCustomer", JSON.stringify(currentStored));
  };

  return (
    <div className={classes.view}>
      {errorBox}
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header path={["Site Management"]} hideSystemSelection hideUnitSelection />

        {isSynchronized ? (
          <Fragment>
            <div className={classes.headerButtons}>
              {/* <ExportExcel></ExportExcel> */}
              <Site save={handleSave}></Site>
            </div>
            <Grid container className={classes.sitesContainer}>
              <DragDropContext
                onDragEnd={onDragEnd}
              >
                <Droppable droppableId="droppable" type="sitesDropdown">
                  {(provided: any) => (
                    <div ref={provided.innerRef} className={classes.siteviewHolder}>
                      {sitesToRender.map(
                        (siteId: any, index: number) => {
                          const site = getSite(siteId);

                          return (
                            <Draggable
                              key={`site-${siteId}`}
                              draggableId={`site-${siteId}`}
                              index={index}
                            >
                              {(provided: any) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <Grid item xs={12} className={classes.siteViewContainer} key={siteId}>
                                    <div className={classes.dragContainer} {...provided.dragHandleProps} ><MoreVert className={classes.whiteMoreVert} /></div>
                                    <div className={classes.siteHolder}>
                                      <SiteView siteId={siteId} openAddDevice={openAddDevice} openFoldersDialog={setOpenSiteFiles} site={site}></SiteView>
                                    </div>
                                  </Grid>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Fragment>
        ) : (
            <Grid className={classes.progress}>
              <CircularProgress />
            </Grid>
          )}

        <ModalWithSteps
          closeModal={onCancel}
          showModal={isModalOpen}
          site={selectedSite}
          onStep={onStep}
          next={next}
          isNextDisabled={isNextDisabled}
        >
          <RegisterDevice
            className="registerDevice"
            site={selectedSite}
            next={() => next}
            setDeviceId={(id: string) => {
              setNewDeviceId(id);
            }}
            deviceId={newDeviceId}
            setDevicePin={(pin: string) => {
              setNewDevicePin(pin);
            }}
            devicePin={newDevicePin}
            selectSite={_setSelectedSite}
            isLoading={isExecutingAddDevice}
            setIsDisabled={(isDisabled: boolean) => setIsNextDisabled(isDisabled)}
          />

          <QueryDeviceStatus
            site={selectedSite}
            deviceId={newDeviceDbId}
            setStep={() => setOnStep(2)}
            setIsDisabled={(isDisabled: boolean) => setIsNextDisabled(isDisabled)}
          />

          <ManuallySetSystems
            mode={0}
            closeModal={onCancel}
            site={selectedSite}
            deviceId={newDeviceDbId}
          />
        </ModalWithSteps>
        {openSiteFiles && <FoldersTableDialog siteId={openSiteFiles} openFoldersDialog={setOpenSiteFiles} />}
      </div>
    </div>
  );
};
export default SiteManagement;
