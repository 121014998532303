import React from 'react';

function SvgSwitchOn(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 46 25" {...props}>
      <defs>
        <path id="switch-on_svg__a" d="M.8.769H25V24H.8z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4B1C46"
          fillOpacity={0.5}
          d="M7.787 21C3.487 21 0 17.642 0 13.5 0 9.358 3.486 6 7.787 6h22.425C34.514 6 38 9.358 38 13.5c0 4.142-3.486 7.5-7.788 7.5H7.787z"
        />
        <g transform="translate(19 1)">
          <mask id="switch-on_svg__b" fill="currentColor">
            <use xlinkHref="#switch-on_svg__a" />
          </mask>
          <path
            fill="#4B1C46"
            d="M25 12.384C25 18.8 19.583 24 12.9 24 6.218 24 .8 18.8.8 12.384.8 5.97 6.219.768 12.9.768c6.683 0 12.1 5.2 12.1 11.616"
            mask="url(#switch-on_svg__b)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSwitchOn;
